export default {
  fr: {
    //Questionnaire a remplir avant le premier investissement
    // lien https://app.viziocapital.com/invest 
    questionnaire: "QUESTIONNAIRE D'INVESTISSEMENT",
    stepOnelabel: "Étape 1/5: Votre situation",
    stepTwoLabel: "Étape 2/5: Connaissance des opérations et des instruments financiers ou immobiliers",
    stepThreelabel: "Étape 3/5: Revenus, patrimoine et capacité d’épargne ",
    stepFourlabel: "Étape 4/5: Intentions d'investissement sur Vizio",
    stepFivelabel: "Étape 5/5: Signature et envoi",
    moneySlices: [
      "Choisissez une reponse",
      "Inférieur à 50 K",
      "De 50 K à 100 K",
      "De 100 K à 300 K",
      "Supérieur à 300 K",
    ],
    yesOrNoChoices: [
      "Oui",
      "Non"
    ],
    trueOrFalseChoices: [
      "Choisissez une reponse",
      "Vrai",
      "Faux",
      "Je ne sais pas",
    ],
    stepOneInformation: "Avant d'investir avec Vizio Capital, nous sommes dans l'obligation légale de vous poser un certain nombre de questions. Cela va nous permettre également de vous dresser un profil d'investisseur ! Grâce à ce profil, nous pourrons vous conseiller au mieux, notamment dans la gestion de vos risques chez Vizio Capital.",
    questionOneLabel: "Quel âge avez vous ?",
    questionTwoLabel: "Quelle est votre situation matrimoniale ?",
    questionTwoChoices: [
      "Choisissez une reponse",
      "Marié(e)",
      "Cohabitant légal",
      "PACS",
      "Célibataire",
      "Divorcé(e)",
      "Veuf(ve)",
    ],
    questionThreeParLabel: "Quelle est votre situation familliale",
    questionThreeLabel: "Avez vous des enfants à charge ?",
    questionThreeSubLabel: "Nombre d'enfants",

    // questionFourLabel: "Votre secteur d'activité",
    // activityChoices: [
      // "Choisissez une reponse",
      // "Agriculteurs exploitants",
      // "Artisans, commerçants et chefs d’entreprise",
      // "Cadres et professions intellectuelles supérieures",
      // "Professions intermédiaires",
      // "Employés",
      // "Ouvriers",
      // "Retraités",
      // "Autres personnes sans activité professionnelle",
      // "Militaires",
      // "Elèves, étudiants",
      // "rentier",
    // ],
    questionFiveLabel: "Votre profession",
    professionChoices: [
      "Choisissez une reponse",
      "Agriculteurs exploitants",
      "Artisans, commerçants et chefs d’entreprise",
      "Cadres et professions intellectuelles supérieures",
      "Professions intermédiaires",
      "Employés",
      "Ouvriers",
      "Retraités",
      "Autres personnes sans activité professionnelle",
      "Militaires",
      "Elèves, étudiants",
      "Rentier",
    ],
    // questionSixLabel: "Secteur d'activité de votre conjoint(e)",
    // activityConjointChoices: [
    //   "Choisissez une reponse",
    //   "Agriculteurs exploitants",
    //   "Artisans, commerçants et chefs d’entreprise",
    //   "Cadres et professions intellectuelles supérieures",
    //   "Professions intermédiaires",
    //   "Employés",
    //   "Ouvriers",
    //   "Retraités",
    //   "Autres personnes sans activité professionnelle",
    // ],
    questionSevenLabvel: "Profession de votre conjoint(e)",
    questionEightLabel: "Quel est votre horizon prévisible de départ à la retraite ?",
    questionEightChoices: [
      "Choisissez une reponse",
      "De 1 à 5 ans",
      "Plus de 5 ans",
      "Je suis déja à la retraite",
    ],
    questionNineLabel: "Quel est votre plus haut diplôme obtenu ?",
    questionNineChoices: [
      "Choisissez une reponse",
      "CEB",
      "CE1D",
      "Brevet des collèges",
      "CE2D",
      "CAP/BEP",
      "CESS",
      "Baccalauréat",
      "BTS/DUT",
      "Bachelier",
      "DEUG licence ou licence professionnelle",
      "Master",
      "Doctorat",
      "MBA",
      "CQ",
    ],
    questionTenLabel: "Êtes-vous une personne politiquement exposée ? (Une personne exerçant ou ayant exercé une haute fonction publique, ou intimement associée à une telle personne)",
    questionTenSubLabel: "Précisez: ",
    questionElevenLabel: "Avez-vous déjà exercé une fonction dans le secteur financier ?",
    questionElevenSubLabel: "fonction exercée",
    questionTwelveLabel: "Avez-vous déjà réalisé des opérations sur des instruments financiers?",
    questionTwelveSubLabel: "Depuis combien de temps ?",
    questionThirteenLabel: "Dans quels produits d’épargne avez-vous déjà investi à votre initiative ou à l’issue d’un conseil ?",
    questionThirteenChoices: [
      "Action cotées",
      "Obligations",
      "Titres non cotés",
      "Titres non cotés via un fond d’investissement (ex : FCPR, FCPI, FIP)",
      "Parts de société civiles immobilières",
      "Produits dérivés et complexes (options, certificats, warrants)",
      "OPCVM (SICAV, FCP,...)",
      "Cryptomonnaie",
      "Investissement autre (noter si autre)",
      "Aucun de ces produits"
    ],
    questionFourteenLabel: "Avez-vous déjà réalisé des opérations sur des produits immobiliers ?",
    questionFourteenSubLabel: "Depuis combien de temps ?",
    questionFifteenLabel: "Sur quels produits immobiliers avez-vous déjà réalisé des opérations ? ",
    questionFifteenChoices: [
      "Investissements immobiliers locatifs individuels",
      "Fonds d’investissement immobilier, SCPI, OPCI,...",
      "Achat de biens immobiliers en vue de leur revente",
    ],
    questionSixteenLabel: "Une action ou une part sociale correspond à :",
    questionSixteenChoices: [
      "Choisissez une reponse",
      "Une part du capital d’une entreprise",
      "Une part d’emprunt émis par une entreprise",
      "Je ne sais pas",
    ],
    questionSeventeenLabel: "La valeur d’une action ou d’une part sociale n’est pas garantie, il est donc possible de perdre tout ou une partie du capital investi ?",

    questionEighteenLabel: "Une obligation correspond à :",
    questionEighteenChoices: [
      "Choisissez une reponse",
      "Une part du capital d’une entreprise",
      "Une part d’emprunt émis par une entreprise",
      "Je ne sais pas",
    ],
    questionNineteenLabel: "La valeur d’un fond immobilier, d’une société immobilière, est soumise aux fluctuations des marchés immobiliers, il est donc possible de perdre tout ou une partie du capital investi ?",

    questionTwentyLabel: "Le risque de liquidité est le risque de ne pas pouvoir vendre son actif à tout moment ou de le vendre avec une forte décote ?",
    questionTwentyOneLabel: "Une société qui a recours à l’effet de levier présente un profil de risque plus élevé pour l’investisseur ?",

    questionTwentyTwoLabel: "Quelles sont vos sources de revenus professionnels ? ",
    questionTwentyTwoChoices: [
      "Salaire, revenus professionnel ou pension ",
      "Revenus financiers ",
      "Revenus immobiliers ",
      "Autres",
    ],

    questionTwentyThreeLabel: "Quel est le montant annuel des revenus de votre foyer ?",


    questionTwentyFourLabel: "Quelle est votre capacité d'épargne annuelle ?",

    questionTwentyFiveLabel: "Quelle est la nature de vos charges financières reccurentes?",
    questionTwentyFiveChoices: [
      "Loyer",
      "Pensions à verser",
      "Frais de scolarité",
      "Impôt",
      "Remboursements de crédits",
      "Autres"
    ],
    questionTwentySixLabel: "Quelle est l’origine de votre patrimoine ? (Valeur en pourcentage estimé approximativement)",
    questionTwentySixChoices: [
      "Revenus professionnels",
      "Héritage et donation",
      "Revenus immobiliers",
      "Autres"
    ],
    questionTwentySevenLabel: "Votre patrimoine est composé de : ",
    questionTwentySevenChoices: [
      "Liquidités.",
      "Contrats d’assurance-vie ou de capitalisation.",
      "Résidence principale.",
      "Investissements immobiliers locatifs.",
      "Investissements dans les fonds d’investissements immobiliers (SCPI, OPCI).",
      "Instruments financiers détenus en direct sur un PEA ou compte-titres.",
      "Capital investissement / private equity ( en direct ou via des fonds d’investissement de type ...).",
      "Investissements dans des fonds d’investissements hors immobilier ()."
    ],
    questionTwentyEightLabel: "Avez vous un projet financier ou immobilier en cours ou envisagé à court terme (ex: acquisition de votre residence principale)",
    questionTwentyEightSublabel: "Précisez: ",
    questionTwentyNineLabel: "Quelle somme approximative envisagez-vous de placer dans l’année à venir sur la plateforme VIZIO CAPITAL ?",
    questionTwentyNineChoices: [
      "Choisissez une réponse",
      "500€ à 2500€",
      "2500€ à 5000€",
      "5000€ à 10 000€",
      "10 000€ à 25 000€",
      "25 000€ à 50 000€ ",
      "50 000€ à 100 000€",
      "100 000€ +"
    ],
    questionThirtyLabel: "Quel pourcentage de votre épargne représenterait cette somme ?",
    questionThirtyChoices: [
      "Choisissez une réponse",
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "plus de 50%",
    ],
    questionThirtyOneLabel: "Quel est la durée envisagée de vos placement sur la platefdorme VIZIO CAPITAL",
    questionThirtyOneChoices: [
      "Choisissez une réponse",
      "Moins de 12 mois",
      "Entre 12 mois et 24 mois",
      "Entre 2 et 5 ans",
      "Supérieure de 5 ans"
    ],
    questionThirtyTwoLabel: "Quel est l’objectif de votre placement sur la plateforme VIZIO CAPITAL ?",
    questionThirtyTwoChoices: [
      "Choisissez une réponse",
      "Diversifier votre épargne",
      "Percevoir des revenus supplémentaires",
      "Augmenter votre épargne à court terme",
      "Augmenter votre épargne à long terme"
    ],
    questionThirtyThreeLabel: "Êtes-vous susceptible d’avoir besoin des sommes investies sur la plateforme Vizo Capital pendant la durée de votre placement ?",
    questionThirtyThreeChoices: [
      "Choisissez une réponse",
      "Oui",
      "Non"
    ],
    questionThirtyFourLabel: "Si le marché financier s'éffondre",
    questionThirtyFourChoices: [
      "Choisissez une réponse",
      "Vous ne dormez plus",
      "Vous suivez l'évolution",
      "Vous profitez pour prendre des vacances "
    ],
    questionThirtyFiveLabel: "Envisageriez-vous d’investir dans un produit d’investissement non diversifié, par exemple un produit dont les revenus et perspectives dépendent exclusivement d’un seul actif immobilier ?",
    questionThirtySixLabel: "Envisageriez-vous d’investir dans des produits diversifiés qui investissent dans plusieurs  actifs immobiliers ?",
    questionThirtySevenLabel: "Envisageriez-vous de placer votre épargne dans des sociétés ne versant pas de revenu pendant la durée de votre placement ?",
    questionThirtyEightLabel: "Envisageriez vous d’investir dans des produits d’investissement vous exposant à un risque de perte partielle ou totale du capital investi ?",
    questionNewOneLabel: "Avez-vous des contacts qui pourraient être intéressés par notre projet  ?",
    questionNewTwoLabel: "Seriez-vous intéressé de nous les recommander afin d’obtenir des avantages financiers et personnels ?",
    questionNewThreeLabel: "Seriez-vous intéressé pour devenir commercial chez Vizio ?",
    questionNewFourLabel: "Connaissez-vous des personnes qui pourraient être intéressées pour devenir commercial chez Vizio ?",
    questionThirtyNineLabel: "J’ai conscience que le placement en société immobilière peut présenter un risque moyen ou élevé",
    questionFortyLabel: "J'ai noté :",
    questionFortyChoices: [
      "L’absence de garantie sur le capital investi.",
      "Une liquidité réduite en comparaison avec celle des actifs financiers.",
      "Une fluctuation possible du dividende à la hausse comme à la baisse."
    ],
    questionFortyOneLabel: "Quel est le montant que vous souhaiteriez investir en société immobilière ?",
    questionFortyOneLabelChoices: [
      "Choisissez une réponse",
      "Complément de revenu",
      "Diversification du patrimoine",
    ],
    questionFortyTwoLabel: [
      "VIZIO CAPITAL ne fournit pas de conseil indépendant.",

      "VIZIO GROUP SRL est susceptible de percevoir 5% des montants investis pour créer une protection interne et 5% pour développer d’autres projets.",

      "VIZIO REAL ESTATE et VIZIO CAPITALISATION sont susceptibles de donner une commission aux apporteurs d’affaires pour le marketing d'affiliation.",

      "VIZIO REAL ESTATE et VIZIO CAPITALISATION sont susceptibles de percevoir un pourcentage des bénéfices des sociétés filiales qui développent l’immobilier (achat-vente / location /rénovation) pour le marketing, les investisseurs et le réinvestissement.",

      "VIZIO GROUP est susceptible de percevoir un pourcentage des bénéfices des sociétés filiales qui développent l’immobilier (achat-vente / location /rénovation) pour les investisseurs du groupe VIZIO GROUP SRL.",

      "Les données à caractère personnel collectées dans le présent formulaire sont traitées par VIZIO GROUP SRL, VIZIO REAL ESTATE & VIZIO CAPITALISATION dans le but d'évaluer votre profil investisseur et de nous permettre de vous proposer des produits financiers adaptés à vous.",

      "Les données à caractère personnel collectées pourront être communiquées par VIZIO CAPITAL aux autorités compétentes à leur demande.",

      "Conformément à la loi, vous disposez d'un droit d'opposition au traitement des données à caractère personnel vous concernant en justifiant d'un motif légitime, ainsi que d'un droit d'accès, de modification et de suppression des données personnelles vous concernant, que vous pouvez exercer en nous écrivant à l'adresse suivante: <a href='mailto:infos@viziocapital.com'>infos@viziocapital.com.</a>",
    ],
    questionFortyThreeLabel: [
      "J'ai conscience du risque d'illiquidité de VIZIO REAL ESTATE et VIZIO CAPITALISATION.",
      "En cas d'investissement, j'ai conscience du risque de perte partielle ou totale du capital.",
      "J'ai pris connaissances des <a href='https://viziocapital.com/cgv/'>CGU</a>, de <a href ='https://viziocapital.com/cgv/'>la politique de confidentialité</a>, des <a href='https://viziocapital.com/notes-dinformations/'>notes d’informations</a>, des <a href='https://viziocapital.com/document-dinformation-synthetique/'>documents d’informations synthétiques</a> et <a href='https://viziocapital.com/disclaimer/'>le disclaimer.</a>",
      "Je certifie l’exactitude des informations communiquées dans ce questionnaire et je m’engage à signaler toute modification des éléments qui y figurent."
    ],
    btnPrec:"Précédent",
    btnNext:"Continuer",
    btnSubm:"Envoyer le formulaire",

    //profile d'investisseur
    // lien https://app.viziocapital.com/invest-profile
    votreProfile: "VOTRE PROFIL D'INVESTISSEMENT",

    profileDef: "Défensif",
    profileDefContent: [
      "Vous êtes plutôt défensif ! Nous pensons qu’il est mieux pour vous de ne prendre que le minimum de risque dans le cadre de vos investissements.",
      "Investir dans le secteur de l'immobilier dans les solutions proposées par VIZIO CAPITALISATION et VIZIO REAL ESTATE est le plus adapté pour ceux qui souhaitent investir de manière sereine et sur le long terme. ",
      "Vos fonds seront utilisés pour investir dans des projets d’achats-reventes, de constructions ou de location dont la rentabilité sera optimalisée grâce à des effets leviers bancaires.",
      "Si vous souhaitez avoir plus d’informations ou comprendre mieux comment changer votre profil ou le faire évoluer, contactez-nous par mail à : <a href='mailto:profils@viziocapital.com'>profils@viziocapital.com.</a>"
    ],
    profileMod: "Modéré",
    profileModContent: [
      "Vous êtes plutôt modéré ! Nous pensons qu’il est mieux pour vous de prendre des risques moyens dans le cadre de vos investissements..",
      "Et si vous obteniez le meilleur de la rentabilité en immobilier ? VIZIO CAPITALISATION vous propose de capitaliser sur du long terme.",
      "Les bénéfices et les plus-values vont être réinvestis chaque année. Cela nous permettra d’obtenir plus de crédits à la banque, de réaliser plus de projets et donc d’obtenir plus de rentabilité.",
      "Si vous souhaitez avoir plus d’informations ou comprendre mieux comment changer votre profil ou le faire évoluer, contactez-nous par mail à : <a href='mailto:profils@viziocapital.com'>profils@viziocapital.com.</a>"
    ],
    profileAgr: "Agressif",
    profileAgrContent: [
      "Vous êtes plutôt Agressif ! Nous pensons que vous pouvez gérer vos risques par vous même dans le cadre de vos investissements.",
      "Plus de risques est souvent lié à plus de rentabilité. Nous vous proposons de diversifier votre patrimoine de la manière suivante : investir en immobilier et réinvestir vos bénéfices dans des secteurs à hautes rentabilités comme la finance, les nouvelles technologies et les start-up.",
      "Si vous souhaitez avoir plus d’informations ou comprendre mieux comment changer votre profil ou le faire évoluer, contactez-nous par mail à : <a href='mailto:profils@viziocapital.com'>profils@viziocapital.com.</a>"

    ],
    labelBtnInvestir:"Investir",
    nosReconmendations: "Nos recommandations",
    message: [
      "*Vous devez équilibrer vos propres risques.",
      "*Vous ne devez pas utiliser l’argent que vous ne pouvez pas vous permettre de perdre.",
      "*N'investissez pas dans ce que vous ne comprenez pas parfaitement.",
      "Demandez une présentation gratuite pour accéder aux détails des projets et profitez des fonctionnalités de la plateforme pour choisir librement vos investissements.",
      "Demandez plus d'informations quant aux risques à : <a href='mailto:aziz.creano.paris'>risques@viziocapital.com</a>"
    ],
    champObligatoire: "ce champs est obligatoire",
    goToDisc: "Voir le disclaimer"
  },
  en: {
    //Questionnaire a remplir avant le premier investissement
    // lien https://app.viziocapital.com/invest 
    questionnaire: "QUESTIONNAIRE D'INVESTISSEMENT",
    stepOnelabel: "Étape 1/5: Votre situation",
    stepTwoLabel: "Étape 2/5: Connaissance des opérations et des instruments financiers ou immobiliers",
    stepThreelabel: "Étape 3/5: Revenus, patrimoine et capacité d’épargne ",
    stepFourlabel: "Étape 4/5: Intentions d'investissement sur Vizio",
    stepFivelabel: "Étape 5/5: Signature et envoi",
    moneySlices: [
      "Choisissez une reponse",
      "Inférieur à 50 K",
      "De 50 K à 100 K",
      "De 100 K à 300 K",
      "Supérieur à 300 K",
    ],
    yesOrNoChoices: [
      "Oui",
      "Non"
    ],
    trueOrFalseChoices: [
      "Choisissez une reponse",
      "Vrai",
      "Faux",
      "Je ne sais pas",
    ],
    stepOneInformation: "Avant d'investir avec Vizio Capital, nous sommes dans l'obligation légale de vous poser un certain nombre de questions. Cela va nous permettre également de vous dresser un profil d'investisseur ! Grâce à ce profil, nous pourrons vous conseiller au mieux, notamment dans la gestion de vos risques chez Vizio Capital.",
    questionOneLabel: "Quel âge avez vous ?",
    questionTwoLabel: "Quelle est votre situation matrimoniale ?",
    questionTwoChoices: [
      "Choisissez une reponse",
      "Marié(e)",
      "Cohabitant légal",
      "PACS",
      "Célibataire",
      "Divorcé(e)",
      "Veuf(ve)",
    ],
    questionThreeParLabel: "Quelle est votre situation familliale",
    questionThreeLabel: "Avez vous des enfants à charge ?",
    questionThreeSubLabel: "Nombre d'enfants",

    // questionFourLabel: "Votre secteur d'activité",
    // activityChoices: [
      // "Choisissez une reponse",
      // "Agriculteurs exploitants",
      // "Artisans, commerçants et chefs d’entreprise",
      // "Cadres et professions intellectuelles supérieures",
      // "Professions intermédiaires",
      // "Employés",
      // "Ouvriers",
      // "Retraités",
      // "Autres personnes sans activité professionnelle",
      // "Militaires",
      // "Elèves, étudiants",
      // "rentier",
    // ],
    questionFiveLabel: "Votre profession",
    professionChoices: [
      "Choisissez une reponse",
      "Agriculteurs exploitants",
      "Artisans, commerçants et chefs d’entreprise",
      "Cadres et professions intellectuelles supérieures",
      "Professions intermédiaires",
      "Employés",
      "Ouvriers",
      "Retraités",
      "Autres personnes sans activité professionnelle",
      "Militaires",
      "Elèves, étudiants",
      "Rentier",
    ],
    // questionSixLabel: "Secteur d'activité de votre conjoint(e)",
    // activityConjointChoices: [
    //   "Choisissez une reponse",
    //   "Agriculteurs exploitants",
    //   "Artisans, commerçants et chefs d’entreprise",
    //   "Cadres et professions intellectuelles supérieures",
    //   "Professions intermédiaires",
    //   "Employés",
    //   "Ouvriers",
    //   "Retraités",
    //   "Autres personnes sans activité professionnelle",
    // ],
    questionSevenLabvel: "Profession de votre conjoint(e)",
    questionEightLabel: "Quel est votre horizon prévisible de départ à la retraite ?",
    questionEightChoices: [
      "Choisissez une reponse",
      "De 1 à 5 ans",
      "Plus de 5 ans",
      "Je suis déja à la retraite",
    ],
    questionNineLabel: "Quel est votre plus haut diplôme obtenu ?",
    questionNineChoices: [
      "Choisissez une reponse",
      "CEB",
      "CE1D",
      "Brevet des collèges",
      "CE2D",
      "CAP/BEP",
      "CESS",
      "Baccalauréat",
      "BTS/DUT",
      "Bachelier",
      "DEUG licence ou licence professionnelle",
      "Master",
      "Doctorat",
      "MBA",
      "CQ",
    ],
    questionTenLabel: "Êtes-vous une personne politiquement exposée ? (Une personne exerçant ou ayant exercé une haute fonction publique, ou intimement associée à une telle personne)",
    questionTenSubLabel: "Précisez: ",
    questionElevenLabel: "Avez-vous déjà exercé une fonction dans le secteur financier ?",
    questionElevenSubLabel: "fonction exercée",
    questionTwelveLabel: "Avez-vous déjà réalisé des opérations sur des instruments financiers?",
    questionTwelveSubLabel: "Depuis combien de temps ?",
    questionThirteenLabel: "Dans quels produits d’épargne avez-vous déjà investi à votre initiative ou à l’issue d’un conseil ?",
    questionThirteenChoices: [
      "Action cotées",
      "Obligations",
      "Titres non cotés",
      "Titres non cotés via un fond d’investissement (ex : FCPR, FCPI, FIP)",
      "Parts de société civiles immobilières",
      "Produits dérivés et complexes (options, certificats, warrants)",
      "OPCVM (SICAV, FCP,...)",
      "Cryptomonnaie",
      "Investissement autre (noter si autre)",
      "Aucun de ces produits"
    ],
    questionFourteenLabel: "Avez-vous déjà réalisé des opérations sur des produits immobiliers ?",
    questionFourteenSubLabel: "Depuis combien de temps ?",
    questionFifteenLabel: "Sur quels produits immobiliers avez-vous déjà réalisé des opérations ? ",
    questionFifteenChoices: [
      "Investissements immobiliers locatifs individuels",
      "Fonds d’investissement immobilier, SCPI, OPCI,...",
      "Achat de biens immobiliers en vue de leur revente",
    ],
    questionSixteenLabel: "Une action ou une part sociale correspond à :",
    questionSixteenChoices: [
      "Choisissez une reponse",
      "Une part du capital d’une entreprise",
      "Une part d’emprunt émis par une entreprise",
      "Je ne sais pas",
    ],
    questionSeventeenLabel: "La valeur d’une action ou d’une part sociale n’est pas garantie, il est donc possible de perdre tout ou une partie du capital investi ?",

    questionEighteenLabel: "Une obligation correspond à :",
    questionEighteenChoices: [
      "Choisissez une reponse",
      "Une part du capital d’une entreprise",
      "Une part d’emprunt émis par une entreprise",
      "Je ne sais pas",
    ],
    questionNineteenLabel: "La valeur d’un fond immobilier, d’une société immobilière, est soumise aux fluctuations des marchés immobiliers, il est donc possible de perdre tout ou une partie du capital investi ?",

    questionTwentyLabel: "Le risque de liquidité est le risque de ne pas pouvoir vendre son actif à tout moment ou de le vendre avec une forte décote ?",
    questionTwentyOneLabel: "Une société qui a recours à l’effet de levier présente un profil de risque plus élevé pour l’investisseur ?",

    questionTwentyTwoLabel: "Quelles sont vos sources de revenus professionnels ? ",
    questionTwentyTwoChoices: [
      "Salaire, revenus professionnel ou pension ",
      "Revenus financiers ",
      "Revenus immobiliers ",
      "Autres",
    ],

    questionTwentyThreeLabel: "Quel est le montant annuel des revenus de votre foyer ?",


    questionTwentyFourLabel: "Quelle est votre capacité d'épargne annuelle ?",

    questionTwentyFiveLabel: "Quelle est la nature de vos charges financières reccurentes?",
    questionTwentyFiveChoices: [
      "Loyer",
      "Pensions à verser",
      "Frais de scolarité",
      "Impôt",
      "Remboursements de crédits",
      "Autres"
    ],
    questionTwentySixLabel: "Quelle est l’origine de votre patrimoine ? (Valeur en pourcentage estimé approximativement)",
    questionTwentySixChoices: [
      "Revenus professionnels",
      "Héritage et donation",
      "Revenus immobiliers",
      "Autres"
    ],
    questionTwentySevenLabel: "Votre patrimoine est composé de : ",
    questionTwentySevenChoices: [
      "Liquidités.",
      "Contrats d’assurance-vie ou de capitalisation.",
      "Résidence principale.",
      "Investissements immobiliers locatifs.",
      "Investissements dans les fonds d’investissements immobiliers (SCPI, OPCI).",
      "Instruments financiers détenus en direct sur un PEA ou compte-titres.",
      "Capital investissement / private equity ( en direct ou via des fonds d’investissement de type ...).",
      "Investissements dans des fonds d’investissements hors immobilier ()."
    ],
    questionTwentyEightLabel: "Avez vous un projet financier ou immobilier en cours ou envisagé à court terme (ex: acquisition de votre residence principale)",
    questionTwentyEightSublabel: "Précisez: ",
    questionTwentyNineLabel: "Quelle somme approximative envisagez-vous de placer dans l’année à venir sur la plateforme VIZIO CAPITAL ?",
    questionTwentyNineChoices: [
      "Choisissez une réponse",
      "500€ à 2500€",
      "2500€ à 5000€",
      "5000€ à 10 000€",
      "10 000€ à 25 000€",
      "25 000€ à 50 000€ ",
      "50 000€ à 100 000€",
      "100 000€ +"
    ],
    questionThirtyLabel: "Quel pourcentage de votre épargne représenterait cette somme ?",
    questionThirtyChoices: [
      "Choisissez une réponse",
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "plus de 50%",
    ],
    questionThirtyOneLabel: "Quel est la durée envisagée de vos placement sur la platefdorme VIZIO CAPITAL",
    questionThirtyOneChoices: [
      "Choisissez une réponse",
      "Moins de 12 mois",
      "Entre 12 mois et 24 mois",
      "Entre 2 et 5 ans",
      "Supérieure de 5 ans"
    ],
    questionThirtyTwoLabel: "Quel est l’objectif de votre placement sur la plateforme VIZIO CAPITAL ?",
    questionThirtyTwoChoices: [
      "Choisissez une réponse",
      "Diversifier votre épargne",
      "Percevoir des revenus supplémentaires",
      "Augmenter votre épargne à court terme",
      "Augmenter votre épargne à long terme"
    ],
    questionThirtyThreeLabel: "Êtes-vous susceptible d’avoir besoin des sommes investies sur la plateforme Vizo Capital pendant la durée de votre placement ?",
    questionThirtyThreeChoices: [
      "Choisissez une réponse",
      "Oui",
      "Non"
    ],
    questionThirtyFourLabel: "Si le marché financier s'éffondre",
    questionThirtyFourChoices: [
      "Choisissez une réponse",
      "Vous ne dormez plus",
      "Vous suivez l'évolution",
      "Vous profitez pour prendre des vacances "
    ],
    questionThirtyFiveLabel: "Envisageriez-vous d’investir dans un produit d’investissement non diversifié, par exemple un produit dont les revenus et perspectives dépendent exclusivement d’un seul actif immobilier ?",
    questionThirtySixLabel: "Envisageriez-vous d’investir dans des produits diversifiés qui investissent dans plusieurs  actifs immobiliers ?",
    questionThirtySevenLabel: "Envisageriez-vous de placer votre épargne dans des sociétés ne versant pas de revenu pendant la durée de votre placement ?",
    questionThirtyEightLabel: "Envisageriez vous d’investir dans des produits d’investissement vous exposant à un risque de perte partielle ou totale du capital investi ?",
    questionNewOneLabel: "Avez-vous des contacts qui pourraient être intéressés par notre projet  ?",
    questionNewTwoLabel: "Seriez-vous intéressé de nous les recommander afin d’obtenir des avantages financiers et personnels ?",
    questionNewThreeLabel: "Seriez-vous intéressé pour devenir commercial chez Vizio ?",
    questionNewFourLabel: "Connaissez-vous des personnes qui pourraient être intéressées pour devenir commercial chez Vizio ?",
    questionThirtyNineLabel: "J’ai conscience que le placement en société immobilière peut présenter un risque moyen ou élevé",
    questionFortyLabel: "J'ai noté :",
    questionFortyChoices: [
      "L’absence de garantie sur le capital investi.",
      "Une liquidité réduite en comparaison avec celle des actifs financiers.",
      "Une fluctuation possible du dividende à la hausse comme à la baisse."
    ],
    questionFortyOneLabel: "Quel est le montant que vous souhaiteriez investir en société immobilière ?",
    questionFortyOneLabelChoices: [
      "Choisissez une réponse",
      "Complément de revenu",
      "Diversification du patrimoine",
    ],
    questionFortyTwoLabel: [
      "VIZIO CAPITAL ne fournit pas de conseil indépendant.",

      "VIZIO GROUP SRL est susceptible de percevoir 5% des montants investis pour créer une protection interne et 5% pour développer d’autres projets.",

      "VIZIO REAL ESTATE et VIZIO CAPITALISATION sont susceptibles de donner une commission aux apporteurs d’affaires pour le marketing d'affiliation.",

      "VIZIO REAL ESTATE et VIZIO CAPITALISATION sont susceptibles de percevoir un pourcentage des bénéfices des sociétés filiales qui développent l’immobilier (achat-vente / location /rénovation) pour le marketing, les investisseurs et le réinvestissement.",

      "VIZIO GROUP est susceptible de percevoir un pourcentage des bénéfices des sociétés filiales qui développent l’immobilier (achat-vente / location /rénovation) pour les investisseurs du groupe VIZIO GROUP SRL.",

      "Les données à caractère personnel collectées dans le présent formulaire sont traitées par VIZIO GROUP SRL, VIZIO REAL ESTATE & VIZIO CAPITALISATION dans le but d'évaluer votre profil investisseur et de nous permettre de vous proposer des produits financiers adaptés à vous.",

      "Les données à caractère personnel collectées pourront être communiquées par VIZIO CAPITAL aux autorités compétentes à leur demande.",

      "Conformément à la loi, vous disposez d'un droit d'opposition au traitement des données à caractère personnel vous concernant en justifiant d'un motif légitime, ainsi que d'un droit d'accès, de modification et de suppression des données personnelles vous concernant, que vous pouvez exercer en nous écrivant à l'adresse suivante: <a href='mailto:infos@viziocapital.com'>infos@viziocapital.com.</a>",
    ],
    questionFortyThreeLabel: [
      "J'ai conscience du risque d'illiquidité de VIZIO REAL ESTATE et VIZIO CAPITALISATION.",
      "En cas d'investissement, j'ai conscience du risque de perte partielle ou totale du capital.",
      "J'ai pris connaissances des <a href='https://viziocapital.com/cgv/'>CGU</a>, de <a href ='https://viziocapital.com/cgv/'>la politique de confidentialité</a>, des <a href='https://viziocapital.com/notes-dinformations/'>notes d’informations</a>, des <a href='https://viziocapital.com/document-dinformation-synthetique/'>documents d’informations synthétiques</a> et <a href='https://viziocapital.com/disclaimer/'>le disclaimer.</a>",
      "Je certifie l’exactitude des informations communiquées dans ce questionnaire et je m’engage à signaler toute modification des éléments qui y figurent."
    ],
    btnPrec:"Précédent",
    btnNext:"Continuer",
    btnSubm:"Envoyer le formulaire",

    //profile d'investisseur
    // lien https://app.viziocapital.com/invest-profile
    votreProfile: "VOTRE PROFIL D'INVESTISSEMENT",

    profileDef: "Défensif",
    profileDefContent: [
      "Vous êtes plutôt défensif ! Nous pensons qu’il est mieux pour vous de ne prendre que le minimum de risque dans le cadre de vos investissements.",
      "Investir dans le secteur de l'immobilier dans les solutions proposées par VIZIO CAPITALISATION et VIZIO REAL ESTATE est le plus adapté pour ceux qui souhaitent investir de manière sereine et sur le long terme. ",
      "Vos fonds seront utilisés pour investir dans des projets d’achats-reventes, de constructions ou de location dont la rentabilité sera optimalisée grâce à des effets leviers bancaires.",
      "Si vous souhaitez avoir plus d’informations ou comprendre mieux comment changer votre profil ou le faire évoluer, contactez-nous par mail à : <a href='mailto:profils@viziocapital.com'>profils@viziocapital.com.</a>"
    ],
    profileMod: "Modéré",
    profileModContent: [
      "Vous êtes plutôt modéré ! Nous pensons qu’il est mieux pour vous de prendre des risques moyens dans le cadre de vos investissements..",
      "Et si vous obteniez le meilleur de la rentabilité en immobilier ? VIZIO CAPITALISATION vous propose de capitaliser sur du long terme.",
      "Les bénéfices et les plus-values vont être réinvestis chaque année. Cela nous permettra d’obtenir plus de crédits à la banque, de réaliser plus de projets et donc d’obtenir plus de rentabilité.",
      "Si vous souhaitez avoir plus d’informations ou comprendre mieux comment changer votre profil ou le faire évoluer, contactez-nous par mail à : <a href='mailto:profils@viziocapital.com'>profils@viziocapital.com.</a>"
    ],
    profileAgr: "Agressif",
    profileAgrContent: [
      "Vous êtes plutôt Agressif ! Nous pensons que vous pouvez gérer vos risques par vous même dans le cadre de vos investissements.",
      "Plus de risques est souvent lié à plus de rentabilité. Nous vous proposons de diversifier votre patrimoine de la manière suivante : investir en immobilier et réinvestir vos bénéfices dans des secteurs à hautes rentabilités comme la finance, les nouvelles technologies et les start-up.",
      "Si vous souhaitez avoir plus d’informations ou comprendre mieux comment changer votre profil ou le faire évoluer, contactez-nous par mail à : <a href='mailto:profils@viziocapital.com'>profils@viziocapital.com.</a>"

    ],
    labelBtnInvestir:"Investir",
    nosReconmendations: "Nos recommandations",
    message: [
      "*Vous devez équilibrer vos propres risques.",
      "*Vous ne devez pas utiliser l’argent que vous ne pouvez pas vous permettre de perdre.",
      "*N'investissez pas dans ce que vous ne comprenez pas parfaitement.",
      "Demandez une présentation gratuite pour accéder aux détails des projets et profitez des fonctionnalités de la plateforme pour choisir librement vos investissements.",
      "Demandez plus d'informations quant aux risques à : <a href='mailto:risques@viziocapital.com'>risques@viziocapital.com</a>"
    ],
    champObligatoire: "ce champs est obligatoire",
    goToDisc: "Voir le disclaimer"
  },

 
}