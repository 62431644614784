import { useSelector } from 'react-redux';

import InputText from '../inputText';
import InputSelect from '../inputSelect';
import InputDate from '../inputDate';
import FormRow from '../formRow';
import FormRowBirthday from '../formRowBirthday';

import FormSectionTitle from '../formSectionTitle';

import regexes from '../../constants/regex';
import { editRegex } from '../../helpers/global';
import countries from '../../constants/countries';
import codes from '../../constants/codes';

const FormRegisterInformationsPersonal = ({texts, handleInputChange, handleUnrequiredInputChange}) => {

  const registerForm = useSelector(state => state.notPersisedReducer.registerForm);
  const {
    // day, month, year,
    lastName, firstName, pseudo,
    nativeCountry, gender, telNum,
    postalAddress, postalCode,
    city, residenceCountry,codeTelCountry,
    date
  } = registerForm;
  // console.log("normal regex",regexes.default);

// console.log("regex personaliser", editRegex(regexes.default));
  const genders = [
    {
      id:"Homme",
      label:"Homme"
    },
    {
      id:"Femme",
      label:"Femme"
    },
    {
      id:"Autre",
      label:"Autre"}
    
  ]

  const getMaxDate = () => {
    const date = new Date();
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
    // console.log(date.getMonth())
    const year = parseInt(date.getFullYear() - 18);
    return `${year}-${month}-${day}`;
  }
  // console.log(getMaxDate());

  return <>

    <FormSectionTitle title={texts.titlePersonalInformations} />

    <FormRow classes={"-two"}> 
      <InputSelect
        label={texts.labelGenre}
        value={gender.value}
        error={gender.error}
        inputs={genders}
        handleSelectChange={(event) => handleInputChange({
          event,
          regex: regexes.default,
          name: "gender"
        })}
      />

      <InputText
        label={texts.labelLastName}
        value={lastName.value}
        error={lastName.error}
        indication = {texts.indicationLastName}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorLastName,
          name: "lastName"
        })}

      />
    </FormRow>

    <FormRow classes={"-two"}>

      <InputText
        label={texts.labelFirstName}
        value={firstName.value}
        error={firstName.error}
        indication = {texts.indicationFirstName}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorFirstName,
          name: "firstName"
        })}
      />

      <InputText
        label={texts.labelPseudo}
        value={pseudo.value}
        error={pseudo.error}
        indication = {texts.indicationPseudo}
        required = {false}
        handleInputChange={(event) => handleUnrequiredInputChange({
          event,
          regex: regexes.pseudo,
          errorText: texts.errorPseudo,
          name: "pseudo"
        })}

      />
    </FormRow>


    <FormRow classes={"-two"}>
      <InputDate
        label={texts.labelDate}
        value={date.value}
        error={date.error}
        min="1950-01-01"
        max={getMaxDate()}
        handleDateChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,50),
          errorText: texts.errorNativeCountry,
          name: "date"
        })}
      />
      <InputSelect
        label={texts.labelNativeCountry}
        value={nativeCountry.value}
        error={nativeCountry.error}
        inputs={countries.map((i)=>(
          {
            id:i.label,
            label:i.label,
          }
        ))}
        handleSelectChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorNativeCountry,
          name: "nativeCountry"
        })}
      />
     
    </FormRow>

    <FormRow classes={"-two"}>
    {/* <InputSelect
        label={texts.labelResidenceCountry}
        value={residenceCountry.value}
        error={residenceCountry.error}
        inputs={countries.map((i)=>(
          {
            id:i.label,
            label:i.label,
          }))}
        handleSelectChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorResidenceCountry,
          name: "residenceCountry"
        })}
      /> */}
       <InputSelect
        label={texts.codeTelCountry}
        value={codeTelCountry.value}
        error={codeTelCountry.error}
        inputs={codes.map((i,key)=>({
          key:key,
          id:`+${i.code}`,
          label:`+${i.code} - (${i.label})`
        }))}
        handleSelectChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorNativeCountry,
          name: "codeTelCountry"
        })}
        />
      <InputText
          label={texts.labelPhoneNumber}
          value={telNum.value}
          error={telNum.error}
          type="number"
          max={15}
          handleInputChange={(event) => handleInputChange({
            event,
            regex: regexes.phone,
            errorText: texts.errorPhoneNumber,
            name: "telNum",
          })}
        />
      
     
    </FormRow>

    <FormRow>
      <InputText
        label={texts.labelPostalAddress}
        value={postalAddress.value}
        error={postalAddress.error}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorPostalAddress,
          name: "postalAddress",
        })}
      />
    </FormRow>
    
    <FormRow classes={"-two"}>
    <InputText
        label={texts.labelPostalCode}
        value={postalCode.value}
        error={postalCode.error}
        max={15}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.postalCode,1,50),
          errorText: texts.errorPostalCode,
          name: "postalCode",
        })}
      />

      <InputText
        label={texts.labelCity}
        value={city.value}
        error={city.error}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorCity,
          name: "city",
        })}
      />
      
    </FormRow>

    <FormRow classes={"-two"}>

    <InputSelect
        label={texts.labelResidenceCountry}
        value={residenceCountry.value}
        error={residenceCountry.error}
        inputs={countries.map((i)=>(
          {
            id:i.label,
            label:i.label,
          }))}
        handleSelectChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorResidenceCountry,
          name: "residenceCountry"
        })}
      />

      </FormRow>


  </>
}


export default FormRegisterInformationsPersonal;
