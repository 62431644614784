import { useSelector } from 'react-redux';

export default ({steps, current}) => {

  const {lang} = useSelector(state => state.persisedReducer.parameters);

  return (
    <ul className="stapper-head">
    {
      steps.map((step) =>
        <li className={ parseInt(step.id) <= parseInt(current) ? "-actif" : "" } key={step.id}>
          <span className="cyrcle">{step.id}</span>
          <span className="label">{step.label[lang]}</span>
        </li>
      )
    }
    </ul>
  )
}
