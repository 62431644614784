import IconArrowDropdown from "./icons/icon-dropdown";
const InputSelect = (props) => {

  const {
    classes,
    value,
    label,
    indication,
    handleSelectChange,
    error,
    inputs,
    readonly,
  } = props;

  const required = props.required === false ? false : true;

  return (
    <label className={`input ${classes ? classes : ""} ${error ? "-error" : "" }`}>
      { required ? <span className="-required">{label}</span> : <span>{label}</span> }
      <div className="-select-wrapper">
        <div className="-icon">
          <IconArrowDropdown />
        </div>
        <select value={value} onChange={handleSelectChange} readOnly={readonly}>
          {
            inputs.map(input => <option key={input.key?input.key:input.id} value={input.id}>{input.label}</option>)
          }
        </select>
      </div>
      {indication && <p className={indication ? "-indication" : "" }>{indication}</p>}
      {error && <p className={error ? "-error" : ""}>{error}</p>}
    </label>
  )
}


export default InputSelect;