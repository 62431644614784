import { useNavigate, useSearchParams } from 'react-router-dom';

export const withRouter = (Component) => {
  
  const Wrapper = (props) => {
    const [searchParams, setSearchParams] = useSearchParams({});
    return (
      <Component
        searchParams={searchParams}
        {...props}
      />
    );
  };

  return Wrapper;
  
};