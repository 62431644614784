import React, { Children } from "react";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import AuthForm from "../components/authentification/authForm";
import DoubleAuthForm from "../components/authentification/doubleAuthForm";
import ResetPasswordForm from "../components/authentification/resetPasswordForm";

import { withRouter } from "../components/withRouter";
import {editRegex }from "../helpers/global";
import Infos from "../components/infos";

import { setUser, setToken, switchLang } from "../redux/store";


import regexes from "../constants/regex";
import urls from "../constants/urls";
import keys from "../constants/keys";
import authTexts from "../texts/authentification";

import {
  SESSION_EXPIRED,
  MAIL_NOT_VALIDATED,
  NOT_CONNECTED,
  VALIDATION_MAIL_NOT_WORKING
} from "../constants/redirections";

import logo from "../assets/img/logo/logo-authentication.svg";
import illustration from "../assets/img/illu-template-login.svg";
import TextWithBold from "../components/textWithBold";

class AuthView extends React.Component {

  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      isLoading: false,
      token: false,
      user: false,
      hasGoogleAuth: false,
      forgotPwd: false,
      redirect: false,

      texts: authTexts[this.props.parameters.lang],

      vizioMail: "mailto:modifications@viziocapital.com?subject=forget_code",

      feedback: {
        status: false,
        message: "",
        class: "",
      },

      email: {
        value: "",
        error: false,
      },

      password: {
        value: "",
        error: false,
      },

      code: {
        value: "",
        error: false,
      },
    };
  }

  componentDidMount = () => {

    const redirection = this.props.searchParams.get("redirection");
    const email = this.props.searchParams.get("email");

   
    // this.recaptchaRef.current.reset();

    if ( redirection ) {

      const texts = this.state.texts
      let message = "";

      if ( redirection === SESSION_EXPIRED ) message = texts.sessionEnd;
      else if ( redirection === MAIL_NOT_VALIDATED ) message = texts.emailInvalide;
      else if ( redirection === NOT_CONNECTED ) message = texts.notConnected;
      // else if ( redirection === VALIDATION_MAIL_NOT_WORKING ) message = texts.failedMailValidation;
      else message = texts.defaultText;

      this.setState({
        feedback: {
          status: true,
          message,
          class: "-info"
        }
      })

    }

    if ( email && email.length > 0 ) {
      this.setState({
        email: {
          value: email,
          error: false,
        }
      })
    }
    
  };

  // Auth Partie
  handleEmailChange = (event) => {
    const value = event.target.value;
    this.setState({
      email: {
        error: regexes.email.test(value) ? false : this.state.texts.mailError,
        value,
      },
    });
  };

  handlePasswordChange = (event) => {
    const value = event.target.value;
    this.setState({
      password: {
        error: editRegex(regexes.password,5,200).test(value)
          ? false
          : this.state.texts.passwordError,
        value,
      },
    });
  };

  handleAuthFormSubmit = (event) => {

    event.preventDefault();

    if (this.props.isLoading) return;

    const { email, password } = this.state;
    let error = false;
    
    if (!regexes.email.test(email.value)) {
      this.setState({
        email: {
          ...email,
          error: this.state.texts.mailError,
        },
      });
      error = true;
    }

    if (!editRegex(regexes.password,5,200).test(password.value)) {
      this.setState({
        password: {
          ...password,
          error: this.state.texts.passwordError,
        },
      });
      error = true;
    }

    if (!error) {

      const recaptchaToken = this.recaptchaRef.current.execute();

      if (!recaptchaToken) this.setState({
        feedback: {
          status: true,
          message: this.texts.errorRecaptcha,
          class: "-error",
        },
      })
      else {

        this.setState({
          isLoading: true,
          feedback: {
            ...this.state.feedback,
            status: false,
            message: "",
          },
        })
  
        axios
        .post(`${urls.apiUrl}/auth/`, {
          email: email.value,
          password: password.value,
        })
        .then((res) => {
          const user = res.data;
          const token = res.headers["x-auth-token"];
          if (user.has_google_auth) {
            this.setState({
              user,
              token,
              hasGoogleAuth: res.data.has_google_auth,
            });
          } else {
            this.props.setUser(user);
            this.props.setToken(token);
            this.setState({ redirect: true });
          }
        })
        .catch((err) => {
          this.setState({
            feedback: {
              ...this.state.feedback,
              status: true,
              message: err.response.data,
            },
          });
        })
        .finally(() => {
          this.setState({ isLoading: false })
        });

      }
    }

  };

  // Double Auth Partie
  handleCodeChange = (event) => {
    const value = event.target.value.replace(/\s/g, "");

    if (
      value.length > 6 ||
      (!Number.isInteger(parseInt(event.nativeEvent.data)) &&
        event.nativeEvent.data !== null)
    )
      return;

    this.setState({
      code: {
        error: !regexes.code2af.test(value)
          ? this.state.texts.codeError
          : false,
        value: (value.slice(0, 3) + " " + value.slice(3, value.length)).trim(),
      },
    });
  };

  handleCodeAuthSubmit = (event) => {
    event.preventDefault();

    if (this.state.isLoading) return;

    const { code, token } = this.state;
    const value = code.value.replace(/\s/g, "");

    if (!regexes.code2af.test(value)) {
      this.setState({
        code: {
          ...code,
          error: this.state.texts.codeError,
        },
      });
    } else if (!token) {
      this.setState({ hasGoogleAuth: false });
    } else {

      const recaptchaToken = this.recaptchaRef.current.execute();

      if (!recaptchaToken) this.setState({
        feedback: {
          status: true,
          message: this.texts.errorRecaptcha,
          class: "-error",
        },
      })
      else {

        this.setState({
          isLoading: true,
          feedback: {
            ...this.state.feedback,
            status: false,
            message: "",
          },
        });
  
        axios
        .post(`${urls.apiUrl}/auth/2fa`, {
          code: value,
          token,
        })
        .then((res) => {
          const user = res.data;
          const token = res.headers["x-auth-token"];
          this.props.setUser(user);
          this.props.setToken(token);
          this.setState({ redirect: true });
        })
        .catch((err) => {
          if (err.response) {
            this.setState({
              feedback: {
                ...this.state.feedback,
                status: true,
                message: err.response.data,
              },
            });
          }
        })
        .finally(() => this.setState({ isLoading: false }));

      }
    }
  };

  // Forgot Password Partie
  handleForgotPwd = () => this.setState({ forgotPwd: true });

  handleForgotPasswordChangeSubmit = (event) => {

    event.preventDefault();

    if (this.state.isLoading) return;

    const { email } = this.state;

    if (!regexes.email.test(email.value)) {
      this.setState({
        email: {
          ...email,
          error: this.state.texts.mailError,
        },
      });
    } else {

      const recaptchaToken = this.recaptchaRef.current.execute();

      if (!recaptchaToken) this.setState({
        feedback: {
          status: true,
          message: this.texts.errorRecaptcha,
          class: "-error",
        },
      })
      else {

        this.setState({
          isLoading: true,
          feedback: {
            ...this.state.feedback,
            status: false,
            message: "",
          },
        });
  
        axios
        .put(`${urls.apiUrl}/resetPassword/email/${email.value}`)
        .then((res) => {
          this.setState({
            feedback: {
              status: true,
              message: res.data,
              class: "-succes",
            },
          });
        })
        .catch((err) => {
          if (err.response) {
            this.setState({
              feedback: {
                status: true,
                message: err.response.data,
              },
            });
          }
        })
        .finally(() => this.setState({ isLoading: false }));

      }
    }
  };

  render() {
    const texts = this.state.texts;
    const { user, token } = this.props.user;
    const { lang, langues } = this.props.parameters;
    
    const custHistory = this.props.searchParams.get("history")

    if (user && token) {
    if(custHistory === null)return <Navigate to="/dashboard" replace={true} />;
    return <Navigate to={custHistory} replace={true} />
    }

    return (
      <>
        {this.state.redirect && <Navigate to="/sumsub" replace={true} />}

        <section className="login-view-container">
          <div className="-infos">
            <div className="-logo">
              <img src={logo} alt="logo vizio" />
              <p>{texts.slogan}</p>
            </div>
            <div className="-title">
              <h1>{texts.welcome}</h1>
            </div>
            <div className="-register">
              <p>{texts.registrationText}</p>
              <Link to="/registration">{texts.registrationLinkText}</Link>
              <TextWithBold
              classe= {"-smallText"}
              content ={texts.registrationDifficultiesText}
              />
              <p className="-subtext"><Link to = "//viziocapital.com/investir/" target={"_blank"}>{texts.registrationLinkSubtext}</Link></p>
            </div>
            <img
              className="-illustration"
              src={illustration}
              alt="Illustration building"
            />
          </div>

          <div className="-form">
            <div className="-logo">
              <img src={logo} alt="logo vizio" />
              <p>{texts.slogan}</p>
            </div>

            <div className="-sup-title">
              <h1>{texts.welcome}</h1>
            </div>

            {this.state.feedback.status && (
              <Infos
                message={this.state.feedback.message}
                classes={
                  this.state.feedback.class && this.state.feedback.class.length
                    ? this.state.feedback.class
                    : "-error"
                }
              />
            )}
            <select
                className="switch-lang"
                style={{
                  position: "absolute",
                  top: 20,
                  right: 20,
                }}
                onChange={(event) => {
                  this.props.switchLang(event.target.value);
                  this.setState({texts : authTexts[event.target.value]})
                }}
                value={lang}
              >
                {langues.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.label}
                  </option>
                ))}
              </select>

            {this.state.forgotPwd ? (
              <ResetPasswordForm
                isLoading={this.state.isLoading}
                texts={texts}
                email={this.state.email}
                handleForgotPasswordChangeSubmit={
                  this.handleForgotPasswordChangeSubmit
                }
                handleEmailChange={this.handleEmailChange}
              />
            ) : this.state.hasGoogleAuth ? (
              <DoubleAuthForm
                isLoading={this.state.isLoading}
                texts={texts}
                code={this.state.code ? this.state.code : ""}
                vizioMail={this.state.vizioMail}
                handleCodeChange={this.handleCodeChange}
                handleCodeAuthSubmit={this.handleCodeAuthSubmit}
              />
            ) : (
              <AuthForm
                isLoading={this.state.isLoading}
                texts={texts}
                email={this.state.email}
                password={this.state.password}
                handleEmailChange={this.handleEmailChange}
                handlePasswordChange={this.handlePasswordChange}
                handleAuthFormSubmit={this.handleAuthFormSubmit}
                handleForgotPwd={this.handleForgotPwd}
              />
            )}

            <div className="-register">
              <p>{texts.registrationText}</p>
              <Link to="/registration">{texts.registrationLinkText}</Link>
              <TextWithBold
              classe= {"-smallText"}
              content ={texts.registrationDifficultiesText}
              />
              <p className="-subtext"><Link to = "//viziocapital.com/investir/">{texts.registrationLinkSubtext}</Link></p>

            </div>
          </div>

          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            sitekey={keys.recaptcha}
          />

        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  parameters: state.persisedReducer.parameters,
  user: state.persisedReducer.user,
});

const actions = {
  setUser,
  setToken,
  switchLang,
};

export default connect(mapStateToProps, actions)(withRouter(AuthView));
