export default () => (
    <svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
        <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-14">
                <rect id="Mask-Copy-2" x="0" y="0" width="35" height="35"></rect>
                <g id="ic_Edit" transform="translate(5.125000, 5.250000)" fill="#FFC542" fillRule="nonzero" stroke="#FFC542">
                    <g id="noun-light-3725467" transform="translate(0.000000, 0.000000)">
                        <path d="M15.0926004,11.3967391 C14.9833123,11.3963337 14.8783706,11.3461625 14.8003328,11.2567488 C14.2812925,10.6445315 13.5734248,10.3017397 12.8359967,10.3050382 C12.6083984,10.3050382 12.423913,10.0897077 12.423913,9.82381543 C12.423913,9.55792321 12.6083984,9.3423913 12.8359967,9.3423913 C13.7920266,9.34044548 14.7092558,9.783599 15.384868,10.5738791 C15.5456237,10.7626569 15.5456237,11.067791 15.384868,11.2567638 C15.3068301,11.3461775 15.2018884,11.3963493 15.0926004,11.3967391 L15.0926004,11.3967391 Z" id="Path"></path>
                        <polygon id="Path" points="9.24456522 17.4619565 14.3804348 17.4619565 14.3804348 18.4891304 9.24456522 18.4891304"></polygon>
                        <path d="M12.3260362,22.5978261 C11.4896978,22.5970605 10.6880092,22.2737795 10.096994,21.6991802 C9.50592831,21.1244193 9.1739551,20.3451845 9.1739551,19.5326793 L9.1739551,18.7287979 C9.1739551,17.963185 8.75883727,17.1728764 7.90300507,16.3196038 C6.77231903,15.1874766 6.14720873,13.6675205 6.16334842,12.0901863 C6.17970095,10.5126561 6.83617167,9.00514579 7.99010895,7.89541124 C9.14404624,6.78567669 10.7022323,6.16304348 12.3260867,6.16304348 C13.9499411,6.16304348 15.5081271,6.78567669 16.6620644,7.89541124 C17.8160017,9.00514579 18.4724835,10.5126561 18.4888249,12.0901863 C18.5049802,13.6675205 17.8798543,15.1874766 16.7491683,16.3196038 C15.9017842,17.1728764 15.4809418,17.9660759 15.4809418,18.7287979 L15.4809418,19.5326793 C15.4803507,20.3453805 15.1475866,21.1245662 14.5561377,21.6991802 C13.9646685,22.2739412 13.1626269,22.5970421 12.3260867,22.5978261 L12.3260362,22.5978261 Z M12.3260362,7.23166333 C10.9916371,7.22994501 9.71065017,7.74062268 8.7617878,8.65225334 C7.81312718,9.56388401 7.27370854,10.8028785 7.26089766,12.0992511 C7.24828853,13.3954278 7.76329085,14.6441731 8.69379937,15.5733945 C9.75866574,16.6380008 10.2754384,17.6698266 10.2754384,18.7288469 L10.2754384,19.5327283 C10.2754384,20.2445401 10.6663215,20.9022077 11.3007121,21.2580402 C11.9351027,21.6138726 12.7168689,21.6138579 13.3512595,21.2580402 C13.9856501,20.9022224 14.3765332,20.2445401 14.3765332,19.5327283 L14.3765332,18.7288469 C14.3765332,17.6696306 14.8933058,16.6379518 15.9581722,15.5733945 C16.8849434,14.6437321 17.3967733,13.3965548 17.3828023,12.1024851 C17.3686171,10.8084154 16.8297658,9.57191988 15.8829209,8.6614162 C14.9362273,7.75110851 13.6580144,7.24024254 12.3259858,7.23985055 L12.3260362,7.23166333 Z" id="Shape"></path>
                        <path d="M3.5617927,20.5434783 C3.4326674,20.5434783 3.30909277,20.4915486 3.21879114,20.3994739 C3.03576527,20.207881 3.03576527,19.9063799 3.21879114,19.7147958 L5.246274,17.692574 C5.32721359,17.5651909 5.461366,17.4815967 5.61146052,17.4649815 C5.76138199,17.4483663 5.91078434,17.5008078 6.01737417,17.6072473 C6.12413704,17.7138591 6.17665326,17.8630501 6.16001454,18.0127594 C6.14337583,18.1624688 6.05949008,18.2965998 5.93209964,18.3772565 L3.90461678,20.3994784 C3.81449262,20.4918986 3.69074052,20.5438221 3.56178826,20.5434783 L3.5617927,20.5434783 Z" id="Path"></path>
                        <path d="M3.46201987,12.3132327 L0.411552738,12.3132327 C0.170708234,12.2600269 0,12.0522301 0,11.8125428 C0,11.5728556 0.170712958,11.3650587 0.411552738,11.3116695 L3.46201987,11.3116695 C3.61907409,11.2770959 3.78369707,11.3136401 3.90956439,11.4110921 C4.03543171,11.5085427 4.10869565,11.6563329 4.10869565,11.8125382 C4.10869565,11.9687436 4.03542699,12.1165338 3.90956439,12.2139844 C3.78369707,12.311435 3.61907881,12.3479802 3.46201987,12.3132281 L3.46201987,12.3132327 Z" id="Path"></path>
                        <path d="M5.66720645,6.16304348 C5.53171782,6.16176841 5.40190072,6.10857902 5.30486132,6.01422682 L3.16303857,3.88344492 C3.03157626,3.67706754 3.06160256,3.40730062 3.23517949,3.23444126 C3.40893454,3.0615819 3.68009929,3.03188758 3.88754603,3.16267222 L6.02936878,5.29345411 C6.1639434,5.44318007 6.20019432,5.6561137 6.12311307,5.84154932 C6.04584902,6.02698027 5.86861138,6.15193142 5.66720645,6.16304348 Z" id="Path"></path>
                        <path d="M11.8256219,4.10869565 C11.539758,4.10869565 11.3060619,3.87248463 11.298913,3.57607729 L11.298913,0.424792644 C11.3534793,0.176200023 11.5665897,0 11.8124059,0 C12.0582222,0 12.271516,0.1762049 12.326087,0.424792644 L12.326087,3.58714709 C12.3141451,3.86888085 12.0973573,4.09479744 11.8256219,4.10869565 Z" id="Path"></path>
                        <path d="M17.9577937,6.16304348 C17.7563887,6.15193236 17.5791556,6.02697567 17.5018868,5.84154946 C17.4248056,5.6561186 17.461057,5.44318506 17.5956312,5.29345451 L19.737455,3.1626736 C19.9449018,3.03188902 20.2160667,3.06157866 20.3898219,3.23444261 C20.5633942,3.40730189 20.5934252,3.67706868 20.4619565,3.88344597 L18.320139,6.01422689 C18.2230995,6.10857905 18.0932871,6.16177047 17.9577937,6.16304348 L17.9577937,6.16304348 Z" id="Path"></path>
                        <path d="M24.103165,12.3132327 L21.0528382,12.3132327 C20.8120047,12.2600269 20.6413043,12.0522301 20.6413043,11.8125428 C20.6413043,11.5728556 20.8120095,11.3650587 21.0528382,11.3116695 L24.103165,11.3116695 C24.260212,11.2770959 24.4248274,11.3136401 24.5506889,11.4110921 C24.6765505,11.5085427 24.75,11.6563329 24.75,11.8125382 C24.75,11.9687436 24.6765505,12.1165338 24.5506889,12.2139844 C24.4248274,12.311435 24.2602167,12.3479802 24.103165,12.3132281 L24.103165,12.3132327 Z" id="Path"></path>
                        <path d="M21.1882117,20.5434783 C21.0592639,20.5438227 20.9355118,20.4918986 20.8453832,20.3994784 L18.8179004,18.3772565 C18.6905099,18.2966042 18.6066242,18.1624688 18.5899855,18.0127594 C18.5733468,17.8630501 18.6258625,17.7138591 18.7326258,17.6072473 C18.8392157,17.5008082 18.988618,17.4483663 19.1385395,17.4649815 C19.288634,17.4815967 19.422782,17.5651909 19.503726,17.6925739 L21.5312089,19.7147958 C21.7142347,19.9063887 21.7142347,20.2078899 21.5312089,20.3994739 C21.4409072,20.4915486 21.3173326,20.5434783 21.1882073,20.5434783 L21.1882117,20.5434783 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)