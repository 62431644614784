export default {
  fr: {
    // Edit profile
    editProfileSuccesTitle: "Demande traitée avec succès.",
    editProfileSuccesMessage: "Informations mises à jour avec succès.",

    editProfileErrorTitle: "Une erreur s'est produite.",
    editProfileErrorMessage: "Une erreur s'est produite lors de la mise à jour de vos informations personnelles. Votre requête n'a pas pu aboutir, veuillez réessayer plus tard.",

  },
  en: {
    // Edit profile
    editProfileSuccesTitle: "The request has successfully processed.",
    editProfileSuccesMessage: "The data has been saved successfully.",

    editProfileErrorTitle: "An error occured.",
    editProfileErrorMessage: "An error occured please try again later.",

  }
}