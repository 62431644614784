export const  getPayloadObject = (name, value, error) => ({
  name,
  field: {
    value,
    error,
  }
})

export const checkInput = (value, regex, emptyErrorMessage, regexErrorMEssage) => {
  if (value.length === 0) return emptyErrorMessage;
  else if (!regex.test(value)) return regexErrorMEssage;
  else return false;
}
//si le champ n'est pas requis, nous ne pouvons pas display ce message d'erreur ... 
export const checkInputUnrequired = (value, regex, regexErrorMEssage) => {
  if(value.length === 0) return false
  if (!regex.test(value)) return regexErrorMEssage;
  else return false;
}

export const checkInput_contact = (value, regex) => {
  if (value.length === 0 || !regex.test(value)) return true
  else return false
}