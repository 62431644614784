import { combineReducers } from 'redux';
import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import registerFormSlice from "./registerForm";
import editeProfileFormSlice from "./editeProfileForm"
import parametersSlice from "./parameters";
import userSlice from "./user";

// Actions from register form slice
const {
  editField,
  incrementStep,
  decrementStep,
  addContact,
  deleteContact,
  editContact,
  setStepToSumsub,
} = registerFormSlice.actions;

// Action from parameters slice
const { switchLang, setFeedback, setRedirect } = parametersSlice.actions;

// Action from user slice
const {
  setUser,
  setToken,
  deconnexion
} = userSlice.actions;

// Action from edite profile slice
const {
  editFieldProfile,
  addContactProfile,
  editContactProfile,
  deleteContactProfile,
  prepopulateEditProfileForm
} = editeProfileFormSlice.actions;

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
}

// Persised Reducers
const persisedReducer = persistReducer(persistConfig, combineReducers({
  parameters: parametersSlice.reducer,
  user: userSlice.reducer,
}));

// Not Persised Reducers
const notPersisedReducer = combineReducers({
  registerForm: registerFormSlice.reducer,
  editeProfileForm: editeProfileFormSlice.reducer,
})

export {
  // Register actions
  editField,
  incrementStep,
  decrementStep,
  addContact,
  deleteContact,
  editContact,
  setStepToSumsub,

  // Parameters actions
  switchLang,
  setFeedback,
  setRedirect,

  // User actions
  setUser,
  setToken,
  deconnexion,

  // Edit profile actions
  editFieldProfile,
  addContactProfile,
  editContactProfile,
  deleteContactProfile,
  prepopulateEditProfileForm,
};



export const store = configureStore({
  reducer: {
    persisedReducer,
    notPersisedReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
