export default ()=>(
<svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1" >
    <g id="Sprint-2-DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-14">
            <path d="M9.00130356,0 C10.5366967,0 11.8026903,1.15343657 11.9800545,2.64107991 C14.4828447,3.77566404 16.2235258,6.2956642 16.2235258,9.22222222 L16.2235258,14.2398916 L17.894025,17.549391 C18.2297482,18.214507 17.7463468,19 17.0013036,19 L11.8302793,19.0009007 C11.4181887,20.1656226 10.3071858,21 9.00130356,21 C7.69542134,21 6.58441841,20.1656226 6.17232779,19.0009007 L1.00130356,19 C0.256260316,19 -0.227141061,18.214507 0.108582166,17.549391 L1.77908134,14.2398916 L1.77908134,9.22222222 C1.77908134,6.2956642 3.51976244,3.77566404 6.02226778,2.6410786 C6.19991684,1.15343657 7.46591043,0 9.00130356,0 Z M9.00130356,4 C6.11714987,4 3.77908134,6.33806853 3.77908134,9.22222222 L3.77908134,14.4779663 C3.77908134,14.6344953 3.74233612,14.7888385 3.67180273,14.9285754 L2.62623275,17 L15.3763744,17 L14.3308044,14.9285754 C14.260271,14.7888385 14.2235258,14.6344953 14.2235258,14.4779663 L14.2235258,9.22222222 C14.2235258,6.33806853 11.8854573,4 9.00130356,4 Z" id="Shape-Copy-8" fill="#92929d"></path>
            <g id="Oval-4" transform="translate(16.000000, 0.000000)" fill="#ff0000">
                <circle id="Oval" cx="3" cy="3" r="3"></circle>
            </g>
        </g>
    </g>
</svg>)