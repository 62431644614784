export default {
  fr: {
    labelDashboard:"Tableau de bord",
    labelContrat:"Mes contrats", 
    labelBenefice:"Mes Investissements",
    labelApportAffaire:"Apport d'affaires",
    labelBeneficeCompteInv:"Compte d'investissemnet",
    labelBeneficeCompteAA:"Compte d'apport d'affaires",
    labelBeneficeCompteOp:"Compte d'opération",
    labelTransaction:"Transactions financières",
    labelReseau:"Mes recommandations",
    labelNotifications:"Notifications",
    labelProjetsimmo:"Projets immobiliers",
    labelInvestir:"Investir",
    labelInvestProfile:"Profil d'investisseur",
    labelMarketing:"Marketing",
    labelDocuments:"Documents",
    labelEvenements:"Evenements",
    labelCommunaute:"Communauté",
    labelInvProfile:"Profil d'investisseur",
    labelDeconnexion:"Déconnexion",

    labelHelp:"AIDE",

    timerText: `$mins minute(s) et $sec seconde(s)`,
    labelTuto:"Tutoriels",
    labelFAQ:"FAQ",
    labelSettings:"Paramètres",
    

    deconnexionMessage: "Vous allez être déconecté dans : $time minute(s)",

    // Profile Sub nav
    labelInformations: "Informations",
    labelSecurity: "Sécurité",
    labelNetwork: "Réseaux",
    labelContracts: "Contrats",
  },

  en:{
    labelDashboard:"Dashboard",
    labelContrat:"My contracts", 
    labelBenefice:"My investments",
    labelBeneficeCompteInv:"Investment account",
    labelApportAffaire:"Apport d'affaire",
    labelBeneficeCompteAA:"Business contribution account",
    labelBeneficeCompteOp:"Operation account",
    labelTransaction:"Financial transactions",
    labelNotifications:"Notifications",
    labelReseau:"My network",
    labelProjetsimmo:"Real estate projects",
    labelInvestir:"Invest",
    labelInvestProfile:"Investor Profile",
    labelMarketing:"Marketing",
    labelDocuments:"Documents",
    labelEvenements:"Events",
    labelCommunaute:"Community",
    labelTuto:"Tutorials",
    labelFAQ:"FAQ",
    labelSettings:"Settings",
    labelInvProfile:"Investor ? profile",
    labelDeconnexion:"Deconnexion",

    labelHelp:"HELP",

    timerText: `$mins minute(s) and $sec second(s)`,
    deconnexionMessage: "Vous allez être déconecter dans : $time minute(s)",

    // Profile Sub nav
    labelInformations: "Informations",
    labelSecurity: "Security",
    labelNetwork: "Network",
    labelContracts: "Contracts",
  }
}