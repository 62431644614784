import axios from 'axios'
import Button from '../components/button'
import { useState } from 'react'
import {useSelector } from "react-redux";
import InfosBar from '../components/infosBar';
import { useEffect, } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import InputSelect from '../components/inputSelect';
import FormRowCheckbox from '../components/formRowCheckbox';
import SubNav from '../components/subnav';

const AALayout = ()=>{
  const { user, token } =  useSelector(state => state.persisedReducer.user);


  const links = [
    {
      id: 1,
      link: "/apport-affaire",
      label: "Apport d'affaires",
      isActive: false,
    },
    {
      id: 0,
      link: "/list-contact",
      label: "Liste de contacts",
      isActive: false,
    },
    
  ]

  return (
    <div className="layout-profile-container">
      <SubNav
        links={links}
      />
      <Outlet/>
    </div>
  )
}
export default AALayout
