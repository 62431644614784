import React,{ useEffect,useState } from 'react';
import axios from 'axios';
import { LoaderContext } from "../context/Context";
import { useContext } from 'react';
import { useLayoutEffect } from 'react';


function FaqsView(props) {
  const baseContentUrl = "https://152-228-211-95.sslip.io"
  const path = '/wp-json/wp/v2';
  const [faqs,setFaqs] = useState([])
  const [taxos, setTaxos] = useState([])
  const [open, setOpen] = useState(true)
  const [classes, setClasses] = useState("")

  const loader = useContext(LoaderContext);
  useLayoutEffect(()=>{
    axios.get(`${baseContentUrl}${path}/faqstaxo`)
    .then((res)=>{
      setTaxos(res.data)
    })
    
    
    //get faq lists: 
    axios.get(`${baseContentUrl}${path}/faqs`)
    .then((res)=>{
      // console.log("all Faqs",res.data)
      // let oldState = [...faqs]
      // console.log(res.data);
      // res.data.map((faq)=>{
      //   oldState.push({
      //     // link:faq._links.self[0].href.replace(baseContentUrl,""),
      //     link : faq.link.replace(baseContentUrl,""),
      //     name:faq.title.rendered
      //   })
      // })
      setFaqs(res.data);
      loader.onSetIsLoading(false);
    })
    .catch((err)=>{
      console.log(err);
    })
    return()=>{
      loader.onSetIsLoading(false);
    }
  },[])
  const handleOpen = (value)=>{
    // console.log(value)
    const id = value.split("-")[1]+'-'+value.split("-")[0]
    // console.log(id)
    var spanelmt = document.getElementById(id);
    
    var element = document.getElementById(value);
    // console.log(element.style.display)
    if (!element.style.display || element.style.display === "none"){
    element.style.display = "block"
    spanelmt.innerHTML ="-"
  }else{
    element.style.display = "none"
    spanelmt.innerHTML ="+"
  }
  
  
    

    // setOpen(!open)
    setOpen(false)
  }
  const taxosSort = taxos.sort((a,b)=>(a.name<b.name))
  return (
  <div className='layout-profile-container'>
    <div className='faq-container '>
  {taxosSort.map((taxo,i)=>  (taxo.count>0 && //le count defini le nombre de fois que la taxononomie a été attribuée si count === 0 alors elle n'a pas été attribuée a un post donc pas la peine d'afficher
    <div key={i}>
    <span className="-subtitle">
      <h3>{taxo.name}</h3>
      </span>
    {faqs.map((faq,i)=>{
      return(
        faq.faqstaxo.includes(taxo.id) && // pour aficher le post on regarde si l'id de la taxo est inclue dans le tableau faqtaxo du post
        <div key ={i} className='-object '>
        
          <div className='-pointer-div' onClick={()=>handleOpen(taxo.id+'-'+i)}>
            <span className='-title' >{faq.title.rendered}</span> 
            <span id={i+'-'+taxo.id} className='-icon' >+</span>
            </div>
         <div className='-content markup' id={taxo.id+'-'+i}  dangerouslySetInnerHTML={{__html:faq.content.rendered}}/>
         
        </div>
           )
    })}
    </div>
    
    ))}
    </div>
  
    
  </div>
  );
}

export default FaqsView;