import { useState, useEffect } from "react"
import {useSelector} from 'react-redux';
import axios from 'axios';
import Button from "../components/button"
import FormRowCheckbox from "../components/formRowCheckbox"
import InfosBar from "../components/infosBar"
import InputSelect from "../components/inputSelect"
import InputText from "../components/inputText"
import urls from "../constants/urls";
import { Navigate } from "react-router-dom";
import texts from '../texts/withdraw'
import { LoaderContext } from "../context/Context";
import { useLayoutEffect, useContext } from 'react';


const WithDrawView =  props =>{
  const loader = useContext(LoaderContext);
 const  {user, token} = useSelector(state => state.persisedReducer.user)
 const {lang} = useSelector(state=>state.persisedReducer.parameters)



  var headers = {headers: {"x-auth-token" : token}}

  useLayoutEffect(() => {
    axios.get(`${urls.apiUrl}/fonds/business`, headers)
    .then((res)=>{
      
      let fondsBusiness = res.data
      let fondAACapital = (fondsBusiness.filter(i=>i.name==="Apport-Affaire-Capitalisation"))[0]
      
      let fondAAReal= fondsBusiness.filter(i=>i.name==="Apport-Affaire-Real-Estate")[0]
      // console.log(fondAAReal._id)
        axios
          .get(`${urls.apiUrl}/investment/user/${user.id}`, headers)
          .then((res) => {
            let benef = res.data.filter((i) => {
              return i.status === "validé" && i.fond ===fondAAReal._id;  //!rouute pour get les fonds_id ....
            });
    
            let benefCapital = res.data.filter((i) => {
              return i.status === "validé" && i.fond ===fondAACapital._id;  //!rouute pour get les fonds_id ....
            });
            
            setBenefice(
              benef
                .map((b) => parseInt(b.montant))
                .reduce((prev, next) => prev + next,0)
            );
            setBeneficeCapital(
              benefCapital
                .map((b) => parseInt(b.montant))
                .reduce((prev, next) => prev + next,0)
            );
          })
          .catch ( err => console.log(err));
        
        axios.get(`${urls.apiUrl}/payment/mollie/withdraw` , headers)
        .then((res)=>{
          loader.onSetIsLoading(false);
          const totalwithdraw = res.data.map((i)=> i.status === "processed" && i.fond ===fondAAReal._id &&
                                                                              parseInt(i.amount)).reduce((prev,next)=>prev+next,0)
          setTotalWithdraw(totalwithdraw)
          const totalwithdrawCapital = res.data.map((i)=> i.status === "processed" && i.fond ===fondAACapital._id &&
                                                                              parseInt(i.amount)).reduce((prev,next)=>prev+next,0)
                                                                              setTotalWithdrawCapital(totalwithdrawCapital)
        })
        .catch ( err => console.log(err));
    })
    .catch ( err => console.log(err));
    return()=>{
      loader.onSetIsLoading(true);
    }
  }, []);
      
  const [totalWihtdraw, setTotalWithdraw] = useState(0) 
  const [totalWihtdrawCapital, setTotalWithdrawCapital] = useState(0) 
  const [benefice, setBenefice] = useState(0);
  const [beneficeCapital, setBeneficeCapital] = useState(0);


  const benefDispo = benefice - totalWihtdraw
  const benefDispoCapital = beneficeCapital - totalWihtdrawCapital
    

  const input = [
    {
      id:"Apport-Affaire-Real-Estate",
      label:texts[lang].inputChoices[0],
      status:true
    },
    {
      id:"Apport-Affaire-Capitalisation",
      label:texts[lang].inputChoices[1],
      status:true
    },
    {
      id:texts[lang].inputChoices[2],
      label:texts[lang].inputChoices[2],
      status:false
    }
  ]
  
const [isPro, setPro] = useState(false);
const [hasDisclaimer, setHasDisclaimer] = useState(false);
const [amount, setAmount] = useState("")
const [account, setAccount] = useState(input[0].id)
const [file, setFile] = useState(null)
const [inputs, setInputs] = useState(user.rib)
const [hasRib, setHasRib] = useState(user.hasRib ? user.hasRib : false)
const [redirect, setRedirect] = useState(false)


  const handleInputChange = (e)=>{
   setAmount(e.target.value)
  }

  const handleCheckBoxchange = (e)=>{
    setPro(e.target.checked) 
  }

  const handleDisclaimer = (e)=>{
    setHasDisclaimer(e.target.checked)
  }

  const getAccount = (e)=>{
    setAccount(e.target.value)
  }
  const handleFileChange = (e) =>{
    if(!e.target.files[0]){
      setFile(null)
      return
    }
    setFile(e.target.files[0])
  }

  const handleFormSubmit = (e)=>{
    e.preventDefault()
    const formData = new FormData()

    //headers
    headers = {...headers, "x-auth-token":token}
    headers = {...headers, "Content-type": "multipart/form-data"}

    //body
    
    formData.append('body', JSON.stringify(
      {
        amount: amount,
        isPro: isPro,
        account: account ? account : "Apport-Affaire-Real-Estate",
        
      }
    ))

    if (file){
      formData.append('file', file)
    }

    axios.post(`${urls.apiUrl}/withdraw`, formData, {headers})
    .then(res=>{

      setRedirect(true)
  })
    .catch(err=>{
      console.log(err)
    })
    
  }
 

  if(!hasRib) return <Navigate to={"/dashboard/banque"} replace={true}/>
  if(redirect) return <Navigate to={"/financial-transactions"} replace={true}/>
  return (
    <div className="form-container">
    <div className="card">
      <div className="card-header">
        <p>{texts[lang].demandeRetrait} </p>
      </div>
      <form className="card-body" onSubmit={handleFormSubmit}>
        <InfosBar
          classes={"-info"}
          content={texts[lang].retraitInfo} />

        <InputSelect
        classes={"-select"}
        label={texts[lang].selectAcount}
        inputs={
          // inputs.map((input)=>{
          //   return {id:input._id,label:input.name}
          // }) 
           input.filter((i)=>i.status)
        }
        handleSelectChange={(e)=>getAccount(e)}/> 

        <span className="-indication">{account === input[0].id ? `${texts[lang].retraitIndication}  ${benefDispo} € `:`${texts[lang].retraitIndication}  ${benefDispoCapital} € `}</span>

        <InputText
        type={"number"}
        min={"0"}
        max={account === input[0].id ? benefDispo.toString(): benefDispoCapital.toString()}
        label={texts[lang].montantRetrait}
        value={amount}
       
        handleInputChange = {(e)=>handleInputChange(e)}/>
        
        <div className="-checkbox">
        <FormRowCheckbox
        
        label={texts[lang].iMPro}
        handleCheckboxChange={(e)=>handleCheckBoxchange(e)}
        isActive={isPro}/>
        { isPro && <div className="-fileupload">
          <InfosBar
          classes={"-info"}
          content={texts[lang].proInfo} />
          <label htmlFor="file">{texts[lang].addFacture}</label>
          <input type={"file"} id="file" onChange={(e)=>handleFileChange(e)}/>
          <span>{texts[lang].requiredFormats}</span>
        </div>}
        </div>

        <FormRowCheckbox
        label={texts[lang].condition}
        isActive={hasDisclaimer}
        handleCheckboxChange={(e)=>handleDisclaimer(e)}
        />
        <p className="-conditions">{texts[lang].condition1}</p>
        <p className="-conditions">{texts[lang].condition2}</p>


        <Button
        

        type={"submit"}
        disable={hasDisclaimer && amount!=="" ? false : true}
        label={texts[lang].submitForm}
        classes={"-tiny"}
        style={{
          marginTop:20,
        }}
        />
       

      </form>
    </div>
    </div>
  )
}
export default WithDrawView