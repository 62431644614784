import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { switchLang } from "./redux/store";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from "./layouts/DashboardLayout";
import FeedBackNotificationLayout from "./layouts/FeedBackNotificationLayout";
import ProtectedRootsLayout from "./layouts/ProtectedRootsLayout";
import ProfileLayout from "./layouts/ProfileLayout";
import InvestLayout from "./layouts/InvestLayout";

import AuthenticationView from "./views/AuthentificationView";
import RegistrationView from "./views/RegistrationView";
import DashboardView from "./views/DashboardView";
import MailValidationView from "./views/MailValidationView";
import NoMatchView from "./views/NoMatchView";
import ResetPwdView from "./views/ResetPwdView";
import SecuriteView from "./views/dashboard/SecuriteView";
import EditProfileView from "./views/dashboard/EditProfileView";
import FiscaliteView from "./views/dashboard/FiscaliteView";
import SumsubView from "./views/SumsubView";
import QuizzView from "./views/QuizzView";
import ConfirmPayView from "./views/ConfirmPayView";
import InvestementView from "./views/InvestementView";
import TransactionView from "./views/TransactionView";
import ApportAffView from "./views/ApportAffaire/ApportAffView";
import ContactLayout from "./layouts/ContactLayout";

// import ContratView from "./views/dashboard/ContratView";
// import ReseauView from "./views/dashboard/ReseauView";

import FirstInvestementView from "./views/FirstInvestementView";

import "./App.css";
import "./scss/style.scss";
import MyInvestView from "./views/investements/MyInvestView";
import DocumentLayout from "./layouts/DocumentLayout";
import PayementProofView from "./views/documents/PaymentProofView";
import ContratView from "./views/documents/ContratView";
import CommissionFicheView from "./views/documents/CommisionFicheView";
import WithDrawView from "./views/WithDrawView";
import BanqueView from "./views/dashboard/BanqueView";
import MyNetworkView from "./views/MyNetworkView";
import DetailsApportAffaireView from "./views/ApportAffaire/DetailsApportAffaireView";
import BecomeCommercialView from "./views/ApportAffaire/BecomeCommercialView";
import ProjectImmoView from "./views/ProjectImmoView";
import NotificationView from "./views/NotificationView";
import CapitalInvestView from "./views/capitalInvestView";
import RealInvestView from "./views/realInvestView";
import RealDetailView from "./views/RealDetailView";
import CapitalDetailView from "./views/CapitalDetailView";
import FaqsView from "./views/FaqsView";
import FaqView from "./views/PartenaireSingleView";

import {LoaderContext} from "./context/Context";
import PartenaireView from "./views/PartenaireView";
import AALayout from "./layouts/AALayout";
import ListeContact from "./views/ApportAffaire/ListeContact";
import AddContact from "./views/ApportAffaire/AddContact";
import ContactSocialNetwork from "./views/ApportAffaire/ContactInfo/ContactSocialNetwork";
import ContactPersonnalInfoView from "./views/ApportAffaire/ContactInfo/ContactPersonnalInfoView";
import ContactInvestInterest from "./views/ApportAffaire/ContactInfo/ContactInvestInterest";
import ContactNotes from "./views/ApportAffaire/ContactInfo/ContactNotes";
import PartenaireSingleView from "./views/PartenaireSingleView";

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const context = {isLoading, onSetIsLoading:setIsLoading}
  const dispatch = useDispatch();
  const { langues } = useSelector((state) => state.persisedReducer.parameters);

  useEffect(() => {
    const lang = window.navigator.language.split("-")[0];
    if (lang && langues.map((lang) => lang.code).includes(lang))
      dispatch(switchLang(lang));
    else dispatch(switchLang("en"));
  }, [langues]);

  return (
    <LoaderContext.Provider value={context}>
    <Router>
      <Routes>
        <Route element={<FeedBackNotificationLayout />}>
          {/* Public routes */}
          <Route path="/" element={<AuthenticationView />} />
          <Route path="/connexion" element={<AuthenticationView />} />
          <Route path="/registration" element={<RegistrationView />} />
          <Route
            path="/registration/:affiliate"
            element={<RegistrationView />}
          />
          <Route
            path="/validation/mail/:token"
            element={<MailValidationView />}
          />
          <Route path="/password/reset/:token" element={<ResetPwdView />} />
          <Route path="/pay-confirm/:token" element={<ConfirmPayView />} />

          {/* Protected Routes */}
          <Route element={ <ProtectedRootsLayout />}>
            {/* Dashboard */}
            <Route element={<DashboardLayout />}>
              {/* Tableau de bord */}
              <Route path="/dashboard" element={<DashboardView />} />

              {/* Profil */}
              <Route element={<ProfileLayout />}>
                <Route
                  path="/dashboard/edit-profile"
                  element={<EditProfileView />}
                />
                <Route path="/dashboard/security" element={<SecuriteView />} />
                <Route
                  path="/dashboard/fiscalite"
                  element={<FiscaliteView />}
                />
                <Route path="/dashboard/banque" element={<BanqueView />} />
                {/* <Route path="/dashboard/network" element={<ReseauView />} /> */}
                {/* <Route path="/dashboard/contracts" element={<ContratView />} /> */}
              </Route>

              {/* Mes contrats */}
              <Route path="/contracts" element={<DashboardView />} />

              {/* Mes Notifications */}

              <Route path="/notifications" element={<NotificationView />} />

              {/* Mes investissements */}
              <Route element={<InvestLayout />}>
                <Route path="investissements" element={<MyInvestView />} />
                <Route
                  path="investissements/withdraw"
                  element={<WithDrawView />}
                />
              </Route>
              {/* Transaction Financiére  */}
              <Route
                  path="/financial-transactions"
                  element={<TransactionView />}
                />
              {/* Apport d'affaire */}
              <Route element={<AALayout/>}>

              <Route path="/apport-affaire" element={<ApportAffView />} />
              <Route
                path="/apport-affaire/detail"
                element={<DetailsApportAffaireView />}
                />
              <Route path="/list-contact" element={<ListeContact/>} />

              <Route element={<ContactLayout/>}>
              <Route path="/list-contact/contact-personal-info/:id" element={<ContactPersonnalInfoView/>} />
              <Route path="/list-contact/contact-social-network/:id" element={<ContactSocialNetwork/>} />
              <Route path="/list-contact/contact-investment-interest/:id" element={<ContactInvestInterest/>} />
              <Route path="/list-contact/contact-notes/:id" element={<ContactNotes/>} />
              </Route>

              <Route path ="/add-contact" element ={<AddContact/>} />
              </Route>

              <Route path ="/apport-affaire/commercial" element ={<BecomeCommercialView/>} />

              {/* Mon réseau */}
              <Route path="/monReseau" element={<MyNetworkView />} />

              {/* Projets immobiliers */}
              
              {/* investir */}

              {/* Profil d'investisseur */}

              {/* Marketing */}

              {/* Documents */}

              {/* Evenements */}

              {/* Communauté */}

              <Route element={<DocumentLayout />}>
                <Route
                  path="/documents"
                  element={<PayementProofView />}
                />
                <Route
                  path="/dashboard/doc-contrat"
                  element={<ContratView />}
                />
                <Route
                  path="/dashboard/doc-commission"
                  element={<CommissionFicheView />}
                />
              </Route>

              <Route path="/dashboard/beneficts" element={<InvestLayout />} />

              <Route path="/dashboard/network" element={<DashboardView />} />
              <Route
                path="/projets-immobiliers"
                element={<ProjectImmoView />}
              />
              <Route path="invest" element={<InvestementView />} />
              <Route path="invest/capitalisation" element={<CapitalInvestView />} />
              <Route path="invest/real-estate" element={<RealInvestView />} />

              <Route path="invest/real-detail" element={<RealDetailView />} />
              <Route path="invest/capital-detail" element={<CapitalDetailView />} />
              <Route
                path="invest-profile"
                element={<FirstInvestementView />}
              />
              <Route path="/dashboard/marketing" element={<DashboardView />} />
              <Route path="/dashboard/documents" element={<DashboardView />} />
              <Route path="/dashboard/events" element={<DashboardView />} />
              <Route path="/dashboard/community" element={<DashboardView />} />

              <Route path="/dashboard/tutoriels" element={<DashboardView />} />
              <Route path="/faqs" element={<FaqsView />} />
              {/* <Route path="/faqs/:faq" element={<FaqView />} /> */}
              <Route path="/partenaires" element={<PartenaireView/>} />
              <Route path="/partenaires/:id" element={<PartenaireSingleView />} />
              <Route path="/dashboard/settings" element={<DashboardView />} />
            </Route>

            {/* Sumsub */}
            <Route path="/sumsub" element={<SumsubView />} />

            {/* Quizz */}
            <Route path="/quizz" element={<QuizzView />} />
          </Route>
          
        </Route>

        <Route path="/*" element={<NoMatchView />} />
      </Routes>
    </Router>
    </LoaderContext.Provider>
  );
}

export default App;
