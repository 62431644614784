import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  // user: {
  //   _id: "62b9acaf7a90b237287bbbf3",
  //   avatar: null,
  //   type: "Entreprise",
  //   email: "entreprise@gdeb.fr",
  //   lastname: "sumsubEntreprise",
  //   firstname: "Guillaume",
  //   dateOfBirth: "2000-01-01T00:00:00.000+00:00",
  //   address: "2b place de l'union europ",
  //   zipCode: "15450",
  //   country: "France",
  //   phone: "0551512183",
  //   haveDiferentFiscalCountry: false,
  //   city: "massy",
  //   fiscalCountry: "FR",
  //   emailValidationLink: null,
  //   has_valided_email: true,
  //   sumsub_id: "entreprise@gdeb.fr",
  //   sumsub_token: "_act-sbx-ca778f84-3cef-42d5-8a9e-17882b47fba9",
  //   has_send_kyx: true,
  //   status_kyx: null,
  //   date_kyx: null,
  //   has_google_auth: false,
  //   google_auth_key: "FZATAO2DLEYS2KJX",
  //   is_valid: false,
  //   affiliate_link: "GeneredAffiliate",
  //   investor_profile: null,
  //   hasDoneQuiz: false,
  //   quizz: null,
  // },

  // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyYWFlYzc1OGM0NGQ1YTY5YmM0NDQwNiIsImZpcnN0bmFtZSI6InJhYmFoIDMiLCJsYXN0bmFtZSI6ImdoaWxlcyAzIiwicm9sZXMiOlsiVXNlciJdLCJlbWFpbCI6InJhYmFoZ2hpbGVzQGdtYWlsLmNvbSIsInR5cGUiOiJFbnRyZXByaXNlIiwiZGF0ZU9mQmlydGgiOiIyMDAwLTAyLTAxVDAwOjAwOjAwLjAwMFoiLCJhZGRyZXNzIjoiMkMgcGxhY2UgZGUgbCd1bmlvbiBldXJvcCIsInppcENvZGUiOiIxNDQ1MCIsImNvdW50cnkiOiJBbGfDqXJpZSIsInBob25lIjoiMDY1MTUxMjE4MyIsImhhdmVEaWZlcmVudEZpc2NhbENvdW50cnkiOnRydWUsImNpdHkiOiJtYXNzeSAyIiwiZmlzY2FsQ291bnRyeSI6IkZJIiwiZW50cmVwcmlzZSI6eyJuYW1lIjoicmdoaWxlcyBwcm9kbHprZW5sa2V6bGt1Y3Rpb24iLCJhZGRyZXNzIjoiUGFyaXMgMTVlbWUgamUgbmUgc2FpcyBwYXMiLCJjaXR5IjoiUGFyaXMiLCJjb3VudHJ5IjoiRnJhbmNlIiwiY29tcGFueU51bWJlciI6IjAwNTU2NTQ1IiwiemlwQ29kZSI6Ijc1MDAxIn0sInBlcnNvbmVUb0NvbnRhY3QiOlt7ImZpcnN0bmFtZSI6IkZhdGltYSIsImxhc3RuYW1lIjoiR2hpbGVzIiwiY2l0eSI6Ik91YWRoaWEiLCJjb3VudHJ5IjoiQWxnw6lyaWUiLCJfaWQiOiI2MmFiM2ZlNDczNWY0ZWEwMDU2OGRkOTUifV0sImhhc192YWxpZGVkX2VtYWlsIjpmYWxzZSwiZm9yY2VSZXNldFBhc3N3b3JkIjp0cnVlLCJzdW1zdWJfdG9rZW4iOiJfYWN0LXNieC1hYWMwZTRhMy1iZmQ4LTQ0OWItYWM2NC04NmZlNWM5MDhjZDkiLCJoYXNfc2VuZF9reXgiOnRydWUsInN0YXR1c19reXgiOm51bGwsImRhdGVfa3l4IjpudWxsLCJpc192YWxpZCI6ZmFsc2UsInJvbGUiOlsiVXNlciJdLCJhZmZpbGlhdGVfbGluayI6IkdlbmVyZWRBZmZpbGlhdGUiLCJpbnZlc3Rvcl9wcm9maWxlIjpudWxsLCJoYXNEb25lUXVpeiI6ZmFsc2UsInF1aXp6IjpudWxsLCJoYXNfZ29vZ2xlX2F1dGgiOnRydWUsImlhdCI6MTY1NjM0NTMwNywiZXhwIjoxNjU2Mzg4NTA3fQ.sumc1qE9eFNf3RTZYf7qTl-OvkrX1qx2tsARj0jYhcs",

  user: null,
  token: null,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    deconnexion: (state) => {
      state.token = null;
      state.user = null;
    }
  },
});

export default userSlice;
