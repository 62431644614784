import React, { Component } from 'react';
import { connect } from "react-redux";
import Button from '../../components/button';
import FormSectionTitle from '../../components/formSectionTitle';
import InfosBar from '../../components/infosBar';
import texts from '../../texts/dashboard';
import { LoaderContext } from "../../context/Context";


class FiscalView extends Component {
  static contextType = LoaderContext;

  componentDidMount(){
    this.context.onSetIsLoading(false);  
  }
  componentWillUnmount(){
    this.context.onSetIsLoading(true);
  }

  render() {
    const { lang } = this.props.parameters;

    return (
      <div className="dashboard-container">
        <div className="dashboard-scontainer -no-padding">

          <FormSectionTitle
            title={texts[lang].fiscaliteTitle}
            classes="-simple-2"
          />

          <div className="fiscality-form-container">

            <InfosBar
              content={texts[lang].fiscaliteContent}
              classes="-info"
              style={{
                marginBottom: 30,
              }}
            />

            {/* <form className="-form">

              <div className="-input-container">
                <label className='-label'>
                  <input className='-input' type="file" accept="application/pdf" />
                  <div className="-button-container">
                    <button type='button'>Upload</button>
                    <div>Pdf, png</div>
                  </div>
                </label>
                <p className='-indication'>Aucun fichier choisi pour l'instant</p>
              </div>

              <Button
                isLoading={false}
                label={"Envoyer"}
                classes="-small"
                icon
                type="button"
                handleClick={() => console.log("hello")}
              />

            </form> */}

          </div>

        </div>
      </div>
    );
  }

}
 
// export default FiscalView;
const mapStateToProps = (state) => ({
  parameters: state.persisedReducer.parameters,
  user: state.persisedReducer.user,
});

export default connect(mapStateToProps)(FiscalView);
