export default {
  fr: {
    //Inscription a la platforme
    //* Lien https://app.viziocapital.com/registration
    formHeaderTitle: "Créer votre compte Vizio Capital",
    formHeaderSubTitle: [
      "Etape 1 : Renseigner vos informations de connexion",
      "Etape 2 : Renseigner vos informations personnelles",
      // "Etape 3 : Vérification de votre identité",
      // "Etape 4 : Inscription finalisé",
      "Etape 2 : Validation de votre Email",
      "Etape 3 : Vérification de votre identité",
      "Etape 4 : Compte créé avec succès",
    ],

    labelMail: "Votre adresse email",
    labelPassword: "Choisir un mot de passe",

    labelPseudo: "Votre pseudo",
    indicationPseudo: "Utile en cas d'apport d’affaires : Si vous renseignez un pseudo, vos informations d'identités seront masquées, seule votre adresse email sera accessible. Votre pseudo doit être composé de 4 a 20 caractéres alpha numérique.",

    errorMail: "La valeur saisie pour le champ email n'est pas correcte",
    errorPseudo: "Votre pseudo dois être composer de 4 a 20 caractéres alphanumérique",
    errorPassword: "La valeur saisie pour le champ mot de passe n'est pas correcte",
    errorEmpryField: "Ce champs est obligatoire",

    // information: "Choisir son email et son mot de passe à utiliser plus tard lors de la connexion.",
    indicationPassword: "Minimum 5 caractères.",

    informationSucces: "Munissez-vous de vos pièces d'identité afin de démarrer la procédure d’inscription.",
    informationWarningHeader: "Afin d’être en accord avec la législation en vigueur, il est obligatoire de renseigner des informations personnelles avant de procéder à la validation de votre inscription.",
    informationWarningTaxation: "Vous pourrez téléverser votre fiche fiscale de référence plus tard dans votre compte utilisateur. Cette action peut vous permettre de faire des économies d’impôts.",
    titlePersonalInformations: "Informations personnelles",
    titleContactsInformations: "Informations de vos contacts",
    titleProfessionalInformations: "Informations à propos de votre entreprise",
    titleFiscality: "Ma fiscalité",

    labelFirstName: "Prénom",
    indicationFirstName:"Veuillez inscrire tous les prénoms de famille identique à votre carte d'identité/passeport",
    labelLastName: "Nom",
    indicationLastName:"veuillez inscrire tous les noms de famille identique à votre carte d'identité/passeport",
    labelDate: "Date de naissance",
    labelNativeCountry: "Pays de naissance",
    codeTelCountry: "Indicatif téléphonique international",
    labelPhoneNumber: "Numéro de téléphone",
    labelPostalAddress: "Adresse",
    labelCity: "Ville",
    labelPostalCode: "Code postal",
    labelResidenceCountry: "Pays de résidence",
    labelIsContacts: "Personnes à contacter en cas d'indisponibilité (peut être ajouté ultérieurement)",
    labelGenre:"Civilité",

    errorFirstName: "La valeur du champ prénom n'est pas valide",
    errorLastName: "La valeur du champ nom n'est pas valide",
    errorDay: "La valeur du champ jour de naissance n'est pas valide",
    errorNativeCountry: "La valeur du champ pays n'est pas valide",
    errorPhoneNumber: "La valeur du champ numéro de téléphone n'est pas valide",
    errorPostalAddress: "La valeur du champ adresse n'est pas valide",
    errorCity: "La valeur du champ ville n'est pas valide",
    errorPostalCode: "La valeur du champ code postal n'est pas valide",
    errorResidenceCountry: "La valeur du champ pays de résidence n'est pas valide",
    errorContacts: 'Vous devez renseigner une personne à contacter',

    labelMailAdress: "Adresse email",

    errorMailAdress: "La valeur du champ adresse email n'est pas valide",

    labelIsProfessionnel: "Je suis un professionnel",
    labelSocialdenomination: "Dénomination sociale",
    labelCompanyNumber: "Numéro d'immatriculation (BCE, SIRET, etc.)",
    labelCompanyFunction: "Votre fonction",
    labelHeadquartersAdress: "Adresse du siège",
    labelMunicipality: "Ville",
    labelCountry: "Pays",

    errorSocialdenomination: "La valeur du champ dénomination sociale n'est pas valide",
    errorCompanyNumber: "La valeur du champ numéro d'immatriculation n'est pas valide",
    errorHeadquartersAdress: "La valeur du champ adresse du siège n'est pas valide",
    errorMunicipality: "La valeur du champ ville n'est pas valide",
    errorCompanyFunction: "La valeur du champ votre fonction n'est pas valide",
    errorCountry: "La valeur du champ pays n'est pas valide",

    labelIsTaxAdressDifferent: "Mon adresse fiscale est différente de mon adresse postale. (peut être ajouté ultérieurelement)",

    labelcgu: 'J’ai lu et accepte les <a href="https://viziocapital.com/cgv" target="_blank">CGU</a>, la <a href="https://viziocapital.com/cgv" target="_blank">politique de respect de la vie privée</a>, les <a href="https://viziocapital.com/notes-dinformations/" target="_blank">notes d’informations</a> et les <a href="https://viziocapital.com/document-dinformation-synthetique/" target="_blank">documents d’informations synthétiques</a>.',
    labelDisclaimer: "Je certifie avoir pris connaissance des conditions générales de Vizio Capital.",
    labelIsOkPersonalInfos: "J’accepte que mes données personnelles soient enregistrées dans le cadre de mes activités sur la plateforme Vizio capital.",

    footerNextButtonLabel: "Étape suivante",
    footerNextButtonLastStepLabel: "Finaliser l'inscription",
    footerPrevButton: "Étape précédente",

    errorRecaptcha: "Le Recaptcha n'est pas valide",
    errorForm: "Un ou plusieurs champs du formulaire sont invalides",

    compteCreatedTitle: "Bravo ! Vous pouvez valider votre email",
    compteCreatedParag: "Vous allez recevoir un email, il vous suffit de cliquer sur le lien pour valider votre email et ainsi passer à l'étape suivante de vérification de votre identité.",

    emailNotValidatedTitle: "Votre adresse email n'est pas encore valide.",
    emailNotValidatedParag: "Votre compte a été créé mais vous ne pouvez pas accéder à votre profil tant que vous n'avez pas confirmer votre email. Un email de vérification vous a été envoyé préalablement.",

    sumsubSendTitle: "Votre vérification d'identité a bien été prise en compte",
    sumsubSendParag: "Vous allez recevoir un email vous informant de l'avancée de votre vérification d'identité par notre partenaire.",

    sumsubStatusOrangeTitle: "Informations d'identité transmises",
    sumsubStatusOrangeParag: "Vos informations d'identités ont été transmises à notre système de vérification partenaire. Vous allez recevoir un email vous informant de la suite de la procedure.",

    sumsubStatusRedFinalTitle: "Informations d'identitée invalidées par notre système de vérification partenaire.",
    sumsubStatusRedParag: "Afin de vérifier vos éléments d'identité, merci de <a href=\"https://viziocapital.com/contact/\" target=\"_blank\">contacter l'équipe Vizio Capital</a>.",
    
    sumsubStatusRedRetryTitle: "Notre système de vérification partenaire a détecté une modification a apporter",
    sumsubStatusRedRetryParag: "Veuillez suivre les indications ci-dessous.",

    labelBtnAddContacte: "Enregistrer ce contact",
    labelBtnEditProfile: "Modifier",

    editUserNotice: "Pour modifier vos informations personnelles, merci de contacter l'administrateur: modifications@viziocapital.com",

    //EditParrain
    //*Lien vers les informations du parrain https://app.viziocapital.com/dashboard/edit-profile
    editParrainTitle: "INFORMATIONS PERSONNELLES DE MON PARRAIN",
    parrainLastName :"Nom de mon parrain",
    parrainFirstName :"Prénom de mon parrain",
    parrainEmail :"Adresse mail de mon référent",
    parrainPhone :"Numéro de téléphone de mon parrain",

  },
  en: {
    //Inscription a la platforme
    //* Lien https://app.viziocapital.com/registration
    formHeaderTitle: "Créer votre compte Vizio Capital",
    formHeaderSubTitle: [
      "Etape 1 : Renseigner vos informations de connexion",
      "Etape 2 : Renseigner vos informations personnelles",
      // "Etape 3 : Vérification de votre identité",
      // "Etape 4 : Inscription finalisé",
      "Etape 2 : Validation de votre Email",
      "Etape 3 : Vérification de votre identité",
      "Etape 4 : Compte créé avec succès",
    ],

    labelMail: "Votre adresse email",
    labelPassword: "Choisir un mot de passe",

    labelPseudo: "Votre pseudo --FR",
    indicationPseudo: "Utile en cas d'apport d’affaires : Si vous renseignez un pseudo, vos informations d'identités seront masquées, seule votre adresse email sera accessible. Votre pseudo doit être composé de 4 a 20 caractéres alpha numérique. --FR",

    errorMail: "La valeur saisie pour le champ email n'est pas correcte",
    errorPseudo: "Votre pseudo dois être composer de 4 a 20 caractéres alphanumérique --FR",
    errorPassword: "La valeur saisie pour le champ mot de passe n'est pas correcte",
    errorEmpryField: "Ce champs est obligatoire",

    // information: "Choisir son email et son mot de passe à utiliser plus tard lors de la connexion.",
    indicationPassword: "5 characters minimum.",

    informationSucces: "Munissez-vous de vos pièces d'identité afin de démarrer la procédure d’inscription.",
    informationWarningHeader: "Afin d’être en accord avec la législation en vigueur, il est obligatoire de renseigner des informations personnelles avant de procéder à la validation de votre inscription.",
    informationWarningTaxation: "Vous pourrez téléverser votre fiche fiscale de référence plus tard dans votre compte utilisateur. Cette action peut vous permettre de faire des économies d’impôts.",
    titlePersonalInformations: "Informations personnelles",
    titleContactsInformations: "Informations de vos contacts",
    titleProfessionalInformations: "Informations à propos de votre entreprise",
    titleFiscality: "Ma fiscalité",

    labelFirstName: "Prénom",
    indicationFirstName:"Veuillez inscrire tous les prénoms de famille identique à votre carte d'identité/passeport",
    labelLastName: "Nom",
    indicationLastName:"veuillez inscrire tous les noms de famille identique à votre carte d'identité/passeport",
    labelDate: "Date de naissance",
    labelNativeCountry: "Pays de naissance",
    codeTelCountry: "Indicatif téléphonique international",
    labelPhoneNumber: "Numéro de téléphone",
    labelPostalAddress: "Adresse",
    labelCity: "Ville",
    labelPostalCode: "Code postal",
    labelResidenceCountry: "Pays de résidence",
    labelIsContacts: "Personnes à contacter en cas d'indisponibilité (peut être ajouté ultérieurement)",
    labelGenre:"Civilité",

    errorFirstName: "La valeur du champ prénom n'est pas valide",
    errorLastName: "La valeur du champ nom n'est pas valide",
    errorDay: "La valeur du champ jour de naissance n'est pas valide",
    errorNativeCountry: "La valeur du champ pays n'est pas valide",
    errorPhoneNumber: "La valeur du champ numéro de téléphone n'est pas valide",
    errorPostalAddress: "La valeur du champ adresse n'est pas valide",
    errorCity: "La valeur du champ ville n'est pas valide",
    errorPostalCode: "La valeur du champ code postal n'est pas valide",
    errorResidenceCountry: "La valeur du champ pays de résidence n'est pas valide",
    errorContacts: 'Vous devez renseigner une personne à contacter',

    labelMailAdress: "Adresse email",

    errorMailAdress: "La valeur du champ adresse email n'est pas valide",

    labelIsProfessionnel: "Je suis un professionnel",
    labelSocialdenomination: "Dénomination sociale",
    labelCompanyNumber: "Numéro d'immatriculation (BCE, SIRET, etc.)",
    labelHeadquartersAdress: "Adresse du siège",
    labelMunicipality: "Ville",
    labelCountry: "Pays",

    errorSocialdenomination: "La valeur du champ dénomination sociale n'est pas valide",
    errorCompanyNumber: "La valeur du champ numéro d'immatriculation n'est pas valide",
    errorHeadquartersAdress: "La valeur du champ adresse du siège n'est pas valide",
    errorMunicipality: "La valeur du champ ville n'est pas valide",
    errorCountry: "La valeur du champs pays n'est pas valide",

    labelIsTaxAdressDifferent: "Mon adresse fiscale est différente de mon adresse postale.(peut être ajouté ultérieurelement)",

    labelcgu: 'J’ai lu et accepte les <a href="https://viziocapital.com/cgv" target="_blank">CGU</a>, la <a href="https://viziocapital.com/cgv" target="_blank">politique de respect de la vie privée</a>, les la <a href="https://viziocapital.com/notes-dinformations/" target="_blank">notes d’informations</a> et les la <a href="https://viziocapital.com/document-dinformation-synthetique/" target="_blank">documents d’informations synthétiques</a>.',
    labelDisclaimer: "Je certifie avoir pris connaissance des conditions générales de Vizio Capital.",
    labelIsOkPersonalInfos: "J’accepte que mes données personnelles soient enregistrées dans le cadre de mes activités sur la plateforme Vizio capital.",

    footerNextButtonLabel: "Étape suivante",
    footerNextButtonLastStepLabel: "Finaliser l'inscription",
    footerPrevButton: "Étape précédente",

    errorRecaptcha: "Le Recaptcha n'est pas valide",
    errorForm: "Un ou plusieurs champs du formulaire sont invalides",

    compteCreatedTitle: "Bravo ! Vous pouvez valider votre email",
    compteCreatedParag: "Vous allez recevoir un email, il vous suffit de cliquer sur le lien pour valider votre email et ainsi passer à l'étape suivante de vérification de votre identité.",

    emailNotValidatedTitle: "Votre adresse email n'est pas encore valide.",
    emailNotValidatedParag: "Votre compte a été créé mais vous ne pouvez pas accéder à votre profil tant que vous n'avez pas confirmer votre email. Un email de vérification vous a été envoyé préalablement.",

    sumsubSendTitle: "Votre vérification d'identité a bien été prise en compte",
    sumsubSendParag: "Vous allez recevoir un email vous informant de l'avancée de votre vérification d'identité par notre partenaire.",

    sumsubStatusOrangeTitle: "Informations d'identité transmises",
    sumsubStatusOrangeParag: "Vos informations d'identités ont été transmises à notre système de vérification partenaire. Vous allez recevoir un email vous informant de la suite de la procedure.",

    sumsubStatusRedFinalTitle: "Informations d'identitée invalidées par notre système de vérification partenaire.",
    sumsubStatusRedParag: "Afin de vérifier vos éléments d'identité, merci de <a href=\"https://viziocapital.com/contact/\" target=\"_blank\">contacter l'équipe Vizio Capital</a>.",

    sumsubStatusRedRetryTitle: "Notre système de vérification partenaire a détecté une modification a apporter",
    sumsubStatusRedRetryParag: "Veuillez suivre les indications ci-dessous.",

    labelBtnAddContacte: "Enregistrer ce contact",
    labelBtnEditProfile: "Modifier",

    editUserNotice: "Pour modifier vos informations personnelles, merci de contacter l'administrateur: modifications@viziocapital.com",

    //EditParrain
    //*Lien vers les informations du parrain https://app.viziocapital.com/dashboard/edit-profile
    editParrainTitle: "INFORMATIONS PERSONNELLES DE MON PARRAIN",
    parrainLastName :"Nom de mon parrain",
    parrainFirstName :"Prénom de mon parrain",
    parrainEmail :"Adresse mail de mon référent",
    parrainPhone :"Numéro de téléphone de mon parrain",

  },
};
