import Button from "./button"

const GreyCard =({header, amount, text,text2, amount2, btnlabel, action,affiliateLink, classes, society1, society2,})=>{
 return( <div className={`card ${classes ? classes : ""}`}>
        <div className="card-header">
          <label>{header}</label>
        </div>
        <div className = "card-body">
          <div className="-texts"><p className="-amountGrey">{amount}</p>
          <p className="-card-body-text">{text} <span>{society1}</span> </p></div>

          <div><p className="-amountGrey">{amount2}</p>
          <p className="-card-body-text">{text2} <span>{society2}</span> </p></div>

          {btnlabel && <Button
            label={btnlabel}
            classes="-card-body-btn" 
            handleClick={action}/>}
            {affiliateLink && <div className="affiliate-link">{affiliateLink}</div>}
            </div>
      </div>)

}
export default GreyCard