export default [
  {id: "FR", label: "France", code: "33"},
  {id: "BE", label: "Belgium", code: "32"},
  {id: "NL", label: "Netherlands", code: "31"},
  {id: "GB", label: "United Kingdom", code: "44"},
  {id: "IT", label: "Italy", code: "39"},
  {id: "ES", label: "Spain", code: "34"},
  {id: "PT", label: "Portugal", code: "351"},
  {id: "DE", label: "Germany", code: "49"},
  {id: "CH", label: "Switzerland", code: "41"},
  {id: "BD", label: "Bangladesh", code: "880"},
  {id: "BF", label: "Burkina Faso", code: "226"},
  {id: "BG", label: "Bulgaria", code: "359"},
  {id: "BA", label: "Bosnia and Herzegovina", code: "387"},
  {id: "BB", label: "Barbados", code: "1-246"},
  {id: "WF", label: "Wallis and Futuna", code: "681"},
  {id: "BL", label: "Saint Barthelemy", code: "590"},
  {id: "BM", label: "Bermuda", code: "1-441"},
  {id: "BN", label: "Brunei", code: "673"},
  {id: "BO", label: "Bolivia", code: "591"},
  {id: "BH", label: "Bahrain", code: "973"},
  {id: "BI", label: "Burundi", code: "257"},
  {id: "BJ", label: "Benin", code: "229"},
  {id: "BT", label: "Bhutan", code: "975"},
  {id: "JM", label: "Jamaica", code: "1-876"},

  {id: "BW", label: "Botswana", code: "267"},
  {id: "WS", label: "Samoa", code: "685"},
  {id: "BQ", label: "Bonaire, Saint Eustatius and Saba ", code: "599"},
  {id: "BR", label: "Brazil", code: "55"},
  {id: "BS", label: "Bahamas", code: "1-242"},
  {id: "JE", label: "Jersey", code: "44-1534"},
  {id: "BY", label: "Belarus", code: "375"},
  {id: "BZ", label: "Belize", code: "501"},
  {id: "RU", label: "Russia", code: "7"},
  {id: "RW", label: "Rwanda", code: "250"},
  {id: "RS", label: "Serbia", code: "381"},
  {id: "TL", label: "East Timor", code: "670"},
  {id: "RE", label: "Reunion", code: "262"},
  {id: "TM", label: "Turkmenistan", code: "993"},
  {id: "TJ", label: "Tajikistan", code: "992"},
  {id: "RO", label: "Romania", code: "40"},
  {id: "TK", label: "Tokelau", code: "690"},
  {id: "GW", label: "Guinea-Bissau", code: "245"},
  {id: "GU", label: "Guam", code: "1-671"},
  {id: "GT", label: "Guatemala", code: "502"},

  {id: "GR", label: "Greece", code: "30"},
  {id: "GQ", label: "Equatorial Guinea", code: "240"},
  {id: "GP", label: "Guadeloupe", code: "590"},
  {id: "JP", label: "Japan", code: "81"},
  {id: "GY", label: "Guyana", code: "592"},
  {id: "GG", label: "Guernsey", code: "44-1481"},
  {id: "GF", label: "French Guiana", code: "594"},
  {id: "GE", label: "Georgia", code: "995"},
  {id: "GD", label: "Grenada", code: "1-473"},
  
  {id: "GA", label: "Gabon", code: "241"},
  {id: "SV", label: "El Salvador", code: "503"},
  {id: "GN", label: "Guinea", code: "224"},
  {id: "GM", label: "Gambia", code: "220"},
  {id: "GL", label: "Greenland", code: "299"},
  {id: "GI", label: "Gibraltar", code: "350"},
  {id: "GH", label: "Ghana", code: "233"},
  {id: "OM", label: "Oman", code: "968"},
  {id: "TN", label: "Tunisia", code: "216"},
  {id: "JO", label: "Jordan", code: "962"},
  {id: "HR", label: "Croatia", code: "385"},
  {id: "HT", label: "Haiti", code: "509"},
  {id: "HU", label: "Hungary", code: "36"},
  {id: "HK", label: "Hong Kong", code: "852"},
  {id: "HN", label: "Honduras", code: "504"},

  {id: "VE", label: "Venezuela", code: "58"},
  {id: "PR", label: "Puerto Rico", code: "1-787 and 1-939"},
  {id: "PS", label: "Palestinian Territory", code: "970"},
  {id: "PW", label: "Palau", code: "680"},
  
  {id: "SJ", label: "Svalbard and Jan Mayen", code: "47"},
  {id: "PY", label: "Paraguay", code: "595"},
  {id: "IQ", label: "Iraq", code: "964"},
  {id: "PA", label: "Panama", code: "507"},
  {id: "PF", label: "French Polynesia", code: "689"},
  {id: "PG", label: "Papua New Guinea", code: "675"},
  {id: "PE", label: "Peru", code: "51"},
  {id: "PK", label: "Pakistan", code: "92"},
  {id: "PH", label: "Philippines", code: "63"},
  {id: "PN", label: "Pitcairn", code: "870"},
  {id: "PL", label: "Poland", code: "48"},
  {id: "PM", label: "Saint Pierre and Miquelon", code: "508"},
  {id: "ZM", label: "Zambia", code: "260"},
  {id: "EH", label: "Western Sahara", code: "212"},
  {id: "EE", label: "Estonia", code: "372"},
  {id: "EG", label: "Egypt", code: "20"},
  {id: "ZA", label: "South Africa", code: "27"},
  {id: "EC", label: "Ecuador", code: "593"},
 
  {id: "VN", label: "Vietnam", code: "84"},
  {id: "SB", label: "Solomon Islands", code: "677"},
  {id: "ET", label: "Ethiopia", code: "251"},
  {id: "SO", label: "Somalia", code: "252"},
  {id: "ZW", label: "Zimbabwe", code: "263"},
  {id: "SA", label: "Saudi Arabia", code: "966"},
  
  {id: "ER", label: "Eritrea", code: "291"},
  {id: "ME", label: "Montenegro", code: "382"},
  {id: "MD", label: "Moldova", code: "373"},
  {id: "MG", label: "Madagascar", code: "261"},
  {id: "MF", label: "Saint Martin", code: "590"},
  {id: "MA", label: "Morocco", code: "212"},
  {id: "MC", label: "Monaco", code: "377"},
  {id: "UZ", label: "Uzbekistan", code: "998"},
  {id: "MM", label: "Myanmar", code: "95"},
  {id: "ML", label: "Mali", code: "223"},
  {id: "MO", label: "Macao", code: "853"},
  {id: "MN", label: "Mongolia", code: "976"},
  {id: "MH", label: "Marshall Islands", code: "692"},
  {id: "MK", label: "Macedonia", code: "389"},
  {id: "MU", label: "Mauritius", code: "230"},
  {id: "MT", label: "Malta", code: "356"},
  {id: "MW", label: "Malawi", code: "265"},
  {id: "MV", label: "Maldives", code: "960"},
  {id: "MQ", label: "Martinique", code: "596"},
  {id: "MP", label: "Northern Mariana Islands", code: "1-670"},
  {id: "MS", label: "Montserrat", code: "1-664"},
  {id: "MR", label: "Mauritania", code: "222"},
  {id: "IM", label: "Isle of Man", code: "44-1624"},
  {id: "UG", label: "Uganda", code: "256"},
  {id: "TZ", label: "Tanzania", code: "255"},
  {id: "MY", label: "Malaysia", code: "60"},
  {id: "MX", label: "Mexico", code: "52"},
  {id: "IL", label: "Israel", code: "972"},
  
  {id: "IO", label: "British Indian Ocean Territory", code: "246"},
  {id: "SH", label: "Saint Helena", code: "290"},
  {id: "FI", label: "Finland", code: "358"},
  {id: "FJ", label: "Fiji", code: "679"},
  {id: "FK", label: "Falkland Islands", code: "500"},
  {id: "FM", label: "Micronesia", code: "691"},
  {id: "FO", label: "Faroe Islands", code: "298"},
  {id: "NI", label: "Nicaragua", code: "505"},
  
  {id: "NO", label: "Norway", code: "47"},
  {id: "NA", label: "Namibia", code: "264"},
  {id: "VU", label: "Vanuatu", code: "678"},
  {id: "NC", label: "New Caledonia", code: "687"},
  {id: "NE", label: "Niger", code: "227"},
  {id: "NF", label: "Norfolk Island", code: "672"},
  {id: "NG", label: "Nigeria", code: "234"},
  {id: "NZ", label: "New Zealand", code: "64"},
  {id: "NP", label: "Nepal", code: "977"},
  {id: "NR", label: "Nauru", code: "674"},
  {id: "NU", label: "Niue", code: "683"},
  {id: "CK", label: "Cook Islands", code: "682"},

  {id: "CI", label: "Ivory Coast", code: "225"},
  
  {id: "CO", label: "Colombia", code: "57"},
  {id: "CN", label: "China", code: "86"},
  {id: "CM", label: "Cameroon", code: "237"},
  {id: "CL", label: "Chile", code: "56"},

  {id: "CA", label: "Canada", code: "1"},
  {id: "CG", label: "Republic of the Congo", code: "242"},
  {id: "CF", label: "Central African Republic", code: "236"},
  {id: "CD", label: "Democratic Republic of the Congo", code: "243"},
  {id: "CZ", label: "Czech Republic", code: "420"},
  {id: "CY", label: "Cyprus", code: "357"},

  {id: "CR", label: "Costa Rica", code: "506"},
  {id: "CW", label: "Curacao", code: "599"},
  {id: "CV", label: "Cape Verde", code: "238"},
  {id: "CU", label: "Cuba", code: "53"},
  {id: "SZ", label: "Swaziland", code: "268"},
  {id: "SY", label: "Syria", code: "963"},
  {id: "SX", label: "Sint Maarten", code: "599"},
  {id: "KG", label: "Kyrgyzstan", code: "996"},
  {id: "KE", label: "Kenya", code: "254"},
  {id: "SS", label: "South Sudan", code: "211"},
  {id: "SR", label: "Suriname", code: "597"},
  {id: "KI", label: "Kiribati", code: "686"},
  {id: "KH", label: "Cambodia", code: "855"},
  {id: "KN", label: "Saint Kitts and Nevis", code: "1-869"},
  {id: "KM", label: "Comoros", code: "269"},
  {id: "ST", label: "Sao Tome and Principe", code: "239"},
  {id: "SK", label: "Slovakia", code: "421"},
  {id: "KR", label: "South Korea", code: "82"},
  {id: "SI", label: "Slovenia", code: "386"},
  {id: "KP", label: "North Korea", code: "850"},
  {id: "KW", label: "Kuwait", code: "965"},
  {id: "SN", label: "Senegal", code: "221"},
  {id: "SM", label: "San Marino", code: "378"},
  {id: "SL", label: "Sierra Leone", code: "232"},
  {id: "SC", label: "Seychelles", code: "248"},
  {id: "KZ", label: "Kazakhstan", code: "7"},
  {id: "KY", label: "Cayman Islands", code: "1-345"},
  {id: "SG", label: "Singapore", code: "65"},
  {id: "SE", label: "Sweden", code: "46"},
  {id: "SD", label: "Sudan", code: "249"},
  {id: "DO", label: "Dominican Republic", code: "1-809 and 1-829"},
  {id: "DM", label: "Dominica", code: "1-767"},
  {id: "DJ", label: "Djibouti", code: "253"},
  {id: "DK", label: "Denmark", code: "45"},
  {id: "VG", label: "British Virgin Islands", code: "1-284"},
  
  {id: "YE", label: "Yemen", code: "967"},
  {id: "DZ", label: "Algeria", code: "213"},
  {id: "US", label: "United States", code: "1"},
  {id: "UY", label: "Uruguay", code: "598"},
  {id: "YT", label: "Mayotte", code: "262"},
  {id: "UM", label: "United States Minor Outlying Islands", code: "1"},
  {id: "LB", label: "Lebanon", code: "961"},
  {id: "LC", label: "Saint Lucia", code: "1-758"},
  {id: "LA", label: "Laos", code: "856"},
  {id: "TV", label: "Tuvalu", code: "688"},
  {id: "TW", label: "Taiwan", code: "886"},
  {id: "TT", label: "Trinidad and Tobago", code: "1-868"},
  {id: "TR", label: "Turkey", code: "90"},
  {id: "LK", label: "Sri Lanka", code: "94"},
  {id: "LI", label: "Liechtenstein", code: "423"},
  {id: "LV", label: "Latvia", code: "371"},
  {id: "TO", label: "Tonga", code: "676"},
  {id: "LT", label: "Lithuania", code: "370"},
  {id: "LU", label: "Luxembourg", code: "352"},
  {id: "LR", label: "Liberia", code: "231"},
  {id: "LS", label: "Lesotho", code: "266"},
  {id: "TH", label: "Thailand", code: "66"},

  {id: "TG", label: "Togo", code: "228"},
  {id: "TD", label: "Chad", code: "235"},
  {id: "TC", label: "Turks and Caicos Islands", code: "1-649"},
  {id: "LY", label: "Libya", code: "218"},
  {id: "VA", label: "Vatican", code: "379"},
  {id: "VC", label: "Saint Vincent and the Grenadines", code: "1-784"},
  {id: "AE", label: "United Arab Emirates", code: "971"},
  {id: "AD", label: "Andorra", code: "376"},
  {id: "AG", label: "Antigua and Barbuda", code: "1-268"},
  {id: "AF", label: "Afghanistan", code: "93"},
  {id: "AI", label: "Anguilla", code: "1-264"},
  {id: "VI", label: "U.S. Virgin Islands", code: "1-340"},
  {id: "IS", label: "Iceland", code: "354"},
  {id: "IR", label: "Iran", code: "98"},
  {id: "AM", label: "Armenia", code: "374"},
  {id: "AL", label: "Albania", code: "355"},
  {id: "AO", label: "Angola", code: "244"},

  {id: "AS", label: "American Samoa", code: "1-684"},
  {id: "AR", label: "Argentina", code: "54"},
  {id: "AU", label: "Australia", code: "61"},
  {id: "AT", label: "Austria", code: "43"},
  {id: "AW", label: "Aruba", code: "297"},
  {id: "IN", label: "India", code: "91"},
  {id: "AX", label: "Aland Islands", code: "358-18"},
  {id: "AZ", label: "Azerbaijan", code: "994"},
  {id: "IE", label: "Ireland", code: "353"},
  {id: "ID", label: "Indonesia", code: "62"},
  {id: "UA", label: "Ukraine", code: "380"},
  {id: "QA", label: "Qatar", code: "974"},
  {id: "MZ", label: "Mozambique", code: "258"}
]