import IconNotifSucces from "../assets/svg/icon-notif-succes";
import IconNotifInfo from "../assets/svg/icon-notif-warning";
import IconInfosError from "../assets/svg/icon-notif-error";
import { Link } from "react-router-dom"
import ArrowIcon from "../assets/svg/icon-arrow";
const NotifCard = ({
    content,
    classes,
    linkValue,
  }) => {
    return (
      // linkValue ?
       <Link to={linkValue} className={`notif-bar -${classes ? classes : ""}`}>
        <i className="-icon">{getIcon(classes)}</i>
        {getContent(content,linkValue)}
      </Link> 
      // : 
    //    <div className={`notif-bar -${classes ? classes : ""}`}>
    //    <i className="-noicon">{getIcon(classes)}</i>
    //    {getContent(content,linkValue)}
    //  </div>
    );
  };
  const getIcon = (classes) => {
    if (classes && classes.includes("warning")) return <IconNotifInfo />;
    else if (classes && classes.includes("error")) return <IconInfosError />;
    return <IconNotifSucces />;
  };
  
  const getContent = (content,linkValue) => {
    // let name = {fr:false,en:false};
    // switch(linkValue){ //! à supprimer probablement
    //   case "/investissements/financial-transactions":
      
    //     name.fr="transactions financières";
    //     name.en="financial transactions"

    //     break; 
    //   case "/apport-affaire/detail":
      
    //       name.fr="detail apport d'affaires";
    //       name.en="detail apport d'affaires(en)"
  
    //     break; 
    //   case "/documents":
    
    //     name.fr="documents";
    //     name.en="documents"

    //     break; 
      
    // }
    let temp = content.split("%%%")
    // console.log(temp)
    const value=( temp[0]+''+'<i>'+temp[1]+'</i>')
    // console.log(content.replace("%%%transactions financières%%%", `${<Link to={linkValue}>${name.fr}</Link>}`))
    if (content  && linkValue ){ return(
      
     <p className="-content"  dangerouslySetInnerHTML={{__html: value}}>
     
      {/* <i>{name.fr}</i> */}
      
      {/* <Link to={linkValue} className="-link" >
      <ArrowIcon />
        </Link> */}
      </p>
      )}
    else{
      return <p className="-content">{content}</p>;
    }
     
  };


export default NotifCard;
