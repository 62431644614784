export default ()=>(
  <svg width="23px" height="22px" viewBox="0 0 23 22" version="1.1" >
    <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10-Dashboard-paiement-en-attente" transform="translate(-277.000000, -670.000000)" fill="#0062FF">
            <g id="ic_Edit" transform="translate(276.694441, 669.000000)">
                <path d="M12,7 C10.3431458,7 9,5.65685425 9,4 C9,2.34314575 10.3431458,1 12,1 C13.6568542,1 15,2.34314575 15,4 C15,5.65685425 13.6568542,7 12,7 Z M12,5 C12.5522847,5 13,4.55228475 13,4 C13,3.44771525 12.5522847,3 12,3 C11.4477153,3 11,3.44771525 11,4 C11,4.55228475 11.4477153,5 12,5 Z M11,7 L13,7 C14.6568542,7 16,8.34314575 16,10 L16,11 C16,12.6568542 14.6568542,14 13,14 L11,14 C9.34314575,14 8,12.6568542 8,11 L8,10 C8,8.34314575 9.34314575,7 11,7 Z M11,9 C10.4477153,9 10,9.44771525 10,10 L10,11 C10,11.5522847 10.4477153,12 11,12 L13,12 C13.5522847,12 14,11.5522847 14,11 L14,10 C14,9.44771525 13.5522847,9 13,9 L11,9 Z M19,16 C17.3431458,16 16,14.6568542 16,13 C16,11.3431458 17.3431458,10 19,10 C20.6568542,10 22,11.3431458 22,13 C22,14.6568542 20.6568542,16 19,16 Z M19,14 C19.5522847,14 20,13.5522847 20,13 C20,12.4477153 19.5522847,12 19,12 C18.4477153,12 18,12.4477153 18,13 C18,13.5522847 18.4477153,14 19,14 Z M18,16 L20,16 C21.6568542,16 23,17.3431458 23,19 L23,20 C23,21.6568542 21.6568542,23 20,23 L18,23 C16.3431458,23 15,21.6568542 15,20 L15,19 C15,17.3431458 16.3431458,16 18,16 Z M18,18 C17.4477153,18 17,18.4477153 17,19 L17,20 C17,20.5522847 17.4477153,21 18,21 L20,21 C20.5522847,21 21,20.5522847 21,20 L21,19 C21,18.4477153 20.5522847,18 20,18 L18,18 Z M5,16 C3.34314575,16 2,14.6568542 2,13 C2,11.3431458 3.34314575,10 5,10 C6.65685425,10 8,11.3431458 8,13 C8,14.6568542 6.65685425,16 5,16 Z M5,14 C5.55228475,14 6,13.5522847 6,13 C6,12.4477153 5.55228475,12 5,12 C4.44771525,12 4,12.4477153 4,13 C4,13.5522847 4.44771525,14 5,14 Z M4,16 L6,16 C7.65685425,16 9,17.3431458 9,19 L9,20 C9,21.6568542 7.65685425,23 6,23 L4,23 C2.34314575,23 1,21.6568542 1,20 L1,19 C1,17.3431458 2.34314575,16 4,16 Z M4,18 C3.44771525,18 3,18.4477153 3,19 L3,20 C3,20.5522847 3.44771525,21 4,21 L6,21 C6.55228475,21 7,20.5522847 7,20 L7,19 C7,18.4477153 6.55228475,18 6,18 L4,18 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
)