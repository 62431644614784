import React from "react";


import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import texts from '../texts/investment'

import Button from "../components/button";
import InformationTemplate from "../components/informationTemplate";
import IconInfosInfo from "../components/icons/icon-infos-info";


const ConfirmPayView = () => {
  const navigate = useNavigate();
  const {lang} = useSelector(state=>state.persisedReducer.parameters)

  const goToDashboard = () => {
    
      navigate("/dashboard", { replace: true });
    
  };
 
return (
    <div className="container-rstpwd">
      
        <div className="container">
          <InformationTemplate
            classes={"-infos"}
            icon={<IconInfosInfo/>}
            title={texts[lang].infoConfPay}
            parag={texts[lang].infoPayNonAbouti}
          />
          <Button label={texts[lang].returnToDash} icon handleClick={goToDashboard} />
        </div>
    </div>
  );
};
export default ConfirmPayView;
