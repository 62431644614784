const FormRowCheckbox = ({classes, isActive, label, style, handleCheckboxChange, error}) => {

  return (
    <div style={style ? style : {}} className={getClasses(classes, error)}>
      <label className={isActive ? "-checked" : ""}>
        <i></i>
        {
          label && <span dangerouslySetInnerHTML={{__html: label}}></span>
        }
        <input type="checkbox" checked={isActive} onChange={(e) => handleCheckboxChange(e) }/>
      </label>
    </div>
  )

}

const getClasses = (classes, error) => {
  let classe = "form-row-checkbox";
  if ( classes ) classe += " "+ classes;
  if ( error ) classe += " -error";
  return classe;
}

export default FormRowCheckbox;
