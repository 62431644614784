import countries from "../constants/countries";

export const getUUID = () => {
  let date = new Date().getTime();
  let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = (date + Math.random()*16)%16 | 0;
      date = Math.floor(date/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

export const parseCountry = (code) => {
  const country = countries.find(country => country.id === code.toUpperCase())
  if ( country ) return country.label;
  else return false;
}

export function editRegex(regex,minValue=1,maxValue=200){
  const newRegex = regex.toString().replace("{",`{${minValue},`).replace("}",`${maxValue}}`);
  // console.log(newRegex)
  return new RegExp(newRegex.replace("/", "").replace("/", ""));
  
}

