import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import urls from "../../constants/urls";
import texts from "../../texts/documents"


import FormSectionTitle from "../../components/formSectionTitle";
import IconFileDownload from "../../components/icons/icon-file-download";
import { roundNumber } from "../../helpers/roundNumberFunc";

import { LoaderContext } from "../../context/Context";
import { useLayoutEffect, useContext } from 'react';

const CommissionFicheView = () => {
  const loader = useContext(LoaderContext);
  const { user, token } = useSelector((state) => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)
  const [documents, setDocuments] = useState([]);
  //trier les documents par date
  documents.sort((a,b)=>(
    new Date(b.date) - new Date(a.date) 
 ))
  // const [doc,setDoc] = useState(null)

  const headers = {
    headers: {
      "x-auth-token": token,
    },
  };
  useLayoutEffect(() => {
    //Récupreration des investissements
    axios
      .get(`${urls.apiUrl}/users/${user.id}/`, headers)
      .then((res) => {
        setDocuments(res.data.doc);
        loader.onSetIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
      return()=>{
        loader.onSetIsLoading(true);
      }
  }, []);
  // const getDocUrl =
  const getDoc = (name) => {
    if (name) {
      // console.log("heyyy")
      axios({
        url: `${urls.apiUrl}/users/doc/${user.id}/${name}`,
        method: "GET",
        responseType: "blob",
        headers: {
          "x-auth-token": token,
        },
      })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="dashboard-container">
      <div className="dashboard-scontainer -no-padding">
        <FormSectionTitle title={texts[lang].docInfo} classes="-simple-2" />

        <div className="security-form-container">
          <div className="_payment_container">
            <div className="_payment_header">
              <span>{texts[lang].docDate}</span>
              <span>{texts[lang].docMontant}</span>
              <span>{texts[lang].docNature}</span>
            </div>

            <ul className="_payment_list">
              {documents.map((document) => {
                return (
                  <li key={document._id} className="_payment">
                    <div data-label="DATE">
                      {`${new Date(document.date).toLocaleDateString()}` != null
                        ? `${new Date(document.date).toLocaleDateString()}`
                        : "waiting for approval ..."}
                    </div>
                    <div data-label="MONTANT: ">
                      {document.montant ?`${roundNumber(document.montant)
                        .toLocaleString("en-EU")
                        .replaceAll(",", " ")}€`:texts[lang].noPay}{" "}
                    </div>
                    <div data-label="NATURE DU DOCUMENT: ">{document.docType}</div>
                    <div data-label="">
                      <a href="#" onClick={() => getDoc(document.name)}>
                        {" "}
                        <IconFileDownload />
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommissionFicheView;
