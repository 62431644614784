import { useSelector } from 'react-redux';
import Button from './button';
import texts from '../texts/registration';

const FormFooter = ({nbrSteps, currentStep, handlePrev, handleNext, isLoading}) => {

  const parameters = useSelector(state => state.persisedReducer.parameters);
  const {lang} = parameters;
  
  const btnNext = currentStep === nbrSteps ? texts[lang].footerNextButtonLastStepLabel : texts[lang].footerNextButtonLabel;


  return (
    <div className={`form-footer ${currentStep < 2 ? "-first-step" : ""}`}>
      <Button
        isLoading={false}
        label={texts[lang].footerPrevButton}
        classes="btn -left -grey"
        icon
        type="button"
        handleClick={handlePrev}
      />
      <Button
        isLoading={false}
        label={btnNext}
        classes="btn"
        icon
        type="button"
        handleClick={handleNext}
      />
    </div>
  )
}

export default FormFooter;