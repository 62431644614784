import { useSelector } from 'react-redux';
import { useState,useEffect } from 'react';
import axios from 'axios';


import urls from "../../constants/urls";

import InputText from '../inputText';
import FormRow from '../formRow';

import FormSectionTitle from '../formSectionTitle';

const ParrainForm = () => {
  const { token, user } = useSelector((state) => state.persisedReducer.user);
  const headers = {
    headers: {
      "x-auth-token": token,
    },
  };
 
  const [parrainFirstName, setParrainFirstName] = useState(null);
  const [parrainEmail, setParrainEmail] = useState(null);
  const [parrainLastName, setParrainLastName] = useState(null);
  const [parrainPhone, setParrainPhone] = useState(null);
  const [parrainPseudo, setParrainPseudo] = useState(null);


  useEffect(() => {
    axios.get(`${urls.apiUrl}/users/parain/${user.id}`, headers)
    .then((res)=>{
     setParrainFirstName(res.data.parain.firstname)
     setParrainLastName(res.data.parain.lastname)
     setParrainEmail(res.data.parain.email)
     setParrainPhone(res.data.parain.phone)
     if(res.data.parain.pseudo){
      setParrainPseudo(res.data.parain.pseudo)
     }
     })
  }, []);

  return <>

    <FormSectionTitle title={"INFORMATIONS PERSONNELLES DE MON RÉFÉRENT"} />

    {parrainPseudo&&
      <FormRow classes={"-two"}>
        <InputText
        label={"Pseudo de mon référent"}
        value={parrainPseudo}
        readonly={true}
      />
      <InputText
        label={"Adresse mail de mon référent"}
        value={parrainEmail?parrainEmail:""}
        readonly={true}  
      />

      </FormRow>  
    }
    {!parrainPseudo&&
     <> <FormRow classes={"-two"}>
        <InputText
          label={"Nom de mon référent"}
          value={parrainLastName?parrainLastName:""}
          readonly={true}
        />
        <InputText
          label={"Prénom de mon référent"}
          value={parrainFirstName?parrainFirstName:""}
          readonly={true}
        />
      </FormRow>

      <FormRow classes={"-two"}>
      <InputText
        label={"Adresse mail de mon référent"}
        value={parrainEmail?parrainEmail:""}
        readonly={true}  
      />
      <InputText
        label={"Télephone de mon référent"}
        value={parrainPhone?parrainPhone:""}
        readonly={true}
      />
    </FormRow>

      </>


    }
   

  </>
}


export default ParrainForm;


