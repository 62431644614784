import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { LoaderContext } from "../../context/Context";
import { useContext } from 'react';
import { useLayoutEffect } from 'react';
import InfosBar from '../../components/infosBar';
import texts from "../../texts/apportAffaire"
import FormSectionTitle from '../../components/formSectionTitle';
import Button from '../../components/button';
import { Link } from 'react-router-dom';
import IconPlus from '../../components/icons/icon-plus';
import axios from 'axios';
import urls from '../../constants/urls';
import ArrowIcon from "../../assets/svg/icon-arrow";
import InputText from '../../components/inputText';




const ListeContact = () => {
    const {lang} = useSelector(state=>state.persisedReducer.parameters)
    const loader = useContext(LoaderContext);
    const { user,token} = useSelector(state => state.persisedReducer.user);
    const headers = {
      headers : {
        "x-auth-token": token
      }
    }
    const [contacts, setContacts] = useState([]);

    const [contactSearch, setContactSearch] = useState("");

    useLayoutEffect(()=>{ 
      // console.log(user.id)
      axios.get(`${urls.apiUrl}/prospect`, headers).then(res=>{
        // console.log(res.data)
        setContacts(res.data)
      })
      .catch(err=>{
        console.log(err)
      })
      // axios.get(`${urls.apiUrl}/prospect/front/search`, headers).then(res=>{
      //   console.log("Ici le call",res.data)
      // })
      loader.onSetIsLoading(false);
      return()=>{
        loader.onSetIsLoading(false);
      }
    },[])

    const handleContactSearchChange = (e)=>{
      setContactSearch(e.target.value)


    }
    const handleContactSearchSubmit = (e)=>{
      e.preventDefault();
      
    const names = contactSearch.split(' ');
    let pseudo = '';
    let firstname = '';
    let lastname = '';

    if (names.length > 1) {
      pseudo = names[0];
      lastname = names[1];
      firstname = names[2];
    } else {
      pseudo = contactSearch;
      firstname = contactSearch;
      lastname = contactSearch;
    }
   
    axios.get(`${urls.apiUrl}/prospect/front/${user.id}/search`, {
      params: {
        pseudo,
        firstname,
        lastname,
      }
    })
      .then(res => {
        // console.log(res.data);
        setContacts(res.data);
      })
      .catch(err => {
        console.log(err);
      });
    
    }
    useLayoutEffect(()=>{ 
      if(contactSearch === ""){
      axios.get(`${urls.apiUrl}/prospect`, headers).then(res=>{
        // console.log(res.data)
        setContacts(res.data)
      })
      .catch(err=>{
        console.log(err)
      })
      // axios.get(`${urls.apiUrl}/prospect/front/search`, headers).then(res=>{
      //   console.log("Ici le call",res.data)
      // })
      loader.onSetIsLoading(false);
      return()=>{
        loader.onSetIsLoading(false);
      }
    }},[contactSearch])

  // const getRandomColor = () => {
  //     const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F"];
  //     let hexaCode = "";
  //     for (let i = 0; i < 6; i++)
  //       hexaCode += codes[Math.floor(Math.random() * codes.length)];
  //     return `#${hexaCode}`;
  //   };

  const colors = ['red', 'green', 'blue', 'purple', 'orange', 'pink', 'cyan', 'magenta', 'teal', 'lime', 'olive', 'maroon', 'navy', 'brown', 'black', 'gray', 'silver', 'gold', 'tan', 'beige', 'salmon', 'plum', 'peach'];
  let currentIndex = 0;

  const getNextColor = () => {
    const nextColor = colors[currentIndex];
    currentIndex = (currentIndex + 1) % colors.length;
    return nextColor;
  }
    return (
        <>
        <div className="layout-AA-container">
        <div className="-contact-list-container">
        <div className="-button-container">
        <Link
            className="-button-wrapper"
            to="/add-contact"
          >
            <div className="-icon">
              <IconPlus/>
            </div>
            <button>{texts[lang].addContact}</button>
          </Link>

          </div>
          <div className="information-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].infoPrealableContact}</h4>
            </div>
            <div className="-information">

                <InfosBar
                classes="-error"
                content={
                  texts[lang].infoPrealableContactContent
                }
      
              />
              <InfosBar
              classes="-info"
              content={
                texts[lang].infoFormContactContentTwo
              }
            />
             </div>
          </div>
             <div className="contact-container">
            <div className="contact-scontainer -no-padding">

              <form onSubmit={handleContactSearchSubmit} className="contact-form-section-title -simple-2">
               <h4 className="-texte">{texts[lang].MesContact}</h4>
               {/* <div className="Search-bar">
               <InputText
               required={false}
                classes={"search"}
                value={contactSearch}
                handleInputChange={(e) => { handleContactSearchChange(e) }}
                handleInputClick = {() => {setContactSearch('')}}
                placeholder={"Recherche par contact"}

              />
               <Button
                classes={"btn-search"}
                label={"Ok"}
                type={"submit"}
              />
              </div> */}
              </form>


              <div className="security-form-container">
                <div className="_contact_container">
                  <div className="_contact_header">
                    <span></span>
                    <span>{texts[lang].contactNomPrenom}</span>
                    <span>{texts[lang].constactPeudo}</span>
                    <span>{texts[lang].contactTel}</span>
                    <span>{texts[lang].contactEmail}</span>
                    <span>{texts[lang].statutInvest}</span>
                    <span>{texts[lang].statutAffaire}</span>
                    <span></span>
                  </div>

                  <ul className="_contact_list">
                    {contacts.map((contact, index) => (

                      <li className="_contact " key={index}>
                        <div className="_picto">
                        <a
                          style={{ backgroundColor: getNextColor() }}
                        >{`${(contact.lastname?contact.lastname:"")
                          .charAt(0)
                          .toUpperCase()}${(contact.firstname?contact.firstname:"")
                          .charAt(0)
                          .toUpperCase()}`}</a>
                      </div>  
                      { contact.lastname || contact.firstname?
                          <div data-label="NOM ET PRENOM :"> {contact.lastname} {contact.firstname} </div>
                          :<div data-label="NOM ET PRENOM :"> {texts[lang].noDataReturnedMessage } </div>}
                          <div data-label="PSEUDO :">{contact.pseudo?contact.pseudo :texts[lang].noDataReturnedMessage } </div>
                          <div data-label="TELEPHONE :">{contact.phone?contact.phone:texts[lang].noDataReturnedMessage}</div>
                          <div data-label="EMAIL :">{contact.mail?contact.mail:texts[lang].noDataReturnedMessage} </div>
                          <div data-label="STATUT D'INVESTISSEMENT :"
                         className={contact.investor.investStatus===`${texts[lang].investorStatusContent[0]}`? "-succes" :contact.investor.investStatus===`${texts[lang].investorStatusContent[1]}` ? "-failed" :contact.investor.investStatus===`${texts[lang].investorStatusContent[2]}`? "-waiting" :""}>
                         {contact.investor.investStatus?contact.investor.investStatus:texts[lang].noDataReturnedMessage}</div>
                         <div data-label="STATUT D'AFFAIRES : "
                            className={contact.business.businessStatus===`${texts[lang].AAStatutContent[0]}`? "-succes" :contact.business.businessStatus===`${texts[lang].AAStatutContent[1]}` ? "-failed" :contact.business.businessStatus===`${texts[lang].AAStatutContent[2]}`? "-waiting":"" }>
                            {contact.business.businessStatus?contact.business.businessStatus:texts[lang].noDataReturnedMessage}
                          </div>
                          <Link
                            className="_icon"
                            to={`/list-contact/contact-personal-info/${contact._id}`}>
                            <ArrowIcon />
                            <p>{texts[lang].seeContact}</p>
                           </Link>
                          
                      </li>

                    ))}

                  </ul>
                </div>
              </div>
            </div>
          </div>

             </div>
          </div>
              
                 </>
    );
};

export default ListeContact;