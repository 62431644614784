
import { useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import SubNav from '../components/subnav';
import texts from '../texts/apportAffaire'

const EditUserLayout = () => {
  const { lang } = useSelector(state => state.persisedReducer.parameters)
  const { id } = useParams();

  const links = [
    {
      id: 1,
      link: `/list-contact/contact-personal-info/${id}`,
      label: texts[lang].infoFormONeContact,
      isActive: false,
    },
    {
      id: 2,
      link: `/list-contact/contact-social-network/${id}`,
      label: texts[lang].socialNetwork,
      isActive: false,
    },
    {
      id: 3, 
      link: `/list-contact/contact-investment-interest/${id}`,
      label: texts[lang].projectInterest,
      isActive: false,
    },
    {
      id: 4, 
      link: `/list-contact/contact-notes/${id}`,
      label: texts[lang].notesAndAppointments,
      isActive: false,
    }
   
  ];

  return (
    <div className="layout-dashboard-container -edit">
      <SubNav
        links={links}
      />
      <Outlet />
    </div>
  )

}

export default EditUserLayout;