import React, { useState, useEffect } from "react";

import axios from "axios";
import { useSelector } from "react-redux";
import InfosBar from "../components/infosBar";
import texts from "../texts/reseau"

import FormSectionTitle from "../components/formSectionTitle";
import urls from "../constants/urls";

import { LoaderContext } from "../context/Context";
import { useLayoutEffect, useContext } from 'react';

const MyNetworkView = () => {

  const loader = useContext(LoaderContext);
  const { user, token } = useSelector((state) => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)
  const headers = {
    headers: {
      "x-auth-token": token,
    },
  };
  const [userReseau, setUserReseau] = useState([]);

  // const getRandomColor = () => {
  //   const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F"];
  //   let hexaCode = "";
  //   for (let i = 0; i < 6; i++)
  //     hexaCode += codes[Math.floor(Math.random() * codes.length)];
  //   return `#${hexaCode}`;
  // };
  const colors = ['red', 'green', 'blue', 'purple', 'orange', 'pink', 'cyan', 'magenta', 'teal', 'lime', 'olive', 'maroon', 'navy', 'brown', 'black', 'gray', 'silver', 'gold', 'tan', 'beige', 'salmon', 'plum', 'peach'];
  let currentIndex = 0;

  const getNextColor = () => {
    const nextColor = colors[currentIndex];
    currentIndex = (currentIndex + 1) % colors.length;
    return nextColor;
  }

  useLayoutEffect(() => {
    axios.get(`${urls.apiUrl}/reseau/parrain/${user.id}`,headers)
    .then((res) => {
      loader.onSetIsLoading(false);
      let users = res.data.filter((user) => {
        return user.depth === 1 && user.filleule;
      });
      // console.log(users)
      setUserReseau(users);
    });
    return()=>{
      loader.onSetIsLoading(true);
    }
  }, []);
  return (
    <div className="layout-profile-container">
      <div className="information-container -apport">
        <div className="-title">
          <h4 className="-texte">{texts[lang].infoPrealable} </h4>
        </div>
        <div className="-information">
          <InfosBar
            classes="-info"
            content={texts[lang].infoPrealableContent}
          />
        </div>
      </div>
      <div className="reseau-container">
        <div className="reseau-scontainer -no-padding">
          <FormSectionTitle title={texts[lang].listeFilleul.toUpperCase()} classes="-simple-2" />

          <div className="security-form-container">
            <div className="_reseau_container">
              <div className="_reseau_header">
                <span></span>
                <span>{texts[lang].rNom}</span>
                <span>{texts[lang].rPrenom}</span>
                <span>{texts[lang].rTel} </span>
                <span>{texts[lang].rEmail} </span>
                <span>{texts[lang].rRole} </span>
              </div>

              <ul className="_reseau_list">
                {userReseau?.map((users) => {
                  return (
                     <li key={users.filleule._id} className="_reseau">
                      <div className="_picto">
                        <a
                          style={{ backgroundColor: getNextColor() }}
                        >{`${users.filleule.lastname 
                          .charAt(0)
                          .toUpperCase()}${users.filleule.firstname
                          .charAt(0)
                          .toUpperCase()}`}</a>
                      </div>
                      <div data-label="NOM :">{users.filleule.lastname}</div>
                      <div data-label="PRENOM :">
                        {users.filleule.firstname}
                      </div>
                      <div data-label="TÉLÉPHONE :">{users.filleule.phone}</div>
                      <div data-label="EMAIL :">{users.filleule.email}</div>
                      <div data-label="ROLE :">{users.filleule.role[0]==="User" ? "Utilisateur" : ""}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyNetworkView;
