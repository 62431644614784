export default {
  fr: {
    //mail validation
    title: "Votre email a été validé avec succès",
    error: "Vous pouvez vous conecter sur votre dashboard pour commencer l'aventure Vizio Capital",

    alreadyValidateTitle: "Votre email a déjà été validé",
    alreadyValidateParag: "Vous avez déjà validé votre email, nous vous invitons à vous connecter directement sur votre compte personnel.",

    errorTitle: "Une erreur c'est produite lors de la validation de votre email",
    errorParag: "Une erreur s'est produite lors de la validation de votre email, Veuillez cliquer sur le lien envoyé par email ou <a href=\"https://viziocapital.com/contact/\" target=\"_blank\">contacter l'équipe Vizio Capital</a>.",

  },
  en: {
    
    title: "Your email has been successfully validated.",
    error: "You can connect to your dashboard to start a new Vizio Capital adventure.",

    alreadyValidateTitle: "Your email address has already been validated.",
    alreadyValidateParag: "Your email address has already been validated, please connect to access to your account dashboard.",

    errorTitle: "An error occured in the process of email validation.",
    errorParag: "An error occured in the process of email validation, please try again the process or <a href=\"https://viziocapital.com/contact/\" target=\"_blank\">contact Vizio Capital team</a>",
  }
}