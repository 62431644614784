export default {
  fr: {
    //* Lien https://app.viziocapital.com/invest

    
    //1ère partie
    labelInfoTitle: "INFORMATION PREALABLES",
    labelInfoText: 'Veuillez sélectionner votre stratégie. Vous avez le choix d’investir sur le Moyen ou le Long terme via les sociétés ci-dessous . Vous devez consulter le détail de chaque investissement dans la <a href="https://viziocapital.com/notes-dinformations/" target="_blank">note d’information</a> et les <a href="https://viziocapital.com/document-dinformation-synthetique/" target="_blank">DIS</a> relatifs pour vous informer complètement. Pour plus d’informations, merci de contacter l’administrateur par email à infos@viziocapital.com ou par téléphone au 00 32 460 22 82 82.',

    labelCtInvestheader: "Vizio",
    labelCtInvestsubHeaderOne: "Investir sur le Moyen terme",
    labelCtInvestsubHeaderTwo: "Investir sur le long terme",

    labelDescObjectif: "Objectif de la collecte:",
    labelDescInterest: "Interêts:",
    labelDescDelay: "Délai de placement:",
    labelDescRendement: "Rendement cible:",
    labelDescMinimum: "Investissement minimum:",

    labelBtnfooter: "Investir dans Vizio ",

    investReal:"Investir dans Vizio Real Estate",
    investCapital:"Investir dans Vizio Capitalisation",


    //2ème partie

    labelInvstInfosHeader: "Informations préalables",
    labelInvstInfosText: "Veuillez sélectionner votre méthode de paiement. Une fois le délai de souscription (Vous trouverez plus d’informations concernant les périodes dans la note d’information ou le DIS) terminé et vos fonds reçus par la société, votre obligation sera activée et disponible sur cette plateforme ainsi que dans le registre des obligataires. Si vous n’arrivez pas à procéder au paiement ou si une erreur survient, veuillez nous contacter par mail à : paiement@viziocapital.com. Veilliez à prendre connaissance des notes d’informations, des documents d’informations synthétiques ainsi que les termes et conditions attachés, présents en bas de page. <p><b>Nous ne prenons pas en charge les frais bancaires.</b></p> ",

    labelCardPayHeader: "Paiement par carte bancaire",
    labelCardPayInfos: "Une fois le montant sélectionné et les conditions validées, vous serez redirigés vers une plateforme de paiement. Une fois le paiement réalisé, vous serez redirigés vers la plateforme. En cas d’échec du paiement, veuillez recommencer la procédure depuis le début.",
    labelCardPayAmount: "Sélectionner le montant a investir en € ",
    labelCardPayBtn: "Payer par carte",
    labelCardPayMaxi: "Montant maximum de 2000€",

    labelBankPayHeader: "Paiement par virement bancaire",
    labelBankPayInfos: "Pour chaque virement bancaire, il est obligatoire d’inscrire votre numéro de référence de paiement lors de votre virement. Sans ce numéro, votre transaction n’aboutira pas et vous serez remboursés.",
    labelBankPayAmount: "Sélectionner le montant a investir en € ",
    labelBankPayBtn: "Payer par virement",
    labelBankPayMaxi: "Montant maximum de 50 000€",
    cbDisclamerChoices: [
      "Je confirme et certifie que les fonds servant à régler la présente transaction ont une origine licite au sens des différentes règlementations belges et internationales de lutte contre l’utilisation du système financier aux fins de blanchiment et de financement du terrorisme et que la transaction ne constitue pas une opération visée par ces législations comme participant à ces actes.",
      "Je certifie sur l'honneur l'exactitude des informations.",
      "J'ai conscience du risque de perte partielle ou totale perte de l’investissement.",
      "J'ai pris connaissances des CGU, de la politique de respect de la vie privée des disclaimer et de la note d'information et des Termes et Conditions des Obligations concernant cet investissement.",
    ],
    virDisclamerChoices: [
      "Je confirme et certifie que les fonds servant à régler la présente transaction ont une origine licite au sens des différentes règlementations belges et internationales de lutte contre l’utilisation du système financier aux fins de blanchiment et de financement du terrorisme et que la transaction ne constitue pas une opération visée par ces législations comme participant à ces actes.",
      "Je certifie sur l'honneur l'exactitude des informations.",
      "J'ai conscience du risque de perte partielle ou totale perte de l’investissement.",
      "J'ai pris connaissances des CGU, de la politique de respect de la vie privée des disclaimer et de la note d'information et des Termes et Conditions des Obligations concernant cet investissement.",
    ],
    paysBank: "Sélectionner le pays de votre banque",
    montantRealCbChoices: [
      "Sélectionner un montant",
      
      "1000.00",
    
      "2000.00"
    ],
    montantCapitalCbChoices: [
      "Sélectionner un montant",
      "500.00",
      "1000.00",
      "1500.00",
      "2000.00"
    ],
    //3ème partie

    labelBigInvstHeader: "Comment investir à partir de 50 000€ ? ",
    labelBigInvstSubHeader: "Vous souhaitez investir un montant plus important?",
    labelBigInvstText: "Nous sommes une organisation professionnelle soucieuse de s’assurer que chaque investissement soit adapté. C’est pourquoi nous vous encourageons à prendre rendez-vous avec notre direction pour un accompagnement personnalisé et sur-mesure.",
    labelBigInvstBtn: "Prendre rendez-vous",

    //ConfirmePaymentView
    infoConfPay: "Si vous êtes allé au bout de votre paiement, félicitations ! Vous recevrez dans quelques minutes une validation par email.",
    infoPayNonAbouti: "Si votre paiement n'a pas abouti, nous vous invitons à recommencer depuis l'interface Vizio Capital dans un délai de 20 minutes.",
    // infoConfPay: "Merci, il ne vous reste plus qu'à effectuer de votre côté votre virement bancaire ! ",
    // infoPayNonAbouti: "Surtout, n'oublier pas d'indiquer votre numéro de transaction sans quoi votre virement sera annulé.",
    returnToDash: "Retourner au Tableau de bord",

    gotoDetail:"En savoir plus",

  },
  en: {
       //* Lien https://app.viziocapital.com/invest

    
    //1ère partie
    labelInfoTitle: "INFORMATION PREALABLES",
    labelInfoText: 'Veuillez sélectionner votre stratégie. Vous avez le choix d’investir sur le Moyen ou le Long terme via les sociétés ci-dessous . Vous devez consulter le détail de chaque investissement dans la <a href="https://viziocapital.com/notes-dinformations/" target="_blank">note d’information</a> et les <a href="https://viziocapital.com/document-dinformation-synthetique/" target="_blank">DIS</a> relatifs pour vous informer complètement. Pour plus d’informations, merci de contacter l’administrateur par email à infos@viziocapital.com ou par téléphone au 00 32 460 22 82 82.',

    labelCtInvestheader: "Vizio",
    labelCtInvestsubHeaderOne: "Investir sur le Moyen terme",
    labelCtInvestsubHeaderTwo: "Investir sur le long terme",

    labelDescObjectif: "Objectif de la collecte:",
    labelDescInterest: "Interêts:",
    labelDescDelay: "Délai de placement:",
    labelDescRendement: "Rendement cible:",
    labelDescMinimum: "Investissement minimum:",

    labelBtnfooter: "Investir dans Vizio ",

    investReal:"Investir dans Vizio Real Estate",
    investCapital:"Investir dans Vizio Capitalisation",


    //2ème partie

    labelInvstInfosHeader: "Informations préalables",
    labelInvstInfosText: "Veuillez sélectionner votre méthode de paiement. Une fois le délai de souscription (Vous trouverez plus d’informations concernant les périodes dans la note d’information ou le DIS) terminé et vos fonds reçus par la société, votre obligation sera activée et disponible sur cette plateforme ainsi que dans le registre des obligataires. Si vous n’arrivez pas à procéder au paiement ou si une erreur survient, veuillez nous contacter par mail à : paiement@viziocapital.com. Veilliez à prendre connaissance des notes d’informations, des documents d’informations synthétiques ainsi que les termes et conditions attachés, présents en bas de page. <p><b>Nous ne prenons pas en charge les frais bancaires.</b></p> ",

    labelCardPayHeader: "Paiement par carte bancaire",
    labelCardPayInfos: "Une fois le montant sélectionné et les conditions validées, vous serez redirigés vers une plateforme de paiement. Une fois le paiement réalisé, vous serez redirigés vers la plateforme. En cas d’échec du paiement, veuillez recommencer la procédure depuis le début.",
    labelCardPayAmount: "Sélectionner le montant a investir en € ",
    labelCardPayBtn: "Payer par carte",
    labelCardPayMaxi: "Montant maximum de 2000€",

    labelBankPayHeader: "Paiement par virement bancaire",
    labelBankPayInfos: "Pour chaque virement bancaire, il est obligatoire d’inscrire votre numéro de référence de paiement lors de votre virement. Sans ce numéro, votre transaction n’aboutira pas et vous serez remboursés.",
    labelBankPayAmount: "Sélectionner le montant a investir en € ",
    labelBankPayBtn: "Payer par virement",
    labelBankPayMaxi: "Montant maximum de 50 000€",
    cbDisclamerChoices: [
      "Je confirme et certifie que les fonds servant à régler la présente transaction ont une origine licite au sens des différentes règlementations belges et internationales de lutte contre l’utilisation du système financier aux fins de blanchiment et de financement du terrorisme et que la transaction ne constitue pas une opération visée par ces législations comme participant à ces actes.",
      "Je certifie sur l'honneur l'exactitude des informations.",
      "J'ai conscience du risque de perte partielle ou totale perte de l’investissement.",
      "J'ai pris connaissances des CGU, de la politique de respect de la vie privée des disclaimer et de la note d'information et des Termes et Conditions des Obligations concernant cet investissement.",
    ],
    virDisclamerChoices: [
      "Je confirme et certifie que les fonds servant à régler la présente transaction ont une origine licite au sens des différentes règlementations belges et internationales de lutte contre l’utilisation du système financier aux fins de blanchiment et de financement du terrorisme et que la transaction ne constitue pas une opération visée par ces législations comme participant à ces actes.",
      "Je certifie sur l'honneur l'exactitude des informations.",
      "J'ai conscience du risque de perte partielle ou totale perte de l’investissement.",
      "J'ai pris connaissances des CGU, de la politique de respect de la vie privée des disclaimer et de la note d'information et des Termes et Conditions des Obligations concernant cet investissement.",
    ],
    paysBank: "Sélectionner le pays de votre banque",
    montantRealCbChoices: [
      "Sélectionner un montant",
      
      "1000.00",
    
      "2000.00"
    ],
    montantCapitalCbChoices: [
      "Sélectionner un montant",
      "500.00",
      "1000.00",
      "1500.00",
      "2000.00"
    ],
    //3ème partie

    labelBigInvstHeader: "Comment investir à partir de 50 000€ ? ",
    labelBigInvstSubHeader: "Vous souhaitez investir un montant plus important?",
    labelBigInvstText: "Nous sommes une organisation professionnelle soucieuse de s’assurer que chaque investissement soit adapté. C’est pourquoi nous vous encourageons à prendre rendez-vous avec notre direction pour un accompagnement personnalisé et sur-mesure.",
    labelBigInvstBtn: "Prendre rendez-vous",

    //ConfirmePaymentView
    infoConfPay: "Si vous êtes allé au bout de votre paiement, félicitations ! Vous recevrez dans quelques minutes une validation par email.",
    infoPayNonAbouti: "Si votre paiement n'a pas abouti, nous vous invitons à recommencer depuis l'interface Vizio Capital dans un délai de 20 minutes.",
    // infoConfPay: "Merci, il ne vous reste plus qu'à effectuer de votre côté votre virement bancaire ! ",
    // infoPayNonAbouti: "Surtout, n'oublier pas d'indiquer votre numéro de transaction sans quoi votre virement sera annulé.",
    returnToDash: "Retourner au Tableau de bord",

    gotoDetail:"En savoir plus",

  },
 
}