import axios from 'axios';
import { useState } from 'react'
import {  useSelector } from "react-redux";
// import FormSectionTitle from '../../components/formSectionTitle';
// import Button from '../../components/button';
import { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import urls from '../../constants/urls'
import Card from '../../components/card';
import texts from '../../texts/myinvest';
import { roundNumber } from '../../helpers/roundNumberFunc';
import InfosBar from '../../components/infosBar';
import { LoaderContext } from "../../context/Context";
import { useLayoutEffect, useContext } from 'react';

const MyInvestView = ()=>{
  const loader = useContext(LoaderContext);
  const  {user,token} =useSelector(state => state.persisedReducer.user)
  const {lang} = useSelector(state=>state.persisedReducer.parameters)

  const headers = {
    headers : {
      "x-auth-token": token
    }
  }

  useLayoutEffect(()=>{ //Récupreration des investissements
    // axios.get(`${urls.apiUrl}/investment/user-invest/${user.id}` , headers)
    axios.get(`${urls.apiUrl}/investment/user/all/${user.id}` , headers)
    .then((res)=>{
      let investmentss =res.data
      axios.get(`${urls.apiUrl}/fonds/display`, headers)
      .then((res)=>{
       let fonds =res.data
       let fondRealEstate = (fonds.filter(i=>i.name==="Real-Estate"))
       let fondCapitalisation = fonds.filter(i=>i.name==="Capitalisation")
       const fondPrelever = fonds.filter(i=>i.name==="Vizio-group")
       //todo : Faire les calcules dans le back ???
       let initial_value = 0;
       setMontantEstate((investmentss.map((i)=>i.fond === fondRealEstate[0]._id && (roundNumber (i.montant))).reduce((prev,next)=> prev+next , initial_value)))
       setMontantCapitalisation((investmentss.map((i)=>i.fond === fondCapitalisation[0]._id && (roundNumber (i.montant))).reduce((prev,next)=> prev+next, initial_value)))
       setMontantPrelever((investmentss.map((i)=>i.fond === fondPrelever[0]._id && (roundNumber (i.montant))).reduce((prev,next)=> prev+next, initial_value)))
       loader.onSetIsLoading(false);
      })
    
    }).catch((err)=>{
      // console.log(err)
    })
    return()=>{
      loader.onSetIsLoading(true);
    }
  
  },[])
  const [MontantCapitalisation, setMontantCapitalisation] = useState(0);
  const [MontantEstate, setMontantEstate] = useState(0);
  const [MontantPrelever, setMontantPrelever] = useState(0);

return(

  <>
  <div className='-big-card-container'>
    <div className="information-container -info-invest">
      <div className="-title">
        <h4 className="-texte">{texts[lang].infoPrealableLabel}</h4>
      </div>
      <div className="-information">
          <InfosBar
            classes="-info"
            content={texts[lang].infoPrealableContent}
          />
        </div>
    </div>
    <h3 className="title">{texts[lang].labelRealTitle}</h3>
    <div className="dashboard-container -card-container">
      <Card
      classes={"first-elmt"}
      header={texts[lang].labelCardCapitalHeader}
      amount={roundNumber(MontantEstate) ? roundNumber(MontantEstate).toLocaleString('en-EU').replaceAll(',', ' ') + "€" : "0€"}
   
      text={texts[lang].labelRealCapText}
      btnlabel={texts[lang].labelCapBtn}
      btnLink="/invest"
      />

      <Card
      header={texts[lang].labelCardValueHeader}
      amount={"0€"}
      btnDisable={true}
      text={texts[lang].labelRealValueText}
      btnlabel={texts[lang].labelValueBtn}
      btnLink ="/investissements/withdraw"
      />

      <Card
      header={texts[lang].labelCardTotalValueHeader}
      amount={"0€"}
      text={texts[lang].labelRealTotalValueText}
      />
     
      
      </div>
  </div>
    {/*  */}
    <div className='-big-card-container'>
    <h3 className="title">{texts[lang].labelCapitalTitle}</h3>
    <div className="dashboard-container -card-container">
      <Card
      classes={"first-elmt"}
        header={texts[lang].labelCardCapitalHeader}
        amount={roundNumber(MontantCapitalisation) ? roundNumber(MontantCapitalisation).toLocaleString('en-EU').replaceAll(',', ' ') + "€" : "0€"}
        
        text={texts[lang].labelCapitalCapText}
        btnlabel={texts[lang].labelCapBtn}
        btnLink="/invest"
        />

        <Card
        header={texts[lang].labelCardValueHeader}
        amount={"0€"}
        btnDisable={true}
        text={texts[lang].labelCapitalValueText}
        btnlabel={texts[lang].labelValueBtn}
        btnLink ="/investissements/withdraw"
        />

        <Card
        header={texts[lang].labelCardTotalValueHeader}

        amount={"0€"}
        text={texts[lang].labelCapitalTotalValueText}
        />
    </div>
    </div>
    {/* { user.role.includes("foundator") && */}
    { MontantPrelever>0 &&
    <div className='-big-card-container'>
    <h3 className="title">Vizio Group</h3>
    <div className="dashboard-container -card-container">
      <Card
      classes={"first-elmt"}
        header={"Mon capital"}
        amount={roundNumber(MontantPrelever) ? roundNumber(MontantPrelever).toLocaleString('en-EU').replaceAll(',', ' ') + "€" : "0€"}
        
        text={"Mon capital investi dans Vizio Group"}
        // btnlabel={"Investir"}
        // btnLink="/invest"
        />

        <Card
        header={"Mes plus values disponibles"}
        amount={"0€"}
        btnDisable={true}
        text={"Mes plus values disponibles dans Vizio Group"}
        btnlabel={"Retirer"}
        btnLink ="/investissements/withdraw"
        />

        <Card
        header={"Total plus value"}

        amount={"0€"}
        text={"Total plus value dans  Vizio Group"}
        />
    </div>
    </div>}
  </>
)
}
export default MyInvestView