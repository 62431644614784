import axios from 'axios'
import { useSelector } from "react-redux";
import urls from '../constants/urls'
import Button from '../components/button'
import { useState } from 'react'
import InfosBar from '../components/infosBar';
import InfosBarWithLink from '../components/infosBarWithLink';
import { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import InputSelect from '../components/inputSelect';
import FormRowCheckbox from '../components/formRowCheckbox';
import IconArrowColored from '../assets/svg/navbar-arrow-colored';
import iconrealestate from '../assets/img/vizio-real-estate.png'
import iconcapitalisation from '../assets/img/vizio-capitalisation.png'
import virementAmount from '../texts/virement-amount';
import texts from '../texts/investment';
import { LoaderContext } from "../context/Context";
import { useContext } from 'react';
import { useLayoutEffect } from 'react';
// import React, { Component } from 'react'
// import FirstInvestementView from './FirstInvestementView';
// import InputText from '../components/inputText'

const InvestementView = ()=>{

  const { user, token } = useSelector(state => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)
  const loader = useContext(LoaderContext);

  
  
  const headers = {
    headers : {
      "x-auth-token": token
    }
  }

useLayoutEffect(()=>{ //Récupreration des Fonds et de l'utilisateur pour approcher le real-time
  axios.get(`${urls.apiUrl}/fonds/` , headers)
  .then((res)=>{
    setFonds(res.data)
    // console.log(res.data);
    setRealEstateId(res.data.filter(i=>i.name ==="Real-Estate")[0]._id)
    setCapitalisationId(res.data.filter(i=>i.name ==="Capitalisation")[0]._id)
      axios.get(`${urls.apiUrl}/users/${user.id}`, headers)
      .then((res)=>{
        setHaveDoneQuizz(res.data.hasDoneInvestQuizz)
        // setAlreadyPayment(res.data.lastPay.status)
        axios.get(`${urls.apiUrl}/payment/mollie/transac` , headers)
        .then((res)=>{
          setTransactions(res.data)
          loader.onSetIsLoading(false)
        }).catch((err)=>{console.log(err)})
      }).catch(err=>console.log(err.response.data))
  }).catch((err)=>{console.log(err)})
  return()=>{
    loader.onSetIsLoading(true);
  }
},[])
  

const [confirmUrl, setConfirmUrl] = useState(false)
const [fonds, setFonds] = useState([])
const [shouldDoQuizz, setShouldDoQuizz] = useState(false)
const [haveChoiseFond ,setHaveChoiseFond] = useState("")
const [realEstateid, setRealEstateId]=useState(false)
const [capitalisationId, setCapitalisationId]=useState(false)
const [transactions, setTransactions]= useState([])

const [haveDoneQuizz, setHaveDoneQuizz] = useState(false)


// console.log(user)

// const [nbCbDisclaimer, setNbCbDisclaimer] = useState(0)
// const [nbVirDisclaimer, setNbVirDisclaimer] = useState(0)

// const [cbDisclaimer, setCbDisclaimer] = useState([
//   {
//     id: 0,
//     label: texts[lang].cbDisclamerChoices[0],
//     value: false, 
//   },
//   {
//     id:1,
//     label: texts[lang].cbDisclamerChoices[1],
//     value:false,
//   },
//   {
//     id:2,
//     label: texts[lang].cbDisclamerChoices[2],
//     value: false,
//   },
//   {
//     id:3,
//     label: texts[lang].cbDisclamerChoices[3],
//     value: false,
//   }
// ])
// const [virDisclaimer, setVirDisclaimer] = useState([
//   {
//     id: 0,
//     label: texts[lang].virDisclamerChoices[0],
//     value: false, 
//   },
//   {
//     id:1,
//     label: texts[lang].virDisclamerChoices[1],
//     value:false,
//   },
//   {
//     id:2,
//     label: texts[lang].virDisclamerChoices[2],
//     value: false,
//   },
//   {
//     id:3,
//     label: texts[lang].virDisclamerChoices[3],
//     value: false,
//   }
// ])


//? Mettre cbInputs et virInputs dans les texts ? 
// const cbInputs = [
//   {
//     id:1,
//     label: texts[lang].montantCbChoices[0],
//   },
//   {
//     id:2,
//     label: texts[lang].montantCbChoices[1],
//   },
//   {
//     id:3,
//     label : texts[lang].montantCbChoices[2],
//   },
//   {
//     id:4,
//     label : texts[lang].montantCbChoices[3],
//   },
//   {
//     id:5,
//     label : texts[lang].montantCbChoices[4],
//   }
// ]
// const virInputs = virementAmount
// const styleCheckBox = {
//   marginBottom: 20,
//   marginTop: 20
// }

// const handleCbSelectChange =(e)=>{
//   setCbValue(e.target.value)
//   setCbAmount(cbInputs[e.target.value -1].label)
//   if(e.target.value === "1"){
//     setShowCbDisclaimer(false)
//   }else{
//     setShowCbDisclaimer(true)
//   }
// }
// const handleVirSelectChange=(e)=>{
//   setVirValue(e.target.value)
//   setVirAmount(virInputs[e.target.value -1].label)
//   if(e.target.value === "1"){
//     setShowVirDisclaimer(false)
//   }else{
//     setShowVirDisclaimer(true)
//   }
// }


// const handleCbDisclaimerChange = (e,id)=>{
//   const newCbDisclaimer = cbDisclaimer
//   var total = 0
//   newCbDisclaimer[id].value = e.target.checked
//   newCbDisclaimer.map(disclaimer=>{
//     if(disclaimer.value === true) total ++
//   })
//   setCbDisclaimer([...newCbDisclaimer])
//   if(total === 4) setCbButtonDisable(false)
//   else setCbButtonDisable(true)
// }
// const handleVirDisclaimerChange = (e,id)=>{
//   const newVirDisclaimer = virDisclaimer
//   var total = 0
//   newVirDisclaimer[id].value = e.target.checked
//   newVirDisclaimer.map(disclaimer=>{
//     if(disclaimer.value === true) total ++
//   })
//   setVirDisclaimer([...newVirDisclaimer])
//   if(total === 4) setVirButtonDisable(false)
//   else setVirButtonDisable(true)
// }

const handleFondClick = (id) =>{

 
 
  if(!haveDoneQuizz){
    setShouldDoQuizz(true)
    return
  }
   setHaveChoiseFond(id)
 
}

// console.log(haveDoneQuizz)
// console.log("commit test");
// const handlePayClick = (method)=>{
//   if(method === "creditcard") var amount = cbAmount;
//   if(method === "banktransfer") var amount = virAmount;
//   const body ={
//     value: amount,
//     lastname: user.lastname,
//     email: user.email,
//     fond: haveChoiseFond,
//   }
//   const paybody ={
//     value: amount,
    
//     lastname: user.lastname,
//     email: user.email,
//     orderId: order_id,
//     method: method,
//     fond: haveChoiseFond,
//   }
//   if(method === "banktransfer") {alert("Pensez à bien entrer le numéro de référence du paiement via votre banque au moment du virement bancaire.")}
//   axios.post(`${urls.apiUrl}/payment/mollie/init`,body, headers)
//   .then((res)=>{
//     // console.log(res)
//     paybody.orderId = res.data
    
//     setOrderId(res.data)
//     //FAIRE MAINTENANT MON CALL API POUR PAYER ET ENREGSITRER EN BASE (METTRE A JOUR)
//     axios.post(`${urls.apiUrl}/payment/mollie/pay`,paybody, headers)
//     .then((res)=>{setConfirmUrl(res.data)})
//     .catch((err)=>{console.log(err)})
//   })
//   .catch((err)=>{console.log(err)})
// }

// const handleCallWithVizio = (e)=>{
//   setGoToVizioLink(true)
// }
// if(goToVizioLink)
//     return <Navigate to={"//calendly.com/viziocapital/investir-des-montants-plus-importants"} />;
if(shouldDoQuizz) return <Navigate to = {"/invest-profile"}/> //! si quizz d'investissemnt non encore rempli
if(confirmUrl) return  window.location.replace(confirmUrl);

if(haveChoiseFond === realEstateid) //! real-estate
  return <Navigate to={"/invest/real-estate"}/>

  if(haveChoiseFond === capitalisationId)//! capitalisation
  return <Navigate to={"/invest/capitalisation"}/>

 





  
  return (
    
    // alreadyPayment === null || alreadyPayment!=="open" ?
    <div className="layout-profile-container">


              <div className="information-container -apport">
              <div className="-title">
                <h4 className="-texte">{texts[lang].labelInfoTitle}</h4>
              </div>
              <div className="-information">
                <InfosBarWithLink
                  classes="-info"
                  content={texts[lang].labelInfoText} />
              </div>
            </div>
            <div className='container-invst '>
                {fonds &&
                  fonds.map((fond ,id) => {
                    return (
                      <div key={id} className='card'>
                        <div className='card-header'>
                          {texts[lang].labelCtInvestheader} {fond.name} : {fond.information}
                        </div>

                        <div className="card-body">
                          <h2>
                          {fond.name === "Real-Estate" ? texts[lang].labelCtInvestsubHeaderOne : texts[lang].labelCtInvestsubHeaderTwo }
                          </h2>
                          <div className='-fond_image'>

                            <img src={fond.imgPath} alt="image du fond" />     {/* //? img vient de base ? admin vizio ?  */}
                          </div>

                          <div className='-fond_description'>
                            {/* {//!Possibilité de map sur un sous object du fond en reflexions} */}
                            <div className='-fond_sub_desc'>
                              <p>
                                {texts[lang].labelDescObjectif}
                                <span>{fond.objectif}</span>
                              </p>
                            </div>
                            <div className='-fond_sub_desc'>
                              <p>
                                {texts[lang].labelDescInterest}
                                <span>{fond.interest}</span>
                              </p>
                            </div>
                            <div className='-fond_sub_desc'>
                              <p>
                                {texts[lang].labelDescDelay}
                                <span>{fond.delay}</span>
                              </p>
                            </div>
                            <div className='-fond_sub_desc'>
                              <p>
                                {texts[lang].labelDescRendement}
                                <span>{fond.rendement}</span>
                              </p>
                            </div>
                            <div className='-fond_sub_desc'>
                              <p>
                                {texts[lang].labelDescMinimum}
                                <span>{fond.minimum}</span>
                              </p>

                            </div>
                            
                          
                          </div>

                          <div className='-fond_footer'>
                            {/* <Link to={fond.objectif}>En savoir plus</Link> */}
                            <Button label={`${texts[lang].labelBtnfooter} ${fond.name}`} handleClick={() => { handleFondClick(fond._id); } } icon />
                          </div>
                          <div className='-detail'>
                              <Link  to={fond.detailLink}>{texts[lang].gotoDetail}</Link>
                            </div>
                        </div>
                      </div>
                    );

                  })}
              </div>
     
      
     
       
    </div>
    
  )
}

export default InvestementView


