export default [
  { id: "default", label: "Sélectionner" },
  { id: "fr_BE", label: "Belgique - français" },
  { id: "nl_BE", label: "België - vlaams" },
  { id: "fr_FR", label: "France" },
  { id: "nl_NL", label: "Pays Bas" },
  { id: "en_GB", label: "United Kingdom" },
  { id: "de_DE", label: "Deutschland" },
  { id: "en_US", label: "United" },
  { id: "de_CH", label: "Switzerland" },
  { id: "es_ES", label: "España" },
  { id: "ca_ES", label: "España - Catalán" },
  { id: "pt_PT", label: "Portugal" },
  { id: "it_IT", label: "Italia" },
  { id: "de_AT", label: "Österreich" },
  { id: "nb_NO", label: "Norge" },
  { id: "sv_SE", label: "Sverige" },
  { id: "fi_FI", label: "Suomi" },
  { id: "da_DK", label: "Danmark" },
  { id: "is_IS", label: "Ísland" },
  { id: "hu_HU", label: "Magyarország" },
  { id: "pl_PL", label: "Polska" },
  { id: "lv_LV", label: "Latvija" },
  { id: "lt_LT", label: "Lietuva" }
];
