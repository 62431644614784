import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  
  feedback: {
    show: false,
    type: "",
    title: "",
    message: "",
    messages: []
  },

  langues: [
    {code: "fr", label: "Français"},
    {code: "en", label: "Anglais"}
  ],

  lang: "fr",

  redirect: {
    // status: false,
    // link: "",
    // params: [],
    // deconnexion: false,
    status: false,
    link: "",
    deconnexion: false,
  }
}

const parametersSlice = createSlice({
  name: "parameters",
  initialState: initialState,
  reducers: {
    
    switchLang: (state, action) => {
      state.lang = action.payload;
    },

    setFeedback: (state, action) => {

      if ( action.payload ) {
        state.feedback = {
          show: action.payload?.show || false,
          type: action.payload?.type || "",
          title: action.payload?.title || "",
          message: action.payload?.message || "",
          messages: action.payload?.messages || [],
        }
      }else {
        state.feedback = {
          ...state.feedback,
          show: false,
        }
      }
    },

    setRedirect: (state, action) => {
      const { params, status, deconnexion } = action.payload;
      let { link } = action.payload;
      state.redirect = {
        status: status || false,
        link: params && params.length > 0 ? link += "?" + params.map( param => `${param.name}=${param.value}`).join("&") : link || "",
        deconnexion: deconnexion || false,
      }
    }
    
  }
})

export default parametersSlice;