import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Paramètres du form
  steps: [
    {
      id: 1,
      label: {
        fr: "Informations de connexion",
        en: "Login Information",
      },
    },
    {
      id: 2,
      label: {
        fr: "Informations personnelles",
        en: "Personal informations",
      },
    },
    {
      id: 3,
      label: {
        // fr: "Vérification d’identité",
        // en: "Identity Verification"
        fr: "Validation émail",
        en: "Email validation",
      },
    },
    {
      id: 4,
      label: {
        // fr: "Validation de l’inscription",
        // en: "Confirmation of registration"
        fr: "Vérification d'indentité",
        en: "Identity Verification",
      },
    },
  ],
  currentStep: 1,

  // Connexion
  email: {
    value: "",
    error: false,
  },
  password: {
    value: "",
    error: false,
  },

  isProfessionnel: {
    value: false,
    error: false,
  },

  lastName: {
    value: "",
    error: false,
  },
  firstName: {
    value: "",
    error: false,
  },

  pseudo:{
    value:"",
    error: false,
  },

  date: {
    value: "",
    error: false,
  },

  nativeCountry: {
    value: "France",
    error: false,
  },
  codeTelCountry: {
    value: "+33",
    error: false,
  },
  gender:{
    value:"Homme",
    error:false,
  },
  telNum: {
    value: "",
    error: false,
  },

  postalAddress: {
    value: "",
    error: false,
  },
  postalCode: {
    value: "",
    error: false,
  },
  city: {
    value: "",
    error: false,
  },
  residenceCountry: {
    value: "France",
    error: false,
  },

  isContacts: {
    value: false,
    error: false,
  },
  contacts: [],

  socialDenomination: {
    value: "",
    error: false,
  },
  companyNumber: {
    value: "",
    error: false,
  },
  headquartersAdress: {
    value: "",
    error: false,
  },
  companyPostalCode: {
    value: "",
    error: false,
  },
  municipality: {
    value: "",
    error: false,
  },
  companyCountry: {
    value: "France",
    error: false,
  },
  companyFunction:{
    value:"",
    error:false
  },
  isTaxAddressDifferent: {
    value: false,
    error: false,
  },

  cgu: {
    value: false,
    error: false,
  },
  disclaimer: {
    value: false,
    error: false,
  },
  isOkPersonalInfos: {
    value: true,
    error: false,
  },
};

const initialStateTest = {
  steps: [
    {
      id: 1,
      label: {
        fr: "Informations de connexion",
        en: "Login Information",
      },
    },
    {
      id: 2,
      label: {
        fr: "Informations personnelles",
        en: "Personal informations",
      },
    },
    {
      id: 3,
      label: {
        fr: "Vérification d’identité",
        en: "Identity Verification",
      },
    },
    {
      id: 4,
      label: {
        fr: "Validation de l’inscription",
        en: "Confirmation of registration",
      },
    },
  ],
  currentStep: 2,

  // Connexion
  email: {
    value: "rabahghiles@gmail.com",
    error: false,
  },
  password: {
    value: "realmadrid",
    error: false,
  },

  isProfessionnel: {
    value: true,
    error: false,
  },

  lastName: {
    value: "ghiles",
    error: false,
  },
  firstName: {
    value: "rabah",
    error: false,
  },

  date: {
    value: "2000-01-01",
    error: false,
  },

  nativeCountry: {
    value: "France",
    error: false,
  },
  gender:{
    value: "Homme",
    error: false,
  },
  telNum: {
    value: "0551512183",
    error: false,
  },

  postalAddress: {
    value: "2b place de l'union europ",
    error: false,
  },
  postalCode: {
    value: "15450",
    error: false,
  },
  city: {
    value: "massy",
    error: false,
  },
  residenceCountry: {
    value: "France",
    error: false,
  },

  isContacts: {
    value: true,
    error: false,
  },
  contacts: [
    {
      _id: "mlsnffmlzenfze",
      firstName: "Ali",
      lastName: "Ghiles",
      email: "alighiles@gmail.com",
      address: "Ouadhia centre",
      zipCode: "15450",
      city: "Ouadhia",
      country: "DZ",
      phone: "0224948485",
    },
  ],

  socialDenomination: {
    value: "rghiles production",
    error: false,
  },

  companyNumber: {
    value: "234543456545",
    error: false,
  },
  headquartersAdress: {
    value: "Paris 15eme je ne sais pas",
    error: false,
  },
  companyPostalCode: {
    value: "75001",
    error: false,
  },
  municipality: {
    value: "Paris",
    error: false,
  },
  companyCountry: {
    value: "France",
    error: false,
  },
  companyFunction:{
    value:"",
    error:false
  },

  isTaxAddressDifferent: {
    value: true,
    error: false,
  },

  cgu: {
    value: true,
    error: false,
  },
  disclaimer: {
    value: true,
    error: false,
  },
  isOkPersonalInfos: {
    value: true,
    error: false,
  },
};

const registerFormSlice = createSlice({
  name: "registerForm",
  initialState: initialState,
  reducers: {
    editField: (state, action) => {
      state[action.payload.name] = action.payload.field;
    },

    incrementStep: (state, action) => {
      if (state.currentStep < state.steps.length) state.currentStep++;
    },
    decrementStep: (state, action) => {
      if (state.currentStep > 0) state.currentStep--;
    },
    setStepToSumsub: (state, action) => {
      state.currentStep = 3;
    },

    addContact: (state, action) => {
      state.contacts.push(action.payload);
    },
    editContact: (state, action) => {
      if (state.contacts.length <= 0) state.contacts.push(action.payload);
      else
        state.contacts = state.contacts.map((contact) =>
          contact._id === action.payload._id ? action.payload : contact
        );
    },
    deleteContact: (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact._id !== action.payload
      );
    },
  },
});

export default registerFormSlice;
