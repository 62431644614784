import { Link } from "react-router-dom"
import Button from "./button"


const copyToClipBoard = ()=>{
  const copyText = document.getElementById("copy-affiliate")

 
  copyText.select();
  document.execCommand('copy')
  alert("le lien d'affiliation a été copié")



// 
}
const CardAA =({header, amount,amount2, text,text2, btnlabel, btnDisable, action,affiliateLink, classes, amountclass, btnLink, society1, society2})=>{
 return( <div className={`card ${classes ? classes : ""}`}>
        <div className="card-header">
          <label>{header}</label>
        </div>
        <div className = "card-body">
          <div className="-texts">
          <p className= {amountclass ?`-amount ${amountclass}` : `-amount`}>{amount}</p>
          <p className="-card-body-text">{text}<span>{society1}</span> </p>
          </div>

          <div>
          <p className= {amountclass ?`-amount ${amountclass}` : `-amount`}>{amount2}</p>
          <p className="-card-body-text">{text2} <span>{society2}</span> </p>
          </div>

          {btnlabel &&
          <Link to={btnLink ? btnLink : ""}>
          <Button
            label={btnlabel}
            disable={btnDisable}
            classes="-card-body-btn" 
            handleClick={action}/></Link> }
            {affiliateLink && <div className="-link" >
              {/* <span className="copy-affiliate"onClick={copyToClipBoard}>{affiliateLink}</span> */}
              <input type="text" id="copy-affiliate" className="copy-affiliate" value={`app.viziocapital.com/registration/${affiliateLink}`} readOnly onClick={copyToClipBoard} />
              </div>}
            </div>
      </div>)

}
export default CardAA