import React, { Component } from 'react';
import axios from 'axios';
import {connect} from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import { setUser, setToken } from '../redux/store';

import Card from '../components/card';
import CardAA from '../components/cardAA'
import NotifCard from '../components/notifCard';


import urls from '../constants/urls';

import DashboardIconGoal from '../assets/svg/dashboard-icon-goal';
import IconInvestir from '../assets/svg/icon-investir';
import DashboardIconNetworking from '../assets/svg/dashboard-icon-networking';
import DashboardIconNotif from '../assets/svg/dashboard-icon-notif';


import texts from '../texts/dashboard';
import { roundNumber } from '../helpers/roundNumberFunc';
import { LoaderContext } from "../context/Context";


// import Button from '../components/button';
// import iconInvestir from '../assets/svg/icon-investir';
// import InfosBar from '../components/infosBar';



class DashboardView extends Component {
  static contextType = LoaderContext;

  constructor(props){
    super(props);
    
    this.state = {
      payStatus : this.props.user.user.lastPay ? this.props.user.user.lastPay.status : "",
      montantEstate:0,
      montantCapitalisation:0,
      comissionDispo:0,
      comissionDispoCapital:0,
      totalwithdrawCapital:0,
      totalwithdraw:0,
      totalCommCapital:0,
      totalComm:0,
      totalLevee:0,
      notif:[]
    }
  }

  componentDidMount(){
    const  user = this.props.user.user
    const token = this.props.user.token
    const headers = {headers: {"x-auth-token": token}}

    axios.get(`${urls.apiUrl}/prospect/`, headers).then(res=>{
      // console.log(res.data)
    })

    axios
    .get(`${urls.apiUrl}/users/${user.id}`, headers)
    .then((res)=>{

      this.setState({payStatus: res.data.lastPay.status})

      // axios.get(`${urls.apiUrl}/investment/user-invest/${user.id}` , headers)
      
      axios.get(`${urls.apiUrl}/fonds/business`, headers)
      .then((res)=>{
        
        let fondsBusiness = res.data
        let fondAAReal= fondsBusiness.filter(i=>i.name==="Apport-Affaire-Real-Estate")[0]
        let fondAACapital = (fondsBusiness.filter(i=>i.name==="Apport-Affaire-Capitalisation"))[0]

        //? on set le context ici pour les test, il sera placer a la fin des call api a terme . 
        // this.context.onSetIsLoading(false);
        

      axios.get(`${urls.apiUrl}/investment/user/all/${user.id}` , headers)
      .then((res)=>{
        let investments = res.data
        axios.get(`${urls.apiUrl}/fonds/display`, headers)

        .then((res)=>{

          let fonds = res.data
          let fondRealEstate = (fonds.filter(i=>i.name==="Real-Estate"))
          let fondCapitalisation = fonds.filter(i=>i.name==="Capitalisation")
          let fondPrelever = fonds.filter(i=>i.name==="Vizio-group")
          let initial_value=0;
          let montantEstate = ((investments.map((i)=>i.fond === fondRealEstate[0]._id && (parseInt (i.montant))).reduce((prev,next)=> prev+next , initial_value)))
          let montantCapitalisation =((investments.map((i)=>i.fond === fondCapitalisation[0]._id && (parseInt (i.montant))).reduce((prev,next)=> prev+next, initial_value)))
          let montantPrelever = ((investments.map((i)=>i.fond === fondPrelever[0]._id && (parseInt (i.montant))).reduce((prev,next)=> prev+next, initial_value)))

          this.setState({
            montantCapitalisation,
            montantEstate,
            montantPrelever
          });
          
          axios
          .get(`${urls.apiUrl}/investment/user/${user.id}`, headers)
          .then((res)=>{
            
            let comissions = res.data.filter((i) => {
              return i.status === "validé" && i.fond ===fondAAReal._id;  //!rouute pour get les fonds_id ....
            })
            let totalComm = comissions
                                  .map((b) => parseInt(b.montant))
                                  .reduce((prev, next) => prev + next,0)
            this.setState({totalComm : totalComm})
            let comissionsCapital = res.data.filter((i) => {
              return i.status === "validé" && i.fond ===fondAACapital._id; //!rouute pour get les fonds_id ....
            })
            let totalCommCapital = comissionsCapital
                                  .map((b) => parseInt(b.montant))
                                  .reduce((prev, next) => prev + next,0)
            this.setState({totalCommCapital : totalCommCapital})
          axios
          .get(`${urls.apiUrl}/payment/mollie/withdraw`, headers)
          .then((res) => {
            axios.get(`${urls.apiUrl}/request/getNotif/user/${user.id}`,headers)
            .then((res) => {

              // console.log(res.data)
              //? le context sera défini ici, mais pour les test nous le remontont au 1er call api : 
              this.context.onSetIsLoading(false);  

              this.setState({notif:res.data.sort((a,b)=>(
                new Date(b.date) - new Date(a.date)
            )).slice(0,5)})
            })
            if(res.data.length <1 )return;
            let totalwithdraw = res.data
              .map((i) => i.status === "processed" &&  i.compte === "Apport-Affaire-Real-Estate" && parseInt(i.amount))
              .reduce((prev, next) => prev + next, 0);
              // console.log(totalwithdraw)
              this.setState({totalwithdraw : totalwithdraw});

              let totalwithdrawCapital = res.data
              .map((i) => i.status === "processed" &&  i.compte === "Apport-Affaire-Capitalisation" && parseInt(i.amount))
              .reduce((prev, next) => prev + next, 0);
              // console.log(totalwithdraw)
              this.setState({totalwithdrawCapital : totalwithdrawCapital});
           
          })
          .catch ( err => {
            console.log(err.response.data);
          });
         
          })
          .catch ( err => console.log(err.response.data))
        })
        .catch ( err => console.log(err.response.data))
      })
      .catch ( err => console.log(err.response.data))
    })
    .catch ( err => console.log(err.response.data))
    })
    .catch( err => console.log(err.response.data))

    axios.get(`${urls.apiUrl}/reseau/parrain/${user.id}`,headers)
    .then((res) => {
      let users = res.data.filter((user) => {
        return user.depth === 0;
      });
      
       let totalLevee = users
                            .map((i) => parseInt(i.totalLeveeReseau))
                            .reduce((prev, next) => prev + next ,0)
        this.setState({totalLevee:totalLevee})
     })
  .catch( err => console.log(err.response.data))
}


  RemoveNotif = (e) => {
    let notif = this.state.notif.filter((i) => {
      return i._id !== e;
    });
    this.setState({ notif });
}

HandleHideClick =(notifs)=>{ 
  const token = this.props.user.token
  const headers = {headers: {"x-auth-token": token}}
  
  let body = {
    id:notifs
  }
  axios.patch(`${urls.apiUrl}/request/hideNotification/${notifs}`,body,headers)
  .then((res) => {
    console.log(res.data);
    this.RemoveNotif(notifs)
  })
  .catch((err) => {
    console.log(err.response.data);
  });
 }
  
 
 
  render() {
    const  user = this.props.user.user
    const commDispo = this.state.totalComm - this.state.totalwithdraw
    const commDispoCapital = this.state.totalCommCapital - this.state.totalwithdrawCapital
    const tatalCommission = this.state.totalComm
    const tatalCommissionCapital = this.state.totalCommCapital
    const totalLevee = this.state.totalLevee
    const notifs = this.state.notif
    const {lang} = this.props.parameters
    
    
    

    return <>
      <div className="layout-profile-container">
      {/* à reverifier */}
      <div className="-big-card-container">
      <div className="title">
            <DashboardIconNotif/> 
            <h3>{texts[lang].labelTitleNotification }</h3>
          </div>
        <ul>
            {notifs.sort((a,b)=>(
        new Date(b.date) - new Date(a.date)
    )).map((notif)=>{
              return notif.showOnDashboard && <li key={notif._id}>
                <div className="-notifContainer">
                <NotifCard 
                classes={`${notif.type} `} 
                content={`${notif.body.fr}`}
                linkValue={`${notif.link}`} />
                <button className='-hide-notif'
                onClick={()=>this.HandleHideClick(notif._id)}
                >x</button>
                </div>
                

                </li>
            })}
          </ul>
        </div>
     
        <div className='-big-card-container'>
        
          <div className="title">
            <DashboardIconGoal/> 
            <h3>{texts[lang].labelTitleCptInvest}</h3>
          </div>

          <div className="dashboard-container -card-container">

            <Link to="/invest">
              <button className={`card -btn`} type="button"  onChange={this.redirectTo}>
                <IconInvestir/>
                <p className="btn-text2">{texts[lang].labelBtnInvest}</p>
              </button>
            </Link>

            <Card
              header={texts[lang].labelCardRealInvestHeader}
              amount={(roundNumber(this.state.montantEstate) === false? 0 :roundNumber(this.state.montantEstate).toLocaleString('en-EU').replaceAll(',', ' ')) + "€"}
              text={texts[lang].labelCardRealInvestText}
              btnlabel={texts[lang].labelCardInvestBtn}
              btnLink ="/investissements"
            />

            <Card
              header={texts[lang].labelCardCapitalInvestHeader}
              amount={(roundNumber(this.state.montantCapitalisation) === false? 0 :roundNumber(this.state.montantCapitalisation).toLocaleString('en-EU').replaceAll(',', ' '))  + "€"}
              text={texts[lang].labelCardCapitalInvestText}
              btnlabel={texts[lang].labelCardInvestBtn}
              btnLink ="/investissements"
            />
            {this.state.montantPrelever> 0 &&
              <Card
                header={texts[lang].labelCardPreleverInvestHeader?texts[lang].labelCardPreleverInvestHeader:"Vizio Group"}
                amount={(roundNumber(this.state.montantPrelever) === false? 0 :roundNumber(this.state.montantPrelever).toLocaleString('en-EU').replaceAll(',', ' '))  + "€"}
                text={texts[lang].labelCardPreleverInvestText?texts[lang].labelCardPreleverInvestText:"Mon investissement dans Vizio Group"}
                btnlabel={texts[lang].labelCardInvestBtn}
                btnLink ="/investissements"
              />
            }

          </div>

        </div>

        <div className='-big-card-container'>

          <div className="title">
            <DashboardIconNetworking/> 
            <h3>{texts[lang].labelAffaires}</h3>
          </div>

          <div className="dashboard-container -card-container">

            <Card
              classes={"first-elmt"}
              header={texts[lang].labelCardAffiliateHeader}
              text={texts[lang].labelCardAffiliateText}
              affiliateLink={`${user.affiliate_link}`}
            />

            <CardAA
              header={texts[lang].labelCardAffaireHeader}
              amount={roundNumber(tatalCommission).toLocaleString('en-EU').replaceAll(',', ' ')  + "€"}
              text={texts[lang].labelCardAffaireText}
              text2={texts[lang].labelCardAffaireText}
              amount2={roundNumber(tatalCommissionCapital).toLocaleString('en-EU').replaceAll(',', ' ')  + "€"}
              society1={" Vizio Real Estate."}
              society2={" Vizio Capitalisation."}
              btnlabel={texts[lang].labelCardAffaireBtn}
              btnLink ="/apport-affaire"
            />
            <CardAA
              header={texts[lang].labelCardCommHeader}
              amount={roundNumber(commDispo).toLocaleString('en-EU').replaceAll(',', ' ')  + "€"}
              text={texts[lang].labelCardCommText}
              text2={texts[lang].labelCardCommText}
              amount2={roundNumber(commDispoCapital).toLocaleString('en-EU').replaceAll(',', ' ')  + "€"}
              society1={" Vizio Real Estate."}
              society2={" Vizio Capitalisation."}
              btnDisable={commDispo >= 100|| commDispoCapital>=100 ? false : true}
              btnlabel={texts[lang].labelCardCommBtn}
              btnLink ="/investissements/withdraw"
            /> 


          </div>

        </div> 

      </div>
       
    </>
  }
}
 
const mapStateToProps = state => ({
  user: state.persisedReducer.user,
  parameters: state.persisedReducer.parameters,
})

const actions = {
  setUser,
  setToken
}

export default connect(mapStateToProps, actions)(DashboardView);
// export default DashboardView;