import Button from '../button';
import InputText from '../inputText';

const ResetPasswordForm = ({email, texts, isLoading, handleEmailChange, handleForgotPasswordChangeSubmit}) => {

  return (
    <div>
      <form className="auth-form" onSubmit={handleForgotPasswordChangeSubmit}>
        <h3 className="-title">{texts.resetPassword}</h3>
        <InputText
          style={{
            marginBottom: 10,
          }}
          label={texts.mail}
          value={email.value}
          error={email.error}
          handleInputChange={handleEmailChange}
        />
        <Button
          style={{
            paddingTop: 30,
          }}
          isLoading={isLoading}
          label={texts.buttonResetPassword}
          classes="btn"
          icon={true}
        />
      </form>
    </div>
  )

}

export default ResetPasswordForm;