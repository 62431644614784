
export default () => (

<svg width="47px" height="47px" viewBox="0 0 47 47" version="1.1" >
    <title>Group 6</title>
    <g id="Sprint-2-DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vue-Notifications" transform="translate(-298.000000, -405.000000)">
            <g id="notif-copy" transform="translate(278.000000, 374.000000)">
                <g id="Group-6" transform="translate(20.000000, 31.000000)">
                    <circle id="Oval-Copy-5" fill="#FF0000" opacity="0.1" cx="23.5" cy="23.5" r="23.5"></circle>
                    <line x1="15.3611075" y1="15.4481812" x2="30.8393214" y2="30.9263951" id="Path-3" stroke="#FF0000" strokeWidth="2" stroke-linecap="round"></line>
                    <line x1="15.3611075" y1="15.4481812" x2="30.8393214" y2="30.9263951" id="Path-3" stroke="#FF0000" strokeWidth="2" stroke-linecap="round" transform="translate(23.100214, 23.187288) scale(-1, 1) translate(-23.100214, -23.187288) "></line>
                </g>
            </g>
        </g>
    </g>
</svg>
    )
