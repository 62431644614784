import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { setUser, setToken } from "../redux/store";

import InputPassword from "../components/inputPassword";
import Button from "../components/button";
import InformationTemplate from "../components/informationTemplate";

import texts from "../texts/modificationUser";

import logo from "../assets/img/logo/logo-authentication.svg";
import urls from "../constants/urls";
import FormHeader from "../components/formHeader";

const ResetPwdView = () => {
  const { token } = useParams();

  const { lang } = useSelector(state => state.persisedReducer.parameters);

  const [error, setError] = useState(false);
  
  const [status, setStatus] = useState(false);
  const [tokenn, setTokenn] = useState();
  const [hasPwd, setHasPwd] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToAuth = () => {
    if (hasPwd) {
      navigate("/", { replace: true });
    }
  };
  const handlePasword = (e) => {
    setPassword(e.target.value);
  };
  const handlePasswordConfirm = (e) => {
    if (e.target.value !== password) {
      setconfirmPassword(e.target.value);
      setError(texts[lang].passwordMissmatch);
      setStatus(false);
    } else {
      setconfirmPassword(e.target.value);
      setError(texts[lang].passwordMatch); //??
      setStatus(true);
    }
  };
  const handlePaswwordSubmit = () => {
    if (password === confirmPassword) {
      const body = {
        password: password,
      };
      const headers = {
        headers: {
          "x-auth-token": tokenn,
        },
      };
      axios
        .patch(`${urls.apiUrl}/resetPassword/change`, body, headers)
        .then((res) => {
          dispatch(setUser(false));
          dispatch(setToken(false));
          setHasPwd(true);
          // navigate('/', { replace: true });
        });
    }
  };

  useEffect(() => {
    if (!token || token.length <= 0) navigate("/", { replace: true });
    setIsLoading(true);
    const controller = new AbortController(); //!Afin d'éviter d'avoir 2 call api et donc le 2eme invalide suite a la suppression du token. 
    //! on créer un AbortController(). 
    axios
      .get(`${urls.apiUrl}/resetPassword/token/${token}`)
      .then((res) => {
        const token = res.headers["x-auth-token"];
        setTokenn(token);
        })
      .catch((err) => {
        if (err.response.status === 400) setError(1);
        if (err.response.status === 401) navigate("/", {replace:true} )//!Redirect sauvage si token reset password KO, penser a faire plus propre ...
      })
      .finally(() => setIsLoading(false));
      //! enfin on return ce controller. 
      return () => controller.abort();
  }, []);

  return (
    <div className="container-rstpwd">
      {!hasPwd ? (
        <div className="container">
            <div className="-logo">
              <img src={logo} alt="Vizio-logo" />
              <p>L’investissement participatif</p>
            </div>
          <FormHeader
          title={texts[lang].headerTitle}
          text={texts[lang].headerText} />

            <InputPassword
              style={{
                marginBottom: 10,
              }}
              label={texts[lang].passwordResetLabel}
              value={password}
              error={""}
              handleInputChange={handlePasword} />
            <InputPassword
              style={{
                marginBottom: 10,
              }}
              label={texts[lang].passwordConfirmLabel}
              value={confirmPassword}
              indication={status && error}
              error={!status && error}
              handleInputChange={handlePasswordConfirm} />
            <Button
              label={texts[lang].ctaPasswordReset}
              // icon
              handleClick={handlePaswwordSubmit} />
          </div>
      ) : (
        <div className="container">
          <InformationTemplate
            icon
            title={texts[lang].passwordResetSuccesTitle}
            parag={texts[lang].passwordResetSuccesText}
          />
          <Button label={texts[lang].ctaAuthPage} icon handleClick={goToAuth} />
        </div>
      )}
    </div>
  );
};
export default ResetPwdView;
