const InputText = (props) => {

  const {
    classes,
    value,
    label,
    indication,
    handleDateChange,
    error,
    min,
    max,
    readonly,
  } = props;

  // const handleDateChange = (event) => {
  //   console.log(event.target.value);
  // }


  const required = props.required === false ? false : true;

  return (
    <label className={`input ${classes ? classes : ""} ${error ? "-error" : "" }`}>
      { required ? <span className="-required">{label}</span> : <span>{label}</span> }
      <input
        type="date"
        value={value ? value : ""}
        min={min ? min : ""}
        max={max ? max : ""}
        onChange={handleDateChange}
        readOnly={readonly}
      />
      {indication && <p className={indication ? "-indication" : "" }>{indication}</p>}
      {error && <p className={error ? "-error" : ""}>{error}</p>}
    </label>
  )
}

export default InputText;