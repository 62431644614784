import { Component } from "react";
import Button from "../components/button";
import FormRowCheckbox from "../components/formRowCheckbox";
import headIcon from "../assets/img/icon-hand.png";
import headLightIcon from "../assets/img/icon-hand-light.png";
import dollarsIcon from "../assets/img/icon-dollars.png";
import icChevronLeft from "../assets/img/ic-chevron-left.png"
import InputSelect from "../components/inputSelect";
import InputDatalist from "../components/inputDatalist";
import { connect } from 'react-redux';
import { setUser, setRedirect } from '../redux/store';
import { Navigate } from "react-router-dom";
import axios from "axios";
import texts from "../texts/quizz";
import urls from "../constants/urls";
import TextWithBold from "../components/textWithBold";
import { LoaderContext } from "../context/Context";
class QuizzView extends Component {
  static contextType = LoaderContext;
  constructor(props) {
    super(props);
    const {lang} = this.props.parameters
    
    this.state = {
      disabled: true,
      steps: [
        {
          id: 0,
          question: false,
          choices: false,
          label: false,
          options: false,
          value: false,
          btnText: texts[lang].firstBtnText,
          firstText:texts[lang].firstStepFirstLabel,
          disclamer:false,
          secondText:texts[lang].firstStepSecondLabel
        },
        {
          id: 7,
          question: false,
          choices: false,
          label: false,
          options: false,
          value: false,
          btnText: texts[lang].lastBtnText,
          firstText: texts[lang].lastStepFirstLabel,
          disclamer:false,
          secondText:texts[lang].lastStepSecondLabel
        },
        {
          id: 4,
          question: texts[lang].stepFourLabelQuestion,
          label: false,
          choices: texts[lang].stepFourLabelAnswer,
          type: "text",
          indication:true,
          btnText:texts[lang].stepsBtnText,
          disclamer:false,
          value: [],
        },
        {
          id: 1,
          question: texts[lang].stepOneLabelQuestion,
          choices: false,
          label: texts[lang].indicationLabel,
          options: texts[lang].stepOneLabelAnswer,
          btnText:texts[lang].stepsBtnText,
          indication:false,
          disclamer:false,
          value: [],
        },
        {
          id: 2,
          question: texts[lang].stepTwoLabelQuestion,
          choices: texts[lang].stepTwoLabelAnswer,
          label: false,
          btnText:texts[lang].stepsBtnText,
          indication:true,
          disclamer:false,
          value: [],
        },
        {
          id: 3,
          question: texts[lang].stepThreeLabelQuestion,
          choices: texts[lang].stepThreeLabelAnswer,
          btnText:texts[lang].stepsBtnText,
          indication:true,
          disclamer:false,
          value: [],
        },

        {
          id: 5,
          question: texts[lang].stepFiveLabelQuestion,
          label: texts[lang].indicationLabel,
          choices: false,
          options: texts[lang].stepFiveLabelAnswer,
          indication:false,
          btnText:texts[lang].stepsBtnText,
          disclamer:false,
          value: [],
        },
        {
          id: 6,
          question: texts[lang].stepSixLabel,
          choices: texts[lang].stepSixSubLabel,
          options: texts[lang].stepSixLabelAnswer,
          check: texts[lang].stepSixLabelQuestionCheckBox,
          indication:false,
          btnText:texts[lang].stepsBtnText,
          disclamer:true,
          value: [],
        },
      ],
      currentStep: 0,
      
      
    };
  }

  componentDidMount = () => {
    const { user } = this.props.user;
    this.context.onSetIsLoading(false);  
    if ( user.hasDoneQuiz ) this.props.setRedirect({
      status: true,
      link: "/dashboard",
      params: [],
      deconnexion: false,
    })
  }
  componentWillUnmount(){
    this.context.onSetIsLoading(true);
  }



  // fonction de mise à jour des state pour reponses
  handleChange = (id, value) => {
   
    let obj = this.state.steps.find((o) => o.id === id); //trouver l'objet
   
    // obj.value = [value];
    obj.value = value;
    if(obj.value === obj.options[0]){
      this.setState({ disabled: true });
      obj.value = ""
    }
    value.concat(value);
    const steps = this.state.steps.map((o) => (o.id === obj.id ? obj : o));
    this.setState({ steps });
    if (value) {
      this.setState({ disabled: false });
    }
    

  };

  // fonction de mise à jour des state pour choix multiples....
  handleCheckBoxChange = (id, value, choice) => {
    let obj = this.state.steps.find((o) => o.id === id); //trouver l'objet
    
    if (value.target.checked) {
      obj.value = [...obj.value, choice]
      if (obj.value.length > 0) {
        this.setState({ disabled: false });
      }
      
    }
    else{
      obj.value = obj.value.filter((c) => c !== choice);
      if (obj.value.length === 0) {
        this.setState({ disabled: true });
      }
    }

    const steps = this.state.steps.map((o) => (o.id === obj.id ? obj : o));
    this.setState({ steps });

    
  };

  // fonction de soumission
  handleSubmit = (event) => {
    event.preventDefault();
    let quizz = { quizz: this.state.steps };
    const  user = this.props.user.user
    const token = this.props.user.token
    
    const headers = {
      headers: {
        "x-auth-token": token,
      },
    };
    axios
      .patch(`${urls.apiUrl}/users/${user.id}`, quizz, headers)
      .then((res) => {
        this.props.setUser({
          ...user,
          hasDoneQuiz: true,
        });
        this.props.setRedirect({
          status: true,
          link: "/dashboard",
          params: [],
          deconnexion: false,
        });
        // this.setState({redirect : true})
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //trouver le currentstep
  findCurrentStep = () => {
    return this.state.steps.find((step) => step.id === this.state.currentStep);
  };
  //trouver la valeur de l'étape actuel : 
  findCurrentStepValue = ()=>{
    return this.state.steps.find((step)=>step.id=== this.state.currentStep).value
  }
  //fonction d'incrementation
  incrementStep = () => {
    let step = this.state.currentStep;
    let value = this.findCurrentStepValue(step) //on récupérer la valueur . 
    if (step <= this.state.steps.length - 1) {
      if(value.length === 0){ //pour les questions nous avons un tableau, donc si la longuer de celui-ci est de 0, pas de valeur. 
        return
      }
      step++;
      this.setState({ currentStep: step });
      this.setState({ disabled: true });
    }
  };
  decrementStep = () => {
    let step = this.state.currentStep;
    if (step >= 1) {
      step--;
      this.setState({ currentStep: step });
      this.setState({ disabled: true });

    }
  };

  render() {
    if(this.state.redirect)return(<Navigate to="/dashboard" replace={true}/>)
    const step = this.findCurrentStep();
    console.log(step);
    // console.log(step)
    let choices = step.choices;
    // console.log(choices);
    let opt = step.options;
    let disc= step.disclamer;

    console.log(this.state.steps);
    // let indication = step.indication;
    
    const {lang} = this.props.parameters;

    return (
      <>
        {this.state.currentStep === 0 && (
          <div className="stps">
            {this.state.currentStep === 0 && (
              <div className="container-stp">
                <div className="header-Stp">
                  <img src={headLightIcon} alt="" />
                  <h1>The Quizz de Vizio Capital</h1>
                </div>
                <div className="body-Stp">
                  <h3>{step.firstText}</h3>
                  <br></br>
                  <span>{step.secondText}</span>
                </div>
                <footer className="footer-Stp">
                  <Button
                    isLoading={false}
                    label={step.btnText}
                    classes="-red"
                    icon
                    type="button"
                    handleClick={this.incrementStep}
                  />
                </footer>
              </div>
            )}
          </div>
        )}
        {this.state.currentStep === 7 && (
          <div className="stps">
            {this.state.currentStep === 7 && (
              <div className="container-end">
                <div className="body-end">
                  <h3>{step.firstText}</h3><br/>
                  <span>{step.secondText}</span>
                </div>
                <footer className="footer-end">
                  <Button
                    isLoading={false}
                    label={step.btnText}
                    classes="-red"
                    icon
                    type="button"
                    handleClick={this.handleSubmit}
                  />
                </footer>
              </div>
            )}
          </div>
        )}
        {this.state.currentStep >= 1 && this.state.currentStep <= 6 && (
          <div className="quizz">
            {this.state.currentStep >= 1 && this.state.currentStep <= 6 && (
              <div className="container-quizz">
                {
                  <div className="header">
                    <img src={headIcon} alt="" />
                    <h1>The Quizz de Vizio Capital</h1>
                  </div>
                }
                <div className="body">
                  {this.state.currentStep > 1 && this.state.currentStep <= 6 &&<span className="back" onClick={this.decrementStep}>
                    <img src={icChevronLeft} alt ="" ></img>
                    {texts[lang].backQuestion}
                  </span>}
                  <div className="question">
                  
                    <p>
                      <img src={dollarsIcon}></img>
                      <i>{this.state.currentStep}/{this.state.steps.length - 2}</i>
                    </p>
                    <p>{step.question}</p>
                  </div>
                  {/* Reponses de l'user */}
                  {choices === false && disc ===false && (
                    <div className="reponse">
                      <InputSelect
                        label={step.label}
                        // options={"values"}
                        inputs={opt.map((i)=>{
                          return {
                            id:i,
                            label:i
                          }
                        })}
                        handleSelectChange={(e) =>
                          this.handleChange(step.id, e.target.value)
                        }
                      />
                    </div>
                  )}
                  {/* choix multiples */}
                  {choices !== false && disc===false && (
                    
                      <div className="-reponse">
                        {choices.map((choice, index) => (
                          <div key={index} className="choice">
                            <FormRowCheckbox
                              label={choice}
                              isActive={step.value.includes(choice)}
                              handleCheckboxChange={(value)=>
                                this.handleCheckBoxChange(
                                  step.id,
                                  value,
                                  choice
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    
                  )}

                  {/* fichier a telecharger */}
                  { choices!==false && disc ===true && (
                    <div className="reponse">
                     <ul className="ulQuizz"><TextWithBold content={choices[0]}/>
                      <li className="liQuizz"><TextWithBold content={opt[0]}/></li>
                      <li className="liQuizz"><TextWithBold content={opt[1]}/></li>
                     </ul> 
            
                     <ul className="ulQuizz"><TextWithBold content={choices[1]}/>
                      <li className="liQuizz"><TextWithBold content={opt[2]}/></li>
                      <li className="liQuizz"><TextWithBold content={opt[3]}/></li>
                     </ul> 
                     <div className="separator"></div>
                    <FormRowCheckbox
                              label={step.check}
                              isActive={step.value.includes(step.check)}
                              handleCheckboxChange={(value)=>
                                this.handleCheckBoxChange(
                                  step.id,
                                  value,
                                  step.check
                                )
                              }
                            />
                    </div>
                  )}
                  {/* Changeur de steps */}
                  {
                     this.state.currentStep <= 6 && (
                      <div className="footer ">
                        <div className="indication">
                          {step.indication && texts[lang].inputIndication}
                        </div>
                        <Button
                          isLoading={false}
                          label={step.btnText}
                          classes="-red"
                          icon
                          disable={this.state.disabled}
                          type="button"
                          handleClick={this.incrementStep}
                        />
                     
                      </div>
                    )
                  }
                  {/* boutton de soummision des données */}
                  {
                    this.state.currentStep ===
                      this.state.steps.length && (
                      <footer className="footer ">
                        <Button
                          isLoading={false}
                          label={step.btnText}
                          icon
                          classes="-red"
                          type="submit"
                          handleClick={this.handleSubmit}
                        />

                      
                      </footer>
                    )
                  }
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
};
const mapStateToProps = state => ({
  parameters: state.persisedReducer.parameters,
  user: state.persisedReducer.user,
});
const actions = {
  setUser,
  setRedirect
}

export default connect(mapStateToProps, actions)(QuizzView);
