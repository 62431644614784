import { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import SumsubWebSdk from "@sumsub/websdk-react";

import {
  setUser,
  setToken,
  setStepToSumsub,
  incrementStep,
  switchLang,
  setRedirect,
} from "../redux/store";

import Container from "../components/container";
import StepperHead from "../components/stepperHead";
import FormHeader from "../components/formHeader";
import InformationTemplate from "../components/informationTemplate";
import IconSumsubOk from "../components/icons/icon-compte-created";

import texts from "../texts/registration";
import urls from "../constants/urls";

import IconCompteCreated from '../components/icons/icon-compte-created';
import IconInfosError from '../components/icons/icon-infos-error';

import logo from "../assets/img/logo/logo-authentication.svg";
import { Navigate } from "react-router-dom";
class Sumsub extends Component {

  constructor(props){
    super(props);
    this.texts = texts[this.props.parameters.lang];
    this.state = {
      kyx: {
        status: false,
        icon: "",
        classes: "",
        title: "",
        parag: "",
      }
    }
  }

  componentDidMount() {
    const { user } = this.props.user;
    if(user === null){
      return(<Navigate to="/"></Navigate>)
    }
    if ( user?.has_send_kyx && user?.status_kyx === null  ) {
      this.setState({
        kyx: {
          status: true,
          icon: <IconCompteCreated />,
          classes: "",
          title: this.texts.sumsubStatusOrangeTitle,
          parag: this.texts.sumsubStatusOrangeParag,
        }
      }) 
    }
    else if ( user?.has_send_kyx && user?.status_kyx === "RED" && user?.status_kyx_RED?.flag ==="FINAL"  ) {
      this.setState({
        kyx: {
          status: true,
          icon: <IconInfosError />,
          classes: "-error",
          title: this.texts.sumsubStatusRedFinalTitle,
          parag: this.texts.sumsubStatusRedParag,
        }
      })
    }
    else if ( user?.has_send_kyx && user?.status_kyx === "RED" && user?.status_kyx_RED.flag ==="RETRY"  ) {
      this.setState({
        kyx: {
          status: true,
          icon: <IconInfosError />,
          classes: "-info",
          title: this.texts.sumsubStatusRedRetryTitle,
          parag: this.texts.sumsubStatusRedRetryParag,
        }
      })
    }
    else if ( user?.has_send_kyx ) {
      this.props.setRedirect({
        status: true,
        link: "/dashboard",
        params: [],
        deconnexion: false,
      })
    }
    else this.props.setStepToSumsub();
  }


  render() {

    if ( this.state.kyx.status ) {

      const renewToken = async () => {
        console.log("renewing token")
        const user = this.props.user.user;
        const token = this.props.user.token;
        const headers = {
          headers: {
            "x-auth-token": token,
          },
        };
        const newToken = axios
          .patch(`${urls.apiUrl}/users/${user._id}/kyx/renew`, null, headers)
          .then((res) => {
            return res.data;
          })
          .catch((err) => console.log(err));
        return newToken;
      };

      const messageHandler = (type, payload) => {
        // console.log(type, payload);
        if (type === "idCheck.onApplicantSubmitted") {
          this.props.incrementStep();
        }
      };
      const { user } = this.props.user;
      const config = {
        email: user?.email,
        props: this.props,
      };
      return (
        <>
        <InformationTemplate
          icon={this.state.kyx.icon}
          classes={this.state.kyx.classes}
          title={this.state.kyx.title}
          parag={this.state.kyx.parag}
        />
        {user?.status_kyx_RED?.flag !== "FINAL" &&
        <SumsubWebSdk
          accessToken={this.props.user.user.sumsub_token}
          expirationHandler={renewToken}
          config={config}
          // options={options}
          // onMessage={messageHandler}
          // onError={errorHandler}
        />
        }
        </>
      )
    }

    else 

    {

      const { user } = this.props.user;

      const config = {
        email: user?.email,
        props: this.props,
      };

      const { steps, currentStep } = this.props.registerForm;
      const { lang } = this.props.parameters;

      const renewToken = async () => {
        const user = this.props.user.user;
        const token = this.props.user.token;
        const headers = {
          headers: {
            "x-auth-token": token,
          },
        };
        const newToken = axios
          .patch(`${urls.apiUrl}/users/${user._id}/kyx/renew`, null, headers)
          .then((res) => {
            return res.data;
          })
          .catch((err) => console.log(err));
        return newToken;
      };

      const messageHandler = (type, payload) => {
        // console.log(type, payload);
        if (type === "idCheck.onApplicantSubmitted") {
          this.props.incrementStep();
        }
      };
      
      return (
        <>
          <Container
            sectionClasses="container -full-height"
            divClasses="content-container -full-height"
            sectionStyle={{
              paddingTop: 0,
              paddingBottom: "120",
              width: "100%",
            }}
          >
            <div className="registration-container">
              <div className="-forms-container">
                <div className="-logo">
                  <img src={logo} alt="Vizio-logo" />
                  <p>L’investissement participatif</p>
                </div>
  
                {/* Stepper */}
                <StepperHead steps={steps} current={currentStep} />
  
                {/* Header - Text */}
                <FormHeader
                  title={texts[lang].formHeaderTitle}
                  text={texts[lang].formHeaderSubTitle[currentStep]}
                />
  
                {currentStep === 3 && (
                  <>
                    {/* Message a dynamiser et styliser */}
                    {/* <p>Votre Email a bien été valider</p> */}
                    <SumsubWebSdk
                      accessToken={this.props.user?.user?.sumsub_token}
                      expirationHandler={renewToken}
                      config={config}
                      // options={options}
                      onMessage={messageHandler}
                      // onError={errorHandler}
                    />
                  </>
                )}
  
                {currentStep === 4 && (
                  // todo : insert component here ...
                  <InformationTemplate
                    icon={<IconSumsubOk />}
                    classes="infos"
                    style={{ height: 0 }}
                    title={texts[lang].sumsubSendTitle}
                    parag={texts[lang].sumsubSendParag}
                  />
                )}
              </div>
            </div>
          </Container>
        </>
      );

    }
  }
}

const mapStateToProps = (state) => ({
  registerForm: state.notPersisedReducer.registerForm,
  parameters: state.persisedReducer.parameters,
  user: state.persisedReducer.user,
});
const actions = {
  setUser,
  setToken,
  setStepToSumsub,
  incrementStep,
  switchLang,
  setRedirect,
};

export default connect(mapStateToProps, actions)(Sumsub);
