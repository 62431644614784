import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lastName: {
    value: "",
    error: false,
  },
  pseudo: {
    value:"",
    error: false,
  },
  firstName: {
    value: "",
    error: false,
  },
  email:{
    value: "",
    error: false,
  },
  date: {
    value: "",
    error: false,
  },
  nativeCountry: {
    value: "",
    error: false,
  },
  gender: {
    value:"",
    error:false
  },
  telNum: {
    value: "",
    error: false,
  },
  residenceCountry: {
    value: "",
    error: false,
  },
  postalAddress: {
    value: "",
    error: false,
  },
  city: {
    value: "",
    error: false,
  },
  postalCode: {
    value: "",
    error: false,
  },

  isContacts: {
    value: false,
    error: false,
  },
  contacts: [],

  isProfessionnel: {
    value: false,
    error: false,
  },
  socialDenomination: {
    value: "",
    error: false,
  },
  companyNumber: {
    value: "",
    error: false,
  },
  headquartersAdress: {
    value: "",
    error: false,
  },
  companyPostalCode: {
    value: "",
    error: false,
  },
  municipality: {
    value: "",
    error: false,
  },
  companyCountry: {
    value: "",
    error: false,
  },
}


const editeProfileFormSlice = createSlice({
  name: "editeProfileForm",
  initialState: initialState,
  reducers: {

    editFieldProfile: (state, action) => {
      state[action.payload.name] = action.payload.field;
    },
    
    addContactProfile: (state, action) => {
      state.contacts.push(action.payload);
    },
    
    editContactProfile: (state, action) => {
      if (state.contacts.length <= 0) state.contacts.push(action.payload);
      else state.contacts = state.contacts.map((contact) =>
            contact._id === action.payload._id ? action.payload : contact
          );
    },

    deleteContactProfile: (state, action) => {
      state.contacts = state.contacts.filter(
        (contact) => contact._id !== action.payload
      );
    },

    prepopulateEditProfileForm: (state, action) => {

      const user = action.payload;
      state.email.value = user.email
      state.pseudo.value= user.pseudo
      state.lastName.value = user.lastname;
      state.firstName.value = user.firstname;
      state.date.value = getDate(user.dateOfBirth);
      state.nativeCountry.value = user.country;
      state.telNum.value = user.phone;
      state.residenceCountry.value = user.fiscalCountry;
      state.postalAddress.value = user.address;
      state.city.value = user.city;
      state.postalCode.value = user.zipCode;
      state.isContacts.value = user.personeToContact.length > 0;
      state.contacts = getContacts(user.personeToContact);

      if ( user.type === "Entreprise" ) {

        const entreprise = user.entreprise;

        state.isProfessionnel.value = true;
        state.socialDenomination.value = entreprise.name;
        state.companyNumber.value = entreprise.companyNumber;
        state.headquartersAdress.value = entreprise.address;
        state.companyPostalCode.value = entreprise.zipCode;
        state.municipality.value = entreprise.city;
        state.companyCountry.value = entreprise.country;

      }
      else state.isProfessionnel.value = false;

    }
  },
})


const getContacts = (contacts) => {
  return contacts.map( contact => ({
      _id: contact._id.toString() || "",
      firstname: contact.firstname || "",
      lastname: contact.lastname || "",
      email: contact.email || "",
      address: contact.address || "",
      zipCode: contact.zipCode || "",
      city: contact.city || "",
      country: contact.country || "",
      phone: contact.phone || "",
    })
  )
}

const getDate = (date) => {

  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export default editeProfileFormSlice;