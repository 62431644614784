import axios from 'axios'
import { useSelector } from "react-redux";
import urls from '../constants/urls'
import Button from '../components/button'
import { useState } from 'react'
import InfosBar from '../components/infosBar';
import InfosBarWithLink from '../components/infosBarWithLink';
import { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import InputSelect from '../components/inputSelect';
import FormRowCheckbox from '../components/formRowCheckbox';
import IconArrowColored from '../assets/svg/navbar-arrow-colored';
import iconrealestate from '../assets/img/vizio-real-estate.png'
import iconcapitalisation from '../assets/img/vizio-capitalisation.png'
import virementAmount from '../texts/virement-amount';
import texts from '../texts/investment';
import mollieCountryCode from '../constants/molliePayCountryCod';

import { LoaderContext } from "../context/Context";
import { useLayoutEffect, useContext } from 'react';
import molliePayCountryCod from '../constants/molliePayCountryCod';

const CapitalInvestView = ()=>{


  const loader = useContext(LoaderContext);
  const { user, token } = useSelector(state => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)

  
  
  const headers = {
    headers : {
      "x-auth-token": token
    }
  }

useLayoutEffect(()=>{ //Récupreration des Fonds et de l'utilisateur pour approcher le real-time
  axios.get(`${urls.apiUrl}/fonds/` , headers)
  .then((res)=>{
    setFonds(res.data)
    setHaveChoiseFond(res.data.filter(i=>i.name ==="Capitalisation")[0]._id)
      axios.get(`${urls.apiUrl}/users/${user.id}`, headers)
      .then((res)=>{
        setHaveDoneQuizz(res.data.hasDoneInvestQuizz)
        // setAlreadyPayment(res.data.lastPay.status)
        axios.get(`${urls.apiUrl}/payment/mollie/transac` , headers)
        .then((res)=>{
          setTransactions(res.data)
          loader.onSetIsLoading(false);
        }).catch((err)=>{console.log(err)})
      }).catch(err=>console.log(err.response.data))
  }).catch((err)=>{console.log(err)})
  console.log(locale)

  return()=>{
    loader.onSetIsLoading(true);
  }
},[])
  
const [order_id, setOrderId] = useState(false)
const [cbValue, setCbValue] = useState(false)
const [virValue, setVirValue] = useState(false)
const [confirmUrl, setConfirmUrl] = useState(false)
const [fonds, setFonds] = useState([])
const [shouldDoQuizz, setShouldDoQuizz] = useState(false)
const [haveChoiseFond ,setHaveChoiseFond] = useState("")
const [cbAmount, setCbAmount] = useState(false)
const [virAmount, setVirAmount] = useState(false)
const [cbButtonDisable, setCbButtonDisable] = useState(true)
const [virButtonDisable, setVirButtonDisable] = useState(true)
const [showCbDisclaimer, setShowCbDisclaimer] = useState(false)
const [showVirDisclaimer, setShowVirDisclaimer] = useState(false)
// const [alreadyPayment,setAlreadyPayment] = useState(user.lastPay.status)
const [transactions, setTransactions]= useState([])
const [haveDoneQuizz, setHaveDoneQuizz] = useState(false)
const [goToVizioLink,setGoToVizioLink] =useState(false)
const [locale, setLocale] = useState("default")

// console.log(user)

// const [nbCbDisclaimer, setNbCbDisclaimer] = useState(0)
// const [nbVirDisclaimer, setNbVirDisclaimer] = useState(0)

const [cbDisclaimer, setCbDisclaimer] = useState([
  {
    id: 0,
    label: texts[lang].cbDisclamerChoices[0],
    value: false, 
  },
  {
    id:1,
    label: texts[lang].cbDisclamerChoices[1],
    value:false,
  },
  {
    id:2,
    label: texts[lang].cbDisclamerChoices[2],
    value: false,
  },
  {
    id:3,
    label: texts[lang].cbDisclamerChoices[3],
    value: false,
  }
])
const [virDisclaimer, setVirDisclaimer] = useState([
  {
    id: 0,
    label: texts[lang].virDisclamerChoices[0],
    value: false, 
  },
  {
    id:1,
    label: texts[lang].virDisclamerChoices[1],
    value:false,
  },
  {
    id:2,
    label: texts[lang].virDisclamerChoices[2],
    value: false,
  },
  {
    id:3,
    label: texts[lang].virDisclamerChoices[3],
    value: false,
  }
])

// console.log(locale)

//? Mettre cbInputs et virInputs dans les texts ? 
let i =0;
let amount =[]
while(i<50000){
 let a;
 a = i+500
 i=a
let amountString = i.toString()+".00"
amount.push(amountString)

}
// console.log(amount)
const allInputs = [{id:1,label:texts[lang].montantCapitalCbChoices[0]}]
for (let a=0; a<amount.length; a++){
  let inputs = {id:a+2, label:amount[a]}
  allInputs.push(inputs)
  
}
// console.log(molliePayCountryCod)
// let paytest = {id:allInputs.length+1, label:"1.00"}
// allInputs.push(paytest)
// console.log(allInputs)
const cbInputs = [
  {
    id:1,
    label: texts[lang].montantCapitalCbChoices[0],
  },
  {
    id:2,
    label: texts[lang].montantCapitalCbChoices[1],
  },
  {
    id:3,
    label : texts[lang].montantCapitalCbChoices[2],
  },
  {
    id:4,
    label : texts[lang].montantCapitalCbChoices[3],
  },
  {
    id:5,
    label : texts[lang].montantCapitalCbChoices[4],
  }
]

const virInputs = allInputs //montants en tranche de 500 jusqu'a 50k
const styleCheckBox = {
  marginBottom: 20,
  marginTop: 20
}

const handleCbSelectChange =(e)=>{
  setCbValue(e.target.value)
  setCbAmount(cbInputs[e.target.value -1].label) //! le -1 
  if(e.target.value === "1"){
    setShowCbDisclaimer(false)
  }else{
    setShowCbDisclaimer(true)
  }
}
const handleVirSelectChange=(e)=>{
  // console.log(e.target.value)
  setVirValue(e.target.value)
  setVirAmount(virInputs[e.target.value-1].label)
  // console.log(virInputs[e.target.value-1].label)
  if(e.target.value === "1"){
    setShowVirDisclaimer(false)
  }else{
    setShowVirDisclaimer(true)
  }
}


const handleCbDisclaimerChange = (e,id)=>{
  const newCbDisclaimer = cbDisclaimer
  var total = 0
  newCbDisclaimer[id].value = e.target.checked
  newCbDisclaimer.map(disclaimer=>{
    if(disclaimer.value === true) total ++
  })
  setCbDisclaimer([...newCbDisclaimer])
  if(total === 4) setCbButtonDisable(false)
  else setCbButtonDisable(true)
}

const handleVirDisclaimerChange = (e,id)=>{
  const newVirDisclaimer = virDisclaimer
  var total = 0
  newVirDisclaimer[id].value = e.target.checked
  newVirDisclaimer.map(disclaimer=>{
    if(disclaimer.value === true) total ++
  })
  console.log(locale)
  setVirDisclaimer([...newVirDisclaimer])
  if(total < 4 || locale ==="default" ) setVirButtonDisable(true)
  else setVirButtonDisable(false)
}

const HandleLocaleChange = (e)=>{
  setLocale(e.target.value)
  if(e.target.value === "default" || virDisclaimer[0].value === false || virDisclaimer[1].value===false || virDisclaimer[2].value===false || virDisclaimer[3].value===false ) setVirButtonDisable(true)
  else setVirButtonDisable(false)
}
const handleFondClick = (id) =>{

  if(!haveDoneQuizz){
    setShouldDoQuizz(true)
    return
  }
   setHaveChoiseFond(id);
}
// console.log(haveDoneQuizz)
// console.log("commit test");
const handlePayClick = (method)=>{
  if(method === "creditcard") {
    var amount = cbAmount;
    var localeToSend = ""
  }
  if(method === "banktransfer") {
    var amount = virAmount;
    var localeToSend = locale === "default" ? "fr_FR" : locale
  }
  const body ={
    value: amount,
    lastname: user.lastname,
    email: user.email,
    fond: haveChoiseFond,
  }
  const paybody ={
    value: amount,
    lastname: user.lastname,
    email: user.email,
    orderId: order_id,
    method: method,
    locale: localeToSend,
    fond: haveChoiseFond,
  }
  if(method === "banktransfer") {alert("Pensez à bien entrer le numéro de référence du paiement via votre banque au moment du virement bancaire.")}
  axios.post(`${urls.apiUrl}/payment/mollie/init`,body, headers)
  .then((res)=>{
    // console.log(res)
    paybody.orderId = res.data
    
    setOrderId(res.data)
    //FAIRE MAINTENANT MON CALL API POUR PAYER ET ENREGSITRER EN BASE (METTRE A JOUR)
    axios.post(`${urls.apiUrl}/payment/mollie/pay`,paybody, headers)
    .then((res)=>{setConfirmUrl(res.data)})
    .catch((err)=>{console.log(err)})
  })
  .catch((err)=>{console.log(err)})
}

const handleCallWithVizio = (e)=>{
  setGoToVizioLink(true)
}
if(goToVizioLink)
    return <Navigate to={"//calendly.com/viziocapital/investir-des-montants-plus-importants"} />;

if(confirmUrl) return  window.location.replace(confirmUrl);
return (
  <div className="layout-profile-container">
    <span className="-subtitle">
      <h3>{texts[lang].investCapital}</h3>
      </span>
   <div className="information-container -pay">
              <div className="-title">
                <h4 className="-texte">{texts[lang].labelInvstInfosHeader}</h4>
              </div>
              <div className="-information">
                <InfosBar
                  classes="-info"
                  content={texts[lang].labelInvstInfosText} />
              </div>
            </div>
           

            <div className='container-invst -methods'>
              <div>
                <div className="card ">
                  <div className="card-header">{texts[lang].labelCardPayHeader}</div>
                  <div className="card-body">
                  <div className='-payement_card'>
                <InfosBar classes={"-info"} content={texts[lang].labelCardPayInfos} />
                <InputSelect 
                  label = {texts[lang].labelCardPayAmount}
                  inputs={cbInputs.map(item=>{
                    return{
                      id:item.id,
                      label: item.label
                    }
                  })} 
                  value={cbValue}
                  handleSelectChange={(e)=>{handleCbSelectChange(e)}} 
                />
                {
                  showCbDisclaimer === true &&
                  <>
                    {
                      cbDisclaimer.map((disclaimer,id)=>
                        <FormRowCheckbox
                        key={id}
                        isActive={disclaimer.value}
                        style={styleCheckBox}
                        label = {disclaimer.label}
                        handleCheckboxChange={(event) => handleCbDisclaimerChange(event, disclaimer.id)}
                      />
                      )
                    }
                  </>
                }
                {cbButtonDisable === true ?
                  <Button 
                  label={texts[lang].labelCardPayBtn} 
                  icon 
                  disable
                  />
                  :
                  <Button 
                      label={texts[lang].labelCardPayBtn}
                      handleClick={()=>{handlePayClick("creditcard")}}
                      icon 
                    />}
                <span className='-sub_payement'>{texts[lang].labelCardPayMaxi}</span>
                </div>
                  </div>
                </div>
              </div>
              {/* finnnn CB */}
              <div>
                <div className="card">
                  <div className="card-header">{texts[lang].labelBankPayHeader}</div>
                  <div className="card-body">
                  <div className='-payement_vir'>
                <InfosBar classes={"-error"} content={texts[lang].labelBankPayInfos} />
                <InputSelect 
                  label = {texts[lang].labelBankPayAmount}
                  inputs={virInputs.map(item=>{
                    return{
                      id:item.id,
                      label: item.label
                    }
                  })} 
                  value={virValue}
                  handleSelectChange={(e)=>{handleVirSelectChange(e)}} 
                />
                
                {
                  showVirDisclaimer === true &&
                  <div>
                  <InputSelect
                  label = {texts[lang].paysBank}
                  inputs={mollieCountryCode.map(item=>{
                    return{
                      id:item.id,
                      label: item.label
                    }
                  })}
                  value={locale}
                  handleSelectChange={(e)=>{HandleLocaleChange(e)}}

                /> 
                  <>
                    {
                      virDisclaimer.map((disclaimer,id)=>
                        <FormRowCheckbox
                        key={id}
                        isActive={disclaimer.value}
                        style={styleCheckBox}
                        label = {disclaimer.label}
                        handleCheckboxChange={(event) => handleVirDisclaimerChange(event, disclaimer.id)}
                      />
                      )
                    }
                  </>
                  </div>
                }
                {
                  virButtonDisable === true ?
                  <Button 
                    label={texts[lang].labelBankPayBtn}
                    icon 
                    disable
                  />
                  :<Button 
                    label={texts[lang].labelBankPayBtn} 
                    handleClick={()=>{handlePayClick("banktransfer")} }
                    icon
                  />
                }
                <span className='-sub_payement'>{texts[lang].labelBankPayMaxi}</span>
                </div>
                  </div>
                </div>
              </div>

             
            </div>

             <div className="information-container -pay">
              <div className="-title">
                <h4 className="-texte">{texts[lang].labelBigInvstHeader}</h4>
              </div>
              <div className="-information">
              <h1>{texts[lang].labelBigInvstSubHeader}</h1>
              <p> {texts[lang].labelBigInvstText}</p>
            <Button classes={"-small"} label={texts[lang].labelBigInvstBtn}handleClick={handleCallWithVizio} icon/>
              </div>
            </div>
      </div>

)
}
export default CapitalInvestView