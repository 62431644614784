import axios from 'axios';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from '../../../components/button';
import FormRow from '../../../components/formRow';
import InfosBar from '../../../components/infosBar';
import InputText from '../../../components/inputText';
import urls from '../../../constants/urls';
import { LoaderContext } from "../../../context/Context";
import { setFeedback } from '../../../redux/store';
import texts from "../../../texts/apportAffaire";


const ContactSocialNetwork = () => {

  const dispatch = useDispatch();

  const { lang } = useSelector(state => state.persisedReducer.parameters)
  const { user, token } = useSelector(state => state.persisedReducer.user);
  const { id } = useParams()

  const headers = {
    headers: {
      "x-auth-token": token
    }
  }

  const [facebook, setFacebook] = useState('')
  const [linkedin, setLinkedin] = useState('')
  const [telegram, setTelegram] = useState('')
  const [instagram, setInstagram] = useState('')
  const [tiktok, setTiktok] = useState('')




  const loader = useContext(LoaderContext);
  useLayoutEffect(() => {
    axios.get(`${urls.apiUrl}/prospect/${id}`, headers).
      then(res => {
        setFacebook(res.data.social_network.facebook)
        setInstagram(res.data.social_network.instagram)
        setLinkedin(res.data.social_network.linkedin)
        setTiktok(res.data.social_network.tiktok)
        setTelegram(res.data.social_network.telegram)
      })
      .catch(err => {
        console.log(err)
      })

    loader.onSetIsLoading(false);
    return () => {

      loader.onSetIsLoading(false);
    }
  }, [])

  const handleFacebookCHange = (e) => {
    setFacebook(e.target.value)
  }
  const handleInstagramCHange = (e) => {
    setInstagram(e.target.value)
  }
  const handleTiktokCHange = (e) => {
    setTiktok(e.target.value)
  }
  const handleTwitterCHange = (e) => {
    setTelegram(e.target.value)
  }
  const handleLinkedinCHange = (e) => {
    setLinkedin(e.target.value)
  }

  const HandleSocialNetworkSubmit = (e) => {
    e.preventDefault()
    const body = {
      social_network: {
        facebook: facebook ? facebook : "",
        instagram: instagram ? instagram : "",
        tiktok: tiktok ? tiktok : "",
        telegram: telegram ? telegram : "",
        linkedin: linkedin ? linkedin : "",
      }
    }
    axios.patch(`${urls.apiUrl}/prospect/socialNetwork/${id}`, body, headers)
    .then((response) => {
      dispatch(setFeedback({ show: true, type: 'success', title: `${texts[lang].confirmationMessageTitle}`, message: `${texts[lang].consirmationMessageValue}` }));
      console.log(response);
      setTimeout(() => {
        dispatch(setFeedback({ show: false }));
      }, 5000);
    }
      )
      .catch((error) => {
        console.log(error);
        dispatch(setFeedback({ show: true, type: 'error', title: `${texts[lang].errorMessage}`, message: `${texts[lang].errorMessage}` }));
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 5000);

      }
      )
  }

  return (
    <div>
      <div className="layout-AA-container">
        <div className="-add-contact-container">
          {/* <div className="contact-info-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].infoPrealableContactForm}</h4>
            </div>
            <div className="-information">

            <InfosBar
              classes="-error"
              content={
                texts[lang].infoFormContactContentOne
              }
            />
            <InfosBar
              classes="-info"
              content={
                texts[lang].infoFormContactContentTwo
              }
            />
            </div>
          </div> */}

          <form onSubmit={HandleSocialNetworkSubmit}>

            <div className="contact-info-container -apporAf">
              <div className="-title">
                <h4 className="-texte">{texts[lang].socialNetwork}</h4>
              </div>

              <div className='contact-scontainer'>

                <FormRow classes={"-two"}>
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={texts[lang].socialNetworkContent[0]}
                    value={facebook}
                    handleInputChange={(e) => handleFacebookCHange(e)}
                  />
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={texts[lang].socialNetworkContent[1]}
                    value={instagram}
                    handleInputChange={(e) => handleInstagramCHange(e)}
                  />
                </FormRow>
                <FormRow classes={"-two"}>
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={texts[lang].socialNetworkContent[2]}
                    value={tiktok}
                    handleInputChange={(e) => handleTiktokCHange(e)}
                  />
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={texts[lang].socialNetworkContent[3]}
                    value={telegram}
                    handleInputChange={(e) => handleTwitterCHange(e)}

                  />
                </FormRow>
                <FormRow classes={"-one"}>
                  <InputText
                  required = {false}
                    classes={"-two"}
                    label={texts[lang].socialNetworkContent[4]}
                    value={linkedin}
                    handleInputChange={(e) => handleLinkedinCHange(e)}
                  />

                </FormRow>


              </div>
            </div>
            <div className="-add-contact-button">
              <Button
                classes={"-add-contact"}
                label={"Confirmer les modifications"}
                type="submit"
              />
            </div>        
          </form>
        </div>
      </div>       
     </div>
  );
};

export default ContactSocialNetwork;    