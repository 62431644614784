import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { QRCodeSVG } from "qrcode.react";

import { setFeedback } from "../../redux/store";

import FormRowCheckbox from "../../components/formRowCheckbox";
import InputPassword from "../../components/inputPassword";
import Button from "../../components/button";
import InfosBar from "../../components/infosBar"
import Infos from "../../components/infos"

import textModUser from "../../texts/modificationUser";

import urls from "../../constants/urls";
import FormRow from "../../components/formRow";
import FormSectionTitle from "../../components/formSectionTitle";
import { LoaderContext } from "../../context/Context";
// import regexes from "../../constants/regex";

class SecuriteView extends Component {
  static contextType = LoaderContext;
  constructor(props) {
    super(props);
    const obj = this.props.user;
    const user = obj.user;

    this.state = {
      id: user.id,
      token: obj.token,
      email: user.email,
      has_google_auth: user.has_google_auth ? user.has_google_auth : false,
      google_auth_key: user.has_google_auth ? user.google_auth_key : false,
      oldPassword:"",
      password: "",
      confirmPassword: "",
      error: {
        status: false,
        message: "",
      },
      feedback: {
        status: false,
        message: "",
        class: "",
      },
    };
  }

  componentDidMount(){
    const  user = this.props.user.user
    const token = this.props.user.token
    const headers = {
      headers: {
        "x-auth-token": token,
      },
     
    }
    // console.log(user)
    axios
     .get(`${urls.apiUrl}/users/${user.id}`, headers)
     .then((res)=>{
       this.setState({
        has_google_auth: res.data.has_google_auth,
        google_auth_key: res.data.google_auth_key,
        
      })
      this.context.onSetIsLoading(false);  
     })
     .catch( err => console.log(err.response.data))
  }
  componentWillUnmount(){
    this.context.onSetIsLoading(true);
  }

  handleGoogleAuthChange = () => {

    const headers = {headers: {"x-auth-token": this.state.token}};

    axios.post(`${urls.apiUrl}/auth/a2fa`, { has_google_auth: !this.state.has_google_auth }, headers)
    .then((res) => {
      this.setState({
        has_google_auth: !this.state.has_google_auth,
        google_auth_key: res.data.secret || false,
      });
    })
    .catch((err) => {
      this.props.setFeedback({
        show: true,
        type: "error",
        title: err.response.data
      })
      setTimeout(this.props.setFeedback, 5000);
    });
    
  };

  handleOldPasword = (e) => {
    this.setState({ oldPassword: e.target.value });
  };

  handlePasword = (e) => {
    this.setState({ password: e.target.value });
  };

  handlePasswordConfirm = (e) => {
    const { lang } = this.props.parameters;
    if (e.target.value !== this.state.password) {
      this.setState({
        confirmPassword: e.target.value,
        error: {
          status: false,
          message: textModUser[lang].passwordMissmatch,
        },
      });
    } else {
      this.setState({
        confirmPassword: e.target.value,
        error: {
          status: true,
          message: textModUser[lang].passwordMatch,
        },
      });
    }
  };

  resetPassword = ()=>{
    const obj =this.props.user
    const token = obj.token
    
    if (this.state.password === this.state.confirmPassword){
      const body = {
        oldPassword:this.state.oldPassword,
        password : this.state.password
      }

      const headers= {
        headers:{
          'x-auth-token': token
        }
      }
      axios.patch(`${urls.apiUrl}/resetPassword/change`,body,headers)
      .then((res)=>{
         this.setState({
            feedback: {
              status: true,
              message: res.data,
              class: "-succes",
            },
            oldPassword:"",
            password:"",
            confirmPassword:"",
            error: {
              status: false,
              message: "",
            },
          }); 
      })
      .catch((err)=>{
        if (err.response) {
          this.setState({
            feedback: {
              status: true,
              message: err.response.data,
            },
            oldPassword:"",
            password:"",
            confirmPassword:"",
            error: {
              status: false,
              message: "",
            },
          });
        }
      })
    }
  }
  
  
  render() {
    const status = this.state.error.status;
    const { lang } = this.props.parameters;

    return (
      <div className="dashboard-container">

        <div className="dashboard-scontainer -no-padding">
          
          <FormSectionTitle
            title={textModUser[lang].authenticatorTitle}
            classes="-simple-2"
          />

          <div className="security-form-container">
            <FormRowCheckbox
              label={textModUser[lang].authenticatorChangeStatus}
              isActive={this.state.has_google_auth}
              handleCheckboxChange={this.handleGoogleAuthChange}
            />
            {
              this.state.has_google_auth && this.state.google_auth_key &&
              <div className="-qrcode-container">
                <div className="-qrcode">
                  <QRCodeSVG value={`otpauth://totp/Vizio:${this.state.email}?secret=${this.state.google_auth_key}`} />
                </div>
                <div className="-infos">
                  <InfosBar
                    content={textModUser[lang].doubleAuthActivated}
                    classes="-succes"
                  />
                  <InfosBar
                    content={textModUser[lang].doubleAuthWarning}
                    classes="-info"
                    style={{
                      marginTop: 10
                    }}
                  />
                </div>
              </div>
            }

          </div>

        </div>

        <div className="dashboard-scontainer -no-padding">

          <FormSectionTitle
            title={textModUser[lang].passwordTitle}
            classes="-simple-2"
          />
          
          <div className="security-form-container">
            {this.state.feedback.status && (
                <Infos
                  message={this.state.feedback.message}
                  classes={
                    this.state.feedback.class && this.state.feedback.class.length
                      ? this.state.feedback.class
                      : "-error"
                  }
                />
              )}
            <FormRow classes={"-two"}>
            <InputPassword
              style={{
                marginBottom: 10,
              }}
              // label={"Entrez le nouveau mot de passe"}
              label={textModUser[lang].oldPasswordResetLabel}
              value={this.state.oldPassword}
              error={""}
              handleInputChange={this.handleOldPasword}
            />
            <InputPassword
              style={{
                marginBottom: 10,
              }}
              // label={"Entrez le nouveau mot de passe"}
              label={textModUser[lang].passwordResetLabel}
              value={this.state.password}
              error={""}
              handleInputChange={this.handlePasword}
            />
            </FormRow>
            <FormRow classes={"-two"}>
            <InputPassword
              style={{
                marginBottom: 10,
              }}
              // label={"Confirmez le mot de passe"}
              label={textModUser[lang].passwordConfirmLabel}
              value={this.state.confirmPassword}
              indication={status && this.state.error.message}
              error={!status && this.state.error.message}
              handleInputChange={this.handlePasswordConfirm}
            />
            </FormRow>
            <div className="btn -small ">
            <Button
              // label="Reinitialiser mon mot de passe"
              classes="-small"
              label={textModUser[lang].ctaPasswordReset}
              icon
              handleClick={this.resetPassword}
            />
            </div>
          </div>
        </div>

      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  parameters: state.persisedReducer.parameters,
  user: state.persisedReducer.user,
});
const actions = {
  setFeedback,
};

export default connect(mapStateToProps, actions)(SecuriteView);
