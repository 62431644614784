import axios from "axios";
import { LoaderContext } from "../context/Context";
import { useLayoutEffect, useContext, useState } from "react";
import InfosBar from "../components/infosBar";
import { Link } from "react-router-dom";

const PartenaireView = () => {
  const baseContentUrl = "https://152-228-211-95.sslip.io";
  const path = "/wp-json/wp/v2";
  const [partenaires, setPartenaires] = useState([]);
  const [taxos, setTaxos] = useState([]);
  
  // const [open, setOpen] = useState(true)
  // const [classes, setClasses] = useState("")
  const loader = useContext(LoaderContext);
  useLayoutEffect(() => {
    loader.onSetIsLoading(true);
    axios.get(`${baseContentUrl}${path}/partenairestaxo`).then((res) => {
      setTaxos(res.data);
    });
    //get partenaires lists:
    axios
      .get(`${baseContentUrl}${path}/partenaires`)
      .then((res) => {
        setPartenaires(res.data);
        loader.onSetIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      loader.onSetIsLoading(false);
    };
  }, []);

  console.log(partenaires);

  return (
    <div className="layout-profile-container">
      <div className="information-container -medium ">
        <div className="-title">
          <h4 className="-texte">{"infos"}</h4>
        </div>
        <div className="-information">
          <InfosBar classes="-info" content={"Notre page Partenaires est un espace privilégié pour les membres de la plateforme VIZIO CAPITAL.<br>Vous pouvez obtenir des avantages chez nos partenaires en effectuant une demande via le formulaire qui est disponible sur chaque fiche ci-dessous.<br><br>Vous pouvez également devenir partenaire et offrir des services privilégiés à notre communauté en contactant&nbsp;: <a href=\"mailto:marketplace@viziocapital.com\">marketplace@viziocapital.com</a>"} />
        </div>
      </div>

      <div className="container-partners">
        {partenaires.map((part) => (
          <Link to={`/partenaires/${part.slug}`}>
            <div className="card">
              <div className="card-body">
                <div className="-bien_image">
                  <div className="img_cont">
                    <img
                      src={part.partenaire_info_fond_ecran}
                      alt="image du bien"
                    />{" "}
                    {/* //? img vient de base ? admin vizio ?  */}
                  </div>

                  <div className="left-corner ">
                    <div className="img_scont">
                      <img
                        src={part.partenaire_info_image}
                        alt="image du bien"
                      />
                    </div>
                  </div>
                </div>

                <div className="infos">
                  <span className="-name">{part.partenaire_info_name}</span>
                  <span className="-service">
                    {part.partenaire_info_service}
                  </span>
                  <span className="-adress">
                    {part.partenaire_info_adresse}
                  </span>
                  <ul className="-prestation">
                    {part.partenaire_liste_prestation.map((i) => (
                      <li>{i.partenaire_prestation_value}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
export default PartenaireView;
