import IconInfosInfo from "./icons/icon-infos-error";
import Button from "./button";

const DeconnexionPopup = ({text, extendSession}) => {

  return <div className="popup-deconnexion">
    <div className="-inner">
      <div className="-content">
        <div className="-icon">
          <IconInfosInfo />
        </div>
        <div className="-texts">{text}</div>
      </div>
      <Button
        style={{
          marginTop: 20,
        }}
        label="Prolonger ma session"
        handleClick={extendSession}
      />
    </div>
  </div>

}


export default DeconnexionPopup;