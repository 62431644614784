import { Link } from "react-router-dom";

import apiUrl from "../../constants/urls";

// import IconSearch from "../icons/icon-search";
import IconHamburger from "../icons/icon-hamburger";

const DashboardHeader = ({title, user, handleOpenNavbar}) => {

  return (
    <div className="dashboard-header">
      <div className="-left">
        <h2>{title}</h2>
      </div>
      <div className="-right">
        {/* <div className="-search"> */}
          {/* //!recherche */}
          {/* <div className="-inner">
            <div className="-icon">
              <IconSearch />
            </div>
            <input className="-input" type="text" placeholder="Recherche" />
          </div> */}
        {/* </div> */}
        <div className="-profile">

          <Link className="-link" to="/dashboard/edit-profile">
            <h3 className="-name">{user? user.firstname + " " + user.lastname : ""}</h3>
            <div className="-img">
              { user?
                (user.avatar
                  ? <img src={apiUrl + "/users/avatar/" + user.avatar} alt="" ></img>
                  : <p>{`${user.lastname.charAt(0).toUpperCase()}${user.firstname.charAt(0).toUpperCase()}`}</p>)
                : " "
              }
            </div>
          </Link>

          <button className="-hamburger" onClick={handleOpenNavbar}>
            <IconHamburger />
          </button>

        </div>
      </div>
    </div>
  )
}

export default DashboardHeader;
