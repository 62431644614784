import { useState ,useEffect} from 'react';
import { useSelector } from "react-redux";
import axios from 'axios';
import urls from "../constants/urls";
import NotifCard from '../components/notifCard';
import InfosBar from "../components/infosBar";
import { useLayoutEffect } from 'react';
import { LoaderContext } from "../context/Context";
import { useContext } from 'react';
import texts from "../texts/notifications"; 


const NotificationView = () => {

    const { user, token } = useSelector((state) => state.persisedReducer.user);
    const loader = useContext(LoaderContext);
    const {lang} = useSelector(state => state.persisedReducer.parameters);
    // console.log(token)
    
    const headers = {
        headers: {
          "x-auth-token": token,
        },
      };
    const [notifications, setNotifications] = useState([]);
    notifications.sort((a,b)=>(
        new Date(b.date) - new Date(a.date)
    ))

    useLayoutEffect(() => {
      axios.get(`${urls.apiUrl}/request/getNotif/user/${user.id}`,headers)
        .then((res) => {
          // console.log(res.data);
          setNotifications(res.data.sort((a,b)=>(
            new Date(b.date) - new Date(a.date)
        )).slice(0,30));
          loader.onSetIsLoading(false);
          axios.patch(`${urls.apiUrl}/request/readNotif/user/${user.id}`,{},headers)
          .then((res)=>{
            // console.log(res.data)
          })
          .catch((err)=>{
            console.log(err)
          })
        })
        .catch((err) => {
            console.log(err);
        })
        return()=>{
          loader.onSetIsLoading(true);
        }
    }, [])

    return (
        <div className="layout-notifications-container">
          {
            notifications.length<=0&&
            <>
              <InfosBar 
                content={ texts[lang].noNotif}
                classes="-info"
                style={{
                  marginBottom: 40
                }}
              />
            </>
          }
          {
            notifications.length>0 &&
            <ul>
                {notifications.map((notif)=>{
                  return (
                    <li key={notif._id}>
                      <NotifCard 
                        classes={`${notif.type}`} 
                        content={`${notif.body.fr}`}
                        linkValue={`${notif.link}`} 
                      />
                    </li>
                  )
                })}
            </ul>
          }
        </div>
    
    );
};

export default NotificationView;