export default {
  fr: {
    //*Entête :
    headerTitle:"Réinitialiser votre mot de passe.",
    headerText:"Choisir et confirmer le mot de passe.",
    //*Mot de passe :
    passwordTitle: "Mot de passe",
    passwordMissmatch: "Les mots de passe ne correspondent pas.",
    passwordMatch: "Les mots de passe correspondent bien.", // Ce message ne sert à rien. A retirer svp.
    ctaPasswordReset: "Réinitialiser le mot de passe",
    oldPasswordResetLabel: "Renseigner l'ancien mot de passe",
    passwordResetLabel: "Renseigner le nouveau mot de passe",
    passwordConfirmLabel: "Confirmer le mot de passe",
    passwordResetSuccesTitle: "Mot de passe réinitialisé",
    passwordResetSuccesText: "Votre mot de passe a été changé. Vous pouvez connecter sur votre compte avec votre nouveau mot de passe.",
    ctaAuthPage: "Se rendre sur la page de connexion.",
    //* Authenticator :
    authenticatorTitle: "Google Authenticator",
    authenticatorChangeStatus: "Je génère mon QR code et j'active mon google authenticator",
    doubleAuthActivated: "Veuillez scanner votre QR code à l'aide de l'appareil photo de votre smartphone.",
    doubleAuthWarning: "Attention si vous ne le scannez vous ne pourrez pas accéder à votre compte.",
  },
  en: {
    //*Entête :
    headerTitle:"Reset your password.",
    headerText:"Choose and confirm your password.",
    //*Mot de passe :
    passwordTitle: "Password",
    passwordMissmatch: "Passwords do not match.",
    passwordMatch: "Passwords match successfully.", // Ce message ne sert à rien. A retirer svp.
    ctaPasswordReset: "Reset password",
    oldPasswordResetLabel: "Please type previous password",
    passwordResetLabel: "Please type new password",
    passwordConfirmLabel: "Confirm the password",
    passwordResetSuccesTitle: "password reset successfully",
    passwordResetSuccesText: "password has been reset successfully. You can connect to your account with the new password.",
    ctaAuthPage: "Go to the sign in page.",
    //* Authenticator :
    authenticatorTitle: "Google Authenticator",
    authenticatorChangeStatus: "Generate QR code and activate Google authentication",
    doubleAuthActivated: "Please scan your QR code using your smartphone camera.",
  doubleAuthWarning: "Be careful if you do not scan it you will not be able to access your account.",
  },
};
