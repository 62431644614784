export default () => (
    <svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
        <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-8">
                <rect id="Mask-Copy-3" x="0" y="0" width="35" height="35"></rect>
                <g id="Group-7" transform="translate(1.000000, 4.000000)">
                    <g id="ic_Edit" transform="translate(7.916667, 3.958333)" fill="#3DD598">
                        <path d="M3.83333333,-8.8817842e-16 L12.9678073,-8.8817842e-16 C13.5366354,-8.8817842e-16 14.0760766,0.252660769 14.4402315,0.689646567 L18.7224241,5.82827776 C19.0094689,6.17273151 19.1666667,6.60691955 19.1666667,7.05529785 L19.1666667,17.25 C19.1666667,19.3670915 17.4504249,21.0833333 15.3333333,21.0833333 L3.83333333,21.0833333 C1.71624179,21.0833333 -1.77635684e-15,19.3670915 -1.77635684e-15,17.25 L-1.77635684e-15,3.83333333 C-1.77635684e-15,1.71624179 1.71624179,-8.8817842e-16 3.83333333,-8.8817842e-16 Z M17.25,17.25 L17.25,7.66666667 L14.375,7.66666667 C13.3164542,7.66666667 12.4583333,6.80854577 12.4583333,5.75 L12.4583333,1.91666667 L3.83333333,1.91666667 C2.77478756,1.91666667 1.91666667,2.77478756 1.91666667,3.83333333 L1.91666667,17.25 C1.91666667,18.3085458 2.77478756,19.1666667 3.83333333,19.1666667 L15.3333333,19.1666667 C16.3918791,19.1666667 17.25,18.3085458 17.25,17.25 Z M16.1622518,5.75 L14.375,3.60529785 L14.375,5.75 L16.1622518,5.75 Z M5.75,12.4583333 C5.22072711,12.4583333 4.79166667,12.0292729 4.79166667,11.5 C4.79166667,10.9707271 5.22072711,10.5416667 5.75,10.5416667 L13.4166667,10.5416667 C13.9459396,10.5416667 14.375,10.9707271 14.375,11.5 C14.375,12.0292729 13.9459396,12.4583333 13.4166667,12.4583333 L5.75,12.4583333 Z M5.75,16.2916667 C5.22072711,16.2916667 4.79166667,15.8626062 4.79166667,15.3333333 C4.79166667,14.8040604 5.22072711,14.375 5.75,14.375 L11.5,14.375 C12.0292729,14.375 12.4583333,14.8040604 12.4583333,15.3333333 C12.4583333,15.8626062 12.0292729,16.2916667 11.5,16.2916667 L5.75,16.2916667 Z M5.75,8.625 C5.22072711,8.625 4.79166667,8.19593955 4.79166667,7.66666667 C4.79166667,7.13739378 5.22072711,6.70833333 5.75,6.70833333 L8.625,6.70833333 C9.15427289,6.70833333 9.58333333,7.13739378 9.58333333,7.66666667 C9.58333333,8.19593955 9.15427289,8.625 8.625,8.625 L5.75,8.625 Z" id="Shape"></path>
                    </g>
                    <g id="Group">
                        <circle id="Oval" fill="#3DD598" cx="6.5" cy="6.5" r="6.5"></circle>
                        <g id="ic_Edit" transform="translate(3.742424, 4.431813)" fill="#FFFFFF">
                            <path d="M0.587210345,1.36914336 C0.451925068,1.23520693 0.233677757,1.2363004 0.0997413336,1.37158567 C-0.0341950901,1.50687095 -0.0331016283,1.72511826 0.102183649,1.85905468 L2.11016308,3.84701368 C2.25192446,3.98736164 2.48285967,3.97843836 2.61336853,3.82756994 L5.43114668,0.57021444 C5.55569347,0.426238168 5.53994277,0.20855721 5.3959665,0.0840104123 C5.25199023,-0.0405363851 5.03430927,-0.0247856833 4.90976247,0.119190589 L2.33318867,3.09771352 L0.587210345,1.36914336 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)