// Connexion
export default {
  fr: {
    labelMail: "Votre adresse email",
    labelPassword: "Votre mot de passe",
    errorMail: "La valeur saisie pour le champ email n'est pas correcte",
    errorPassword: "La valeur saisie pour le champ mot de passe n'est pas correcte",
    errorEmpryField: "Ce champs est obligatoire",
    information: 'Choisissez l’e-mail et le mot de passe à utiliser plus tard lors de la connexion. <br> Attention : Si vous souhaitez avoir un compte professionnel et un compte personnel, veuillez utiliser une adresse mail personnelle pour le compte personnel et une adresse mail professionnelle pour le compte professionnel.',
    labelparrainInfo:"Votre référent&nbsp;: ",
    indicationPassword: "Minimum 5 caractères.",
  },
  en: {
    labelMail: "Your email address",
    labelPassword: "Choose a password",
    errorMail: "The value entered in the email field is not correct",
    errorPassword: "The value entered in the password field is not correct",
    errorEmpryField: "This field cannot be empty",
    // information: "Choose an email and password to connect later.",
    information: "Choose the email and password to use later when logging in. <br/> Attention: If you want to have a professional account and a personal account, please use a personal email address for the personal account and a professional email address for the professional account.",
    labelparrainInfo:"Your referent : ",
    indicationPassword: "5 characters minimum.",
  }
}