import { useSelector } from 'react-redux';
import { useState,useEffect } from 'react';
import axios from 'axios';


import urls from "../../constants/urls";

import InputText from '../inputText';
import InputSelect from '../inputSelect';
import InputDate from '../inputDate';
import FormRow from '../formRow';

import FormSectionTitle from '../formSectionTitle';

import regexes from '../../constants/regex';
import countries from '../../constants/countries';

const FormRegisterInformationsPersonal = ({texts, handleInputChange}) => {
  const { token, user } = useSelector((state) => state.persisedReducer.user);
  const headers = {
    headers: {
      "x-auth-token": token,
    },
  };
  const {
    lastName, firstName,email,pseudo,
    nativeCountry, telNum,
    postalAddress, postalCode,
    city, residenceCountry,
    date
  } = useSelector(state => state.notPersisedReducer.editeProfileForm);



  const getMaxDate = () => {
    const date = new Date();
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month = date.getMonth() < 10 ? `0${date.getMonth()}` : date.getMonth();
    const year = parseInt(date.getFullYear() - 18);
    return `${year}-${month}-${day}`;
  }


  return <>

    <FormSectionTitle title={texts.titlePersonalInformations} />

    <FormRow classes={"-two"}>
      <InputText
          label={texts.labelMail}
          value={email.value}
          error={email.error}
          readonly={true}
          // handleInputChange={(event) => handleInputChange({
          //   event,
          //   regex: regexes.default,
          //   errorText: texts.errorLastName,
          //   name: "lastName"
          // })}
        />
      <InputText 
        label={texts.labelPseudo}
        value={pseudo.value}
        error={pseudo.error}
        readonly={true}
      />
    </FormRow>

    <FormRow classes={"-two"}>
      <InputText
        label={texts.labelLastName}
        value={lastName.value}
        error={lastName.error}
       
        readonly={true}
        // handleInputChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.default,
        //   errorText: texts.errorLastName,
        //   name: "lastName"
        // })}
      />
      <InputText
        label={texts.labelFirstName}
        value={firstName.value}
        error={firstName.error}
        readonly={true}
        // handleInputChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.default,
        //   errorText: texts.errorFirstName,
        //   name: "firstName"
        // })}
      />
    </FormRow>


    <FormRow classes={"-two"}>
      <InputDate
        label={texts.labelDate}
        value={date.value}
        error={date.error}
        min="1950-01-01"
        max={getMaxDate()}
        readonly={true}
        // handleDateChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.default,
        //   errorText: texts.errorNativeCountry,
        //   name: "date"
        // })}
      />
      <InputText
        label={texts.labelNativeCountry}
        value={nativeCountry.value}
        error={nativeCountry.error}
        inputs={countries}
        readonly={true}
        // handleSelectChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.default,
        //   errorText: texts.errorNativeCountry,
        //   name: "nativeCountry"
        // })}
      />
    </FormRow>

    <FormRow classes={"-two"}>
      <InputText
        label={texts.labelPhoneNumber}
        value={telNum.value}
        error={telNum.error}
        type="text"
        max={15}
        readonly={true}
        // handleInputChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.phone,
        //   errorText: texts.errorPhoneNumber,
        //   name: "telNum",
        // })}
      />
      <InputText
        label={texts.labelResidenceCountry}
        value={residenceCountry.value}
        error={residenceCountry.error}
        inputs={countries}
        readonly={true}
        // handleSelectChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.default,
        //   errorText: texts.errorResidenceCountry,
        //   name: "residenceCountry"
        // })}
      />
    </FormRow>

    <FormRow classes={""}>
      <InputText
        label={texts.labelPostalAddress}
        value={postalAddress.value}
        error={postalAddress.error}
        readonly={true}
        // handleInputChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.default,
        //   errorText: texts.errorPostalAddress,
        //   name: "postalAddress",
        // })}
      />
    </FormRow>
    
    <FormRow classes={"-two"}>
      <InputText
        label={texts.labelCity}
        value={city.value}
        error={city.error}
        readonly={true}
        // handleInputChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.default,
        //   errorText: texts.errorCity,
        //   name: "city",
        // })}
      />
      <InputText
        label={texts.labelPostalCode}
        value={postalCode.value}
        error={postalCode.error}
        max={8}
        readonly={true}
        // handleInputChange={(event) => handleInputChange({
        //   event,
        //   regex: regexes.postalCode,
        //   errorText: texts.errorPostalCode,
        //   name: "postalCode",
        // })}
      />

    </FormRow>


  </>
}


export default FormRegisterInformationsPersonal;
