export default () =>  (
    <svg viewBox="0 0 72 72">
        <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-7">
                <rect id="Rectangle" x="0" y="0" width="72" height="72"></rect>
                <g id="noun-envelope-185619" transform="translate(6.000000, 13.000000)" fill="#FC5A5A">
                    <path d="M30.5,57 C22.5064132,57 16,50.496326 16,42.5005649 C16,34.5042389 22.5066391,28 30.5,28 C38.4933609,28 45,34.503674 45,42.4994351 C45,50.4951962 38.4933609,57 30.5,57 Z M30.5,30.6359528 C23.9588989,30.6359528 18.6360555,35.9585888 18.6360555,42.4994351 C18.6360555,49.0402813 23.9588989,54.3629174 30.5,54.3629174 C37.0411011,54.3629174 42.3639445,49.0402813 42.3639445,42.4994351 C42.3639445,35.9585888 37.0411011,30.6359528 30.5,30.6359528 Z" id="Shape" fillRule="nonzero"></path>
                    <path d="M35.5351779,38.2512701 C35.8706285,37.9158908 36.4140975,37.9162953 36.7490498,38.2521735 C37.0840021,38.5880518 37.0835981,39.1322135 36.7481475,39.4675928 L36.7481475,39.4675928 L32.214,44 L36.7488298,48.5358832 C37.0560121,48.8431052 37.081404,49.325097 36.8251508,49.6612139 L36.7482807,49.7490245 C36.4128696,50.0838727 35.8693066,50.083627 35.5341987,49.7484758 L35.5341987,49.7484758 L31,45.213 L26.4648221,49.7487299 C26.1573257,50.0561609 25.6750361,50.0814403 25.3387913,49.8248068 L25.2509502,49.7478265 C24.9159979,49.4119482 24.9164019,48.8677865 25.2518525,48.5324072 L25.2518525,48.5324072 L29.785,43.999 L25.2511702,39.4641168 C24.9439879,39.1568948 24.918596,38.674903 25.1748492,38.3387861 L25.2517193,38.2509755 C25.5871304,37.9161273 26.1306934,37.916373 26.4658013,38.2515242 L26.4658013,38.2515242 L30.999,42.786 Z" id="Shape"></path>
                    <path d="M54.3696838,43 L49.0651581,43 C48.3329953,43 47.738913,42.3980152 47.738913,41.6561132 C47.7393571,40.9142113 48.3334386,40.3122265 49.0651581,40.3122265 L54.3696838,40.3122265 C56.5630804,40.3122265 58.3479645,38.5036 58.3479645,36.2810268 L58.3479645,6.71851245 C58.3479645,4.49640004 56.5630804,2.68777355 54.3696838,2.68777355 L6.63031618,2.68777355 C4.43691956,2.68777355 2.65203554,4.49640004 2.65203554,6.71897316 L2.65203554,36.2814876 C2.65203554,38.5036 4.43691956,40.3122265 6.63031618,40.3122265 L11.9348419,40.3122265 C12.6670047,40.3122265 13.261087,40.9142113 13.261087,41.6561132 C13.2606429,42.3980152 12.6665614,43 11.9348419,43 L6.63031618,43 C2.97302595,43 0,39.9846627 0,36.2814876 L0,6.71897316 C0,3.01533731 2.97302595,0 6.63031618,0 L54.3696838,0 C58.026974,0 61,3.01533731 61,6.71851245 L61,36.2810268 C61,39.9846627 58.026974,43 54.3696838,43 Z" id="Path" fillRule="nonzero"></path>
                    <path d="M30.4990378,25 C29.065613,25 27.6349489,24.5787339 26.411344,23.7340339 L5.55588088,9.32684703 C4.95464997,8.91074212 4.82030306,8.10732026 5.25526543,7.5317811 C5.69022779,6.95404102 6.52741417,6.83066875 7.12857606,7.24419859 L27.9840392,21.6513855 C29.5086851,22.706702 31.4920822,22.706702 33.0167282,21.6513855 L53.8721913,7.24419859 C54.4734222,6.82809368 55.3110227,6.95661609 55.745502,7.5317811 C56.1804643,8.10694611 56.0434141,8.91076413 55.4448865,9.3238758 L34.5894234,23.7310627 C33.3631727,24.5761809 31.9323936,25 30.4989688,25 L30.4990378,25 Z" id="Path" fillRule="nonzero"></path>
                    <path d="M5.3343372,38 C4.99279817,38 4.65170488,37.8691863 4.39009031,37.6106791 C3.8699699,37.0905331 3.8699699,36.2453929 4.39009031,35.725224 L17.7245475,22.3901095 C18.2446679,21.8699635 19.0897664,21.8699635 19.6099097,22.3901095 C20.1300301,22.9102556 20.1300301,23.7553957 19.6099097,24.2755646 L6.27545249,37.6106791 C6.01695809,37.8691863 5.6758648,38 5.33432577,38 L5.3343372,38 Z" id="Path" fillRule="nonzero"></path>
                    <path d="M54.6687944,38 C54.3272554,38 53.9861621,37.8691863 53.7245475,37.6106791 L40.3900903,24.2755646 C39.8699699,23.7554186 39.8699699,22.9102784 40.3900903,22.3901095 C40.9102107,21.8699635 41.7553092,21.8699635 42.2754525,22.3901095 L55.6099097,35.725224 C56.1300301,36.24537 56.1300301,37.0905102 55.6099097,37.6106791 C55.3514153,37.8691863 55.010322,38 54.668783,38 L54.6687944,38 Z" id="Path" fillRule="nonzero"></path>
                </g>
            </g>
        </g>
    </svg>
)