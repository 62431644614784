import axios from 'axios';
import urls from '../../constants/urls.js';
import { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { setRedirect } from "../../redux/store";

import texts from "../../texts/dashboardLayout";

import logo from "../../assets/img/logo/logo-vizio-blue.svg";

import IconBenefices from "../../assets/svg/navbar-icon-benefices";
import IconCommunaute from "../../assets/svg/navbar-icon-communaute";
import IconContrats from "../../assets/svg/navbar-icon-contrats";
import IconDashboard from "../../assets/svg/navbar-icon-dashboard";
import IconDocuments from "../../assets/svg/navbar-icon-documents";
import IconEvenements from "../../assets/svg/navbar-icon-evenements";
import IconFaq from "../../assets/svg/navbar-icon-faq";
import IconFormations from "../../assets/svg/navbar-icon-formations";
import IconImmobiliers from "../../assets/svg/navbar-icon-immobiliers";
import IconMarketing from "../../assets/svg/navbar-icon-marketing";
import IconParametres from "../../assets/svg/navbar-icon-parametres";
import IconReseau from "../../assets/svg/navbar-icon-reseau";
import IconTutoriels from "../../assets/svg/navbar-icon-tutoriels";
import IconArrow from "../../assets/svg/navbar-arrow";

import IconBeneficesColored from "../../assets/svg/navbar-icon-benefices-colored";
import IconCommunauteColored from "../../assets/svg/navbar-icon-communaute-colored";
import IconContratsColored from "../../assets/svg/navbar-icon-contrats-colored";
import IconDashboardColored from "../../assets/svg/navbar-icon-dashboard-colored";
import IconDocumentsColored from "../../assets/svg/navbar-icon-documents-colored";
import IconFormationsColored from "../../assets/svg/navbar-icon-formations-colored";
import IconEvenementsColored from "../../assets/svg/navbar-icon-evenements-colored";
import IconFaqColored from "../../assets/svg/navbar-icon-faq-colored";
import IconImmobiliersColored from "../../assets/svg/navbar-icon-immobiliers-colored";
import IconMarketingColored from "../../assets/svg/navbar-icon-marketing-colored";
import IconParametresColored from "../../assets/svg/navbar-icon-parametres-colored";
import IconReseauColored from "../../assets/svg/navbar-icon-reseau-colored";
import IconTutorielsColored from "../../assets/svg/navbar-icon-tutoriels-colored";
import IconArrowColored from "../../assets/svg/navbar-arrow-colored";
import IconInvest from "../../assets/svg/navbar-icon-invest";
import IconInvestColored from "../../assets/svg/navbar-icon-invest-colored";
import IconInvestorProfile from "../../assets/svg/navbar-icon-investor-profile";
import IconInvestorProfileColored from "../../assets/svg/navbar-icon-investor-profile-colored";
import IconNotification from "../../assets/svg/icon-notification";
import IconNotificationColored from "../../assets/svg/icon-notification-colored";
import IconNotificationRed from "../../assets/svg/navbar-icon-notif-red";
import IconNotificationGreyRed from "../../assets/svg/navbar-icon-notif-grey-red";

import IconClose from "../../assets/svg/navbar-close";
import IconLogout from "../../assets/svg/navbar-logout";

const Navbar = ({ lang, parentCallback, handleCloseNavbar, isNavbarOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);

  const { user, token } = useSelector((state) => state.persisedReducer.user);

  const [items, setItems] = useState([
    {
      id: 0,
      itemLink: "/dashboard",
      itemTitle: texts[lang].labelDashboard,
      isActive: false,
      class: "",
      icons: [<IconDashboard />, <IconDashboardColored />],
      internItems: false,
      child: false,
    },
    {
      id: 1,
      itemLink: "/invest",
      itemTitle: texts[lang].labelInvestir,
      class: "",
      icons: [<IconInvest/>, <IconInvestColored />],
      isActive: false,
      internItems: false,
      child: false,
    },
   
    {
      id: 2,
      itemLink: "/notifications",
      itemTitle: texts[lang].labelNotifications,
      class: "",
      icons: [<IconNotification />, <IconNotificationColored />, <IconNotificationRed/>, <IconNotificationGreyRed/>],
      isActive: false,
      internItems: false,
      child: false,
    },

    {
      id: 3,
      itemLink: "/investissements",
      itemTitle: texts[lang].labelBenefice ,
      isActive: false,
      class: "",
      icons: [<IconBenefices />, <IconBeneficesColored />],
      child: false,
      internItems: [
        {
          id: 0,
          itemLink: "/investissements",
          itemTitle: texts[lang].labelBeneficeCompteInv,
        },
        {
          id: 1,
          itemLink: "/dashboard/beneficts-aa",
          itemTitle: texts[lang].labelBeneficeCompteAA,
        },
        {
          id: 2,
          itemLink: "/dashboard/beneficts-op",
          itemTitle: texts[lang].labelBeneficeCompteOp,
        },
      ],
    },
    {
      id: 4,
      itemLink: "/financial-transactions",
      itemTitle: texts[lang].labelTransaction ,
      class: "",
      icons: [<IconBenefices />, <IconBeneficesColored />],
      isActive: false,
      internItems: false,
      child: false,
    },
    {
      id: 5,
      itemLink: "/apport-affaire",
      itemTitle: texts[lang].labelApportAffaire ? texts[lang].labelApportAffaire :"Apport d'affaires" ,
      class: "",
      icons: [<IconBenefices />, <IconBeneficesColored />],
      isActive: false,
      internItems: false,
      child: false,
    },
    
    {
      id: 6,
      itemLink: "/monReseau",
      itemTitle: texts[lang].labelReseau,
      class: "",
      icons: [<IconReseau />, <IconReseauColored />],
      isActive: false,
      internItems: false,
      child: false,
    },

    {
      id: 7,
      itemLink: "/projets-immobiliers",
      itemTitle: texts[lang].labelProjetsimmo,
      class: "",
      icons: [<IconImmobiliers />, <IconImmobiliersColored />],
      isActive: false,
      child: false,
    },
    {
      id: 11,
      itemLink: "/partenaires",
      itemTitle: texts[lang].labelPartenaires ? texts[lang].labelPartenaires : "Partenaires",
      class: "",
      icons: [<IconImmobiliers />, <IconImmobiliersColored />],
      isActive: false,
      child: false,
    },
    {
      id: 8,
      itemLink: "/documents",
      itemTitle: texts[lang].labelDocuments,
      class: "",
      icons: [<IconDocuments />, <IconDocumentsColored />],
      isActive: false,
      internItems: false,
      child: false,
    },
    {
      id: 9,
      itemLink: "/invest-profile",
      itemTitle: texts[lang].labelInvestProfile,
      class: "",
      icons: [<IconInvestorProfile />, <IconInvestorProfileColored />],
      isActive: false,
      internItems: false,
      child: false,
    },
  {
      id: 10,
      itemLink: "/faqs",
      itemTitle: texts[lang].labelFAQ,
      class: "",
      icons: [<IconFaq />, <IconFaqColored />],
      isActive: false,
      internItems: false,
      child: false,
    },
    // {
    //   id: 8,
    //   itemLink: "/dashboard/marketing",
    //   itemTitle: texts[lang].labelMarketing,
    //   class: "",
    //   icons: [<IconMarketing />, <IconMarketingColored />],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
    
    // {
    //   id: 10,
    //   itemLink: "/dashboard/events",
    //   itemTitle: texts[lang].labelEvenements,
    //   class: "",
    //   icons: [<IconEvenements />, <IconEvenementsColored />],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
    // {
    //   id: 11,
    //   itemLink: "/dashboard/community",
    //   itemTitle: texts[lang].labelCommunaute,
    //   class: "",
    //   icons: [<IconCommunaute />, <IconCommunauteColored />],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
    // {
    //   id: 12,
    //   itemTitle: texts[lang].labelHelp,
    // },
    // {
    //   id: 13,
    //   itemLink: "/dashboard/tutoriels",
    //   itemTitle: texts[lang].labelTuto,
    //   class: "",
    //   icons: [<IconTutoriels />, <IconTutorielsColored />],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
    // {
    //   id: 14,
    //   itemLink: "/dashboard/faq",
    //   itemTitle: texts[lang].labelFAQ,
    //   class: "",
    //   icons: [<IconFaq />, <IconFaqColored />],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
    // {
    //   id: 15,
    //   itemLink: "/dashboard/settings",
    //   itemTitle: texts[lang].labelSettings,
    //   class: "",
    //   icons: [<IconParametres />, <IconParametresColored />],
    //   isActive: false,
    //   internItems: false,
    //   child: false,
    // },
     // {
    //   id: 1,
    //   itemLink: "/contracts",
    //   itemTitle: texts[lang].labelContrat,
    //   isActive: false,
    //   class: "",
    //   icons: [<IconContrats />, <IconContratsColored />],
    //   internItems: false,
    //   child: false,
    // },
  ]);
  const headers = {
    headers: {
      "x-auth-token": token,
    },
  };

  useEffect(() => {
    const currentLocation = `/${location.pathname.split("/")[1]}`;
    

    const newItems = items.map((item) => {
      if (item.itemLink === currentLocation) {
        parentCallback(item.itemTitle);
        return { ...item, class: "-active" };
      } else return { ...item, class: "" };
    });
    setItems(newItems);
   

    axios.get(`${urls.apiUrl}/request/getNotif/user/${user.id}`,headers)
            .then((res) => {
                // console.log((res.data).filter(i=>i.read === false));
                setNotifications((res.data).filter(i=>i.read === false));
            }
            )
            .catch((err) => {
                console.log(err);
            }
            )
  }, [location]);

  const handleItemClick = (id) => {
    const newItems = items.map((item) =>
      item.id === id ? { ...item, class: "-active" } : { ...item, class: "" }
    );
    setItems(newItems);
  };

  return (
    <nav className={`navbar ${isNavbarOpen ? "-active" : ""}`}>
      <div className="-header">
        <div className="-logoNav">

          <Link to="/dashboard">
          <img src={logo} alt="" />
          </Link>
          
        </div>
        <button className="-icon" onClick={handleCloseNavbar}>
          <IconClose />
        </button>
      </div>

      <div className="-links">
        {items.map((obj) => {
          if (obj.itemLink)
            return (
              <NavLink
                to={obj.itemLink}
                key={obj.id}
                className={`-link ${obj.class}`}
                onClick={() => {
                  parentCallback(obj.itemTitle);
                  handleItemClick(obj.id);
                  handleCloseNavbar()
                }}
              >
                <div className="-icon">
                  {obj.itemLink==="/notifications"&&notifications.length>0?obj.icons[3]:obj.icons[0] }
                  {obj.itemLink==="/notifications"&&notifications.length>0?obj.icons[2]:obj.icons[1]}
                </div>
                <div className="-label">{obj.itemTitle}</div>
                <div className="-arrow">
                  <IconArrow />
                  <IconArrowColored />
                </div>
              </NavLink>
            );
          else
            return (
              <h1 className="-title" key={obj.id}>
                {obj.itemTitle}
              </h1>
            );
        })}
        <button
          className="-link -button"
          onClick={() => {
            dispatch(
              setRedirect({
                status: true,
                link: "/connexion",
                params: [{ name: "email", value: user.email }],
                deconnexion: true,
              })
            );
          }}
        >
          <div className="-icon">
            <IconLogout />
          </div>
          <div className="-label">{texts[lang].labelDeconnexion}</div>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
