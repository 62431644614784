// Apport d'affaire et detail d'apport d'affaire

export default {
  fr: {
    //! Vue apport d'affaire
    //* Lien https://app.viziocapital.com/apport-affaire
    infop: "Informations préalables",
    becomeCommercial:"Félicitations, vous avez atteint les 100 000 euros d'apport d'affaires avec vos partenaires directs , c'est incroyable ! Vous êtes éligible au statut de collaborateur, qui vous permettrait d'intégrer plus sérieusement l'équipe Vizio. Saurez-vous relever le défi..?",
    infoboxCommercialEnCours: `Votre demande pour Intégrer l'équipe Vizio est en cours de traitement. Il ne vous reste qu’une seule étape : Prenez rendez-vous avec notre direction pour un entretien personnalisé ! <a href="https://calendly.com/viziocapital/rendez-vous-devenir-commercial-pour-vizio-capital?month=2022-09" target="_blank">Rendez-vous : "Intégrer l'équipe Vizio" - VIZIO CAPITAL</a> `,
    labelRdv: "Prendre un rendez-vous",
    infobox1: "Lorsque grâce à votre intervention, un investissement est réalisé dans nos projets pour un minimum de 500 EUR, nous octroyons une indemnité unique pour vous remercier de cet apport. Cette indemnité sera visible sur votre tableau bord lorsque l'investissement lié à votre intervention sera validé par un notaire, à la date du 30 décembre. Suivant votre statut fiscal et social, une facture pourra être demandée avant tout paiement ou celui-ci s’accompagnera de l’émission d’une fiche fiscale ad hoc (disponible dans « Documents »).",
    infobox2:"Lorsque vous devenez apporteur d’affaires, vous signez un contrat dans lequel toutes les conditions sont détaillées. Vous pouvez également contacter Vizio pour toute question à ce sujet via l’adresse mail suivante : marketing@viziocapital.com.",
    infobox3: "La loi ne vous autorise pas à offrir nos produits ou services directement à des tiers sans un agrément spécifique d’intermédiaire mais uniquement à les mettre en contact avec nous. Vous n’êtes donc pas autorisé à offrir nos solutions ni à utiliser les informations disponibles sur la Plateforme, sauf accord préalable de Vizio.",
    labelBeCom: "Intégrer l'équipe Vizio",
    labelTitle: "Mon affiliation",
    montantleve: "Montant levé",
    /*!eviter de mettre des accents sur les variables*/ ////montantlevé///
    montantlevetext: "L’ensemble des investissements de mes clients dans Vizio",
    montantlevebtn: "Voir le détail",
    benefdispo: "Mes indemnités disponibles",
    benefdispotext: "Générés grâce à mon montant levé dans  ",
    benefdispoCapitaltext: "Générés grâce à mon montant levé dans   ",
    benefdispobtn: "Retirer",
    totalbenef: "Total indemnités",
    totalbeneftext: "Généres sur l’ensemble de mon montant levé dans",
    totalbenefbtn: "Historique de retrait",
    detailbenef: "Détail de mes indemnités",
    nom: "NOM",
    prenom: "PRÉNOM",
    date: "DATE",
    commission: "INDEMENITÉS",
    status: "STATUT",
    investissement: "INVESTISSEMENT",

    dTnom: "NOM :",
    dTprenom: "PRÉNOM :",
    dTdate: "DATE :",
    dTcommission: "INDEMENITÉS :",
    dTstatus: "STATUT :",
    dTinvestissement: "INVESTISSEMENT :",
    dTTotaldeposer: "TOTAL DÉPOSÉ :",
    fondAA: "Apport-Affaires",

    //!vue contact
    //*Lien https://app.viziocapital.com/list-contact
    infoPrealableContact: "Informations préalables",
    infoPrealableContactContent: "Les données que vous renseignez dans la liste de vos contacts sont strictement confidentielles et accessibles uniquement entre vous et la société VIZIO CAPITAL. Veillez à ne pas exposer ces informations en dehors de la plateforme afin de respecter les règles de la vie privée.",
    MesContact :"Mes contacts",
    constactPeudo: "PSEUDO",
    contactNom: "NOM",
    contactPrenom: "PRENOM",
    contactNomPrenom: "NOM ET PRENOM",
    contactEmail: "EMAIL",
    contactTel: "TELEPHONE",
    statutAffaire: "STATUT D'AFFAIRES",
    statutInvest: "STATUT D'INVESTISSEMENT",
    addContact: "Ajouter un contact",
    errorMail: "La valeur saisie pour le champ email n'est pas correcte",
    errorPseudo: "Votre pseudo dois être composer de 4 a 20 caractéres alphanumérique",
    errorPassword: "La valeur saisie pour le champ mot de passe n'est pas correcte",
    errorPhoneNum: "La valeur saisie pour le champ téléphone doit être composer de 9 a 10 chiffres",
    errorEmpryField: "Ce champs est obligatoire",
    errorEmpryFieldOne: "Ce champs est obligatoire est doit être composer d'au moins 1 caractéres",
    seeContact: "Voir la fiche de mon contact",
    errorRéseaux:"Erreur réseaux",
    errorRéseauxText:"Une erreur est survenue lors de la récupération des données",
    inputError: "Erreur de saisie",
    noDataReturnedMessage:"Non renseigné",
    //!vue premier formulaire a remplir contact
    infoPrealableContactFormOne: "Informations préalables",
    infoPrealableContactFormOneContent: [
      "Les données que vous renseignez dans la liste de vos contacts sont strictement confidentielles et accessibles uniquement entre vous et la société VIZIO CAPITAL. Veillez à ne pas exposer ces informations en dehors de la plateforme afin de respecter les règles de la vie privée.",
      "Vous pouvez compléter ulterieurement votre fiche contact afin d'y apporter plus d'informations.",
    ],
    infoFormONeContact: "Information personnelles",
    formOneContactStatus: "C'est un professionnel",
    formContactPseudoOne: "Pseudo",
    formContactLastNameOne: "Nom",
    formContactFirstNameOne: "Prénom",
    formContactEmailOne: "Adresse e-mail",
    formContactPhoneOne: "Numéro de téléphone",
    formContactIndicatif: "Indicatif téléphonique international",
    formContactDenominationOne: "Dénomination sociale",
    FormContactSubmitButtonONe:"Valider les informations du contact",
    errorMail: "La valeur saisie pour le champ email n'est pas correcte",
    errorPseudo: "Votre pseudo dois être composer de 4 a 20 caractéres alphanumérique",
    errorPassword: "La valeur saisie pour le champ mot de passe n'est pas correcte",
    errorEmpryField: "Ce champs est obligatoire",
    
    addmessageTitle: "Ajout d'un contact",
    addMessageValue: "Vous avez bien ajouté un contact",
    errorMessage:"Erreur",
    erreur:"Erreur",

    confirmationMessageTitle: "Confirmation des modifications",
    consirmationMessageValue: "Vos données ont bien été mises à jour",


    //! vue formulaire a remplir contact
    infoPrealableContactForm: "Informations préalables",
    infoFormContactContentOne: "Les données que vous renseignez dans la liste de vos contacts sont strictement confidentielles et accessibles uniquement entre vous et la société VIZIO CAPITAL. Veillez à ne pas exposer ces informations en dehors de la plateforme afin de respecter les règles de la vie privée.",
    infoFormContactContentTwo: "Si vous souhaitez modifier les champs nom, prénom, e-mail et numero de téléphone, vous devez contacter la société par email a modifications@viziocapital.com",
    infoForm:"Renseigner les informations de mon contact",
    contactType:["C'est un particulier","C'est un proffesionnel"],
    formContactPseudo: "Pseudo",
    formContactName: "Nom",
    formContactPrenom: "Prénom",
    formContactEmail: "Email",
    formContactIndicatif: "Indicatif téléphonique international",
    formContactTel: "Téléphone",
    formContactCountry: "Pays",
    formContactAdresse: "Adresse postale",
    formContactCity: "Ville",
    formContactCodePostal: "Code postal",
    formContactProfession: "Métier",
    formContactProspectSource: "Source de prospection",
    formContactProspectSourceContent: [
      "Marché chaud",
      "Marché froid",
    ],
    aucun: "Aucun",
    formContactProspectProvenance: "Provenance de la prospection",
    formContactProspectProvenanceContent: [
      "Réseaux sociaux",
      "Recommandation",
      "Famille",
      "Amis",
      "Connaissance",
      "Personnalités"
    ],
    projectInterest: "Intérêt du contact pour le projet Vizio",
    projectInterestvalue: "Renseigner les intérêts du contact pour le projet Vizio",
    projectInterestlabel:"Il est interessé par :",
    projectInterestContent: [
      "Investissement immobilier",
      "Investissement start-up",
      "Investissement finance",
      "Apports d'affaires",
    ],
    firstContact: "Premier contact",
    firstContactContent: [
      "Oui",
      "Non",
    ],
    demandAssistance: "Demander un accompagnement de la société (demander de l'aide pour contacter ou présenter) ",
    demandAssistanceContent: [
      "Oui",
      "Non",
    ],
    socialNetwork: "Réseaux sociaux",
    socialNetworkContent: [
      "Facebook",
      "Instagram",
      "Tiktok",
      "Telegram",
      "Linkedin",
    ],

    investmentTitle:"L'investissement",
    AATitle:"L'apporteur d'affaires",
    investInVizio: "Interessé pour investir",
    investContent: [
      "Oui",
      "Non",
      "En attente",
    ],
    investorStatus: "Statut d'investisseur",
    investorStatusContent: [
      "Validé",
      "A refusé",
      "En attente",
    ],
    investitmenttAmount: "Montant d'investissement",

    AAinterest:"Intéressé pour faire des affaires",
    AAinterestContent: [
      "Oui",
      "Non",
      "En attente",
      "Pas pour le moment",
    ],
    AAStatut : "Statut d'apporteur d'affaires",
    AAStatutContent: [
      "Validé",
      "A refusé",
      "En attente",
    ],
    notesAndAppointments:"Notes et rendez-vous",
    AppointmentDate:"Date de rendez-vous",
    AppointmentDateContent: "Date de rendez-vous",
    notes: "Notes",
    noteDate: "Date",
    noteContent: "Contenu",
    noteAdd: "Ajouter une note",

    //!vue detail apport d'affaire
    //*Lien https://app.viziocapital.com/apport-affaire/detail
    detailInfop: "Informations préalables",
    detailInfopContent: "Vous trouverez ci-dessous le détail de votre affiliation dans les différentes solutions de VIZIO CAPITAL. Vous trouverez également (d’ici quelques temps) le détail des affiliations qui ont été générées via votre développement.",
    
    //levée direct
    detailMonaffiliation:"Montant levé",
    detailMontantleveTotal: "Total direct",
    detailMontantleveTotalContent: "L’ensemble des investissements de mes clients (Vizio Real estate & Capitalisation).",
    detailMontantlevéRE: "Vizio Real estate",
    detailMontantlevéREContent: "L’ensemble des investissements de mes clients.",
    detailMontantlevéCA: "Capitalisation",
    detailMontantlevéCAContent: "L’ensemble des investissements de mes clients.",
    
    //levée indirect
    detailMonaffiliationIndirect: "Montant levé indirectement",
    detailMontantleveTotalIndirect:"Total indirect",
    detailMontantleveTotalContentIndirect: "L’ensemble des investissements des clients indirects (Vizio Real Estate & Capitalisation).",


    detailNom: "NOM ",
    detailPrenom: "PRÉNOM ",
    detailRealEstate: "REAL ESTATE ",
    detailCapitalisation: "CAPITALISATION ",
    detailTotaldeposer: "TOTAL INVESTI ",
    detailNoInvestor:"Vos investisseurs n'ont pas encore investi." ,

    //!vue devenir commercial   
    //* lien = https://app.viziocapital.com/apport-affaire/commercial 
    commercialFormLabel:"Formulaire pour intégrer l'équipe Vizio",
    errorMsg:"Des Données sont manquantes !!!",
    continuerBtn:"êtes vous sur de vouloir continuer",
    yesOrNoChoices:[
      "Selectionner une reponse",
      "Oui",
      "Non",
    ],
    commonVariables:[
      "Oui",
      "Non",
      "Autres"
    ],
    niveauEtudeLabel: "Niveau d'étude",
    niveauEtudeChoices:[
      "Aucun niveau d'étude",
      "BAC+1",
      "BAC+2",
      "BAC+3",
      "BAC+4",
      "BAC+5",
      "BAC+6",
      "BAC+7",
      "BAC+8",
      "BAC+9",
      "BAC+10"
    ],
    professionLabel:"Profession actuelle",
    permisLabel:"Permis de conduire",
    vehiculeLabel:"Véhicule",
    vehiculeChoices:[
      "Selectionner une reponse",
      "Oui",
      "Non",
      "Autre moyen de locomotion"
    ],
    formationLabel: "J’ai déjà suivi une formation dans le monde de la finance ?",
    formationSubLabel: "Si oui, précisez ?",
    tempsConsacrerFormLabel: "Puis-je consacrer du temps à la formation ?",
    tempsConsacrerFormSubLabel: "Si oui, précisez combien de temps (+/-) : ",
    tempsConsacrerFormSemaineLabel: "Combien de temps par semaine puis-je consacrer à mon activité d’apporteurs d’affaires chez Vizio Capital ?",
    tempsPasserSemaineFormationChoices:[
      "Selectionner une reponse",
      "1h par semaine",
      "1 demie journée par semaine",
      "1 journée par semaine",
      "1 mi-temps par semaine",
      "1  temps plein"
    ],
    langueLabel: "Je parle correctement les langues suivantes :",
    langueChoices:[
      "Français",
      "Anglais",
      "Espagnol",
      "Italien",
      "Allemand",
      "Néerlandais",
      "Chinois"
    ],
    motivationLabel: "Mes motivations sont orientées sur :",
    statusLabel:"Le statut d’entrepreneur indépendant m’intéresse :",
    statusPrefLabel:"Mon statut préféré :",
    statusPrefChoices:[
      "Selectionner une reponse",
      "Etudiant",
      "Salarié",
      "Indépendant",
      "Rentier",
      "Pensionné",
      "Autres"
    ],
    statusPrefSubLabel:"Si autre, précisez le status que vous préféré",
    ressourceApportéLabel:"Quelles ressources puis-je apporter à Vizio Capital ?",
    passionLabel:"Mes passions :",
    passionChoices:[
      "Sport",
      "Lecture",
      "Musique",
      "Voyage",
      "Fête",
      "Formations",
      "Cinéma/Film",
      "Netwoking",
      "Spectacle",
      "Rire",
      "Autres"
    ],
    transmissionLabel:"Ce que je souhaite transmettre à mes enfants / proches : ",
    transmissionChoices:[
      "Selectionner une reponse",
      "Un patrimoine",
      "De beaux souvenirs",
      "Une ouverture d’esprit",
      "De bonnes valeurs",
      "De bonnes relations",
      "Des connaissances",
      "Des compétences",
      "Le goût du travail",
      "Autres"
    ],
    transmissionSubLabel:"Si autre, précisez le status que vous préféré ",
    temperamentLabel:"Quel est mon tempérament ?",
    temperamentSubLabel:"Si autre, précisez le status que vous préféré ",
    temperamentChoices:[
      "Selectionner une reponse",
      "Bosseur",
      "Fainéant",
      "Intelligent",
      "Visionnaire",
      "Motivé",
      "Ambitieux",
      "à l’écoute",
      "Généreux",
      "Positif",
      "Perfectionniste",
      "Respectueux",
      "Autres"
    ],
    changerLeMondeLabel: "Si je devais changer ou apporter quelque chose à ce monde, ce serait :",
    activitelabel: "Qu’est ce qui pourrait m’effrayer ou me déplaire dans ce genre d’activité :",
    activiteSublabel:"Si autre, précisez le status que vous préféré ",
    activiteChoices:[
      "Selectionner une reponse",
      "Le manque de compétences",
      "Le temps à y consacrer",
      "Le monde de la “Finance”",
      "Le marketing/ La vente agressive",
      "Rien",
      "Autres"
    ],
    deposerDemande:"Déposer ma demande",
   
  },
  en: {
        //! Vue apport d'affaire
    //* Lien https://app.viziocapital.com/apport-affaire
    infop: "Informations préalables",
    becomeCommercial:"Félicitations, vous avez atteint les 100 000 euros d'apport d'affaires avec vos partenaires directs , c'est incroyable ! Vous êtes éligible au statut de collaborateur, qui vous permettrait d'intégrer plus sérieusement l'équipe Vizio. Saurez-vous relever le défi..?",
    infoboxCommercialEnCours: `Votre demande pour Intégrer l'équipe Vizio est en cours de traitement. Il ne vous reste qu’une seule étape : Prenez rendez-vous avec notre direction pour un entretien personnalisé ! <a href="https://calendly.com/viziocapital/rendez-vous-devenir-commercial-pour-vizio-capital?month=2022-09" target="_blank">Rendez-vous : "Intégrer l'équipe Vizio" - VIZIO CAPITAL</a> `,
    labelRdv: "Prendre un rendez-vous",
    infobox1: "Lorsque grâce à votre intervention, un investissement est réalisé dans nos projets pour un minimum de 500 EUR, nous octroyons une indemnité unique pour vous remercier de cet apport. Cette indemnité sera visible sur votre tableau bord lorsque l'investissement lié à votre intervention sera validé par un notaire, à la date du 30 décembre. Suivant votre statut fiscal et social, une facture pourra être demandée avant tout paiement ou celui-ci s’accompagnera de l’émission d’une fiche fiscale ad hoc (disponible dans « Documents »).",
    infobox2:"Lorsque vous devenez apporteur d’affaires, vous signez un contrat dans lequel toutes les conditions sont détaillées. Vous pouvez également contacter Vizio pour toute question à ce sujet via l’adresse mail suivante : marketing@viziocapital.com.",
    infobox3: "La loi ne vous autorise pas à offrir nos produits ou services directement à des tiers sans un agrément spécifique d’intermédiaire mais uniquement à les mettre en contact avec nous. Vous n’êtes donc pas autorisé à offrir nos solutions ni à utiliser les informations disponibles sur la Plateforme, sauf accord préalable de Vizio.",
    labelBeCom: "Intégrer l'équipe Vizio",
    labelTitle: "Mon affiliation",
    montantleve: "Montant levé",
    /*!eviter de mettre des accents sur les variables*/ ////montantlevé///
    montantlevetext: "L’ensemble des investissements de mes clients dans Vizio",
    montantlevebtn: "Voir le détail",
    benefdispo: "Mes indemnités disponibles",
    benefdispotext: "Générés grâce à mon montant levé dans  ",
    benefdispoCapitaltext: "Générés grâce à mon montant levé dans   ",
    benefdispobtn: "Retirer",
    totalbenef: "Total indemnités",
    totalbeneftext: "Généres sur l’ensemble de mon montant levé dans",
    totalbenefbtn: "Historique de retrait",
    detailbenef: "Détail de mes indemnités",
    nom: "NOM",
    prenom: "PRÉNOM",
    date: "DATE",
    commission: "INDEMENITÉS",
    status: "STATUT",
    investissement: "INVESTISSEMENT",

    dTnom: "NOM :",
    dTprenom: "PRÉNOM :",
    dTdate: "DATE :",
    dTcommission: "INDEMENITÉS :",
    dTstatus: "STATUT :",
    dTinvestissement: "INVESTISSEMENT :",
    dTTotaldeposer: "TOTAL DÉPOSÉ :",
    fondAA: "Apport-Affaires",

    //!vue contact
    //*Lien https://app.viziocapital.com/list-contact
    infoPrealableContact: "Informations préalables",
    infoPrealableContactContent: "Les données que vous renseignez dans la liste de vos contacts sont strictement confidentielles et accessibles uniquement entre vous et la société VIZIO CAPITAL. Veillez à ne pas exposer ces informations en dehors de la plateforme afin de respecter les règles de la vie privée.",
    MesContact :"Mes contacts",
    constactPeudo: "PSEUDO",
    contactNom: "NOM",
    contactPrenom: "PRENOM",
    contactNomPrenom: "NOM ET PRENOM",
    contactEmail: "EMAIL",
    contactTel: "TELEPHONE",
    statutAffaire: "STATUT D'AFFAIRES",
    statutInvest: "STATUT D'INVESTISSEMENT",
    addContact: "Ajouter un contact",
    errorMail: "La valeur saisie pour le champ email n'est pas correcte",
    errorPseudo: "Votre pseudo dois être composer de 4 a 20 caractéres alphanumérique",
    errorPassword: "La valeur saisie pour le champ mot de passe n'est pas correcte",
    errorPhoneNum: "La valeur saisie pour le champ téléphone doit être composer de 9 a 10 chiffres",
    errorEmpryField: "Ce champs est obligatoire",
    errorEmpryFieldOne: "Ce champs est obligatoire est doit être composer d'au moins 1 caractéres",
    seeContact: "Voir la fiche de mon contact",
    errorRéseaux:"Erreur réseaux",
    errorRéseauxText:"Une erreur est survenue lors de la récupération des données",
    inputError: "Erreur de saisie",
    noDataReturnedMessage:"Non renseigné",
    //!vue premier formulaire a remplir contact
    infoPrealableContactFormOne: "Informations préalables",
    infoPrealableContactFormOneContent: [
      "Les données que vous renseignez dans la liste de vos contacts sont strictement confidentielles et accessibles uniquement entre vous et la société VIZIO CAPITAL. Veillez à ne pas exposer ces informations en dehors de la plateforme afin de respecter les règles de la vie privée.",
      "Vous pouvez compléter ulterieurement votre fiche contact afin d'y apporter plus d'informations.",
    ],
    infoFormONeContact: "Information personnelles",
    formOneContactStatus: "C'est un professionnel",
    formContactPseudoOne: "Pseudo",
    formContactLastNameOne: "Nom",
    formContactFirstNameOne: "Prénom",
    formContactEmailOne: "Adresse e-mail",
    formContactPhoneOne: "Numéro de téléphone",
    formContactIndicatif: "Indicatif téléphonique international",
    formContactDenominationOne: "Dénomination sociale",
    FormContactSubmitButtonONe:"Valider les informations du contact",
    errorMail: "La valeur saisie pour le champ email n'est pas correcte",
    errorPseudo: "Votre pseudo dois être composer de 4 a 20 caractéres alphanumérique",
    errorPassword: "La valeur saisie pour le champ mot de passe n'est pas correcte",
    errorEmpryField: "Ce champs est obligatoire",
    
    addmessageTitle: "Ajout d'un contact",
    addMessageValue: "Vous avez bien ajouté un contact",
    errorMessage:"Erreur",
    erreur:"Erreur",

    confirmationMessageTitle: "Confirmation des modifications",
    consirmationMessageValue: "Vos données ont bien été mises à jour",


    //! vue formulaire a remplir contact
    infoPrealableContactForm: "Informations préalables",
    infoFormContactContentOne: "Les données que vous renseignez dans la liste de vos contacts sont strictement confidentielles et accessibles uniquement entre vous et la société VIZIO CAPITAL. Veillez à ne pas exposer ces informations en dehors de la plateforme afin de respecter les règles de la vie privée.",
    infoFormContactContentTwo: "Si vous souhaitez modifier les champs nom, prénom, e-mail et numero de téléphone, vous devez contacter la société par email a modifications@viziocapital.com",
    infoForm:"Renseigner les informations de mon contact",
    contactType:["C'est un particulier","C'est un proffesionnel"],
    formContactPseudo: "Pseudo",
    formContactName: "Nom",
    formContactPrenom: "Prénom",
    formContactEmail: "Email",
    formContactIndicatif: "Indicatif téléphonique international",
    formContactTel: "Téléphone",
    formContactCountry: "Pays",
    formContactAdresse: "Adresse postale",
    formContactCity: "Ville",
    formContactCodePostal: "Code postal",
    formContactProfession: "Métier",
    formContactProspectSource: "Source de prospection",
    formContactProspectSourceContent: [
      "Marché chaud",
      "Marché froid",
    ],
    aucun: "Aucun",
    formContactProspectProvenance: "Provenance de la prospection",
    formContactProspectProvenanceContent: [
      "Réseaux sociaux",
      "Recommandation",
      "Famille",
      "Amis",
      "Connaissance",
      "Personnalités"
    ],
    projectInterest: "Intérêt du contact pour le projet Vizio",
    projectInterestvalue: "Renseigner les intérêts du contact pour le projet Vizio",
    projectInterestlabel:"Il est interessé par :",
    projectInterestContent: [
      "Investissement immobilier",
      "Investissement start-up",
      "Investissement finance",
      "Apports d'affaires",
    ],
    firstContact: "Premier contact",
    firstContactContent: [
      "Oui",
      "Non",
    ],
    demandAssistance: "Demander un accompagnement de la société (demander de l'aide pour contacter ou présenter) ",
    demandAssistanceContent: [
      "Oui",
      "Non",
    ],
    socialNetwork: "Réseaux sociaux",
    socialNetworkContent: [
      "Facebook",
      "Instagram",
      "Tiktok",
      "Telegram",
      "Linkedin",
    ],

    investmentTitle:"L'investissement",
    AATitle:"L'apporteur d'affaires",
    investInVizio: "Interessé pour investir",
    investContent: [
      "Oui",
      "Non",
      "En attente",
    ],
    investorStatus: "Statut d'investisseur",
    investorStatusContent: [
      "Validé",
      "A refusé",
      "En attente",
    ],
    investitmenttAmount: "Montant d'investissement",

    AAinterest:"Intéressé pour faire des affaires",
    AAinterestContent: [
      "Oui",
      "Non",
      "En attente",
      "Pas pour le moment",
    ],
    AAStatut : "Statut d'apporteur d'affaires",
    AAStatutContent: [
      "Validé",
      "A refusé",
      "En attente",
    ],
    notesAndAppointments:"Notes et rendez-vous",
    AppointmentDate:"Date de rendez-vous",
    AppointmentDateContent: "Date de rendez-vous",
    notes: "Notes",
    noteDate: "Date",
    noteContent: "Contenu",
    noteAdd: "Ajouter une note",

    //!vue detail apport d'affaire
    //*Lien https://app.viziocapital.com/apport-affaire/detail
    detailInfop: "Informations préalables",
    detailInfopContent: "Vous trouverez ci-dessous le détail de votre affiliation dans les différentes solutions de VIZIO CAPITAL. Vous trouverez également (d’ici quelques temps) le détail des affiliations qui ont été générées via votre développement.",
    
    //levée direct
    detailMonaffiliation:"Montant levé",
    detailMontantleveTotal: "Total direct",
    detailMontantleveTotalContent: "L’ensemble des investissements de mes clients (Vizio Real estate & Capitalisation).",
    detailMontantlevéRE: "Vizio Real estate",
    detailMontantlevéREContent: "L’ensemble des investissements de mes clients.",
    detailMontantlevéCA: "Capitalisation",
    detailMontantlevéCAContent: "L’ensemble des investissements de mes clients.",
    
    //levée indirect
    detailMonaffiliationIndirect: "Montant levé indirectement",
    detailMontantleveTotalIndirect:"Total indirect",
    detailMontantleveTotalContentIndirect: "L’ensemble des investissements des clients indirects (Vizio Real Estate & Capitalisation).",


    detailNom: "NOM ",
    detailPrenom: "PRÉNOM ",
    detailRealEstate: "REAL ESTATE ",
    detailCapitalisation: "CAPITALISATION ",
    detailTotaldeposer: "TOTAL INVESTI ",
    detailNoInvestor:"Vos investisseurs n'ont pas encore investi." ,

    //!vue devenir commercial   
    //* lien = https://app.viziocapital.com/apport-affaire/commercial 
    commercialFormLabel:"Formulaire pour intégrer l'équipe Vizio",
    errorMsg:"Des Données sont manquantes !!!",
    continuerBtn:"êtes vous sur de vouloir continuer",
    yesOrNoChoices:[
      "Selectionner une reponse",
      "Oui",
      "Non",
    ],
    commonVariables:[
      "Oui",
      "Non",
      "Autres"
    ],
    niveauEtudeLabel: "Niveau d'étude",
    niveauEtudeChoices:[
      "Aucun niveau d'étude",
      "BAC+1",
      "BAC+2",
      "BAC+3",
      "BAC+4",
      "BAC+5",
      "BAC+6",
      "BAC+7",
      "BAC+8",
      "BAC+9",
      "BAC+10"
    ],
    professionLabel:"Profession actuelle",
    permisLabel:"Permis de conduire",
    vehiculeLabel:"Véhicule",
    vehiculeChoices:[
      "Selectionner une reponse",
      "Oui",
      "Non",
      "Autre moyen de locomotion"
    ],
    formationLabel: "J’ai déjà suivi une formation dans le monde de la finance ?",
    formationSubLabel: "Si oui, précisez ?",
    tempsConsacrerFormLabel: "Puis-je consacrer du temps à la formation ?",
    tempsConsacrerFormSubLabel: "Si oui, précisez combien de temps (+/-) : ",
    tempsConsacrerFormSemaineLabel: "Combien de temps par semaine puis-je consacrer à mon activité d’apporteurs d’affaires chez Vizio Capital ?",
    tempsPasserSemaineFormationChoices:[
      "Selectionner une reponse",
      "1h par semaine",
      "1 demie journée par semaine",
      "1 journée par semaine",
      "1 mi-temps par semaine",
      "1  temps plein"
    ],
    langueLabel: "Je parle correctement les langues suivantes :",
    langueChoices:[
      "Français",
      "Anglais",
      "Espagnol",
      "Italien",
      "Allemand",
      "Néerlandais",
      "Chinois"
    ],
    motivationLabel: "Mes motivations sont orientées sur :",
    statusLabel:"Le statut d’entrepreneur indépendant m’intéresse :",
    statusPrefLabel:"Mon statut préféré :",
    statusPrefChoices:[
      "Selectionner une reponse",
      "Etudiant",
      "Salarié",
      "Indépendant",
      "Rentier",
      "Pensionné",
      "Autres"
    ],
    statusPrefSubLabel:"Si autre, précisez le status que vous préféré",
    ressourceApportéLabel:"Quelles ressources puis-je apporter à Vizio Capital ?",
    passionLabel:"Mes passions :",
    passionChoices:[
      "Sport",
      "Lecture",
      "Musique",
      "Voyage",
      "Fête",
      "Formations",
      "Cinéma/Film",
      "Netwoking",
      "Spectacle",
      "Rire",
      "Autres"
    ],
    transmissionLabel:"Ce que je souhaite transmettre à mes enfants / proches : ",
    transmissionChoices:[
      "Selectionner une reponse",
      "Un patrimoine",
      "De beaux souvenirs",
      "Une ouverture d’esprit",
      "De bonnes valeurs",
      "De bonnes relations",
      "Des connaissances",
      "Des compétences",
      "Le goût du travail",
      "Autres"
    ],
    transmissionSubLabel:"Si autre, précisez le status que vous préféré ",
    temperamentLabel:"Quel est mon tempérament ?",
    temperamentSubLabel:"Si autre, précisez le status que vous préféré ",
    temperamentChoices:[
      "Selectionner une reponse",
      "Bosseur",
      "Fainéant",
      "Intelligent",
      "Visionnaire",
      "Motivé",
      "Ambitieux",
      "à l’écoute",
      "Généreux",
      "Positif",
      "Perfectionniste",
      "Respectueux",
      "Autres"
    ],
    changerLeMondeLabel: "Si je devais changer ou apporter quelque chose à ce monde, ce serait :",
    activitelabel: "Qu’est ce qui pourrait m’effrayer ou me déplaire dans ce genre d’activité :",
    activiteSublabel:"Si autre, précisez le status que vous préféré ",
    activiteChoices:[
      "Selectionner une reponse",
      "Le manque de compétences",
      "Le temps à y consacrer",
      "Le monde de la “Finance”",
      "Le marketing/ La vente agressive",
      "Rien",
      "Autres"
    ],
    deposerDemande:"Déposer ma demande",
    
  },
};