import axios from 'axios'
import { useSelector } from "react-redux";
import urls from '../constants/urls'
import { Link, Navigate } from 'react-router-dom';
import Button from '../components/button'
import { LoaderContext } from "../context/Context";
import { useContext } from 'react';
import { useLayoutEffect } from 'react';
import texts from '../texts/detailReal'


  const RealDetailView = ()=>{
    const {lang} = useSelector(state=>state.persisedReducer.parameters)
    const loader = useContext(LoaderContext);
    useLayoutEffect(()=>{ 
      
      loader.onSetIsLoading(false);
      return()=>{
        loader.onSetIsLoading(false);
      }
    },[])

    return(
      <div className="layout-profile-container">
         <span className="-subtitle">
       <h3>VIZIO REAL ESTATE</h3>
      </span>
        <div className="container-detail">
          <div className="fond-presentation">
            <div className="-img">
             <img src="https://app.viziocapital.com/pub/vizio-real-estate.png" alt="" />
            </div>

            <div className="-text-btn">
              <p>{texts[lang].presentationText}</p>
              <Link to={"/invest/real-estate"}><Button
          label={texts[lang].btnLabel}
          icon
          classes={"-normal"}
          />
          </Link>
            </div>
          </div>
          <div className="fond-infos">
                  <div className="information-container -info-invest">
              <div className="-title">
                <h4 className="-texte">{texts[lang].infoHeader}</h4>
              </div>
              <div className="-information">
                  <span className='subtitle'>{texts[lang].stratLabel}</span>
                  <p>{texts[lang].stratText}</p>

                  <span className='subtitle'>{texts[lang].docLabel}</span>
                  <p>
                    {/* <a href='#'>Exemple de contrat</a> */}
                    {/* <a href='#'>Constitution de la société</a> */}
                    <a href='https://viziocapital.com/notes-dinformations/' target={'_blank'}>{texts[lang].infosLink}</a>
                    <a href='https://viziocapital.com/viziocapital.com/bilans' target={'_blank'}>{texts[lang].bilanLink}</a>
                  </p>

                  <span className='subtitle'>{texts[lang].estimLabel}</span>
                  <p>
                    <span> {texts[lang].estimTexts[0]} </span>
                     <span> {texts[lang].estimTexts[1]}</span>
                  </p>

                  <span className='subtitle'>{texts[lang].benefLabel}</span>
                  <p>
                  <span> {texts[lang].benefTexts[0]}</span>
                   <span>{texts[lang].benefTexts[1]}</span>
                   <span>{texts[lang].benefTexts[2]}</span>
                  </p>

                  <span className='subtitle'>{texts[lang].fiscalityLabel}</span>
                  <p>
                    <span>{texts[lang].fiscalityTexts[0]} </span>
                   <span> {texts[lang].fiscalityTexts[1]} </span>
                   <br />
                    <span>{texts[lang].fiscalityTexts[2]}</span>
                    
                  </p>

                  <span className='subtitle'>{texts[lang].endContratLabel}</span>
                  <p>
                    <span>{texts[lang].endContratTexts[0]}</span>
                    <span>{texts[lang].endContratTexts[1]}</span>
                  </p>
                  <p>
                    <span className='-text'>{texts[lang].endContratTexts[2]}</span>
                    <span className='-text'>{texts[lang].endContratTexts[3]}</span>
                    <span className='-text'>{texts[lang].endContratTexts[4]}</span>
                  </p>
                  <p>
                   <span> {texts[lang].endContratTexts[5]} </span>
                   <span> {texts[lang].endContratTexts[6]} <a href='mailto:remboursement@viziocapital.com' >remboursement@viziocapital.com</a></span>
                   <span> {texts[lang].endContratTexts[7]}</span>
                  </p>
                </div>
            </div>
          </div>

          <Link to={"/invest/real-estate"}><Button
          label={texts[lang].btnLabel}
          icon
          classes={"-normal"}
          />
          </Link>

          <div className="links">
            
            <ul>
              
            <li><Link to={"#"}>{texts[lang].faqLabel} </Link></li> 
            <li><a href={"https://calendly.com/viziocapital/presentation-de-vizio-capital?month=2022-12"} target={'_blank'}>{texts[lang].rdvLabel}</a></li>
          </ul>
          </div>
        </div>
      </div>
    )
  }

  export default RealDetailView