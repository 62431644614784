
export default {
    fr: {
        // Mes recommandations
        //* Lien https://app.viziocapital.com/monReseau
        infoPrealable: "INFORMATION PRÉALABLES",
        infoPrealableContent: "Sur cette page vous trouverez  le détail et le suivi de vos recommandations.",
        listeFilleul: "Liste des recommandations",
        rNom: "NOM ",
        rPrenom: "PRÉNOM ",
        rTel: "TÉLÉPHONE ",
        rEmail: "EMAIL ",
        rRole: "RÔLE ",
    },
    en: {
        // Mes recommandations
        //* Lien https://app.viziocapital.com/monReseau
        infoPrealable: "INFORMATION PRÉALABLES",
        infoPrealableContent: "Sur cette page vous trouverez  le détail et le suivi de vos recommandations.",
        listeFilleul: "Liste des recommandations",
        rNom: "NOM ",
        rPrenom: "PRÉNOM ",
        rTel: "TÉLÉPHONE ",
        rEmail: "EMAIL ",
        rRole: "RÔLE ",
    },
};