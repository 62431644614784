import { useSelector } from 'react-redux';

import InputText from '../inputText';
import InputSelect from '../inputSelect';
import FormRow from '../formRow';

import FormSectionTitle from '../formSectionTitle';

import regexes from '../../constants/regex';
import { editRegex } from '../../helpers/global';
import countries from '../../constants/countries';


const FormRegisterInformationsProfessional = ({texts, handleInputChange}) => {

  const registerForm = useSelector(state => state.notPersisedReducer.registerForm);
  const {
    socialDenomination, companyNumber,
    headquartersAdress, companyPostalCode,
    municipality, companyCountry,companyFunction,
  } = registerForm;

  return <>

    <FormSectionTitle title={texts.titleProfessionalInformations} style={{marginTop:50}} />

    <FormRow classes="-two">
      <InputText
        label={texts.labelSocialdenomination}
        value={socialDenomination.value}
        error={socialDenomination.error}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,2,200),
          errorText: texts.errorSocialdenomination,
          name: "socialDenomination",
        })}
      />
      <InputText
        label={texts.labelCompanyNumber}
        value={companyNumber.value}
        error={companyNumber.error}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.siret,2,30),
          errorText: texts.errorCompanyNumber,
          name: "companyNumber",
        })}
      />
    </FormRow>

    <FormRow classes={"-two"}>
      <InputText
        label={texts.labelHeadquartersAdress}
        value={headquartersAdress.value}
        error={headquartersAdress.error}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorHeadquartersAdress,
          name: "headquartersAdress",
        })}
      />
      <InputText
        label={texts.labelPostalCode}
        value={companyPostalCode.value}
        error={companyPostalCode.error}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.postalCode,1,50),
          errorText: texts.errorPostalCode,
          name: "companyPostalCode",
        })}
      />
    </FormRow>

    <FormRow classes={"-two"}>
      <InputText
        label={texts.labelMunicipality}
        value={municipality.value}
        error={municipality.error}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorMunicipality,
          name: "municipality",
        })}
      />
      <InputSelect
        label={texts.labelCountry}
        value={companyCountry.value}
        error={companyCountry.error}
        inputs={countries.map((i)=>(
          {
            id:i.label,
            label:i.label,
          }))}
        handleSelectChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorCountry,
          name: "companyCountry"
        })}
      />
    </FormRow>
    <FormRow classes={"-two"}>
    <InputText
        label={texts.labelCompanyFunction}
        value={companyFunction.value}
        error={companyFunction.error}
        handleInputChange={(event) => handleInputChange({
          event,
          regex: editRegex(regexes.default,1,200),
          errorText: texts.errorCompanyFunction,
          name: "companyFunction",
        })}
      />
    </FormRow>

  </>
}


export default FormRegisterInformationsProfessional;