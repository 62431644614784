import axios from 'axios';
import { useState } from 'react'
import {  useSelector } from "react-redux";
import FormSectionTitle from '../components/formSectionTitle';
import { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import urls from '../constants/urls'
import texts from '../texts/financialsTransactions';
import { roundNumber } from '../helpers/roundNumberFunc';
import { LoaderContext } from "../context/Context";
import { useLayoutEffect, useContext } from 'react';
// import IconArrowColored from '../../assets/svg/navbar-arrow-colored';

const TransactionView = ()=>{
  const loader = useContext(LoaderContext);
  const { token} = useSelector(state => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)

  const [transactions, setTransactions]= useState([])
  const [withdraw , setWithDraw] = useState([0])
  const [operations, setOperations] = useState([])
  
  const headers = {
    headers : {
      "x-auth-token": token
    }
  }


  useLayoutEffect(()=>{ //Récupreration des paiements
    axios.get(`${urls.apiUrl}/payment/mollie/transac` , headers)
    .then((res)=>{
      // console.log(res.data)
      // console.log(res.data.filter(pay=>pay.status !== "open"))
      setTransactions(res.data.filter(pay=>pay.status !== "open"))// on ne l'ulilise preque pas 
      
      var transactions = res.data.filter(pay=>pay.status )
      axios.get(`${urls.apiUrl}/payment/mollie/withdraw/` , headers)
      .then((res)=>{
        // console.log(res.data)
       setWithDraw(res.data)
       var withdraws = res.data
       const operations = transactions.concat(withdraws)
       setOperations(operations.sort((a,b)=>(
        new Date(b.date) - new Date(a.date) 
     )))
     loader.onSetIsLoading(false);
    })
      .catch((err)=>{
        console.log(err)
      })
    }).catch((err)=>{
      console.log(err)
    })
    return()=>{
      loader.onSetIsLoading(true);
    }
  },[])

  
  


  //trier les operations par date
  // console.log(operations.map((i)=>parseFloat(i.amount.value).toLocaleString('en-EU')))



 
return(
  <div className="layout-profile-container transac-container">
    <div className="transac-scontainer -no-padding">
      <FormSectionTitle
              title={texts[lang].labelTitle}
              classes="-simple-2"
            />

     <div className="security-form-container">
        <div className="_transac_container">
          <div className="_transac_header">
            
            <span>{texts[lang].labelOperation}</span>
            <span>{texts[lang].labelDate}</span>
            <span>{texts[lang].labelMontant}</span>
            <span>{texts[lang].labelFond}</span>
            <span>{texts[lang].labelStatut}</span>
            <span>{texts[lang].labelInformation}</span>
          </div>

          <ul className="_transac_list">
            {operations.map((operation)=>{
              return <li key={operation._id} className="_transaction">
                <div data-label={texts[lang].labelDataOperation}>{operation.facture ? texts[lang].labelRetrait:texts[lang].labelDepot}</div>
                <div data-label={texts[lang].labelDataDate}>{ new Date (operation.date).toLocaleDateString()}</div>
                <div data-label={texts[lang].labelDataMontant}>{`${operation.facture ? roundNumber(operation.amount)
                  .toLocaleString("en-EU").replaceAll(',', ' ')  
                  : roundNumber(operation.amount.value).toLocaleString("en-EU")
                  .replaceAll(',', ' ')} €`}</div>
                <div data-label={texts[lang].labelDataFond}>{operation.fond.name}</div>
                <div data-label={texts[lang].labelDataStatut}  
                className={operation.status === "paid" || operation.status === "processed"  ? "-succes" : operation.status === "open" ? "-waiting" : "-failed"}>
                {operation.status === "paid" ||operation.status === "processed" ? texts[lang].StatusOk : operation.status === "open" ? texts[lang].StatusWait : texts[lang].StatusRefused}
                </div>
                 <div data-label={texts[lang].labelDataInformation}>{operation.status === "processed" ? texts[lang].labelRetraitOk : operation.facture && operation.status === "open" ? texts[lang].labelRetraitSend : operation.status === "paid" ? texts[lang].labelPayOk : operation.status==="open" ? texts[lang].labelPaySend : (operation.fature && operation.status !== "open")||(operation.status !== "processed") ? texts[lang].labelPayRefused  : texts[lang].labelRetraitRefused }</div>  
                </li>
            })}
          </ul>
      </div>
    </div>
    </div>
  </div>
)
}
export default TransactionView