const TextWithBold =(props) => {

  const {
    classe,
    content,
  } = props;


  return (
    <p className={classe} dangerouslySetInnerHTML={{__html: content}}></p>
  )
}
export default TextWithBold;