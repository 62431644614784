export default ()=>(
  <svg width="15px" height="18px" viewBox="0 0 15 18" version="1.1"ò="true">
    <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10-Dashboard-paiement-en-attente" transform="translate(-282.000000, -120.000000)" fill="#0062FF">
            <g id="ic_Edit-Copy" transform="translate(276.694441, 117.000000)">
                <path d="M12.8491611,5.61729886 L12.9515668,5.99747166 C14.971705,6.44811118 16.6784593,7.9791665 17.251258,10.1168803 L18.2260486,13.7548484 L20.0702906,15.8187698 C20.566719,16.374331 20.3030897,17.2581724 19.5834332,17.4510038 L15.8025039,18.4651029 C15.6539911,19.4230099 14.9573519,20.2504842 13.9608667,20.5174916 C12.9641138,20.7845707 11.9467906,20.4160069 11.3392781,19.6602807 L7.80833738,20.6061312 C7.08868087,20.7989626 6.41845044,20.1653481 6.57058948,19.4360038 L7.13578916,16.7264745 L6.16099853,13.0885064 C5.56167639,10.8518057 6.3695115,8.56956737 8.04184003,7.18171285 L7.97353554,6.9237188 C7.61277764,5.57735197 8.41177154,4.19345393 9.75813837,3.83269603 C11.1045052,3.47193812 12.4884032,4.27093202 12.8491611,5.61729886 Z M10.7379533,7.98941523 C8.74239494,8.52412349 7.55814192,10.5753099 8.09285018,12.5708683 L9.12925907,16.4387989 C9.16977176,16.5899943 9.17422558,16.7485888 9.1422621,16.9018196 L8.86026036,18.253717 L17.496234,15.9397148 L16.5760646,14.9099382 C16.4717681,14.7932182 16.3963279,14.6536444 16.3558153,14.502449 L15.3194064,10.6345184 C14.7846981,8.63895999 12.7335117,7.45470697 10.7379533,7.98941523 Z M10.2757765,5.76454768 C10.0820775,5.81644917 9.94296612,5.97038498 9.90077538,6.15303229 C10.0065439,6.11813152 10.1126603,6.08640963 10.2203152,6.05756358 C10.4164238,6.00501643 10.6128825,5.96328662 10.8090577,5.93200839 C10.6834673,5.78194792 10.4775904,5.71047181 10.2757765,5.76454768 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
)