
export default {
    fr: {
        //Projet immobilier
        //* Lien https://app.viziocapital.com/immo-projects
    pInfo:"INFORMATIONS ",
    pInfoContent:"Les biens déjà financés sont des projets qui ont été réalisés par nos partenaires hors Vizio dans le but de vous partager les résultats attendus concrètement.  Les biens à financés sont des projets mis en place directement dans Vizio par ces mêmes partenaires. Si vous avez des questions concernant ces projets, vous pouvez nous contacter à : <a href='mailto:infos@viziocapital.com'>infos@viziocapital.com</a>",
    },
    en: {
                //Projet immobilier
        //* Lien https://app.viziocapital.com/immo-projects
        pInfo:"INFORMATIONS ",
        pInfoContent:"Les biens déjà financés sont des projets qui ont été réalisés par nos partenaires hors Vizio dans le but de vous partager les résultats attendus concrètement.  Les biens à financés sont des projets mis en place directement dans Vizio par ces mêmes partenaires. Si vous avez des questions concernant ces projets, vous pouvez nous contacter à : <a href='mailto:infos@viziocapital.com'>infos@viziocapital.com</a>",
    },
};