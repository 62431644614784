export default ()=>(
  <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1" >
    <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10-Dashboard-paiement-en-attente" transform="translate(-278.000000, -320.000000)" fill="#0062FF">
            <g id="ic_Edit" transform="translate(276.694441, 319.000000)">
                <path d="M19.7273076,5.65220662 C21.1473777,7.37888073 22,9.58987006 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C14.3924087,2 16.5885945,2.84013006 18.3096271,4.24145996 L18.8604485,3.69063858 L19.1222246,2.29626572 C19.2241289,1.75346376 19.7467665,1.39604557 20.2895685,1.49794985 C20.8323704,1.59985413 21.1897886,2.12249179 21.0878843,2.66529375 L21.0308256,2.96922217 L21.334754,2.91216341 C21.877556,2.81025914 22.4001936,3.16767733 22.5020979,3.71047928 C22.6040022,4.25328124 22.246584,4.7759189 21.703782,4.87782318 L20.2238529,5.15566137 L19.7273076,5.65220662 Z M16.8860936,5.66499347 C15.5345995,4.62107032 13.8398291,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,10.142409 19.3668801,8.43269107 18.3046541,7.07486016 L16.8762786,8.50323562 C17.5835323,9.48776468 18,10.6952467 18,12 C18,15.3137085 15.3137085,18 12,18 C8.6862915,18 6,15.3137085 6,12 C6,8.6862915 8.6862915,6 12,6 C13.2869026,6 14.4791773,6.40515002 15.4562306,7.09485653 L16.8860936,5.66499347 Z M14.0100975,8.54098959 C13.4194746,8.19702573 12.7327462,8 12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16 C14.209139,16 16,14.209139 16,12 C16,11.2491408 15.7931132,10.5466027 15.4332342,9.94628005 L13.9247713,11.4547429 C13.9737773,11.6280777 14,11.8109766 14,12 C14,13.1045695 13.1045695,14 12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 C12.1692315,10 12.3335539,10.0210188 12.4904988,10.0605883 L14.0100975,8.54098959 L14.0100975,8.54098959 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
)