// Authentification

export default {
  fr: {
    slogan: "L’entreprenariat participatif",
    welcome: "Hey, bienvenue chez Vizio !",
    registrationText: "Si vous n’avez pas encore de compte,",
    registrationLinkText: "Enregistrez-vous ici !",
    registrationDifficultiesText:"Si vous rencontrez des difficultés lors de l’inscription, contactez la société par email <b>infos@viziocapital.com</b> <br>ou par téléphone <b>+32 460 22 82 82</b>",
    registrationLinkSubtext:"Pourquoi s’inscrire ?",

    identifiers: "Saisir vos identifiants de connexion",
    mail: "Email",
    mailError: "La valeur du champs n'est pas valide",

    password: "Mot de passe",
    passwordError: "La valeur du champs n'est pas valide",

    googleAuth: "Votre code de validation avec Google Authenticator",
    code: "Votre code de validation",
    codeError: "Le code n'est pas correct",

    resetPassword: "Réinitialiser votre mot de passe",

    forgotPassword: "Mot de passe oublié",
    forgotAuth: 'Vous n\'avez plus accès votre code ?',

    buttonConnexion: "Connexion",
    buttonCode: "Valider",
    buttonResetPassword: "Réinitialiser mon mot de passe",

    sessionEnd: "Session terminée",
    emailInvalide: "Avant de vous connecter, vous devez valider votre adresse email.",
    notConnected: "Vous devez être connecté pour accéder au tableau de bord.",
    defaultText: "Un problème est survenu veuillez recharger la page et vous reconnecter.",
  },
  en: {
    slogan: "Crowdfunding",
    welcome: "Hey, welcome to Vizio!",
    registrationText: "Create an account",
    registrationLinkText: "Sign in",
    identifiers: "Connect to your account",
    mail: "Email",
    mailError: "There is an issue with the email address you entered",

    password: "Password",
    passwordError: "The password you entered in not correct",
    
    googleAuth: "Your Google Authenticator validation code",
    code: "Your validation code",
    codeError: "The validation code you entered is not correct",

    resetPassword: "Reset your password",

    forgotAuth: 'You do not have access to your validation code?',
    forgotPassword: "Forgot your password",

    buttonConnexion: "Login",
    buttonCode: "Validate",
    buttonResetPassword: "Reset my password",

    sessionEnd: "Session expired",
    emailInvalide: "you need to validate your email address before connecting to the dashboard.",
    notConnected: "You should be loged in to acces the dashboard",
    defaultText: "An error occured. Please reload the page and try to reconnect.",

    registrationLinkSubtext:"Pourquoi s’inscrire -EN ?",
  },
};