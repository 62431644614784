export default {
  fr: {
    //Tableau de bord
    //* Lien https://app.viziocapital.com/dashboard
   labelRealTitle:"Vizio real estate",
   labelCardCapitalHeader:"Mes investissements",
   labelCardValueHeader:"Mes retours sur investissement",
   labelCardTotalValueHeader:"Total des retours sur investissement",
   labelCapBtn:"Investir",
   labelValueBtn:"Retirer",

   labelRealCapText:"Montant des investissements dans Vizio Real estate",
   labelRealValueText:"Mes retours sur investissements dans Vizio Real Estate",
   labelRealTotalValueText:"Total de mes retours sur investissement dans Vizio Real Estate",
   
   labelCapitalTitle:"Vizio Capitalisation",
   labelCapitalCapText:"Montant des investissements dans Vizio Capitalisation",
   labelCapitalValueText:"Mes retours sur investissements dans Vizio Capitalisation",
   labelCapitalTotalValueText:"Total de mes retours sur investissement dans Vizio Capitalisation",
   infoPrealableLabel:"INFORMATIONS PREALABLES",
   infoPrealableContent:"Vos montants investis dans Vizio sont visibles sur cette vue. Ils seront validés par la société le 30 décembre, date à laquelle ils pourront être utilisés dans l'immobilier par la société. A partir de cette date, vous pourrez télécharger votre registre des obligataires."


  },
  en: {
    //Tableau de bord
    //* Lien https://app.viziocapital.com/dashboard
   labelRealTitle:"Vizio real estate",
   labelCardCapitalHeader:"Mes investissements",
   labelCardValueHeader:"Mes retours sur investissement",
   labelCardTotalValueHeader:"Total des retours sur investissement",
   labelCapBtn:"Investir",
   labelValueBtn:"Retirer",

   labelRealCapText:"Montant des investissements dans Vizio Real estate",
   labelRealValueText:"Mes retours sur investissements dans Vizio Real Estate",
   labelRealTotalValueText:"Total de mes retours sur investissement dans Vizio Real Estate",
   
   labelCapitalTitle:"Vizio Capitalisation",
   labelCapitalCapText:"Montant des investissements dans Vizio Capitalisation",
   labelCapitalValueText:"Mes retours sur investissements dans Vizio Capitalisation",
   labelCapitalTotalValueText:"Total de mes retours sur investissement dans Vizio Capitalisation",

   infoPrealableLabel:"INFORMATIONS PREALABLES",
   infoPrealableContent:"Vos montants investis dans Vizio sont visibles sur cette vue. Ils seront validés par la société le 30 décembre, date à laquelle ils pourront être utilisés dans l'immobilier par la société. A partir de cette date, vous pourrez télécharger votre registre des obligataires."



  }
}