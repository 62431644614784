import { Component } from 'react';
import { connect } from 'react-redux';

import FormRow from '../formRow';
import InputText from '../inputText';
import InputSelect from '../inputSelect';
import Button from '../button';

import FormSectionTitle from '../formSectionTitle';

import { addContact, deleteContact, editContact } from '../../redux/store';

import regexes from '../../constants/regex';
import countries from '../../constants/countries';

import { getUUID ,editRegex } from '../../helpers/global';

import { checkInput, checkInput_contact } from '../../helpers/registration';

class FormRegisterInformationsContacts extends Component {

  

  constructor(props){
    super(props);
    this.state = this.initialState();
  }

  initialState = () => {
    const obj = {
      value: "",
      error: false,
    }
    return  {
      _id: false,
      firstName: obj,
      lastName: obj,
      email: obj,
      address: obj,
      zipCode: obj,
      city: obj,
      country: {...obj,value:"FR"},
      phone: obj,
      isCompleted: false,
    }
  }

  handleInputChange = ({event, regex, errorText, name}) => {
    const texts = this.props.texts;
    const value = event.target.value;
    const error = checkInput(value, regex, texts.errorEmpryField, errorText) ? `"${checkInput(value, regex, texts.errorEmpryField, errorText)}"` : false;
    const json = `{"${name}":{"value":"${value}", "error":${error}}}`;
    this.setState(JSON.parse(json));
    this.checkIsCompleted();
  }

  checkIsCompleted = () => {

    const {
      firstName, lastName,
      email, address,
      zipCode, city,
      country, phone
    } = this.state;
    

    this.setState({isCompleted: false})

    if (checkInput_contact(firstName.value, editRegex(regexes.default,1,200))) return false;
    if (checkInput_contact(lastName.value, editRegex(regexes.default,1,200))) return false;
    if (checkInput_contact(email.value, regexes.email)) return false;
    if (checkInput_contact(address.value, editRegex(regexes.default,1,200))) return false;
    if (checkInput_contact(zipCode.value, editRegex(regexes.postalCode,1,50))) return false;
    if (checkInput_contact(city.value, editRegex(regexes.default,1,200))) return false;
    if (checkInput_contact(country.value, editRegex(regexes.default,1,200))) return false;
    if (checkInput_contact(phone.value, regexes.phone)) return false;

    this.setState({isCompleted: true})
  }

  handleAddUser = () => {

    const {
      firstName, lastName,
      email, address,
      zipCode, city,
      country, phone
    } = this.state;

    let errors = [];
    let error = false;
    const texts = this.props.texts;
    
    error = checkInput(firstName.value, editRegex(regexes.default,1,200), texts.errorEmpryField, texts.errorLastName);
    if ( error ) errors.push(this.getStringifiedJson("firstName", firstName.value, error));

    error = checkInput(lastName.value, editRegex(regexes.default,1,200), texts.errorEmpryField, texts.errorLastName);
    if ( error ) errors.push(this.getStringifiedJson("lastName", lastName.value, error));

    error = checkInput(email.value, regexes.email, texts.errorEmpryField, texts.errorMailAdress);
    if ( error ) errors.push(this.getStringifiedJson("email", email.value, error));

    error = checkInput(address.value, editRegex(regexes.default,1,200), texts.errorEmpryField, texts.errorPostalAddress);
    if ( error ) errors.push(this.getStringifiedJson("address", address.value, error));

    error = checkInput(zipCode.value, editRegex(regexes.postalCode,1,50), texts.errorEmpryField, texts.errorPostalCode);
    if ( error ) errors.push(this.getStringifiedJson("zipCode", zipCode.value, error));

    error = checkInput(city.value, editRegex(regexes.default,1,200), texts.errorEmpryField, texts.errorCity);
    if ( error ) errors.push(this.getStringifiedJson("city", city.value, error));

    error = checkInput(country.value, editRegex(regexes.default,1,200), texts.errorEmpryField, texts.errorResidenceCountry);
    if ( error ) errors.push(this.getStringifiedJson("country", country.value, error));

    error = checkInput(phone.value, regexes.phone, texts.errorEmpryField, texts.errorPhoneNumber);
    if ( error ) errors.push(this.getStringifiedJson("phone", phone.value, error));


    if ( errors.length ) errors.forEach(error =>  this.setState(JSON.parse(error)))
    else {
      if ( this.state._id ) this.props.editContact({_id: this.state._id, ...this.getContact(this.state)});
      else this.props.addContact({_id: getUUID(), ...this.getContact(this.state)});
      this.setState({...this.initialState(), country})
    };

  }

  handleEditContact = (id) => {
    const contact = this.props.contacts.find(contact => contact._id === id);
    if (contact) this.setState({
      _id: contact._id,
      firstName: {error: false, value: contact.firstName},
      lastName: {error: false, value: contact.lastName},
      email: {error: false, value: contact.email},
      address: {error: false, value: contact.address},
      zipCode: {error: false, value: contact.zipCode},
      city: {error: false, value: contact.city},
      country: {error: false, value: contact.country},
      phone: {error: false, value: contact.phone}
    });
  }

  getStringifiedJson = (name, value, error) => (
    `{"${name}":{"value":"${value}", "error":${error ? `"${error}"` : false}}}`
  )

  getContact = (contact) => ({
    firstName: contact.firstName.value,
    lastName: contact.lastName.value,
    email: contact.email.value,
    address: contact.address.value,
    zipCode: contact.zipCode.value,
    city: contact.city.value,
    country: contact.country.value,
    phone: contact.phone.value,
  })

  render() { 

    const {contacts, texts} = this.props;

    return (
      <>

        <FormSectionTitle title={texts.titleContactsInformations} style={{marginTop:50}} />

        {
          contacts && contacts.length > 0
          && <Contacts
                contacts={contacts}
                handleEditContact={this.handleEditContact}
                handleDeleteContact={this.props.deleteContact}
              />
        }

        <FormRow classes={"-two"}>
          <InputText
            label={texts.labelLastName}
            value={this.state.lastName.value}
            error={this.state.lastName.error}
            handleInputChange={(event) => this.handleInputChange({
              event,
              regex: editRegex(regexes.default,1,200),
              errorText: texts.errorLastName,
              name: "lastName"
            })}
            indication={texts.indicationLastName}

          />
          <InputText
            label={texts.labelFirstName}
            value={this.state.firstName.value}
            error={this.state.firstName.error}
            handleInputChange={(event) => this.handleInputChange({
              event,
              regex: editRegex(regexes.default,1,200),
              errorText: texts.errorFirstName,
              name: "firstName"
            })}
            indication={texts.indicationFirstName}
          />
        </FormRow>

        <FormRow classes={"-two"}>
          <InputText
            label={texts.labelPhoneNumber}
            value={this.state.phone.value}
            error={this.state.phone.error}
            type="number"
            max={15}
            handleInputChange={(event) => this.handleInputChange({
              event,
              regex: regexes.phone,
              errorText: texts.errorPhoneNumber,
              name: "phone",
            })}
          />
          <InputText
            label={texts.labelMailAdress}
            value={this.state.email.value}
            error={this.state.email.error}
            handleInputChange={(event) => this.handleInputChange({
              event,
              regex: regexes.email,
              errorText: texts.errorMailAdress,
              name: "email",
            })}
          />
        </FormRow>

        <FormRow classes={"-two"}>
          <InputText
            label={texts.labelPostalAddress}
            value={this.state.address.value}
            error={this.state.address.error}
            handleInputChange={(event) => this.handleInputChange({
              event,
              regex: editRegex(regexes.default,1,200),
              errorText: texts.errorPostalAddress,
              name: "address",
            })}
          />
          <InputText
            label={texts.labelPostalCode}
            value={this.state.zipCode.value}
            error={this.state.zipCode.error}
            max={12}
            handleInputChange={(event) => this.handleInputChange({
              event,
              regex: editRegex(regexes.postalCode,1,50),
              errorText: texts.errorPostalCode,
              name: "zipCode",
            })}
          />
        </FormRow>

        <FormRow classes={"-two"}>
          <InputText
            label={texts.labelCity}
            value={this.state.city.value}
            error={this.state.city.error}
            handleInputChange={(event) => this.handleInputChange({
              event,
              regex: editRegex(regexes.default,1,200),
              errorText: texts.errorCity,
              name: "city",
            })}
          />
          <InputSelect
            label={texts.labelResidenceCountry}
            value={this.state.country.value}
            error={this.state.country.error}
            inputs={countries}
            handleSelectChange={(event) => this.handleInputChange({
              event,
              regex: editRegex(regexes.default,1,200),
              errorText: texts.errorResidenceCountry,
              name: "country"
            })}
          />
        </FormRow>

        <Button
          label={texts.labelBtnAddContacte}
          classes="-small -flex-right -left"
          type="button"
          disable={!this.state.isCompleted}
          handleClick={this.handleAddUser}
        />

      </>
    );
  }
}

// Composant qui affiche les contacts ajoutÃ©s par l'utilisateur
const Contacts = ({contacts, handleDeleteContact, handleEditContact}) => (
  <ul className='form-user-contacts'>
    {
      contacts.map( contact => <li className="-contact" key={contact._id}>
          <button className="-btn -btn-edit" type="button" onClick={() => handleEditContact(contact._id)}>{contact.firstName} {contact.lastName}</button>
          <button className="-btn -btn-delete" type="button" onClick={() => handleDeleteContact(contact._id)}>X</button>
        </li>
      )
    }
  </ul>
)


const mapStateToProps = state => ({
  contacts: state.notPersisedReducer.registerForm.contacts,
})

const actions = {
  addContact,
  deleteContact,
  editContact,
}

export default connect(mapStateToProps, actions)(FormRegisterInformationsContacts);