import { useSelector } from 'react-redux';

import FormRow from "./formRow";

import InputSelect from "./inputSelect";

import months from "../constants/months";

import texts from "../texts/registration";

const startYear = 1900;
const startDay = 1;
const stopYear = new Date().getFullYear() - 18;


const FormRowBirthday = ({day, month, year, handleDayChange, handleMonthChange, handleYearChange}) => {

  const {lang} = useSelector(state => state.persisedReducer.parameters);

  const getNbrDays = (month, year) => {

    switch (month) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;
      case 2 :
        if ( (year%4 === 0 && year%100 !== 0) || year%400 === 0 ) return 29;
        else return 28;
      default : return 30;
    }
  }

  const getMonths = (months) => months.map((m) => ({
    id: m.id,
    label: m.name[lang]
  }))

  const getOptions = (start, end) => {
    const options = [];
    for(let i=start; i<=end; i++) options.push({
      id: i,
      label: i
    })
    return options;
  }

  return (

    <FormRow classes={"-three"}>

      <InputSelect
        value={day.value}
        error={day.error}
        label={texts[lang].labelDay}
        handleSelectChange={handleDayChange}
        inputs={getOptions(startDay, getNbrDays(parseInt(month.value), parseInt(year.value)))}
      />

      <InputSelect
        value={month.value}
        error={month.error}
        label={texts[lang].labelMonth}
        handleSelectChange={handleMonthChange}
        inputs={getMonths(months)}
      />

      <InputSelect
        value={year.value}
        error={year.error}
        label={texts[lang].labelYear}
        handleSelectChange={handleYearChange}
        inputs={getOptions(startYear, stopYear)}
      />
    </FormRow>
  )
}


export default FormRowBirthday;