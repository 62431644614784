export default {
    fr: {
        //demande de retrait
        //*Lien https://app.viziocapital.com/investissements/withdraw
        inputChoices: [
            "COMPTE D'APPORT D'AFFAIRES REAL-ESTATE ",
            "COMPTE D'APPORT D'AFFAIRES CAPITALISATION ",
            "COMPTE D'INVESTISSEMENT REAL-ESTATE",
            "COMPTE D'INVESTISSEMENT CAPITALISATION"
        ],
        demandeRetrait: "Demande de retrait",
        retraitInfo: "Il vous faut un minimum de 100 € afin de demander un retrait de vos benefices d'apports d'affaires",
        selectAcount: "Selectionnez le compte à debiter",
        montantRetrait: "Renseignez le montant à retirer",
        iMPro: "je suis professionnel",
        proInfo: "Si vous êtes un professionnel vous devez ajouter une facture à votre demande de retrait. Assurez vous que vos coordonnées bancaires sur votre facture ainsi que sur votre onglet banque sur la plateforme correspondent bien",
        addFacture:"Ajouter une facture",
        requiredFormats:"formats acceptés : pdf, jpg, png",
        condition: "je suis d'accord avec les conditions suivantes :",
        condition1: "Les frais de retrait s’élèvent à 10% ",
        condition2: "Le paiement est effectué sous 72 heures",
        submitForm: "Envoyer ma demande de retrait",
        retraitIndication:"Retrait possible :"
    },
    en: {

        //demande de retrait
        //*Lien https://app.viziocapital.com/investissements/withdraw
        inputChoices: [
            "COMPTE D'APPORT D'AFFAIRES REAL-ESTATE ",
            "COMPTE D'APPORT D'AFFAIRES CAPITALISATION ",
            "COMPTE D'INVESTISSEMENT REAL-ESTATE",
            "COMPTE D'INVESTISSEMENT CAPITALISATION"
        ],
        demandeRetrait: "Demande de retrait",
        retraitInfo: "Il vous faut un minimum de 100 € afin de demander un retrait de vos benefices d'apports d'affaires",
        selectAcount: "Selectionnez le compte à debiter",
        montantRetrait: "Renseignez le montant à retirer",
        iMPro: "je suis professionnel",
        proInfo: "Si vous êtes un professionnel vous devez ajouter une facture à votre demande de retrait. Assurez vous que vos coordonnées bancaires sur votre facture ainsi que sur votre onglet banque sur la plateforme correspondent bien",
        addFacture:"Ajouter une facture",
        requiredFormats:"formats acceptés : pdf, jpg, png",
        condition: "je suis d'accord avec les conditions suivantes :",
        condition1: "Les frais de retrait s’élèvent à 10% ",
        condition2: "Le paiement est effectué sous 72 heures",
        submitForm: "Envoyer ma demande de retrait",
        retraitIndication:"Retrait possible :"
    }

}