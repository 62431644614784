import IconInfosSucces from "./icons/icon-infos-succes";
import IconInfosInfo from "./icons/icon-infos-info";
import IconInfosError from "./icons/icon-infos-error";
import IconComment from "../assets/svg/comment-icon";
import Button from "./button";
import { Link } from "react-router-dom";

const InfosBar = ({
  content,
  classes,
  style,
  linkValue,
  btnlabel,
  btnDisable,
  btnClasse,
  action,
}) => {
  return (
    <div
      style={style ? style : {}}
      className={`infos-bar ${classes ? classes : ""}`}
    >
      <div className="-icon">
        <i>{getIcon(classes)}</i>
      </div>
      {getContent(content)}
      {btnlabel && (
        
          <Button
          label={btnlabel}
          diable={btnDisable}
          classes={btnClasse}
          // classes="-card-body-btn"
          handleClick={action}
        />
      )}
    </div>
  );
};

const getIcon = (classes) => {
  if (classes && classes.includes("-info")) return <IconInfosInfo />;
  else if (classes && classes.includes("-error")) return <IconInfosError />;
  else if (classes && classes.includes("-comment")) return <IconComment />;
  return <IconInfosSucces />;
};

const getContent = (content) => {
  if (Array.isArray(content))
    return (
      <ul className="-liste">
        {content.map((message, index) => (
          <li key={index}>{message}</li>
        ))}
      </ul>
    );
  else return <p dangerouslySetInnerHTML={{__html: content}} className="-text"></p>;
};
// const getButton =(button) =>{
//   if(button) return <Button
//   label={"Devenir commercial"}
//   icon={icon}
//   type={type}
//   style={style}
//   handleClick={handleClick}
//   disable={disable}
//   />
// }

// const getContent = (content, type) => {
//   if (type === "liste" )  return <ul className="-liste">
//     {content.map((message, index) => <li key={index}>{message}</li>)}
//   </ul>
//   else return <p className="-text">{content}</p>
// }

export default InfosBar;
