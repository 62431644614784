import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { withRouter } from "../components/withRouter";

import { editRegex } from "../helpers/global";
import FormRegisterConnexion from "../components/registration/formRegisterConnexion";
import FormRegisterInformations from "../components/registration/formRegisterInformations";

import Container from "../components/container";
import StepperHead from "../components/stepperHead";
import FormHeader from "../components/formHeader";
import FormFooter from "../components/formFooter";
import InformationTemplate from "../components/informationTemplate";
import IconMailSend from "../components/icons/icon-mail-send";

import {
  incrementStep,
  decrementStep,
  editField,
  switchLang,
} from "../redux/store";

import { getPayloadObject, checkInput, checkInputUnrequired } from "../helpers/registration";

import texts from "../texts/registration";
import regexes from "../constants/regex";
import keys from "../constants/keys";
import urls from "../constants/urls";

import logo from "../assets/img/logo/logo-authentication.svg";

import { LoaderContext } from "../context/Context";

class RegisterView extends React.Component {
  static contextType = LoaderContext;
  constructor(props) {
    super(props);

    this.recaptchaRef = React.createRef();

    this.state = {
      isLoading: false,
      error: false,
      stepOneError: false,
      errorContacts: true,
      compteCreated: false,
      timeout: null,
      hasNotValidateEmail : false,
      redirect: false,
      parrainLink: false,
      parrain:false
    };
  }

  componentDidMount = () => {
    // this.recaptchaRef.current.reset();

    const {user, token} = this.props.user;
    if ( user && token && !user.has_valided_email ) this.setState({hasNotValidateEmail: true});
    else if ( user && token ) this.setState({redirect: true});

    const parrainLink = (window.location.href.split('/')[4])///Thx Js :)
   
  
    this.setState({parrainLink : parrainLink ? parrainLink : false})

    if (parrainLink){
      this.context.onSetIsLoading(true);  
      axios.get(`${urls.apiUrl}/reseau/affiliate/${parrainLink}`).
      then((res)=>{
        this.context.onSetIsLoading(false);  
        this.setState({parrain : res.data })
      })
    }else{
      this.context.onSetIsLoading(false);  
    }
    

  }
  componentWillUnmount(){
    this.context.onSetIsLoading(true);
  }

 
  handleNext = () => {
    const { currentStep } = this.props.registerForm;
    const { lang } = this.props.parameters;

    // First step check email and password
    if (currentStep === 1 && !this.checkStepOne()){ 
      const { email, password } = this.props.registerForm;
      axios.post(`${urls.apiUrl}/users/validate_step_one`,{
        
          email : email.value,
          password: password.value
        
      }).then((res)=>{
        this.setStepOneError(false)
        this.props.incrementStep()
      })
      .catch((err)=>{
        this.setStepOneError(err.response.data);
        console.log(err.response.data)
      })
    };
    if (currentStep === 2) {
      this.setState({ error: false });
      const { payload, error } = this.checkStepTwo();

      if (error) {
        this.setError(texts[lang].errorForm);
      } else {
        const recaptchaToken = this.recaptchaRef.current.execute();

        if (!recaptchaToken) this.setError(texts[lang].errorRecaptcha)
        else {
          axios
          .put(`${urls.apiUrl}/users`, {
            body: JSON.stringify(payload),
          })
          .then((res) => {
            this.setState({ compteCreated: true });
            this.props.incrementStep();
          })
          .catch((err) => {
            this.setError(err?.response?.data);
          });
        }

      }
    }
  };
  setStepOneError = (error) =>{
    this.setState({stepOneError:error}) 
  }
  setError = (error) => {
    if (this.state.timeout) clearTimeout(this.state.timeout);
    window.scrollTo(0, 200);
    const timeout = setTimeout(() => {
      this.setState({ error: false });
    }, 5000);
    this.setState({ error, timeout });
  };

  handlePrev = () => {
    const { currentStep } = this.props.registerForm;
    if(currentStep > 1) this.props.decrementStep();
  };

  checkStepOne = () => {
    const { email, password } = this.props.registerForm;
    const { lang } = this.props.parameters;
    let errors = [];
    let errorText = false;

    // Check email value
    errorText = checkInput(
      email.value,
      regexes.email,
      texts[lang].errorEmpryField,
      texts[lang].errorMail
    );
    if (errorText)
      errors.push(getPayloadObject("email", email.value, errorText));

    // Check password value
    errorText = checkInput(
      password.value,
      editRegex(regexes.password,5,200),
      texts[lang].errorEmpryField,
      texts[lang].errorPassword
    );
    if (errorText)
      errors.push(getPayloadObject("password", password.value, errorText));

    if (errors.length) {
      errors.forEach((error) => this.props.editField(error));
      return 1;
    } else return false;
  };

  checkStepTwo = () => {
    const { lang } = this.props.parameters;
    let errors = [];
    let errorText = false;
    const parrainLink = (window.location.href.split('/')[4])///Thx Js :)
    // console.log(parrainLink)

    const {
      email,
      password,
      lastName,
      firstName,
      pseudo,
      date,
      nativeCountry,
      gender,
      telNum,
      codeTelCountry,
      postalAddress,
      postalCode,
      city,
      residenceCountry,
      isContacts,
      contacts,
      isProfessionnel,
      socialDenomination,
      companyNumber,
      headquartersAdress,
      companyPostalCode,
      municipality,
      companyCountry,
      companyFunction,
      isTaxAddressDifferent,
      cgu,
      disclaimer,
    } = this.props.registerForm;
//  console.log(companyFunction.value)
    // Check mail
    errorText = checkInput(
      email.value,
      regexes.email,
      texts[lang].errorEmpryField,
      texts[lang].errorMail
    );
    if (errorText)
      errors.push(getPayloadObject("email", email.value, errorText));
   
    // Check pseudo
    errorText = checkInputUnrequired(
      pseudo.value,
      regexes.pseudo,
      texts[lang].errorEmpryField,
      texts[lang].errorPseudo
    );
    if (errorText)
      errors.push(getPayloadObject("pseudo", pseudo.value, errorText));

    // Check password
    errorText = checkInput(
      password.value,
      editRegex(regexes.password,5,200),
      texts[lang].errorEmpryField,
      texts[lang].errorPassword
    );
    if (errorText)
      errors.push(getPayloadObject("password", password.value, errorText));

    // Interomp the process if mail or password not renseigned
    if (errors.length) {
      errors.forEach((error) => this.props.editField(error));
      this.props.decrementStep();
    }

    // Check lastName
    errorText = checkInput(
      lastName.value,
      editRegex(regexes.default,1,200),
      texts[lang].errorEmpryField,
      texts[lang].errorLastName
    );
    if (errorText)
      errors.push(getPayloadObject("lastName", lastName.value, errorText));

    // Check firstName
    errorText = checkInput(
      firstName.value,
      editRegex(regexes.default,1,200),
      texts[lang].errorEmpryField,
      texts[lang].errorFirstName
    );
    if (errorText)
      errors.push(getPayloadObject("firstName", firstName.value, errorText));

    // Check date
    errorText = checkInput(
      date.value,
      editRegex(regexes.default,1,50),
      texts[lang].errorEmpryField,
      texts[lang].errorDate
    );
    if (errorText) errors.push(getPayloadObject("date", date.value, errorText));

    // Check native country
    errorText = checkInput(
      nativeCountry.value,
      editRegex(regexes.default,1,200),
      texts[lang].errorEmpryField,
      texts[lang].errorNativeCountry
    );
    if (errorText)
      errors.push(
        getPayloadObject("nativeCountry", nativeCountry.value, errorText)
      );

    // Check phone
    errorText = checkInput(
      telNum.value,
      regexes.phone,
      texts[lang].errorEmpryField,
      texts[lang].errorTelNum
    );
    if (errorText)
      errors.push(getPayloadObject("telNum", telNum.value, errorText));

    // Check postal adress
    errorText = checkInput(
      postalAddress.value,
      editRegex(regexes.default,1,200),
      texts[lang].errorEmpryField,
      texts[lang].errorPostalAddress
    );
    if (errorText)
      errors.push(
        getPayloadObject("postalAddress", postalAddress.value, errorText)
      );

    // Check postal code
    errorText = checkInput(
      postalCode.value,
      editRegex(regexes.postalCode,1,50),
      texts[lang].errorEmpryField,
      texts[lang].errorPostalCode
    );
    if (errorText)
      errors.push(getPayloadObject("postalCode", postalCode.value, errorText));

    // Check city
    errorText = checkInput(
      city.value,
      editRegex(regexes.default,1,200),
      texts[lang].errorEmpryField,
      texts[lang].errorCity
    );
    if (errorText) errors.push(getPayloadObject("city", city.value, errorText));

    // Check residence country
    errorText = checkInput(
      residenceCountry.value,
      editRegex(regexes.default,1,200),
      texts[lang].errorEmpryField,
      texts[lang].errorResidenceCountry
    );
    if (errorText)
      errors.push(
        getPayloadObject("residenceCountry", residenceCountry.value, errorText)
      );

    if (isProfessionnel.value) {
      // Check social denomination
      errorText = checkInput(
        socialDenomination.value,
        editRegex(regexes.default,1,200),
        texts[lang].errorEmpryField,
        texts[lang].errorSocialDenominations
      );
      if (errorText)
        errors.push(
          getPayloadObject(
            "socialDenomination",
            socialDenomination.value,
            errorText
          )
        );

      // Check company number
      errorText = checkInput(
        companyNumber.value,
        editRegex(regexes.siret,1,200),
        texts[lang].errorEmpryField,
        texts[lang].errorCompanyNumber
      );
      if (errorText)
        errors.push(
          getPayloadObject("companyNumber", companyNumber.value, errorText)
        );

      // Check social denomination
      errorText = checkInput(
        headquartersAdress.value,
        editRegex(regexes.default,1,200),
        texts[lang].errorEmpryField,
        texts[lang].errorHeadquartersAdress
      );
      if (errorText)
        errors.push(
          getPayloadObject(
            "headquartersAdress",
            headquartersAdress.value,
            errorText
          )
        );

      // Check company postalCode
      errorText = checkInput(
        companyPostalCode.value,
        editRegex(regexes.postalCode,1,50),
        texts[lang].errorEmpryField,
        texts[lang].errorCompanyPostalCode
      );
      if (errorText)
        errors.push(
          getPayloadObject(
            "companyPostalCode",
            companyPostalCode.value,
            errorText
          )
        );

      // Check company postalCode
      errorText = checkInput(
        municipality.value,
        editRegex(regexes.default,1,200),
        texts[lang].errorEmpryField,
        texts[lang].errorMunicipality
      );
      if (errorText)
        errors.push(
          getPayloadObject("municipality", municipality.value, errorText)
        );

      // Check company postalCode
      errorText = checkInput(
        companyCountry.value,
        editRegex(regexes.default,1,200),
        texts[lang].errorEmpryField,
        texts[lang].errorCountry
      );
      if (errorText)
        errors.push(
          getPayloadObject("companyCountry", companyCountry.value, errorText)
        );
    }

    // Check if add contacts is checked end has contacts
    if (isContacts.value && contacts.length < 1) {
      errors.push(getPayloadObject("isContacts", isContacts.value, true));
    }

    // check CGU
    if (!cgu.value) {
      errors.push(getPayloadObject("cgu", city.value, true));
    }

    // check disclamer
    if (!disclaimer.value) {
      errors.push(getPayloadObject("disclaimer", disclaimer.value, true));
    }

    if (errors.length) {
      errors.forEach((error) => this.props.editField(error));
      return { error: true };
    } else
      return {
        error: false,
        payload: {
          type: isProfessionnel.value ? "Entreprise" : "Particulier",
          email: email.value,
          password: password.value,
          lastname: lastName.value,
          firstname: firstName.value,
          pseudo: pseudo.value,
          dateOfBirth: new Date(date.value),
          address: postalAddress.value,
          zipCode: postalCode.value,
          parrain_link : this.state.parrainLink ? this.state.parrainLink:"false",
          country: nativeCountry.value,
          
          gender:gender.value,
          phone: codeTelCountry.value+telNum.value,
          haveDiferentFiscalCountry: isTaxAddressDifferent.value,
          city: city.value,
          fiscalCountry: residenceCountry.value,
          entreprise: isProfessionnel.value
           ? {
                name: socialDenomination.value,
                address: headquartersAdress.value,
                city: municipality.value,
                country: companyCountry.value,
                companyNumber: companyNumber.value,
                zipCode: companyPostalCode.value,
                function:companyFunction.value
              }
            : false,
          personeToContact: this.getContacts(contacts),
        },
      };
  };

  getContacts = (contacts) => {
    return contacts.map((contact) => ({
      firstname: contact.firstName,
      lastname: contact.lastName,
      email: contact.email,
      address: contact.address,
      zipCode: contact.zipCode,
      city: contact.city,
      country: contact.country,
      phone: contact.phone,
    }));
  };

  

  render() {

    const { lang, langues } = this.props.parameters;

    if ( this.state.hasNotValidateEmail ) {
      return (
        <InformationTemplate
          icon={<IconMailSend />}
          classes={"-infos"}
          title={texts[lang].emailNotValidatedTitle}
          parag={texts[lang].emailNotValidatedParag}
        />
      )
    }else if (this.state.redirect) return <Navigate to="/dashboard" replace={true} />
    else {

      const { steps, currentStep } = this.props.registerForm;

      return (
        <div className={this.context.isLoading?"-loading":""}>
          {!this.state.compteCreated ? (
            <Container
              sectionClasses="container -full-height"
              divClasses="content-container -full-height"
              sectionStyle={{
                paddingTop: 0,
                paddingBottom: "120",
              }}
            >
              <select
                className="switch-lang"
                style={{
                  position: "absolute",
                  top: 20,
                  right: 20,
                }}
                onChange={(event) => {
                  this.props.switchLang(event.target.value);
                }}
                value={lang}
              >
                {langues.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.label}
                  </option>
                ))}
              </select>

              <div className="registration-container">
                <div className="-forms-container">
                  <div className="-logo">
                    <img src={logo} alt="Vizio-logo" />
                    <p>L’investissement participatif</p>
                  </div>

                  {/* Stepper */}
                  <StepperHead steps={steps} current={currentStep} />

                  {/* Header - Text */}
                  <FormHeader
                    title={texts[lang].formHeaderTitle}
                    text={texts[lang].formHeaderSubTitle[currentStep - 1]}
                  />

                  {/* Form parts */}
                  <form>
                    {currentStep === 1 && <FormRegisterConnexion 
                      error={this.state.stepOneError}
                      parrain = {this.state.parrain }/>}
                    {currentStep === 2 && (
                      <FormRegisterInformations
                        error={this.state.error}
                        errorContacts={this.state.errorContacts}
                      />
                    )}
                    {currentStep === 3 && (
                      <InformationTemplate
                        icon={<IconMailSend />}
                        classes="infos"
                        title={texts[lang].compteCreatedTitle}
                        parag={texts[lang].compteCreatedParag}
                      />
                    )}
                  </form>

                  {/* Footer */}
                  <FormFooter
                    isLoading={this.state.isLoading}
                    currentStep={currentStep}
                    nbrSteps={steps.length}
                    handlePrev={this.handlePrev}
                    handleNext={this.handleNext}
                  />
                </div>
              </div>

              <ReCAPTCHA
                ref={this.recaptchaRef}
                size="invisible"
                sitekey={keys.recaptcha}
              />
            </Container>
          ) : (
            <Container
              sectionClasses="container -full-height"
              divClasses="content-container -full-height"
              sectionStyle={{
                paddingTop: 0,
                paddingBottom: "120",
              }}
            >
              <select
                className="switch-lang"
                style={{
                  position: "absolute",
                  top: 20,
                  right: 20,
                }}
                onChange={(event) => {
                  this.props.switchLang(event.target.value);
                }}
                value={lang}
              >
                {langues.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.label}
                  </option>
                ))}
              </select>

              <div className="registration-container">
                <div className="-forms-container">
                  <div className="-logo">
                    <img src={logo} alt="Vizio-logo" />
                    <p>L’investissement participatif</p>
                  </div>

                  {/* Stepper */}
                  <StepperHead steps={steps} current={currentStep} />

                  {/* Header - Text */}
                  <FormHeader
                    title={texts[lang].formHeaderTitle}
                    text={texts[lang].formHeaderSubTitle[currentStep - 1]}
                  />
                  <InformationTemplate
                    icon={<IconMailSend />}
                    classes="infos"
                    style={{ height: 0 }}
                    title={texts[lang].compteCreatedTitle}
                    parag={texts[lang].compteCreatedParag}
                  />
                  {/* Footer */}
                  {/* <FormFooter
                    isLoading={this.state.isLoading}
                    currentStep={currentStep}
                    nbrSteps={steps.length}
                    handlePrev={this.handlePrev}
                    handleNext={this.handleNext}
                  /> */}
                </div>
              </div>
            </Container>
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  registerForm: state.notPersisedReducer.registerForm,
  parameters: state.persisedReducer.parameters,
  user: state.persisedReducer.user,
});

const actions = {
  incrementStep,
  decrementStep,
  editField,
  switchLang,
};

export default connect(mapStateToProps, actions)(withRouter(RegisterView));
