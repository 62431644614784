import { Component } from 'react';
import { connect } from 'react-redux';

import FormRow from '../formRow';
import InputText from '../inputText';
import InputSelect from '../inputSelect';
import Button from '../button';

import FormSectionTitle from '../formSectionTitle';

import { addContactProfile, deleteContactProfile, editContactProfile } from '../../redux/store';

import regexes from '../../constants/regex';
import countries from '../../constants/countries';

import { getUUID } from '../../helpers/global';

import { checkInput, checkInput_contact } from '../../helpers/registration';

class FormRegisterInformationsContacts extends Component {

  

  constructor(props){
    super(props);
    this.state = this.initialState();
  }

  initialState = () => {
    const obj = {
      value: "",
      error: false,
    }
    // console.log(this.props.contacts)
    if(this.props.contacts[0] ) return {
      _id: {...obj, value: this.props.contacts[0]._id},
      firstname: {...obj, value: this.props.contacts[0].firstname},
      lastname: {...obj, value: this.props.contacts[0].lastname},
      email: {...obj, value: this.props.contacts[0].email},
      address: {...obj, value: this.props.contacts[0].address},
      zipCode: {...obj, value: this.props.contacts[0].zipCode},
      city: {...obj, value: this.props.contacts[0].city},
      country: {...obj, value: this.props.contacts[0].country},
      phone: {...obj, value: this.props.contacts[0].phone},
      isCompleted: false,
    }
    return  {
      _id: false,
      firstname: obj,
      lastname: obj,
      email: obj,
      address: obj,
      zipCode: obj,
      city: obj,
      country: {...obj,value:"FR"},
      phone: obj,
      isCompleted: false,
    }
  }

  handleInputChange = ({event, regex, errorText, name}) => {
    const texts = this.props.texts;
    const value = event.target.value;
    const error = checkInput(value, regex, texts.errorEmpryField, errorText) ? `"${checkInput(value, regex, texts.errorEmpryField, errorText)}"` : false;
    const json = `{"${name}":{"value":"${value}", "error":${error}}}`;
    this.setState(JSON.parse(json));
    this.checkIsCompleted();
  }

  checkIsCompleted = () => {

    const {
      firstname, lastname,
      email, address,
      zipCode, city,
      country, phone
    } = this.state;
    
    // console.log(firstname, lastname, email, address, zipCode, city, country, phone)
    this.setState({isCompleted: false})

    if (checkInput_contact(firstname.value, regexes.default)) return false;
    if (checkInput_contact(lastname.value, regexes.default)) return false;
    if (checkInput_contact(email.value, regexes.email)) return false;
    if (checkInput_contact(address.value, regexes.default)) return false;
    if (checkInput_contact(zipCode.value, regexes.postalCode)) return false;
    if (checkInput_contact(city.value, regexes.default)) return false;
    if (checkInput_contact(country.value, regexes.default)) return false;
    if (checkInput_contact(phone.value, regexes.phone)) return false;

    this.setState({isCompleted: true})
  }

  handleAddUser = () => {

    const {
      firstname, lastname,
      email, address,
      zipCode, city,
      country, phone
    } = this.state;

    let errors = [];
    let error = false;
    const texts = this.props.texts;
    
    error = checkInput(firstname.value, regexes.default, texts.errorEmpryField, texts.errorLastname);
    if ( error ) errors.push(this.getStringifiedJson("firstname", firstname.value, error));

    error = checkInput(lastname.value, regexes.default, texts.errorEmpryField, texts.errorLastname);
    if ( error ) errors.push(this.getStringifiedJson("lastname", lastname.value, error));

    error = checkInput(email.value, regexes.email, texts.errorEmpryField, texts.errorMailAdress);
    if ( error ) errors.push(this.getStringifiedJson("email", email.value, error));

    error = checkInput(address.value, regexes.default, texts.errorEmpryField, texts.errorPostalAddress);
    if ( error ) errors.push(this.getStringifiedJson("address", address.value, error));

    error = checkInput(zipCode.value, regexes.postalCode, texts.errorEmpryField, texts.errorPostalCode);
    if ( error ) errors.push(this.getStringifiedJson("zipCode", zipCode.value, error));

    error = checkInput(city.value, regexes.default, texts.errorEmpryField, texts.errorCity);
    if ( error ) errors.push(this.getStringifiedJson("city", city.value, error));

    error = checkInput(country.value, regexes.default, texts.errorEmpryField, texts.errorResidenceCountry);
    if ( error ) errors.push(this.getStringifiedJson("country", country.value, error));

    error = checkInput(phone.value, regexes.phone, texts.errorEmpryField, texts.errorPhoneNumber);
    if ( error ) errors.push(this.getStringifiedJson("phone", phone.value, error));


    if ( errors.length ) errors.forEach(error =>  this.setState(JSON.parse(error)))
    else {
      if ( this.state._id ) this.props.editContactProfile({_id: this.state._id, ...this.getContact(this.state)});
      else this.props.addContactProfile({_id: getUUID(), ...this.getContact(this.state)});
      this.setState({...this.initialState(), country})
    };

  }

  handleEditContact = (id) => {
    const contact = this.props.contacts.find(contact => contact._id === id);
    if (contact) this.setState({
      _id: contact._id,
      firstname: {error: false, value: contact.firstname},
      lastname: {error: false, value: contact.lastname},
      email: {error: false, value: contact.email},
      address: {error: false, value: contact.address},
      zipCode: {error: false, value: contact.zipCode},
      city: {error: false, value: contact.city},
      country: {error: false, value: contact.country},
      phone: {error: false, value: contact.phone}
    });
  }

  getStringifiedJson = (name, value, error) => (
    `{"${name}":{"value":"${value}", "error":${error ? `"${error}"` : false}}}`
  )

  getContact = (contact) => ({
    firstname: contact.firstname.value,
    lastname: contact.lastname.value,
    email: contact.email.value,
    address: contact.address.value,
    zipCode: contact.zipCode.value,
    city: contact.city.value,
    country: contact.country.value,
    phone: contact.phone.value,
  })

  render() { 

    const {contacts, texts} = this.props;

    return (
      <>

        <FormSectionTitle title={texts.titleContactsInformations} style={{marginTop:50}} />

        {
          contacts && contacts.length > 0
          && <Contacts
                contacts={contacts}
                handleEditContact={this.handleEditContact}
                handleDeleteContact={this.props.deleteContactProfile}
              />
        }

        <FormRow classes={"-two"}>
          <InputText
            label={texts.labelLastName}
            value={this.state.lastname.value}
            error={this.state.lastname.error}
            readonly={true}
          />
          <InputText
            label={texts.labelFirstName}
            value={this.state.firstname.value}
            error={this.state.firstname.error}
            readonly={true}
          />
        </FormRow>

        <FormRow classes={"-two"}>
          <InputText
            label={texts.labelPhoneNumber}
            value={this.state.phone.value}
            error={this.state.phone.error}
            type="number"
            max={15}
            readonly={true}
          />
          <InputText
            label={texts.labelMailAdress}
            value={this.state.email.value}
            error={this.state.email.error}
            readonly={true}
          />
        </FormRow>

        <FormRow classes={"-two"}>
          <InputText
            label={texts.labelPostalAddress}
            value={this.state.address.value}
            error={this.state.address.error}
            readonly={true}
          />
          <InputText
            label={texts.labelPostalCode}
            value={this.state.zipCode.value}
            error={this.state.zipCode.error}
            max={8}
            readonly={true}
          />
        </FormRow>

        <FormRow classes={"-two"}>
          <InputText
            label={texts.labelCity}
            value={this.state.city.value}
            error={this.state.city.error}
            readonly={true}
          />
          <InputSelect
            label={texts.labelResidenceCountry}
            value={this.state.country.value}
            error={this.state.country.error}
            inputs={countries}
            readonly={true}
          />
        </FormRow>

        {/* <Button
          label={texts.labelBtnAddContacte}
          classes="-small -flex-right -left"
          type="button"
          disable={!this.state.isCompleted}
          handleClick={this.handleAddUser}
        /> */}

      </>
    );
  }
}

// Composant qui affiche les contacts ajoutés par l'utilisateur
const Contacts = ({contacts, handleDeleteContact, handleEditContact}) => (
  <ul className='form-user-contacts'>
    {
      contacts.map( contact => <li className="-contact" key={contact._id}>
          <button className="-btn -btn-edit" type="button" onClick={() => handleEditContact(contact._id)}>{contact.firstname} {contact.lastname}</button>
          {/* <button className="-btn -btn-delete" type="button" onClick={() => handleDeleteContact(contact._id)}>X</button> */}
        </li>
      )
    }
  </ul>
)


const mapStateToProps = state => ({
  contacts: state.notPersisedReducer.editeProfileForm.contacts,
})

const actions = {
  addContactProfile,
  deleteContactProfile,
  editContactProfile,
}

export default connect(mapStateToProps, actions)(FormRegisterInformationsContacts);