import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import SubNav from "../components/subnav";

import texts from "../texts/dashboardLayout";

const ProfileLayout = () => {

  const {lang} = useSelector(state => state.persisedReducer.parameters);

  const links = [
    {
      id: 1,
      link: "/dashboard/edit-profile",
      label: texts[lang].labelInformations,
      isActive: false,
    },
    // {
    //   id: 2,
    //   link: "#",
    //   label: texts[lang].labelInvestorProfile?texts[lang].labelInvestorProfile:"Profil d'investisseur",
    //   isActive: false,
    // },
    {
      id: 3,
      link: "/dashboard/security",
      label: texts[lang].labelSecurity,
      isActive: false,
    },
    {
      id: 4, 
      link:"/dashboard/fiscalite",
      label: texts[lang].labelFicalite?texts[lang].labelFicalite:"Ma fiscalité",
      isActive: false,
    },
    {
      id: 5, 
      link:"/dashboard/banque",
      label: texts[lang].labelFicalite?texts[lang].labelFicalite:"Banque",
      isActive: false,
    }
  ];

  return (
    <div className="layout-profile-container">
      <SubNav
        links={links}
      />
      <Outlet />
    </div>
  )

}

export default ProfileLayout;