import React, { useContext, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormRow from '../../../components/formRow';
import InfosBar from '../../../components/infosBar';
import InputText from '../../../components/inputText';
import InputDate from '../../../components/inputDate';
import { LoaderContext } from "../../../context/Context";
import texts from "../../../texts/apportAffaire";
import InputTextArea from '../../../components/inputTextArea';
import axios from 'axios';
import urls from '../../../constants/urls';
import { useParams } from 'react-router-dom';
import Button from '../../../components/button';



const ContactNotes = () => {
  const dispatch = useDispatch();

  const { lang } = useSelector(state => state.persisedReducer.parameters)
  const { user, token } = useSelector(state => state.persisedReducer.user);
  const{id}=useParams()
  const headers = {
    headers: {
      "x-auth-token": token
    }
  }
  const [notes, setNotes] = useState('')
  const [date, setDate] = useState(``)
  const [notesValue, setNotesValue] = useState([])

const [isDisabled, setIsDisabled] = useState(true)
  

  const loader = useContext(LoaderContext);
  useLayoutEffect(() => {
    axios.get(`${urls.apiUrl}/prospect/${id}`, headers).
      then(res => {
        setNotesValue(res.data.note)
        // console.log(res.data.note)
      })

    loader.onSetIsLoading(false);
    return () => {
      loader.onSetIsLoading(false);
    }
  }, [])

  const handleDateChange = (e) => {
    console.log(e.target.value)
    setDate(e.target.value)
    if(e.target.value.length === 0 || notes.length === 0){
      setIsDisabled(true)
    }
    else{
      setIsDisabled(false)
    }

    
  }
  const handleNotesChange = (e) => {
    setNotes(e.target.value)
   if (e.target.value.length === 0 || date.length === 0) {
      setIsDisabled(true)
    }
    else {
      setIsDisabled(false)
    }
  }
  const sortedNotes = notesValue.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });

  const handleNotesSubmit = (e) => {
    e.preventDefault()
    console.log(notes)
    console.log(date)
    const body ={
      note :{
        date : date ,  
        text : notes
      }
    }
    axios.patch(`${urls.apiUrl}/prospect/notes/${id}`, body, headers).
      then((res) => {
        // console.log(res.data)
        setDate(``)
        setNotes('')
        setIsDisabled(true)
        axios.get(`${urls.apiUrl}/prospect/${id}`, headers).
        then(res => {
          setNotesValue(res.data.note)
          // console.log(res.data.note)
        })
      }
      )
      .catch(err => {
        console.log(err)
      }
      )
  }

  return (
    <div className="layout-AA-container">
      <div className="-add-contact-container">
        {/* <div className="contact-info-container -apporAf">
          <div className="-title">
            <h4 className="-texte">{texts[lang].infoPrealableContactForm}</h4>
          </div>
          <div className="-information">

            <InfosBar
              classes="-error"
              content={
                texts[lang].infoFormContactContentOne
              }
            />
            <InfosBar
              classes="-info"
              content={
                texts[lang].infoFormContactContentTwo
              }
            />
          </div>
        </div> */}

        
<form onSubmit={handleNotesSubmit}>

        <div className="contact-info-container -apporAf">
          <div className="-title">
            <h4 className="-texte">{texts[lang].noteAdd}</h4>
          </div>
          <div>
          <ul className='_notes_list'>
          {sortedNotes.map((note, index) => {
      return (
        <li key={index} className='_notes'>
          <div className='-not-overflow-date' >
            <label>
            { note.date!== ""?new Date (note.date).toLocaleDateString():""}
            </label>
            </div>
          <div className='-not-overflow-text'>
            <p>
              {note.text}
              </p>
            </div>
        </li>
      )
    })}
          </ul>
        </div>

          <div className='contact-scontainer'>

            <FormRow classes={"-one"}>
              <InputTextArea
              required = {false}
                classes ={`-big`}
                label={texts[lang].notes}
                value={notes}
                handleOnChange={(e) => handleNotesChange(e)}
              />
            </FormRow>
            <FormRow classes={"-three"}>
              <InputDate
              required = {false}
                label={texts[lang].noteDate}
                value={date}
                min="1950-01-01"
                handleDateChange={(e) => handleDateChange(e)}
              />
            </FormRow>

          </div>
          
        </div>
        <div className="-add-contact-button">
              <Button
                classes={"-add-contact"}
                label={"Ajouter"}
                type="submit"
                disable ={isDisabled}
              />
            </div>
            </form>

      </div>

    </div>
  );
};

export default ContactNotes;