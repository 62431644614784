import { Link } from 'react-router-dom';
import Button from '../button';
import InputText from '../inputText';


const DoubleAuthForm = ({texts, isLoading, code, vizioMail, handleCodeAuthSubmit, handleCodeChange}) => {

  // console.log(vizioMail);
   
  return (
    <div>
      <form className="double-auth-form" onSubmit={handleCodeAuthSubmit}>
        <h3 className="-title">{texts.code}</h3>
        <InputText
          label={texts.code}
          classes="code"
          value={code.value}
          error={code.error}
          handleInputChange={handleCodeChange}
        />
        <div className="-forget">
          <a href={vizioMail}>{texts.forgotAuth}</a>
        </div>
        <Button
          isLoading={isLoading}
          label={texts.buttonCode}
          marginTop={40}
          classes="btn"
          icon={true}
          type="submit"
        />
      </form>
    </div>
  );

}
 
export default DoubleAuthForm;