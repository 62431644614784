import { Link } from "react-router-dom"
import Button from "./button"


const copyToClipBoard = ()=>{
  const copyText = document.getElementById("copy-affiliate")

 
  copyText.select();
  document.execCommand('copy')
  alert("le lien d'affiliation a été copié")



// 
}
const Card =({header, amount, text, btnlabel, btnDisable, action,affiliateLink, classes, amountclass, btnLink})=>{
 return( <div className={`card ${classes ? classes : ""}`}>
        <div className="card-header">
          <label>{header}</label>
        </div>
        <div className = "card-body">
          <p className= {amountclass ?`-amount ${amountclass}` : `-amount`}>{amount}</p>
          <p className="-card-body-text">{text} </p>
          {btnlabel &&
          <Link to={btnLink ? btnLink : ""}>
          <Button
            label={btnlabel}
            disable={btnDisable}
            classes="-card-body-btn" 
            handleClick={action}/></Link> }
            {affiliateLink && <div className="-link" >
              {/* <span className="copy-affiliate"onClick={copyToClipBoard}>{affiliateLink}</span> */}
              <input type="text" id="copy-affiliate" className="copy-affiliate" value={`app.viziocapital.com/registration/${affiliateLink}`} readOnly onClick={copyToClipBoard} />
              </div>}
            </div>
      </div>)

}
export default Card