
export default {
    fr: {
        //document
        //* Lien https://app.viziocapital.com/documents
        docInfo: "Informations",
        docDate:"DATE",
        docMontant:"MONTANT",
        docNature:"NATURE DU DOCUMENT",
        noPay: "pas de montant",

    },
    en: {
        //document
        //* Lien https://app.viziocapital.com/documents
        docInfo: "Informations",
        docDate:"DATE",
        docMontant:"MONTANT",
        docNature:"NATURE DU DOCUMENT",
        noPay: "pas de montant",
    },
};