import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
// import SubNav from "../components/subnav";

import texts from "../texts/dashboardLayout";

const DocumentLayout = () => {

  const {lang} = useSelector(state => state.persisedReducer.parameters);

  const links = [
    {
      id: 1,
      link: "/doc-pay-proof",
      label: "Preuves de paiement",
      isActive: false,
    },
    // {
    //   id: 2,
    //   link: "#",
    //   label: texts[lang].labelInvestorProfile?texts[lang].labelInvestorProfile:"Profil d'investisseur",
    //   isActive: false,
    // },
    {
      id: 3,
      link: "/dashboard/doc-contrat",
      label: "Contrat d'obligation",
      isActive: false,
    },
    {
      id: 4, 
      link:"/dashboard/doc-commission",
      label: "Fiches de commisions",
      isActive: false,
    }
  ];

  return (
    <div className="layout-profile-container">
      {/* <SubNav */}
        {/* links={links} */}
      {/* /> */}
      <Outlet />
    </div>
  )

}

export default DocumentLayout;