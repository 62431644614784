export default () =>  (
    <svg viewBox="0 0 60 58">
        <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="ic_Edit-Copy" fill="#3DD598" fillRule="nonzero">
                <g id="noun-envelope-185619">
                    <path d="M30,57.3913043 C22.0903039,57.3913043 15.6521739,50.9558848 15.6521739,43.0440373 C15.6521739,35.1316307 22.0905275,28.6956522 30,28.6956522 C37.9094725,28.6956522 44.3478261,35.1310717 44.3478261,43.0429193 C44.3478261,50.9547668 37.9094725,57.3913043 30,57.3913043 Z M30,31.3039413 C23.5275462,31.3039413 18.2605646,36.5707176 18.2605646,43.0429193 C18.2605646,49.5151209 23.5275462,54.7818972 30,54.7818972 C36.4724538,54.7818972 41.7394354,49.5151209 41.7394354,43.0429193 C41.7394354,36.5707176 36.4724538,31.3039413 30,31.3039413 Z" id="Shape"></path>
                    <path d="M27.3927269,48.2608696 C27.0586126,48.2608696 26.7249343,48.1328927 26.469007,47.8799916 L21.2511753,42.6616213 C20.7423618,42.1527552 20.7423618,41.325943 21.2511753,40.8170546 C21.7599888,40.3081886 22.5867157,40.3081886 23.0955516,40.8170546 L27.3927604,45.1115761 L35.6001006,36.9033887 C36.1089141,36.3945226 36.935641,36.3945226 37.4444768,36.9033887 C37.9532903,37.4122547 37.9532903,38.2390669 37.4444768,38.7479553 L28.3133832,47.8799916 C28.0605083,48.1328927 27.726394,48.2608696 27.3927157,48.2608696 L27.3927269,48.2608696 Z" id="Path"></path>
                    <path d="M53.4783775,41.7391304 L48.2608112,41.7391304 C47.5406511,41.7391304 46.9563079,41.1547974 46.9563079,40.4346498 C46.9567447,39.7145023 47.5410872,39.1301693 48.2608112,39.1301693 L53.4783775,39.1301693 C55.6358168,39.1301693 57.3914405,37.3745763 57.3914405,35.2171747 L57.3914405,6.52150854 C57.3914405,4.36455414 55.6358168,2.60896118 53.4783775,2.60896118 L6.52162247,2.60896118 C4.36418318,2.60896118 2.60855955,4.36455414 2.60855955,6.52195574 L2.60855955,35.2176219 C2.60855955,37.3745763 4.36418318,39.1301693 6.52162247,39.1301693 L11.7391888,39.1301693 C12.4593489,39.1301693 13.0436921,39.7145023 13.0436921,40.4346498 C13.0432553,41.1547974 12.4589128,41.7391304 11.7391888,41.7391304 L6.52162247,41.7391304 C2.92428782,41.7391304 0,38.8122105 0,35.2176219 L0,6.52195574 C0,2.92691994 2.92428782,0 6.52162247,0 L53.4783775,0 C57.0757122,0 60,2.92691994 60,6.52150854 L60,35.2171747 C60,38.8122105 57.0757122,41.7391304 53.4783775,41.7391304 Z" id="Path"></path>
                    <path d="M29.9990649,23.4782609 C28.6059666,23.4782609 27.2155513,23.0508895 26.0263702,22.1939475 L5.75763359,7.57796076 C5.17331711,7.15582534 5.04274978,6.34075969 5.46547535,5.75687937 C5.88820093,5.17076625 6.70183474,5.04560598 7.28608415,5.46512901 L27.5548207,20.0811157 C29.0365738,21.1517267 30.964172,21.1517267 32.4459251,20.0811157 L52.7146617,5.46512901 C53.2989781,5.04299359 54.1130144,5.17337864 54.5352704,5.75687937 C54.957996,6.34038011 54.8248015,7.15584767 54.2431122,7.57494646 L33.9743756,22.1909332 C32.7826231,23.0482994 31.3920961,23.4782609 29.9989978,23.4782609 L29.9990649,23.4782609 Z" id="Path"></path>
                    <path d="M6.52272117,36.5217391 C6.18860691,36.5217391 5.85492869,36.3937692 5.59900139,36.1408817 C5.09018794,35.6320432 5.09018794,34.8052757 5.59900139,34.2964148 L18.6435791,21.2511941 C19.1523925,20.7423556 19.9791193,20.7423556 20.4879551,21.2511941 C20.9967686,21.7600326 20.9967686,22.5868002 20.4879551,23.0956611 L7.44337744,36.1408817 C7.19050248,36.3937692 6.85682426,36.5217391 6.52270999,36.5217391 L6.52272117,36.5217391 Z" id="Path"></path>
                    <path d="M53.4803424,36.5217391 C53.1462281,36.5217391 52.8125499,36.3937692 52.5566226,36.1408817 L39.5120449,23.0956611 C39.0032314,22.5868225 39.0032314,21.760055 39.5120449,21.2511941 C40.0208583,20.7423556 40.8475851,20.7423556 41.3564209,21.2511941 L54.4009986,34.2964148 C54.9098121,34.8052533 54.9098121,35.6320209 54.4009986,36.1408817 C54.1481237,36.3937692 53.8144454,36.5217391 53.4803312,36.5217391 L53.4803424,36.5217391 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </svg>
)