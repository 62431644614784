import { useDispatch, useSelector } from 'react-redux';

import FormInformationsPersonal from './formRegisterInformationsPersonal';
import FormRegisterInformationsProfessional from './formRegisterInformationsProfessional';
import FormContacts from './formRegisterInformationsContacts';

import InfosBar from '../infosBar';
import FormRowCheckbox from '../formRowCheckbox';

import FormSectionTitle from '../formSectionTitle';

import { editField } from '../../redux/store';

import { getPayloadObject, checkInput, checkInputUnrequired } from '../../helpers/registration';

import texts from '../../texts/registration';

const styleCheckBox = {
  marginBottom: 20,
  marginTop: 50
}

const styleCheckBox_2 = {
  marginBottom: 20,
  marginTop: 20
}

export default ({error, errorContacts}) => {

  const registerForm = useSelector(state => state.notPersisedReducer.registerForm);
  const {lang} = useSelector(state => state.persisedReducer.parameters);
  const {
    isContacts, isProfessionnel,
    isTaxAddressDifferent,
    cgu, disclaimer,
    isOkPersonalInfos
  } = registerForm;
  const dispatch = useDispatch();

  const handleSimpleInputChange = (event, name) => {
    dispatch(editField({
      name,
      field: {
        error: false,
        value: event.target.checked,
      }
    }));
  }

  const handleInputChange = ({event, regex, errorText, name}) => {
    const value = event.target.value;
    const error = checkInput(value, regex, texts[lang].errorEmpryField, errorText);
    const payload = getPayloadObject(name, value, error);
    dispatch(editField(payload));
  }

  const handleUnrequiredInputChange = ({event,regex,errorText,name})=>{
    const value = event.target.value;
    const error = checkInputUnrequired(value, regex, errorText);
    const payload = getPayloadObject(name,value,error);
    dispatch(editField(payload));
  }


  return (
    <div>

      {/* Error Messages */}
      {
        error
        ? <InfosBar
            content={error}
            classes="-error"
          />
        : <>
            <InfosBar
              content={texts[lang].informationSucces}
            />
            <InfosBar
              content={texts[lang].informationWarningHeader}
              classes="-info"
              style={{
                marginBottom: 40
              }}
            />
          </>
      }

      {/* Personnal Informations */}
      <FormInformationsPersonal
        texts={texts[lang]}
        handleInputChange={handleInputChange}
        handleUnrequiredInputChange = {handleUnrequiredInputChange}
      />

      {/* Contacts Informations */}
      <FormRowCheckbox
        isActive={isContacts.value}
        error={isContacts.error}
        style={styleCheckBox}
        label={texts[lang].labelIsContacts}
        handleCheckboxChange={(event) => handleSimpleInputChange(event, "isContacts")}
      />

      {
        isContacts.error &&
        <InfosBar
          content={texts[lang].errorContacts}
          classes="-error"
        />
      }

      {
        isContacts.value &&
        <FormContacts
          texts={texts[lang]}
        />
      }

      {/* Professional Informations */}
      <FormRowCheckbox
        isActive={isProfessionnel.value}
        error={isProfessionnel.error}
        style={styleCheckBox}
        label={texts[lang].labelIsProfessionnel}
        handleCheckboxChange={(event) => handleSimpleInputChange(event, "isProfessionnel")}
      />

      {
        isProfessionnel.value &&
        <FormRegisterInformationsProfessional
          texts={texts[lang]}
          handleInputChange={handleInputChange}
        />
      }


      {/* Fiscalité */}
      <FormSectionTitle title={texts[lang].titleFiscality} style={{marginTop:50}}/>

      <FormRowCheckbox
        style={{
          marginTop: 50,
          marginBottom: isTaxAddressDifferent ? 20 : 40
        }}
        isActive={isTaxAddressDifferent.value}
        error={isTaxAddressDifferent.error}
        label={texts[lang].labelIsTaxAdressDifferent}
        handleCheckboxChange={(event) => handleSimpleInputChange(event, "isTaxAddressDifferent")}
      />

      {
        isTaxAddressDifferent.value &&
        <InfosBar
          content={texts[lang].informationWarningTaxation}
          classes="-info"
          style={{
            marginBottom: 40
          }}
        />
      }

      {/* CGU */}
      <FormSectionTitle classes="-simple" style={{marginTop:0}}/>

      <FormRowCheckbox
        isActive={cgu.value}
        error={cgu.error}
        style={styleCheckBox_2}
        label={texts[lang].labelcgu}
        handleCheckboxChange={(event) => handleSimpleInputChange(event, "cgu")}
      />
      <FormRowCheckbox
        isActive={disclaimer.value}
        error={disclaimer.error}
        style={styleCheckBox_2}
        label={texts[lang].labelDisclaimer}
        handleCheckboxChange={(event) => handleSimpleInputChange(event, "disclaimer")}
      />
      <FormRowCheckbox
        isActive={isOkPersonalInfos.value}
        error={isOkPersonalInfos.error}
        style={styleCheckBox_2}
        label={texts[lang].labelIsOkPersonalInfos}
        handleCheckboxChange={(event) => handleSimpleInputChange(event, "isOkPersonalInfos")}
      />

    </div>
  )
}