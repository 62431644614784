const InputText = (props) => {

  const {
    classes,
    value,
    label,
    type,
    indication,
    handleInputChange,
    placeholder,
    error,
    max,
    style,
    readonly,
    min,
  } = props;



  const handleChange = (event) => {
    const value = event.target.value;
    if ( max && value.length > max ) return;
    handleInputChange(event);
  }

  const isPhoneNumber = (event) => {
    const value = event.target.value;
    const intredChar = event.nativeEvent.data
    if (value.length <= 0 ) return true;
    else if (intredChar === "+" && value.replace(/[^+]/g, "").length >1) return false;
    else if (intredChar !== "+") return Number.isInteger(parseInt(intredChar));
    return true;
  }

  const required = props.required === false ? false : true;
  // if(readonly !== true ||readonly !==false) readonly = false

  return (
    <label
      className={`input ${classes ? classes : ""} ${error ? "-error" : "" }`}
      style={style ? style : {}}
    >
      { required ? <span className="-required">{label}</span> : <span>{label}</span> }
      <input
        value={value ? value : ""}
        min={min?min:""}
        max={max?max:""}
        type={type ? type : "text"}
        onChange={handleChange}
        placeholder={placeholder ? placeholder : ""}
        autoComplete="on"
        readOnly={readonly}
        // pattern={type === "tel" ? "/^\+?[0-9]{9,15}$/":false}
      />
      {indication && <p className={indication ? "-indication" : "" }>{indication}</p>}
      {error && <p className={error ? "-error" : ""}>{error}</p>}
    </label>
  )
}

export default InputText;