export default ()=>(
<svg width="24px" height="21px" viewBox="0 0 24 21" version="1.1" >
    <g id="all-Sprints-:-DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
        <g id="user---AA---annuaire-partenaires-details" transform="translate(-338.000000, -240.000000)" fill="#3DD598">
            <g id="Group-Copy" transform="translate(309.000000, 191.000000)">
                <g id="Comments" transform="translate(29.919492, 49.122881)">
                    <path d="M18.2439247,18.2131122 C18.2418955,19.4557513 17.2328925,20.4614642 15.9902535,20.4594381 C15.4940882,20.4586248 15.0121047,20.2938334 14.6193032,19.9907037 L10.827478,17.0645057 L3.75,17.0645057 C1.67893219,17.0645057 0,15.3855736 0,13.3145057 L0,3.75 C0,1.67893219 1.67893219,0 3.75,0 L18.75,0 C20.8210678,0 22.5,1.67893219 22.5,3.75 L22.5,13.3145057 C22.5,15.3855736 20.8210678,17.0645057 18.75,17.0645057 L18.2458004,17.0645057 L18.2439247,18.2131122 Z M15.7478417,15.8124645 C15.7489677,15.1229065 16.3082811,14.5645057 16.99784,14.5645057 L18.75,14.5645057 C19.4403559,14.5645057 20,14.0048617 20,13.3145057 L20,3.75 C20,3.05964406 19.4403559,2.5 18.75,2.5 L3.75,2.5 C3.05964406,2.5 2.5,3.05964406 2.5,3.75 L2.5,13.3145057 C2.5,14.0048617 3.05964406,14.5645057 3.75,14.5645057 L11.2719069,14.5645057 C11.6266925,14.5645057 11.9381638,14.7090594 12.1615675,14.9361721 L15.7447571,17.7013633 L15.7478417,15.8124645 Z" id="Combined-Shape-Copy" transform="translate(11.250000, 10.229719) scale(-1, 1) translate(-11.250000, -10.229719) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)