export default () => (
    <svg width="47px" height="47px" viewBox="0 0 47 47" version="1.1">
    <title>Group 5</title>
    <g id="Sprint-2-DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vue-Notifications" transform="translate(-298.000000, -149.000000)">
            <g id="notif" transform="translate(278.000000, 118.000000)">
                <g id="Group-5" transform="translate(20.000000, 31.000000)">
                    <circle id="Oval-Copy-5" fill="#FFC542" opacity="0.1" cx="23.5" cy="23.5" r="23.5"></circle>
                    <g id="Group-2" transform="translate(12.000000, 12.000000)" fill="#FF974A" fillRule="nonzero">
                        <path d="M22.68726,1.44082165 C21.980514,1.44082165 21.4000178,2.02214247 21.4000178,2.72989234 L21.4000178,4.54982056 L20.3904709,3.5388396 C18.1438482,1.26383905 15.1403582,0 11.9600015,0 C8.7795546,0 5.75073357,1.26383905 3.50429112,3.51347255 C1.08107204,5.94013379 -0.180974284,9.30185537 0.0209982007,12.7142208 C0.071529634,13.3965585 0.626825508,13.9274611 1.28304452,13.9274611 L1.35875378,13.9274611 C2.06549972,13.8768579 2.59560425,13.27031 2.54507732,12.5626052 C2.39365881,9.85789941 3.40340404,7.22920448 5.29647352,5.33344591 C8.95636278,1.64312617 14.9385074,1.64312617 18.5984417,5.33344591 L19.6079887,6.34442687 L17.7908222,6.34460317 C17.0840763,6.34460317 16.5287759,6.90069235 16.5287759,7.60844222 C16.5287759,8.31619209 17.1092722,8.89751291 17.8160181,8.89751291 L22.7127578,8.89751291 C23.4195038,8.89751291 24,8.31619209 24,7.60844222 L24,2.70474672 C23.9748226,1.99699686 23.3943079,1.44090768 22.687607,1.44090768 L22.68726,1.44082165 Z" id="Path"></path>
                        <path d="M16.1076017,11.896144 L13.1257194,11.0617105 L13.1257194,6.6366842 C13.1257194,5.92867999 12.5494426,5.34715026 11.8478338,5.34715026 C11.146225,5.34715026 10.5699482,5.92867999 10.5699482,6.6366842 L10.5699482,12.0225734 C10.5699482,12.6041031 10.9457385,13.1097752 11.4970294,13.2616259 L15.4059481,14.3742491 C15.5312712,14.3994713 15.6314249,14.4248705 15.7567435,14.4248705 C16.3080076,14.4248705 16.8091568,14.0456547 16.9846037,13.4893386 C17.1849113,12.7811538 16.7839472,12.0731948 16.1075032,11.8961034 L16.1076017,11.896144 Z" id="Path"></path>
                        <path d="M8.9210138,21.0698198 C8.31783079,20.8712526 7.73986828,20.6231821 7.18694678,20.3005021 C6.63407016,19.9778132 5.930409,20.176376 5.62888481,20.7223881 C5.30213114,21.268387 5.50319513,21.9632946 6.05608522,22.2610678 C6.7346605,22.6581977 7.46336266,22.9807049 8.2173302,23.204201 C8.34301988,23.253886 8.46870956,23.253886 8.56915181,23.253886 C9.04668104,23.253886 9.49894489,22.9561216 9.64968454,22.4845683 C9.8256851,21.9136184 9.52399486,21.2684357 8.92098238,21.0698464 L8.9210138,21.0698198 Z" id="Path"></path>
                        <path d="M3.43930903,16.1746764 C3.15888211,15.5916532 2.44510044,15.3888468 1.88426482,15.6677056 C1.3234292,15.9465644 1.06841288,16.6563869 1.37445978,17.2141045 C1.73134607,17.923927 2.16468263,18.6084439 2.64908618,19.2167274 C2.87847796,19.4955953 3.2099218,19.6476684 3.56681719,19.6476684 C3.8218153,19.6476684 4.07662222,19.5716296 4.28060797,19.3940879 C4.79041301,18.9884344 4.86689059,18.2786074 4.48457096,17.7716366 C4.07664954,17.2901071 3.7451966,16.757695 3.43933179,16.1746718 L3.43930903,16.1746764 Z" id="Path"></path>
                        <path d="M19.1553116,18.2921033 C19.0290263,18.4418627 18.8775543,18.5916222 18.7512736,18.7164752 C18.4229716,19.0410654 18.0948229,19.3156645 17.7413702,19.5903171 C17.2364185,19.9647112 17.135329,20.6887936 17.5141623,21.1880363 C17.7413702,21.4875552 18.0948635,21.6373057 18.4231564,21.6373057 C18.675727,21.6373057 18.9029485,21.562426 19.1049472,21.4126665 C19.5341719,21.0880763 19.9381918,20.7385842 20.3422344,20.3392881 C20.5190689,20.1644528 20.6957231,19.9897959 20.847186,19.8149473 C21.2764108,19.3405798 21.2258615,18.6167024 20.7462633,18.217228 C20.2914957,17.7679586 19.5591602,17.8177581 19.1552981,18.2921077 L19.1553116,18.2921033 Z" id="Path"></path>
                        <path d="M14.3799984,21.1745353 C13.7822474,21.3497525 13.1347765,21.4497522 12.5121656,21.4747086 C11.8895547,21.5247955 11.4163259,22.0500988 11.4413637,22.6755716 C11.4662055,23.2760701 11.9892614,23.7512953 12.5620189,23.7512953 L12.6367189,23.7512953 C13.408712,23.7012084 14.2057429,23.5760781 14.9527427,23.3760832 C15.5504936,23.2008659 15.9240157,22.5753887 15.7497736,21.9750689 C15.575358,21.3747044 14.9777805,21.0244844 14.3799851,21.1745531 L14.3799984,21.1745353 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
)
