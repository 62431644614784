import FormSectionTitle from "../../components/formSectionTitle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import urls from "../../constants/urls";
import IconFileDownload from "../../components/icons/icon-file-download";

import { LoaderContext } from "../../context/Context";
import { useLayoutEffect, useContext } from 'react';

const ContratView = () => {
  const loader = useContext(LoaderContext);
  const { user, token } = useSelector((state) => state.persisedReducer.user);

  const [contracts, setContracts] = useState([]);
  // const [doc,setDoc] = useState(null)

  const headers = {
    headers: {
      "x-auth-token": token,
    },
  };
  // const getDocUrl =
  const getDoc = (path) => {
    if (path) {
      window.location.replace(`${urls.apiUrl}/${path}`);
    }
  };
  useLayoutEffect(() => {
    //Récupreration des investissements
    axios
      .get(`${urls.apiUrl}/investment/user-invest/${user.id}`, headers)
      .then((res) => {
        setContracts(res.data);
        loader.onSetIsLoading(false);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return()=>{
      loader.onSetIsLoading(true);
    }
  }, []);

  return (
    <div className="dashboard-container">
      <div className="dashboard-scontainer -no-padding">
        <FormSectionTitle title="Information" classes="-simple-2" />

        <div className="security-form-container">
          <div className="_contract_container">
            <div className="_contract_header">
              <span>DATE</span>
              <span>MONTANT DEPOSE</span>
              <span>INFORMATION</span>
            </div>

            <ul className="_contract_list">
              {contracts.map((contract) => {
                return (
                  <li key={contract._id} className="_contract">
                    <div data-label="DATE">
                      {contract.dateContrat != null
                        ? contract.dateContrat
                        : "waiting for approval ..."}
                    </div>
                    <div data-label="MONTANT">{`${contract.montant} €`} </div>
                    <div data-label="INFORMATION">{contract.doc.name}</div>
                    <div data-label="INFORMATION">
                      {" "}
                      <a onClick={() => getDoc(contract.doc.path)}>
                        {" "}
                        <IconFileDownload />
                      </a>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContratView;
