import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import axios from "axios";
import IconArrowColored from "../../assets/svg/navbar-arrow-colored";
import texts from "../../texts/apportAffaire"
import urls from "../../constants/urls";
import InfosBar from "../../components/infosBar";
import Card from "../../components/card";
import FormSectionTitle from "../../components/formSectionTitle";
import { roundNumber } from "../../helpers/roundNumberFunc";
import { LoaderContext } from "../../context/Context";
import { useLayoutEffect, useContext } from 'react';

const DetailsApportAffaireView = () => {
  const loader = useContext(LoaderContext);
  const { token, user } = useSelector((state) => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)

  const headers = {
    headers: {
      "x-auth-token": token,
    },
  };
  const [filleuleDepot, setFilleuleDepot] = useState([]);
  const [showFilleule, setShowFilleule] = useState([]);
  const[totaleLevéeIndirecte, setTotaleLevéeIndirecte] = useState(0)
  const [totalDeposerRealE, setTotalDeposerRealE] = useState(0);
  const [totalDeposerCap, setTotalDeposerCap] = useState(0);

  useLayoutEffect(() => {
    axios
      .get(`${urls.apiUrl}/investment/user/${user.id}`, headers)
      //* retourne tout les benefice sur investissement reçu
      .then((res) => {
        loader.onSetIsLoading(false);
        let benef = res.data.filter((i) => {
          return i.status === "validé";
        });
        //* retourne tout les benefice sur investissement real estate reçu
        let realEstate = benef.filter((user) => {
          return user.fromFond.fond.name === "Real-Estate";
        });
        setTotalDeposerRealE(
          realEstate
            .map((i) => parseInt(i.fromFond.montant))
            .reduce((prev, next) => prev + next,0)
        );
        //* retourne tout les benefice sur investissement capitalisation reçu
        let capitalisation = benef.filter((user) => {
          return user.fromFond.fond.name === "Capitalisation";
        });
        setTotalDeposerCap(
          capitalisation
            .map((i) => parseInt(i.fromFond.montant))
            .reduce((prev, next) => prev + next,0)
        );
        return()=>{
          loader.onSetIsLoading(true);
        }
      },[]);

    axios.get(`${urls.apiUrl}/reseau/parrain/${user.id}`,headers).then((res) => {
      // console.log(res.data);
      let parrain = res.data.filter((user) => {
        return user.depth === 0;
      });
      setTotaleLevéeIndirecte(parrain[0].totalLeveeArbre)
      // console.log(parrain);
      let users = res.data.filter((user) => {
        return user.depth === 1;
      });
      const filleule = users.map((i) => {
        return {
          id: i.filleule._id,
          firstname: i.filleule.firstname,
          lastname: i.filleule.lastname,
          realEstate: i.filleule.investments
            ? i.filleule.investments.filter(
                (o) => o.fond.name === "Real-Estate" && o.status === "processed"
              )
            : 0,

          capitalisation: i.filleule.investments
            ? i.filleule.investments.filter(
                (o) =>
                  o.fond.name === "Capitalisation" && o.status === "processed"
              )
            : 0,
          role: i.filleule.role,
        };
      });
      
      setFilleuleDepot(filleule.filter((i)=>i.role.includes("investisseur")));
      setShowFilleule(filleule.filter((i)=>!i.role.includes("investisseur")));
      
      
    });
  }, []);

  // console.log(filleuleDepot)
  // console.log(showFilleule);
  
  // console.log(filleuleDepot);

  // const getRandomColor = () => {
  //   const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F"];
  //   let hexaCode = "";
  //   for (let i = 0; i < 6; i++)
  //     hexaCode += codes[Math.floor(Math.random() * codes.length)];
  //   return `#${hexaCode}`;
  // };

  const colors = ['red', 'green', 'blue', 'purple', 'orange', 'pink', 'cyan', 'magenta', 'teal', 'lime', 'olive', 'maroon', 'navy', 'brown', 'black', 'gray', 'silver', 'gold', 'tan', 'beige', 'salmon', 'plum', 'peach'];
  let currentIndex = 0;

  const getNextColor = () => {
    const nextColor = colors[currentIndex];
    currentIndex = (currentIndex + 1) % colors.length;
    return nextColor;
  }

  return (
    <>
      <div className="layout-profile-container">
        <div className="-big-card-container">
          <div className="information-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].detailInfop}</h4>
            </div>
            <div className="-information">
              <InfosBar
                classes="-info"
                content={texts[lang].detailInfopContent}
              />
            </div>
          </div>
          
          <div className="title-dicrect">{(texts[lang].detailMonaffiliation).toUpperCase()}</div>
        
          <div className="dashboard-container -card-container">
            <Card
              classes={"first-elmt"}
              header={texts[lang].detailMontantleveTotal}
              amount={`${roundNumber(totalDeposerRealE+totalDeposerCap)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={texts[lang].detailMontantleveTotalContent}
            />
            <Card
              classes={"first-elmt"}
              header={texts[lang].detailMontantlevéRE}
              amount={`${roundNumber(totalDeposerRealE)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={texts[lang].detailMontantlevéREContent}
            />
            <Card
              classes={"first-elmt"}
              header={texts[lang].detailMontantlevéCA}
              amount={`${roundNumber(totalDeposerCap)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={texts[lang].detailMontantlevéCAContent}
            />
          </div>
          <div className="title-dicrect">{(texts[lang].detailMonaffiliationIndirect).toUpperCase()}</div>
        
          <div className="dashboard-container -card-container">
            <Card
              classes={"first-elmt"}
              header={texts[lang].detailMontantleveTotalIndirect}
              amount={`${roundNumber(totaleLevéeIndirecte)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={texts[lang].detailMontantleveTotalContentIndirect}
            />
            
          </div>
          <div className="details-container">
            <div className="details-scontainer -no-padding">
              <FormSectionTitle
                title="Montant total investi par filleuls"
                classes="-simple-2"
              />

              <div className="security-form-container">
                <div className="_details_container">
                  <div className="_details_header">
                    <span></span>
                    <span>{texts[lang].detailNom}</span>
                    <span>{texts[lang].detailPrenom}</span>
                    <span>{texts[lang].detailRealEstate} </span>
                    <span>{texts[lang].detailCapitalisation} </span>
                    <span>{texts[lang].detailTotaldeposer}</span>
                    <span></span>
                    </div>
                  {filleuleDepot && filleuleDepot.length>0 ?
                  <ul className="_details_list">
                    {filleuleDepot.map((users) => {
                      return (
                        <li key={users.id} className="_details">
                          <div className="_picto">
                            <a style={{ backgroundColor: getNextColor() }}>
                                {`${users.lastname
                              .charAt(0)
                              .toUpperCase()}${users.firstname
                              .charAt(0)
                              .toUpperCase()}`}</a>
                          </div>
                          <div data-label="NOM :">{users.lastname}</div>
                          <div data-label="PRENOM :">{users.firstname}</div>
                          <div data-label="REAL ESTATE :">
                            {roundNumber(
                              users.realEstate.length !== 0
                                ? users.realEstate
                                    .map((i) => roundNumber(i.montant))
                                    .reduce((prev, next) => prev + next)
                                : 0
                            )
                              .toLocaleString("en-EU")
                              .replaceAll(",", " ")}{" "}
                            €
                          </div>
                          <div data-label="CAPITALISATION : ">
                            {roundNumber(
                              users.capitalisation.length !== 0
                                ? users.capitalisation
                                    .map((i) => roundNumber(i.montant))
                                    .reduce((prev, next) => prev + next)
                                : 0
                            )
                              .toLocaleString("en-EU")
                              .replaceAll(",", " ")}{" "}
                            €
                          </div>
                          <div data-label="TOTAL DÉPOSÉ :">
                            {roundNumber(
                              (users.capitalisation.length !== 0
                                ? users.capitalisation
                                    .map((i) => roundNumber(i.montant))
                                    .reduce((prev, next) => prev + next)
                                : 0) +
                                (users.realEstate.length !== 0
                                  ? users.realEstate
                                      .map((i) => roundNumber(i.montant))
                                      .reduce((prev, next) => prev + next)
                                  : 0)
                            )
                              .toLocaleString("en-EU")
                              .replaceAll(",", " ")}{" "}
                            €
                          </div>
                          
                        </li>
                      );
                    })}
                  </ul>
                  :
                   <p className="error_details_list">{texts[lang].detailNoInvestor}</p>
                   
                   }
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailsApportAffaireView;
