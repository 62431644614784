import React from 'react';
import IconInfosInfo from './icons/icon-infos-info';

const InformationTemplate = ({icon, classes, title, parag}) => {

  return (
    <div className="information-template">
      <div className="-inner">
        <div className={`-icon ${ classes ? classes : "" }`}>
          {icon}
        </div>
        <h3>{title}</h3>
        <p dangerouslySetInnerHTML={{__html: parag}} ></p>
      </div>
    </div>
  )

}


export default InformationTemplate