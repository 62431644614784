import Button from '../button';
import InputText from '../inputText';
import InputPassword from '../inputPassword';

const AuthForm = props => {

  const {
    email, password,
    texts, isLoading,
    handleEmailChange,
    handlePasswordChange,
    handleAuthFormSubmit,
    handleForgotPwd,
  } = props

  return (
    <form className="auth-form" onSubmit={handleAuthFormSubmit}>
      <h3 className="-title">{texts.identifiers}</h3>
      <InputText
        style={{
          marginBottom: 20,
        }}
        type={"email"}
        label={texts.mail}
        value={email.value}
        error={email.error}
        handleInputChange={handleEmailChange}
      />
      <InputPassword
        style={{
          marginBottom: 10,
        }}
        label={texts.password}
        value={password.value}
        error={password.error}
        handleInputChange={handlePasswordChange}
      />
      <div className="-forget">
        <button type="button" onClick={handleForgotPwd}>{texts.forgotPassword}</button>
      </div>
      <Button
        isLoading={isLoading}
        label={texts.buttonConnexion}
        classes="btn"
        icon={true}
      />
    </form>
  );

}

export default AuthForm;