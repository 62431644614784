export default () => (
  <svg width="55px" height="64px" viewBox="0 0 55 64" version="1.1">
    <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="10-Dashboard-paiement-en-attente" transform="translate(-427.000000, -439.000000)">
            <g id="Group-6" transform="translate(427.000000, 439.000000)">
                <g id="Group-Copy" transform="translate(0.000000, 0.325720)">
                    <ellipse id="Oval-Copy-27" fill="#FC5A5A" cx="27.0700637" cy="50.487497" rx="11.9426752" ry="4.37898089"></ellipse>
                    <ellipse id="Oval-Copy-48" stroke="#FFFFFF" cx="27.0700637" cy="51.2836754" rx="17.8121019" ry="6.26751592"></ellipse>
                    <ellipse id="Oval-Copy-49" strokeOpacity="0.331547237" stroke="#FFFFFF" cx="27.0700637" cy="52.8760321" rx="26.5700637" ry="9.4522293"></ellipse>
                    <g id="Group-10-Copy-2" transform="translate(9.458587, 0.000000)">
                        <g id="Group-5" fill="#FFFFFF">
                            <path d="M1.87297297,21.0412195 L2.3832973,21.145122 C5.46105405,21.7717073 7.8272027,24.3976829 8.13721622,27.5310976 L8.18355405,28.000122 L3.66068919,28.000122 C2.67494595,28.000122 1.87297297,27.1959756 1.87297297,26.207561 L1.87297297,21.0412195 Z M27.7899324,27.5310976 C28.0998243,24.3976829 30.465973,21.7717073 33.5437297,21.145122 L34.0540541,21.0412195 L34.0540541,26.207561 C34.0540541,27.1959756 33.2520811,28.000122 32.2663378,28.000122 L27.743473,28.000122 L27.7899324,27.5310976 Z M3.66056757,29.8778049 L32.265973,29.8778049 C34.284527,29.8778049 35.9266622,28.2312195 35.9266622,26.2073171 L35.9266622,0 L34.0538108,0 L34.0538108,19.1615854 L33.6884595,19.2140244 C29.4774324,19.8180488 26.2033784,23.3460976 25.9035811,27.6030488 L25.8757297,27.9997561 L10.0511757,27.9997561 L10.0232027,27.6030488 C9.72340541,23.3460976 6.44935135,19.8180488 2.23832432,19.2140244 L1.87297297,19.1615854 L1.87297297,0 L0,0 L0,26.2073171 C0,28.2312195 1.64213514,29.8778049 3.66056757,29.8778049 L3.66056757,29.8778049 Z" id="Fill-3"></path>
                        </g>
                        <path d="M11.155275,42.2628511 L12.639503,42.2628511 C12.7194551,42.2628511 12.7933502,42.2320787 12.8473245,42.1759191 C12.8648225,42.1579686 12.8782824,42.1398898 12.8891849,42.1201442 C12.9115285,42.0797554 12.9222964,42.0376998 12.9222964,41.9933363 L12.9222964,36.1159386 C12.9222964,35.4726675 13.4718659,34.9492805 14.1474205,34.9492805 L21.7812274,34.9492805 C22.456782,34.9492805 23.0063514,35.4726675 23.0063514,36.1159386 L23.0063514,41.9933363 C23.0063514,42.0383409 23.0169848,42.0803965 23.0381169,42.1184773 C23.0492887,42.1386076 23.0634216,42.1575839 23.0807849,42.175278 C23.1354323,42.2320787 23.2090582,42.2628511 23.2887411,42.2628511 L24.7735074,42.2628511 C25.2966954,42.2628511 25.7490841,42.5658309 25.9259478,43.0348534 C26.102946,43.5038758 25.9565018,44.0120049 25.5529727,44.3293451 L18.7439912,49.6824582 C18.5179987,49.8604252 18.2411277,49.9492805 17.9642566,49.9492805 C17.687251,49.9492805 17.4105146,49.8604252 17.1846567,49.6824582 L10.3754059,44.3293451 C9.97187681,44.0118767 9.82570182,43.5037476 10.0027001,43.0347251 C10.1796983,42.5658309 10.6322216,42.2628511 11.155275,42.2628511" id="Fill-8" fill="#0062FF"></path>
                        <path d="M24.3304685,37.814356 L24.3310673,35.9333445 C24.3310673,34.2880228 23.0149574,32.9492805 21.3970644,32.9492805 L14.605147,32.9492805 C12.9873737,32.9492805 11.6711441,34.2880228 11.6711441,35.9334663 L11.6706651,37.818741 L11.6700663,39.8886697 L11.3324764,39.9566361 C10.2340815,40.1784402 9.37603531,41.0242438 9.09329324,42.164081 C8.81114994,43.3017257 9.17784042,44.430357 10.0740885,45.182859 L16.1325051,50.2681553 C17.2144935,51.1763222 18.7855623,51.1763222 19.8676704,50.2681553 L25.925608,45.182859 C26.8219758,44.430357 27.1889058,43.3018475 26.9067625,42.1642028 C26.62426,41.0243656 25.7662138,40.1784402 24.6675794,39.9566361 L24.3301092,39.8886697 L24.3304685,37.814356 Z M11.9428687,41.7728481 L13.2634095,41.7728481 C13.3345441,41.7728481 13.4002897,41.7436153 13.4483116,41.6903871 C13.4638798,41.6732128 13.4758553,41.6560386 13.4855555,41.6372808 C13.5054348,41.5990345 13.5150152,41.5589612 13.5150152,41.516939 L13.5150152,35.9334663 C13.5150152,35.3223782 14.0040956,34.8252981 14.6050273,34.8252981 L21.3969446,34.8252981 C21.9979961,34.8252981 22.4869566,35.3223782 22.4869566,35.9334663 L22.4869566,41.516939 C22.4869566,41.5595702 22.4964173,41.5995217 22.5152189,41.6356974 C22.5251585,41.6549423 22.5377328,41.6728474 22.5531813,41.6897781 C22.6018019,41.7436153 22.667308,41.7728481 22.7382031,41.7728481 L24.0592229,41.7728481 C24.5247115,41.7728481 24.9272087,42.0607916 25.0845671,42.506227 C25.2420452,42.9517842 25.1117515,43.4344915 24.7527254,43.7359552 L18.694668,48.8212515 C18.493599,48.9903147 18.2472625,49.0748464 18.0009261,49.0748464 C17.7544698,49.0748464 17.5082531,48.9903147 17.3073038,48.8212515 L11.249007,43.7359552 C10.8899808,43.4343697 10.7599266,42.9516624 10.9175245,42.5061052 C11.0750026,42.0607916 11.4774999,41.7728481 11.9428687,41.7728481 L11.9428687,41.7728481 Z" id="Fill-6" fill="#FFFFFF"></path>
                    </g>
                </g>
                <g id="€" transform="translate(27.108599, 7.875000) rotate(-90.000000) translate(-27.108599, -7.875000) translate(19.233599, 0.399000)" fill="#FFFFFF" fillRule="nonzero">
                    <path d="M9.009,13.23 C10.059,13.23 10.9655,12.999 11.7285,12.537 C12.4915,12.075 13.076,11.417 13.482,10.563 L15.75,10.563 C15.218,11.963 14.357,13.0445 13.167,13.8075 C11.977,14.5705 10.591,14.952 9.009,14.952 C7.357,14.952 5.901,14.497 4.641,13.587 C3.381,12.677 2.513,11.459 2.037,9.933 L0,9.933 L0,8.589 L1.743,8.589 C1.701,8.225 1.68,7.861 1.68,7.497 C1.68,7.147 1.701,6.804 1.743,6.468 L0,6.468 L0,5.145 L2.016,5.145 C2.324,4.123 2.8175,3.2235 3.4965,2.4465 C4.1755,1.6695 4.9875,1.0675 5.9325,0.6405 C6.8775,0.2135 7.903,0 9.009,0 C10.591,0 11.977,0.385 13.167,1.155 C14.357,1.925 15.218,3.017 15.75,4.431 L13.482,4.431 C13.076,3.563 12.495,2.898 11.739,2.436 C10.983,1.974 10.073,1.743 9.009,1.743 C7.833,1.743 6.8075,2.044 5.9325,2.646 C5.0575,3.248 4.431,4.081 4.053,5.145 L9.009,5.145 L9.009,6.468 L3.717,6.468 C3.675,6.804 3.654,7.147 3.654,7.497 C3.654,7.903 3.682,8.267 3.738,8.589 L9.009,8.589 L9.009,9.933 L4.074,9.933 C4.48,10.969 5.1205,11.7775 5.9955,12.3585 C6.8705,12.9395 7.875,13.23 9.009,13.23 Z" id="Path"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
)