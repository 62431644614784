import React, { useContext, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormRow from '../../../components/formRow';
import InputSelect from '../../../components/inputSelect';
import InputText from '../../../components/inputText';
import { LoaderContext } from "../../../context/Context";
import texts from "../../../texts/apportAffaire";
import codes from "../../../constants/codes";
import countries from '../../../constants/countries';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import urls from '../../../constants/urls';
import Button from '../../../components/button';
import { setFeedback } from '../../../redux/store';
import FormRowCheckbox from '../../../components/formRowCheckbox';
import regexes from '../../../constants/regex';




const ContactPersonnalInfoView = () => {
  const dispatch = useDispatch();

  const { lang } = useSelector(state => state.persisedReducer.parameters)
  const { user, token } = useSelector(state => state.persisedReducer.user);
  const { id } = useParams()
  const prospectSource = [
    {
      id: texts[lang].aucun,
      label: texts[lang].aucun,
      value: texts[lang].aucun,
    },
    {
      id: texts[lang].formContactProspectSourceContent[0],
      label: texts[lang].formContactProspectSourceContent[0],
      value: texts[lang].formContactProspectSourceContent[0],
    },
    {
      id: texts[lang].formContactProspectSourceContent[1],
      label: texts[lang].formContactProspectSourceContent[1],
      value: texts[lang].formContactProspectSourceContent[1],
    },

  ]

  const prospectProvenance = [
    {
      id: texts[lang].aucun,
      label: texts[lang].aucun,
      value: texts[lang].aucun,
    },
    {
      id: texts[lang].formContactProspectProvenanceContent[0],
      label: texts[lang].formContactProspectProvenanceContent[0],
      value: texts[lang].formContactProspectProvenanceContent[0],
    },
    {
      id: texts[lang].formContactProspectProvenanceContent[1],
      label: texts[lang].formContactProspectProvenanceContent[1],
      value: texts[lang].formContactProspectProvenanceContent[1],
    },
    {
      id: texts[lang].formContactProspectProvenanceContent[2],
      label: texts[lang].formContactProspectProvenanceContent[2],
      value: texts[lang].formContactProspectProvenanceContent[2],
    },
    {
      id: texts[lang].formContactProspectProvenanceContent[3],
      label: texts[lang].formContactProspectProvenanceContent[3],
      value: texts[lang].formContactProspectProvenanceContent[3],
    },
    {
      id: texts[lang].formContactProspectProvenanceContent[4],
      label: texts[lang].formContactProspectProvenanceContent[4],
      value: texts[lang].formContactProspectProvenanceContent[4],
    },
    {
      id: texts[lang].formContactProspectProvenanceContent[5],
      label: texts[lang].formContactProspectProvenanceContent[5],
      value: texts[lang].formContactProspectProvenanceContent[5],
    },
  ]

  const [pseudo, setPseudo] = useState('');
  const [pseudoExist, setPseudoExist] = useState('');
  const [nom, setNom] = useState('');
  // const [nomExist, setNomExist] = useState('');
  const [prenom, setPrenom] = useState('');
  // const [prenomExist, setPrenomExist] = useState('');
  const [denomination, setDenomination] = useState('');
  // const [denominationExist, setDenominationExist] = useState('');
  const [email, setEmail] = useState('');
  // const [emailExist, setEmailExist] = useState('');
  const [indicateur, setIndicateur] = useState('+33')
  // const [indicateurExist, setIndicateurExist] = useState('')
  const [telephone, setTelephone] = useState('');
  // const [telephoneExist, setTelephoneExist] = useState('');
  const [pays, setPays] = useState('France');
  const [ville, setVille] = useState('');
  const [codePostal, setCodePostal] = useState('');
  const [adresse, setAdresse] = useState('');
  const [metier, setMetier] = useState('');
  const [prosSource, setProsSource] = useState('');
  const [prosProvenance, setProsProvenance] = useState('');
  const [premierContact, setPremierContact] = useState("non");
  const [demandeAccompagnement, setDemandeAccompagnement] = useState("");
  const [isPro, setIsPro] = useState(false);

  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState("");



  const headers = {
    headers: {
      "x-auth-token": token
    }
  }
  const loader = useContext(LoaderContext);
  useLayoutEffect(() => {
    console.log(id)
    axios.get(`${urls.apiUrl}/prospect/${id}`, headers).
      then((res) => {
        console.log("res Data here", res.data)
        setPseudo(res.data.pseudo)
        // console.log('pseudo :',res.data.pseudo)
        setNom(res.data.lastname)
        // console.log('nom :',res.data.lastname)
        setPrenom(res.data.firstname)
        // console.log('prenom :',res.data.firstname)
        setDenomination(res.data.denomination)
        setIsPro(res.data.isPro)
        // console.log('denomination :',res.data.denomination)
        setEmail(res.data.mail)
        // console.log('mail :',res.data.mail)
        setIndicateur(res.data.phoneIndicator)
        // console.log('indicateur :',res.data.phoneIndicator)
        setTelephone(res.data.phone)
        // console.log('phone :', res.data.phone)
        setPays(res.data.country)
        // console.log('pay :', res.data.country);
        setAdresse(res.data.address)
        // console.log('address :' ,res.data.address)
        setVille(res.data.city)
        // console.log('ville', res.data.city);
        setCodePostal(res.data.zipCode)
        // console.log(res.data.zipCode)
        setMetier(res.data.function)
        // console.log(res.data.function)
        setProsSource(res.data.source_prospect)
        // console.log(res.data.source_prospect);
        setProsProvenance(res.data.provenance_prospect)
        // console.log(res.data.provenance_prospect);
        setPremierContact(res.data.firstcontact)
        // console.log(res.data.firstcontact);
        setDemandeAccompagnement(res.data.accompaniement)
        // console.log(res.data.accompaniement);


      })


    loader.onSetIsLoading(false);
    return () => {
      loader.onSetIsLoading(false);
    }
  }, [])


  const handlePseudo = (e) => {
    setPseudo(e.target.value)
  }
  const handleNom = (e) => {
    setNom(e.target.value)
  }
  const handlePrenom = (e) => {
    setPrenom(e.target.value)
  }
  const handleEmail = (e) => {
    setEmail(e.target.value)
    if (e.target.value === "") {
      setEmailErrorText("")
    }
    else if ( !regexes.email.test(e.target.value) ) {setEmailErrorText(texts[lang].errorMail); }
    else {setEmailErrorText("");
  }
  }
  const handleIndicateur = (e) => {
    setIndicateur(e.target.value)
  }
  const handleTelephone = (e) => {
    setTelephone(e.target.value)
    if (e.target.value === "") {
      setPhoneErrorText("")
    }
    else if ( e.target.value.length <9 || e.target.value.length >10 ) {setPhoneErrorText(texts[lang].errorPhoneNum);}
    else {setPhoneErrorText("");

  }}
  const handlePays = (e) => {
    setPays(e.target.value)
  }
  const handleVille = (e) => {
    setVille(e.target.value)
  }
  const handleCodePostal = (e) => {
    setCodePostal(e.target.value)
  }
  const handleAdresse = (e) => {
    setAdresse(e.target.value)
  }
  const handleMetier = (e) => {
    setMetier(e.target.value)
  }
  const handleProsSource = (e) => {
    setProsSource(e.target.value)
    console.log(e.target.value)

  }
  const handleProsProvenance = (e) => {
    setProsProvenance(e.target.value)
    // console.log(e.target.value)

  }
  const handleCheckBoxchange = (event) => {
    setIsPro(event.target.checked);
  };
  const handleDenomination = (event) => {
    setDenomination(event.target.value);
  }
  console.log("i'm a pro", isPro)

  const onChangePremierContactValue = (e) => {
    setPremierContact(e.target.value)
    console.log(e.target.value)
  }
  const onChangeAssistanceValue = (e) => {
    setDemandeAccompagnement(e.target.value)
    console.log(e.target.value)
  }

  const HandleInfoPersoSubmit = (e) => {
    e.preventDefault();
    const body = {
      pseudo: pseudo,
      lastname: nom,
      firstname: prenom,
      isPro: isPro,
      mail: email,
      phoneIndicator: indicateur,
      phone: telephone,
      country: pays,
      city: ville,
      zipCode: codePostal,
      address: adresse,
      function: metier,
      source_prospect: prosSource,
      provenance_prospect: prosProvenance,
      firstcontact: premierContact,
      accompaniement: demandeAccompagnement
    }
    if (isPro === true) {
      body.denomination = denomination;
    }
    axios.patch(`${urls.apiUrl}/prospect/personalInfo/${id}`, body, headers)
    .then((response) => {
        dispatch(setFeedback({ show: true, type: 'success', title: `${texts[lang].confirmationMessageTitle}`, message: `${texts[lang].consirmationMessageValue}` }));
        console.log(response);
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 5000);
      })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 401) {
        console.log(error.response.data);
        dispatch(setFeedback({ show: true, type: 'error', title: `${texts[lang].inputError}`, message: `${error.response.data}` }));
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 5000);
      }else{
        dispatch(setFeedback({ show: true, type: 'error', title: `${texts[lang].errorRéseaux}`, message: `${texts[lang].errorRéseauxText}` }));
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 5000);
      }
      }
      )
  }




  return (
    <div className="layout-AA-container">
      <div className="-add-contact-container">
        {/* <div className="contact-info-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].infoPrealableContactForm}</h4>
            </div>
            <div className="-information">

            <InfosBar
              classes="-error"
              content={
                texts[lang].infoFormContactContentOne
              }
            />
            <InfosBar
              classes="-info"
              content={
                texts[lang].infoFormContactContentTwo
              }
            />
            </div>
          </div> */}

        <form onSubmit={HandleInfoPersoSubmit}>
          <div className="contact-info-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].infoFormONeContact}</h4>
            </div>

            <div className='contact-scontainer'>

              <FormRow classes={"-one"}>
                <InputText
                  required={false}

                  classes={"-two"}
                  label={texts[lang].formContactPseudo}
                  value={pseudo}
                  handleInputChange={(e) => handlePseudo(e)}
                  max={50}
                />
              </FormRow>
              {/* C'est un Particulier */}


              <FormRow classes={"-two"}>
                <InputText
                  required={false}
                  classes={"-two"}
                  label={texts[lang].formContactName}
                  value={nom}
                  handleInputChange={(e) => handleNom(e)}
                  max={100}

                />
                <InputText
                  required={false}
                  classes={"-two"}
                  label={texts[lang].formContactPrenom}
                  value={prenom}
                  handleInputChange={(e) => handlePrenom(e)}
                  max={100}
                />

              </FormRow>
              <div className="-infp-checkbox">
                <FormRowCheckbox
                  classes={"-add-status"}
                  label={texts[lang].formOneContactStatus}
                  handleCheckboxChange={(e) => handleCheckBoxchange(e)}
                  isActive={isPro} />
                {isPro &&
                  <InputText
                    required={false}
                    label={texts[lang].formContactDenominationOne}
                    value={denomination}
                    handleInputChange={(e) => handleDenomination(e)}
                    max={200}
                  />}
              </div>

              <FormRow classes={"-one"}>
                <InputText
                  classes={"-two"}
                  required={false}
                  label={texts[lang].formContactEmail}
                  value={email}
                  error={emailErrorText}
                  handleInputChange={(e) => { handleEmail(e) }}
                  max={50}
                />
              </FormRow>
              <FormRow classes={"-two"}>
                <InputSelect
                  required={false}
                  label={texts[lang].formContactIndicatif}
                  value={indicateur}
                  inputs={codes.map((i) => ({
                    id: `+${i.code}`,
                    label: `+${i.code} - (${i.label})`
                  }))}
                  handleSelectChange={(e) => handleIndicateur(e)}

                />
                <InputText
                  required={false}
                  classes={"-two"}
                  type="number"
                  label={texts[lang].formContactPhoneOne}
                  value={telephone}
                  error={phoneErrorText}
                  handleInputChange={(e) => { handleTelephone(e) }}
                />

              </FormRow>
              <FormRow classes={"-one"}>

                <InputText
                  required={false}
                  classes={"-two"}
                  label={texts[lang].formContactAdresse}
                  value={adresse}
                  handleInputChange={(e) => { handleAdresse(e) }}
                />

              </FormRow>
              <FormRow classes={"-two"}>
                <InputText
                  required={false}
                  classes={"-two"}
                  label={texts[lang].formContactCodePostal}
                  value={codePostal}
                  handleInputChange={(e) => { handleCodePostal(e) }}
                />
                <InputText
                  required={false}
                  classes={"-two"}
                  label={texts[lang].formContactCity}
                  value={ville}
                  handleInputChange={(e) => { handleVille(e) }}
                />

              </FormRow>
              <FormRow classes={"-one"}>
                <InputSelect
                  required={false}
                  label={texts[lang].formContactCountry}
                  value={pays}
                  inputs={countries.map((i) => (
                    {
                      id: i.label,
                      label: i.label,
                    }))}
                  handleSelectChange={(e) => { handlePays(e) }}
                />
              </FormRow>
              <FormRow classes={"-one"}>
                <InputText
                  classes={"-two"}
                  required={false}
                  label={texts[lang].formContactProfession}
                  value={metier}
                  handleInputChange={(e) => { handleMetier(e) }}
                />


              </FormRow>
              <FormRow classes={"-two"}>
                <InputSelect
                  required={false}
                  label={texts[lang].formContactProspectSource}
                  inputs={prospectSource.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={prosSource}
                  handleSelectChange={(e) => {
                    handleProsSource(e);
                  }}
                />
                <InputSelect
                  required={false}
                  label={texts[lang].formContactProspectProvenance}
                  inputs={prospectProvenance.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={prosProvenance}
                  handleSelectChange={(e) => {
                    handleProsProvenance(e);
                  }}
                />

              </FormRow>



              <FormRow classes={"-one"}>

                <div onChange={onChangePremierContactValue}>
                  <label>{texts[lang].firstContact}</label>

                  <div className='radio-container'>
                    <div className='radio-input'>
                      <input id="rad8" type="radio" value={`${texts[lang].firstContactContent[0]}`} name="premierContact" checked={premierContact === `${texts[lang].firstContactContent[0]}`} /> <label for="rad8" >{texts[lang].firstContactContent[0]}</label>

                    </div>
                    <div className='radio-input'>
                      <input id="rad9" type="radio" value={`${texts[lang].firstContactContent[1]}`} name="premierContact" checked={premierContact === `${texts[lang].firstContactContent[1]}`} /> <label for="rad9" >{texts[lang].firstContactContent[1]}</label>

                    </div>
                  </div>
                </div>
              </FormRow>

              <FormRow classes={"-one"}>


                <div onChange={onChangeAssistanceValue} >
                  <label>{texts[lang].demandAssistance}</label>
                  <div className='radio-container'>
                    <div className='radio-input'>
                      <input id="rad10" type="radio" value={`${texts[lang].demandAssistanceContent[0]}`} name="demandeAccompagnement" checked={demandeAccompagnement === `${texts[lang].demandAssistanceContent[0]}`} /> <label for="rad10"> {texts[lang].firstContactContent[0]}</label>
                    </div>
                    <div className='radio-input'>        
                       <input id="rad11" type="radio" value={`${texts[lang].demandAssistanceContent[1]}`} name="demandeAccompagnement" checked={demandeAccompagnement === `${texts[lang].demandAssistanceContent[1]}`} /> <label for="rad11"> {texts[lang].firstContactContent[1]} </label>
                    </div>
                  </div>
                </div>
              </FormRow>


            </div>

          </div>
          <div className="-add-contact-button">
            <Button
              classes={"-add-contact"}
              label={"Confirmer les modifications"}
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactPersonnalInfoView;