export default {
  email: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/,
  // password: /^[a-zA-Z0-9$%]{3,}$/,
  password: /^.{}$/, //? any character, whit a min of 5 char and a max of 250 char (in case of very long pass)

  code2af: /^[0-9]{6}$/,
  default: /^.{}$/,
  defaults: /^.{2,}$/,
  day: /^[0-9]{1,2}$/,
  month: /^[0-9]{1,2}$/,
  year: /^[0-9]{4}$/,
  phone: /^\+?[0-9]{9,15}$/,
  postalCode: /^[a-zA-Z0-9-]{}$/,
  postalCodes: /^[a-zA-Z0-9-]{2,10}$/,
  siret: /^[a-zA-Z0-9-]{}$/,

  pseudo: /^(\w{4,20})$/,
};
