export default {
  fr: {
    labelTitleNotification:"Notifications",
    labelTitleCptInvest:"Mes comptes d'investissements",
    labelAffaires:"Mes parrainages",
    labelBtnInvest:"Investir dans Vizio Real Estate ou Vizio Capitalisation",
    labelCardRealInvestHeader:"Vizio Real Estate",
    labelCardRealInvestText:"Mon investissement dans Vizio Real Estate",
    
    labelCardCapitalInvestHeader:"Vizio Capitalisation",
    labelCardCapitalInvestText:"Mon investissement dans Vizio Capitalisation",
    labelCardInvestBtn:"Gérer mon compte",


    labelCardAffiliateHeader:"Mon lien d'affiliation",
    labelCardAffiliateText:"Partagez le lien ci-dessous si vous souhaitez inviter une personne à nos produits et services et percevoir une indemnité pouvant aller jusqu’à 5% de marge !",
    
    labelCardAffaireHeader:"Total indemnités",
    labelCardAffaireText:"Généres sur l’ensemble de mon montant levé dans",
    labelCardAffaireBtn:"Voir le détail",

    labelCardCommHeader:"Mes indemnités disponibles",
    labelCardCommText:"Générés grâce à mon montant levé dans",
    labelCardCommBtn:"Retirer",

     //dashboard sous page: Banque
     //*lien https://app.viziocapital.com/dashboard/banque
     banqueInfo: "Information préalable ",
     banqueInfoText: [
      "Merci d'avoir ajouté votre rib. Pour changer vos coordonnées bancaires, merci de contacter Vizio capital.",
      "Avant d’éffectuer une demande de retrait, merci de spécifier les informations de votre compte bancaire."
     ],
     ribComptePerso:"Renseigner le RIB de mon compte personnel",
     ribCompteAA:"Renseigner mon RIB secondaire pour l' apport d'affaires",
     ribInfoBarText:"Merci de renseigner votre rib afin que Vizio puisse procéder au paiement de vos commissions lorsque vous en ferez la demande. Attention, ce rib ne sera pas modifiable une fois qu’il sera ajouté ! Pour tout changement, contactez l’équipe Vizio.",
     iban:"Renseigner mon IBAN",
     errorIban:"Veuillez renseigner un IBAN valide",
     ibanInvest:"IBAN compte investissement",
     bic:"Renseigner mon BIC",
      errorBic:"Veuillez renseigner un BIC valide",
     bicInvest:"BIC compte investissement ",
     rib:"Renseigner mon RIB",
     ribComptG:"RIB compte général",
     ribComptAA:"RIB compte business",
     addRib:"Ajouter mon RIB",
     banqueInfoChoices:[
       "Je certifie que les informations renseignées sont exactes, sincères et fournies conformément aux Conditions Générales d’Utilisation de la Plateforme.",
       "Je confirme mon accord pour que ces données soient utilisées conformément aux Conditions Générales d’Utilisation de la Plateforme et à la Politique de respect de la vie privée, pour les besoins des services qui me sont prestés."
     ],
     renseignerRib:"Renseigner mon RIB",
     requiredFormats:"formats acceptés : pdf, jpg, png",
     telecharger:"Telecharger mon",
     uploadRIB:"Charger mon RIB",
     ajoutRIB:"Ajouter un RIB pour mes indemnités d’apport d’affaires",
       
     //dashboard sous page: Fiscalité
     //*lien https://app.viziocapital.com/dashboard/fiscalite
    fiscaliteTitle:"Formulaires fiscaux",
    fiscaliteContent :"Vous pouvez téléverser en cliquant ici les formulaires fiscaux que vous souhaitez joindre à votre compte d’utilisateur. En fonction des dispositions légales et conventionnelles applicables, ces formulaires peuvent donner droit à de s réductions ou exonérations d’impôts belges."
  
  },
  en: {
    labelTitleNotification:"Notifications",
    labelTitleCptInvest:"Mes comptes d'investissements",
    labelAffaires:"Mes parrainages",
    labelBtnInvest:"Investir dans Vizio Real Estate ou Vizio Capitalisation",
    labelCardRealInvestHeader:"Vizio Real Estate",
    labelCardRealInvestText:"Mon investissement dans Vizio Real Estate",
    
    labelCardCapitalInvestHeader:"Vizio Capitalisation",
    labelCardCapitalInvestText:"Mon investissement dans Vizio Capitalisation",
    labelCardInvestBtn:"Gérer mon compte",


    labelCardAffiliateHeader:"Mon lien d'affiliation",
    labelCardAffiliateText:"Partagez le lien ci-dessous si vous souhaitez inviter une personne à nos produits et services et percevoir une indemnité pouvant aller jusqu’à 5% de marge !",
    
    labelCardAffaireHeader:"Total indemnités",
    labelCardAffaireText:"Généres sur l’ensemble de mon montant levé dans",
    labelCardAffaireBtn:"Voir le détail",

    labelCardCommHeader:"Mes indemnités disponibles",
    labelCardCommText:"Générés grâce à mon montant levé dans",
    labelCardCommBtn:"Retirer",

     //dashboard sous page: Banque
     //*lien https://app.viziocapital.com/dashboard/banque
     banqueInfo: "Information préalable ",
     banqueInfoText: [
      "Merci d'avoir ajouté votre rib. Pour changer vos coordonnées bancaires, merci de contacter Vizio capital",
      "Avant d’éffectuer une demande de retrait, merci de spécifier les informations de votre compte bancaire."
     ],
     ribComptePerso:"Renseigner le RIB de mon compte personnel",
     ribCompteAA:"Renseigner mon RIB secondaire pour l' apport d'affaires",
     ribInfoBarText:"Merci de renseigner votre rib afin que Vizio puisse procéder au paiement de vos commissions lorsque vous en ferez la demande. Attention, ce rib ne sera pas modifiable une fois qu’il sera ajouté ! Pour tout changement, contactez l’équipe Vizio.",
     iban:"Renseigner mon IBAN",
     errorIban:"Veuillez renseigner un IBAN valide",
     ibanInvest:"IBAN compte investissement",
     bic:"Renseigner mon BIC",
      errorBic:"Veuillez renseigner un BIC valide",
     bicInvest:"BIC compte investissement ",
     rib:"Renseigner mon RIB",
     ribComptG:"RIB compte général",
     ribComptAA:"RIB compte business",
     addRib:"Ajouter mon RIB",
     banqueInfoChoices:[
       "Je certifie que les informations renseignées sont exactes, sincères et fournies conformément aux Conditions Générales d’Utilisation de la Plateforme.",
       "Je confirme mon accord pour que ces données soient utilisées conformément aux Conditions Générales d’Utilisation de la Plateforme et à la Politique de respect de la vie privée, pour les besoins des services qui me sont prestés."
     ],
     renseignerRib:"Renseigner mon RIB",
     requiredFormats:"formats acceptés : pdf, jpg, png",
     telecharger:"Telecharger mon",
     uploadRIB:"Charger mon RIB",
     ajoutRIB:"Ajouter un RIB pour mes indemnités d’apport d’affaires",
       
     //dashboard sous page: Fiscalité
     //*lien https://app.viziocapital.com/dashboard/fiscalite
    fiscaliteTitle:"Formulaires fiscaux",
    fiscaliteContent :"Vous pouvez téléverser en cliquant ici les formulaires fiscaux que vous souhaitez joindre à votre compte d’utilisateur. En fonction des dispositions légales et conventionnelles applicables, ces formulaires peuvent donner droit à de s réductions ou exonérations d’impôts belges"
  
  },
}