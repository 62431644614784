import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { LoaderContext } from "../../context/Context";
import { useContext } from 'react';
import { useLayoutEffect } from 'react';
import axios from 'axios';
import InfosBar from '../../components/infosBar';
import texts from "../../texts/apportAffaire"
import urls from '../../constants/urls';
import regexes from '../../constants/regex';
import { editRegex } from '../../helpers/global';
import FormSectionTitle from '../../components/formSectionTitle';
import Button from '../../components/button';
import FormRow from '../../components/formRow';
import InputText from '../../components/inputText';
import FormRowRadio from '../../components/formRowRadio';
import FormRowCheckbox from '../../components/formRowCheckbox';
import { Link, useNavigate } from 'react-router-dom';
import InputSelect from '../../components/inputSelect';
import codes from '../../constants/codes';
import { editField, setFeedback } from '../../redux/store';



const AddContact = () => {
  const dispatch = useDispatch();

  const { lang } = useSelector(state => state.persisedReducer.parameters)
  const { user, token } = useSelector(state => state.persisedReducer.user);


  const headers = {
    headers: {
      "x-auth-token": token
    }
  }
  const navigate = useNavigate();
  const [pseudo, setPseudo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [denomination, setDenomination] = useState("");
  const [isPro, setPro] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [telNum, setTelNum] = useState('');
  const [numCode, setNumCode] = useState("");
  const [isRequired, setIsRequired] = useState(true);

  const [pseudoErrorText, setPseudoErrorText] = useState("");
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [denominationErrorText, setDenominationErrorText] = useState("");
  const [telNumErrorText, setTelNumErrorText] = useState("");


  const [errorFirstName, setErrorFirstName] = useState(true);
  const [errorLastName, setErrorLastName] = useState(true);
  const [errorEmail, setErrorEmail] = useState(true);
  const [errorPhone, setErrorPhone] = useState(true);
  const [errorDenomination, setErrorDenomination] = useState(true);


  const loader = useContext(LoaderContext);
  useLayoutEffect(() => {
    loader.onSetIsLoading(false);
    return () => {
      loader.onSetIsLoading(false);
    }
  }, [])


  const handlePseudo = (event) => {
    setPseudo(event.target.value);
    if (lastName !== "" || firstName !== "" || event.target.value !== "" || email !== "" || telNum !== "") {
      setBtnDisabled(false);
    }
    else if (lastName === "" || firstName === "" || event.target.value === "" || email === "" || telNum === "") {
      setBtnDisabled(true);
    }
  };

  const handleLastName = (event) => {
    setLastName(event.target.value);
    if (event.target.value !== "" || firstName !== "" || pseudo !== "" || email !== "" || telNum !== "") {
      setBtnDisabled(false);
      setIsRequired(false);
    }
    else if (event.target.value === "" || firstName === "" || pseudo === "" || email === "" || telNum === "") {
      setBtnDisabled(true);
      setIsRequired(true);
    }
    // if (event.target.value.length === 0 ) {
    //   setLastNameErrorText(texts[lang].errorEmpryFieldOne);
    //   setErrorLastName(true);
    // }
    // else {
    // setLastNameErrorText("");
    // setErrorLastName(false);}

    // if (lastName==="" || errorLastName===true || firstName==="" || errorFirstName===true || email==="" || errorEmail===true || telNum==="" || errorPhone===true) {
    //   setBtnDisabled(true);
    // }else {
    //   setBtnDisabled(false);
    // }

  };

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
    if (lastName !== "" || event.target.value !== "" || pseudo !== "" || email !== "" || telNum !== "") {
      setBtnDisabled(false);
      setIsRequired(false);
    }
    else if (lastName === "" || event.target.value === "" || pseudo === "" || email === "" || telNum === "") {
      setBtnDisabled(true);
      setIsRequired(true);
    }
    // if (event.target.value.length === 0 ) {setFirstNameErrorText(texts[lang].errorEmpryFieldOne); setErrorFirstName(true)}
    // else {setFirstNameErrorText(""); setErrorFirstName(false)}

    // if (firstName==="" || errorFirstName===true || lastName==="" || errorLastName===true || email==="" || errorEmail===true || telNum==="" || errorPhone===true) {
    //   setBtnDisabled(true);
    // }else {
    //   setBtnDisabled(false);
    // }

  };


  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (lastName !== "" || firstName !== "" || pseudo !== "" || event.target.value !== "" || telNum !== "") {
      setBtnDisabled(false);
      setIsRequired(false);
    }
    else if (lastName === "" || firstName === "" || pseudo === "" || event.target.value === "" || telNum === "") {
      setBtnDisabled(true);
      setIsRequired(true);
    }

    // if (event.target.value.length === 0 ) {setEmailErrorText(texts[lang].errorEmpryField); setErrorEmail(true)}
    // else if ( !regexes.email.test(event.target.value) ) {setEmailErrorText(texts[lang].errorMail); setErrorEmail(true)}
    // else {setEmailErrorText(""); setErrorEmail(false)}

    // if (email==="" || errorEmail===true || lastName==="" || errorLastName===true || firstName==="" || errorFirstName===true || telNum==="" || errorPhone===true) {
    //   setBtnDisabled(true);
    // }else {
    //   setBtnDisabled(false);
    // }

  };
  const handleDenomination = (event) => {
    setDenomination(event.target.value);

    // if (denomination==="" || denomination.length<3 || pseudo ==="" || pseudo.length<3 || lastName==="" || lastName.length<3 || firstName==="" || firstName.length<3 || email==="" || email.length<3  && isPro===true ) {
    //   setBtnDisabled(true);
    // }else{
    //   setBtnDisabled(false);
    // }

  };
  const handleTelNum = (event) => {
    setTelNum(event.target.value);
    if (lastName !== "" || firstName !== "" || pseudo !== "" || email !== "" || event.target.value !== "") {
      setBtnDisabled(false);
      setIsRequired(false);
    }
    else if (lastName === "" || firstName === "" || pseudo === "" || email === "" || event.target.value === "") {
      setBtnDisabled(true);
      setIsRequired(true);
    }

    // if (event.target.value.length === 0 ) {setTelNumErrorText(texts[lang].errorEmpryFieldOne) ; setErrorPhone(true)}
    // else if ( event.target.value.length <8 || event.target.value.length >15 ) {setTelNumErrorText(texts[lang].errorPhoneNum); setErrorPhone(true)}
    // else {setTelNumErrorText(""); setErrorPhone(false)}

    // if (telNum==="" || errorPhone ===true || lastName==="" || errorLastName===true || firstName==="" || errorFirstName ===true || email==="" || errorEmail ===true) {
    //   setBtnDisabled(true);
    // }else {
    //   setBtnDisabled(false);
    // }

  };

  const handleNumCode = (event) => {
    setNumCode(event.target.value);
  };

  const handleCheckBoxchange = (event) => {
    setPro(event.target.checked);
    if (isPro === true) {
      setDenomination("");
    }
  };


  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("form submitted");

    var headers = {
      headers: {
        "x-auth-token": token,
      }
    }
    //setting des headers : 
    headers = { ...headers, "x-auth-token": token }

    const body = {
      pseudo: pseudo ? pseudo : "",
      firstname: firstName ? firstName : "",
      lastname: lastName ? lastName : "",
      isPro: isPro,
      // mail: (email) ? (email) : "",
      // phoneIndicator: numCode ? numCode : "",
      // phone: telNum ? telNum : "",
    }
    if (isPro === true) {
      body.denomination = denomination ? denomination : "";
    }
    console.log(body);
    axios.post(`${urls.apiUrl}/prospect`, body, { headers })
      .then((response) => {
        dispatch(setFeedback({ show: true,  title: `${texts[lang].addmessageTitle}`, message: `${texts[lang].addMessageValue}`}));
        console.log(response);
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 2000);
        navigate("/list-contact");
      }
      )
      .catch((error) => {
        console.log(error);
        dispatch(setFeedback({show: true, type: 'error', title: `${texts[lang].errorMessage}`, message: `${texts[lang].errorMessage}`}));
        setTimeout(() => {
          dispatch(setFeedback({ show: false }));
        }, 2000);
      }
      )
  };


  return (
    <>
      <div className="layout-AA-container">
        <div className="-add-contact-container">
          <div className="contact-info-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].infop}</h4>
            </div>
            <div className="-information">

              <InfosBar
                classes="-error"
                content={
                  texts[lang].infoPrealableContactFormOneContent[0]
                }
              />
              <InfosBar
                classes="-info"
                content={
                  texts[lang].infoPrealableContactFormOneContent[1]
                }

              />
            </div>
          </div>
          <form onSubmit={handleFormSubmit} >


            <div className="contact-info-container -apporAf">
              <div className="-title">
                <h4 className="-texte">{texts[lang].infoFormONeContact}</h4>
              </div>

              <div className='contact-scontainer'>

                <FormRow classes={"-one"}>
                  <InputText
                    required={false}
                    classes={"-two"}
                    label={texts[lang].formContactPseudoOne}
                    value={pseudo}
                    handleInputChange={(e) => handlePseudo(e)}
                  />
                 

                </FormRow>
                <FormRow classes={"-two"}>
                <InputText
                    required={false}
                    classes={"-two"}
                    label={texts[lang].formContactLastNameOne}
                    value={lastName}
                    error={lastNameErrorText}
                    handleInputChange={(e) => handleLastName(e)}
                  />

                  <InputText
                    required={false}
                    classes={"-two"}
                    label={texts[lang].formContactFirstNameOne}
                    value={firstName}
                    error={firstNameErrorText}
                    handleInputChange={(e) => handleFirstName(e)}
                  />
                </FormRow>







                {/* <FormRow classes={"-two"}>
                  <InputText
                    required={isRequired}
                    classes={"-two"}
                    label={texts[lang].formContactFirstNameOne}
                    value={firstName}
                    error={firstNameErrorText}
                    handleInputChange={(e) => handleFirstName(e)}
                  />
                  <InputText
                    required={isRequired}
                    classes={"-two"}
                    label={texts[lang].formContactEmailOne}
                    value={email}
                    error={emailErrorText}
                    handleInputChange={(e) => handleEmailChange(e)}
                  />

                </FormRow> */}
                {/* <FormRow classes={"-two"}>
                  <InputSelect
                    required={isRequired}
                    label={texts[lang].formContactIndicatif}
                    value={numCode}
                    inputs={codes.map((i) => ({
                      id: `+${i.code}`,
                      label: `+${i.code} - (${i.label})`
                    }))}
                    handleSelectChange={(e) => handleNumCode(e)}

                  />
                  <InputText
                    required={isRequired}
                    classes={"-two"}
                    type="number"
                    label={texts[lang].formContactPhoneOne}
                    value={telNum}
                    error={telNumErrorText}
                    handleInputChange={(e) => handleTelNum(e)}
                  />

                </FormRow> */}
                <div className="-checkbox">

                  <FormRowCheckbox
                    classes={"-add-status"}
                    label={texts[lang].formOneContactStatus}
                    handleCheckboxChange={(e) => handleCheckBoxchange(e)}
                    isActive={isPro} />
                  {isPro &&
                    <InputText
                      required={false}
                      classes={"-two"}
                      label={texts[lang].formContactDenominationOne}
                      value={denomination}
                      handleInputChange={(e) => handleDenomination(e)}
                    />}
                </div>

              </div>


            </div>
            <div className="-add-contact-button">
              <Button
                classes={"-add-contact"}
                label={texts[lang].FormContactSubmitButtonONe}
                type="submit"
                disable={btnDisabled}
              />
            </div>
          </form>
        </div>
      </div>

    </>
  );
};

export default AddContact;