
export default () => (

<svg width="47px" height="47px" viewBox="0 0 47 47" version="1.1">
    <title>Group</title>
    <g id="Sprint-2-DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Vue-Notifications" transform="translate(-298.000000, -277.000000)" fill="#3DD598">
            <g id="Notif" transform="translate(278.000000, 232.000000)">
                <g id="Group" transform="translate(20.000000, 45.000000)">
                    <path d="M16.3485886,20.6340049 C15.8907,20.1806816 15.1520168,20.1843826 14.6986935,20.6422712 C14.2453702,21.1001598 14.2490712,21.838843 14.7069598,22.2921663 L21.5031979,29.0206429 C21.9830056,29.4956668 22.7646325,29.4654649 23.2063548,28.9548333 L32.7434501,17.9299378 C33.1649931,17.4426335 33.111683,16.7058672 32.6243787,16.2843242 C32.1370744,15.8627812 31.4003081,15.9160912 30.9787651,16.4033955 L22.2580538,26.4845501 L16.3485886,20.6340049 Z" id="Shape"></path>
                    <circle id="Oval-Copy-5" opacity="0.1" cx="23.5" cy="23.5" r="23.5"></circle>
                </g>
            </g>
        </g>
    </g>
</svg>
    )
