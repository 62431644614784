import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

const FeedBackNotificationLayout = (props) => {

  const {feedback} = useSelector(state => state.persisedReducer.parameters);

  const getClasse = (type) => {
    if ( type === "error" ) return "-error";
    else if ( type === "warning" ) return "-warning";
    else return "";
  }
  useEffect(()=>{
    setTimeout(()=>{
      props.setFeedback({
        show:false,
        type:"",
        title:"",
        message:"",
        messages:[],
      })
    },10000)
  },[])
  return (
    <>
      <Outlet />
      <div className={`feedback-container ${feedback.show ? "-active" : ""} ${getClasse(feedback.type)}`}>
        {feedback.title && <h2>{feedback.title}</h2>}
        {
          feedback.message.length > 0 && <p>{feedback.message}</p>
        }
        {
          feedback.messages.length > 0 &&
          <ul>
            {
              feedback.messages.map( (message, index) => <li key={index}>{message}</li> )
            }
          </ul>
        }
      </div>
    </>

  )

  // if ( !user || !token ) return <Navigate to="/" replace />;
  // return <Outlet />

}

export default FeedBackNotificationLayout;