import FormSectionTitle from '../../components/formSectionTitle';
import IconFileDownload from '../../components/icons/icon-file-download'
import { LoaderContext } from "../../context/Context";
import { useLayoutEffect, useContext } from 'react';

const paymentProofView = ()=>{
  //! le loader throw une erre. siginfiant que ce composant a un pb ... 
  // const loader = useContext(LoaderContext);
  // useLayoutEffect(()=>{
  //   loader.onSetIsLoading(false);
  //   return()=>{
  //     loader.onSetIsLoading(true);
  //   }
  // },[])
    return(
        <div className="dashboard-container">
          <div className="dashboard-scontainer -no-padding">
            <FormSectionTitle
                    title="Information"
                    classes="-simple-2"
                  />
      
           <div className="security-form-container">
                <div className="_payment_container">
                    <div className="_payment_header">
                      <span>DATE</span>
                      <span>MONTANT DEPOSE</span>
                      <span>INFORMATION</span>
                    </div>
                    
                  <ul className="_payment_list">
                         <li  className="_payment">
                            <div data-label ="DATE" > 25/05/2022 </div>
                            <div data-label ="MONTANT" > 1000€ </div>
                            <div data-label ="INFORMATION" >Fichier commision</div>
                            <div data-label ="INFORMATION" >  < IconFileDownload /></div>
                        </li>
                  </ul>
                </div>
           </div>
          </div>
        </div>
      )

}
export default paymentProofView