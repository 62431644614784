export default {
  fr: {
    // transactions financières
    //* Lien https://app.viziocapital.com/financial-transactions
    labelTitle: "Toutes vos transactions financières",
    labelOperation: "TYPE D'OPERATION",
    labelDate: "DATE",
    labelMontant: "MONTANT",
    labelFond:"FOND",
    labelStatut: "STATUT",
    labelInformation: "INFORMATION",


    labelDataOperation: "TYPE D'OPERATION :",
    labelRetrait: "Retrait de compte",
    labelDepot: "Dépôt",
    labelDataDate: "DATE :",
    labelDataMontant: "MONTANT :",
    labelDataFond:"FOND :",
    labelDataStatut: "STATUT :",
    labelDataInformation: "INFORMATION :",

    StatusOk: "Validé",
    StatusWait: "En cours",
    StatusRefused: "Refusé",

    labelRetraitOk: "Votre retrait a été validé",
    labelRetraitSend: "Votre demande  de retrait a été envoyé",

    labelPayOk: "Votre paiement a été validé",
    labelPaySend: "Votre paiement a bien  été pris en compte",
    labelPayRefused: "Votre paiement a été refusé",

    labelRetraitRefused: "votre retrait a été refusé"
  },
  en: {
    // transactions financières
    //* Lien https://app.viziocapital.com/financial-transactions
    labelTitle: "Toutes vos transactions financières",
    labelOperation: "TYPE D'OPERATION",
    labelDate: "DATE",
    labelMontant: "MONTANT",
    labelStatut: "STATUT",
    labelInformation: "INFORMATION",


    labelDataOperation: "TYPE D'OPERATION :",
    labelRetrait: "Retrait de compte",
    labelDepot: "Dépôt",
    labelDataDate: "DATE :",
    labelDataMontant: "MONTANT :",
    labelDataStatut: "STATUT :",
    labelDataInformation: "INFORMATION :",

    StatusOk: "Validé",
    StatusWait: "En cours",
    StatusRefused: "Refusé",

    labelRetraitOk: "Votre retrait a été validé",
    labelRetraitSend: "Votre demande  de retrait a été envoyé",

    labelPayOk: "Votre paiement a été validé",
    labelPaySend: "Votre paiement a bien  été pris en compte",
    labelPayRefused: "Votre paiement a été refusé",

    labelRetraitRefused: "votre retrait a été refusé"
  }
}