import React ,{useState,useEffect} from 'react';
import {useSelector} from 'react-redux';
import { useNavigate } from "react-router-dom";
import IconFileDownload from '../../components/icons/icon-file-download'

import urls from '../../constants/urls';
import texts from '../../texts/dashboard';
import axios from 'axios';
import InputText from '../../components/inputText';
import Button from '../../components/button';
import InfosBar from '../../components/infosBar';
import FormRowCheckbox from '../../components/formRowCheckbox';
import { LoaderContext } from "../../context/Context";
import { useLayoutEffect, useContext } from 'react';

const BanqueView = () => {
  const loader = useContext(LoaderContext);
  const { user,token} = useSelector(state => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)
  const [showForm, setShowForm] = useState(false);
  let navigate = useNavigate()

  const showFormulaire = () => {
    setShowForm(!showForm)

  }
  const styleCheckBox = {
    marginBottom: 20,
    marginTop: 20
  }

  useLayoutEffect(() => {
    loader.onSetIsLoading(false);
    return()=>{
      loader.onSetIsLoading(true);
    }
  },[])

  const getDoc = (name)=>{
      
    if (name){
          axios({
            url: `${urls.apiUrl}/users/doc/${user.id}/${name}`,
            method: 'GET',
            responseType: 'blob',
            headers: {
              "x-auth-token": token
            }
          })
          .then((res)=>{ // On crée un element html qui va telecharger le document
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', name); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((err)=>{
            console.log(err)
          })
        }
  }
    

    // fonction et constante formulaire compte personnel

    const userDocRibGeneral =  user.doc.find(x=>x.docType ==="RIB compte général") ? user.doc.find(x=>x.docType ==="RIB compte général") : ""

    const [inputCheckCP, setInputCheckCP] = useState([
        {
          id: 0,
          label: texts[lang].banqueInfoChoices[0],
          value: false, 
        },
        {
          id:1,
          label: texts[lang].banqueInfoChoices[1] ,
          value:false,
        }
      ])
      const [ibanPerso, setIbanPerso] = useState(null);
      const [bicPerso, setBicPerso] = useState(null);
      const [cpButtonDisable, setCpButtonDisable] = useState(true)
      const [fileCi,setFileCi] = useState(null)
      const [errorBicEmpty, setErrorBicEmpty] = useState(false);
      const [errorIbanEmpty, setErrorIbanEmpty] = useState(false);

      
      const handleIbanChange = (e) => {
        if(e.target.value !== "")setErrorIbanEmpty(false);
        else setErrorIbanEmpty(true);
        setIbanPerso(e.target.value) 
     
    }
      const handleBicChange = (e) => {
        if(e.target.value !== "")setErrorBicEmpty(false);
        else setErrorBicEmpty(true);
        setBicPerso(e.target.value)
       
    }

      const handleInputCheckCi = (e,id)=>{
          const newInputCheckCP = inputCheckCP
          var total = 0
          newInputCheckCP[id].value = e.target.checked
          newInputCheckCP.map(check=>{
            if(check.value === true) total ++
          })
          setInputCheckCP([...newInputCheckCP])
          if(total === 2) setCpButtonDisable(false)
          else setCpButtonDisable(true)
        }

        
        const handleCiFileToUpload = (e) =>{
          if(!e.target.files[0]){
            setFileCi(null)
            return
          }
          setFileCi(e.target.files[0])
        }

        
        
        const handleRibSubmit = (e)=>{
        e.preventDefault()
        console.log(ibanPerso)
        if(ibanPerso === null || ibanPerso === "" )setErrorIbanEmpty(true)
        if(bicPerso === null || bicPerso === "")setErrorBicEmpty(true)
        if(ibanPerso === null || ibanPerso === "" || bicPerso === null || bicPerso === "")return

      
        
        const formData = new FormData()


    
       var headers = {
            headers: {
              "x-auth-token": token,
      }}
          //setting des headers : 
          headers ={...headers, "x-auth-token" : token}
          headers ={...headers, "Content-type":"multipart/form-data"}

          //preparation du body :
          formData.append('body', JSON.stringify(
            {
              name :"compte général",
              iban:ibanPerso,
              bic: bicPerso

            }
          ))
          if(fileCi &&
            ibanPerso &&
            bicPerso){    
            formData.append('file',fileCi)
          }
          axios.patch(`${urls.apiUrl}/users/${user.id}/rib-Investissement`,formData, {headers})
          .then(res=>{
            // console.log(res)
            navigate(0)
            
          })
          .catch(err=>{
            // console.log(err)
          }) 
        
        
        }
    // ========================================================================

    // fonction et constante formulaire compte apport d'affaire
//RIB Compte apport d'affaire
      const userDocRibApportAffaire =  user.doc.find(x=>x.docType ==="RIB compte business") ? user.doc.find(x=>x.docType ==="RIB compte business") : ""
  
      const [hideCaButton,setHideCaButton]= useState(false)
      const hideButtonCa = () =>{
        setHideCaButton(!hideCaButton)
      }
      const [inputCheckCS, setInputCheckCS] = useState([
        {
          id: 0,
          label: texts[lang].banqueInfoChoices[0] ,
          value: false, 
        },
        {
          id:1,
          label: texts[lang].banqueInfoChoices[1],
          value:false,
        }
      ])
      
      const [ibanSecondaire, setIbanSecondaire] = useState(null);
      const [bicSecondaire, setBicSecondaire] = useState(null);
      const [csButtonDisable, setCsButtonDisable] = useState(true)
      const [fileCa,setFileCa] = useState(null)
      const [errorBicEmptyCa, setErrorBicEmptyCa] = useState(false);
      const [errorIbanEmptyCa, setErrorIbanEmptyCa] = useState(false);

      

      const handleSecondIbanChange = (e) => {
        if(e.target.value !== "")setErrorIbanEmptyCa(false);
        else setErrorIbanEmptyCa(true);
        setIbanSecondaire(e.target.value)
        
      }
      const handleSecondBicChange = (e) => {
        if(e.target.value !== "")setErrorBicEmptyCa(false);
        else setErrorBicEmptyCa(true);
        setBicSecondaire(e.target.value)
         
    }
      
      const handleInputCheckCS = (e,id)=>{
        const newInputCheckCS = inputCheckCS
        var total = 0
        newInputCheckCS[id].value = e.target.checked
        newInputCheckCS.map(check=>{
          if(check.value === true) total ++
        })
        setInputCheckCS([...newInputCheckCS])
        if(total === 2) setCsButtonDisable(false)
        else setCsButtonDisable(true)
      }

      
      const handleCaFileToUpload = (e) =>{
        if(!e.target.files[0]){
          setFileCa(null)
          return
        }
        setFileCa(e.target.files[0])
      }
      const handleSecondRibSubmit = (e)=>{
          
        e.preventDefault()
        if(ibanSecondaire === null || ibanSecondaire === "" )setErrorIbanEmptyCa(true)
        if(bicSecondaire === null || bicSecondaire === "")setErrorBicEmptyCa(true)
        if(ibanSecondaire === null || ibanSecondaire === "" || bicSecondaire === null || bicSecondaire === "")return

      const formData = new FormData()
   
  
      var headers = {
           headers: {
             "x-auth-token": token,
      }}
         //setting des headers : 
         headers ={...headers, "x-auth-token" : token}
         headers ={...headers, "Content-type":"multipart/form-data"}
 
         //preparation du body :
         formData.append('body', JSON.stringify(
           {
             name :"compte business",
             iban:ibanSecondaire,
             bic: bicSecondaire
 
           }
         ))


         if(fileCa &&
           ibanSecondaire &&
           bicSecondaire
           ){    
           formData.append('file',fileCa)
         }
         axios.patch(`${urls.apiUrl}/users/${user.id}/rib-ApportAffaire`,formData, {headers})
         .then(res=>{
          //  console.log(res)
           navigate(0)

         })
         .catch(err=>{
          //  console.log(err)
          
         }) 
 
       
    }
      //  console.log(Object.keys(user.ribInvestissment).length)
      // ========================================================================


    return (
      <>
 
        <div className="information-container -apporAf">
                  <div className="-title">
                    <h4 className="-texte">{texts[lang].banqueInfo}</h4>
                  </div>
                  <div className="-information">
                    <InfosBar
                      classes="-info"
                      content= { Object.keys(user.ribInvestissment).length === 3 ?texts[lang].banqueInfoText[0] :texts[lang].banqueInfoText[1]} />
                  </div>
                </div>
                
                      { Object.keys(user.ribInvestissment).length === 3   ?
                      <div className="add-RIB">
                        <div className="RIB-container">
                          <div className="RIB-dashboard-container">
                            <div className="RIB-dashboard-scontainer">
                              <div className="RIB-section-title">
                                  <h4 className="RIB-texte">{texts[lang].ribComptG} </h4>
                              </div>

                              <div className="spacing">
                              <InputText
                                  label={texts[lang].ibanInvest}
                                  name="IBAN"
                                  value ={user.ribInvestissment.iban}
                                  placeholder="IBAN"
                                  />
                              </div>
                              <div className="spacing ">
                              <InputText
                                  label={texts[lang].bicInvest}
                                  name="BIC"
                                  value={user.ribInvestissment.bic}
                                  placeholder="BIC"
                                  />
                              </div>

                              <div className="spacing-rib " >     
                                <p className='rib-dwl'> Telecharger mon {userDocRibGeneral.docType} </p>
                              <a href='#' onClick={()=>getDoc(userDocRibGeneral.name)} > < IconFileDownload /></a>  
                              </div>
                                
                            </div>        
                          </div> 
                        </div>
                      </div>
                         : 
                        <div className="add-RIB">
                        <div className="RIB-container">
                           <div className="RIB-dashboard-container">
                               <div className="RIB-dashboard-scontainer">
                                  <div className="RIB-section-title">
                                       <h4 className="RIB-texte">
                                       {texts[lang].ribComptePerso}
                                       </h4>
                                   </div>
                                   <form className="RIB-form" onSubmit={handleRibSubmit}  >
                                      <div className="spacing">
                                        <InfosBar
                                            classes="-error"
                                            content={texts[lang].ribInfoBarText} />  
                                      </div>
                                        <div className="spacing">
                                        <InputText
                                            label={texts[lang].iban}
                                            name="IBAN"
                                            value={ibanPerso}
                                            placeholder="IBAN"
                                            error={errorIbanEmpty && texts[lang].errorIban}
                                            handleInputChange={handleIbanChange}
                                            />
                                        </div>
                                        <div className="spacing ">
                                        <InputText
                                            label={texts[lang].bic}
                                            name="BIC"
                                            value={bicPerso}
                                            placeholder="BIC"
                                            error={errorBicEmpty && texts[lang].errorBic}
                                            handleInputChange={handleBicChange}
                                            />
                                        </div>
                
                                  
                                        <div className="spacing -bottom" >
                                        <div className="file-upload">
                                        <label className="label-doc"> {texts[lang].renseignerRib} </label>
                                        <input
                                            className="file"
                                            type='file'
                                            onChange={(e)=>{handleCiFileToUpload(e)}}
                
                                             />
                                        <span>{texts[lang].requiredFormats}</span>
                                        </div>     
                                        </div> 
                
                                        {
                                          inputCheckCP.map(CheckCP=>
                                            <FormRowCheckbox
                                            isActive={CheckCP.value}
                                            style={styleCheckBox}
                                            label = {CheckCP.label}
                                            handleCheckboxChange={(event) => handleInputCheckCi(event, CheckCP.id)}
                                          />
                                          )
                                         } 
                                         
                                         <Button 
                                          type='submit'
                                          classes = '-tiny'
                                          label={texts[lang].uploadRIB}
                                          icon 
                                          disable={cpButtonDisable ? true : false}
                                        />  
                                    </form>
                                 </div>        
                             </div> 
                         </div>
                         <div className="nextFormTrigger">
                        </div>
                        </div>
                       } 

{ Object.keys(user.ribApportAffaire).length ===0  ? 

          <div className='nextFormTrigger'>
              <Button 
                  classes = '-medium'
                  type="submit"
                  label={texts[lang].ajoutRIB}
                  icon
                  handleClick={showFormulaire}
                />    
                 </div>
                 : ""}  
            
          
   { Object.keys(user.ribApportAffaire).length === 3   ?

        <div className="add-RIB">
          <div className="RIB-container">
             <div className="RIB-dashboard-container">
                 <div className="RIB-dashboard-scontainer">
                    <div className="RIB-section-title">
                         <h4 className="RIB-texte">{texts[lang].ribComptAA}</h4>
                     </div>

                              <div className="spacing">
                              <InputText
                                  label={texts[lang].ibanInvest}
                                  name="IBAN"
                                  value = {user.ribApportAffaire.iban}
                                  placeholder="IBAN"
                                  />
                              </div>
                              <div className="spacing ">
                              <InputText
                                  label={texts[lang].bicInvest}
                                  name="BIC"
                                  value={user.ribApportAffaire.bic}
                                  placeholder="BIC"
                                  />
                              </div>
                              <div className="spacing-rib " >     
                                <p className='rib-dwl'> {texts[lang].telecharger} {userDocRibApportAffaire.docType} </p>
                              <a href='#' onClick={()=>getDoc(userDocRibApportAffaire.name)} > < IconFileDownload /></a>  
                              </div>
                   </div>        
               </div> 
           </div>
           </div>   

          :  showForm && (  
          <div className="add-RIB">
          <div className="RIB-container">
             <div className="RIB-dashboard-container">
                 <div className="RIB-dashboard-scontainer">
                    <div className="RIB-section-title">
                         <h4 className="RIB-texte">
                         {texts[lang].ribCompteAA}
                         </h4>
                     </div>
                     <form className="RIB-form" onSubmit={handleSecondRibSubmit}   >
                        <div className="spacing">
                          <InfosBar
                              classes="-error"
                              content={texts[lang].ribInfoBarText} />  
                        </div>
                          <div className="spacing">
                          <InputText
                              label={texts[lang].iban}
                              name="IBAN"
                              value = {ibanSecondaire}
                              placeholder="IBAN"
                              error={ errorIbanEmptyCa && texts[lang].errorIban}
                              handleInputChange={handleSecondIbanChange}
                              />
                          </div>
                          <div className="spacing ">
                          <InputText
                              label={texts[lang].bic}
                              name="BIC"
                              value={bicSecondaire}
                              placeholder="BIC"
                              error={errorBicEmptyCa && texts[lang].errorBic}
                              handleInputChange={handleSecondBicChange}
                              />
                          </div>
  
                    
                          <div className="spacing">
                          <div className="file-upload">
                          <label className="label-doc"> {texts[lang].renseignerRib}  </label>
                          <input
                          className="file"
                              type='file'
                              onChange={(e)=>{handleCaFileToUpload(e)}}
                               />
                          <span>{texts[lang].requiredFormats}</span>
                          </div> 
                          </div> 
  
                          {
                            inputCheckCS.map(CheckCS=>
                              <FormRowCheckbox
                              isActive={CheckCS.value}
                              style={styleCheckBox}
                              label = {CheckCS.label}
                              handleCheckboxChange={(event) => handleInputCheckCS(event, CheckCS.id)}
                            />
                            )
                           }
                          
                            <Button 
                                type='submit'
                                classes = '-tiny'
                                label={texts[lang].uploadRIB}
                                handleClick={hideButtonCa}
                                icon 
                                disable={csButtonDisable ? true : false}
                              /> 
                      </form>
                   </div>        
               </div> 
  
           </div>
           </div> 
           )} 
           
        
        </>  
    );
};


export default BanqueView;


