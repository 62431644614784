import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import jwt from 'jwt-decode';
import axios from 'axios';
import LoadinTemplate from "../components/loadingTemplate";

import { setRedirect, deconnexion, setUser, setToken } from '../redux/store';

import {
  SESSION_EXPIRED,
  NOT_CONNECTED
} from "../constants/redirections";
import urls from "../constants/urls";
import texts from "../texts/dashboardLayout";
import PopupDeconnexion from "../components/popupDeconnexion";

const ACTIVITY_EVENTS = ["click", "touchstart", "scroll"];

const SESSION_DURATION = 25 * 60 * 1000; // 25 minutes;
const CHECK_SESSION_INTERVAL = 10 * 60 * 1000; // 10 minutes
const POPUP_MESSAGE_DURATION = 5 * 60 * 1000; // 5 minutes

// const SESSION_DURATION = 20000;
// const CHECK_SESSION_INTERVAL = 5000;
// const POPUP_MESSAGE_DURATION = 10000;

const ProtectedRootsLayout = () => {

  const [loading, setLoading] = useState(true);
  const [popup, setPopup] = useState(false);

  
  const {user, token} = useSelector(state => state.persisedReducer.user);
  const {redirect, lang} = useSelector(state => state.persisedReducer.parameters);
  
  const shouldCheck = useRef(true);
  const lastInteraction = useRef(Date.now());
  
  const timeoutInteractivity = useRef(0);
  const timeoutPopupDeconnexion = useRef(0);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  {/* PARTIE POUR FAIRE LA REDIRECTION */}

  // Hook pour détecter les demande de redirection
  useEffect(() => {

    if ( redirect.status ) {
      const link = redirect.link;
      if ( redirect.deconnexion ) dispatch(deconnexion());
      navigate(link, { replace: true })
      dispatch(setRedirect({}));
    };

  }, [redirect, dispatch, navigate])


  {/* PARTIE POUR FAIRE LE ROOTING */}

  // Hook pour faire le rooting
  useEffect(() => {

    const fechUserData = async () => {

      const url = `${urls.apiUrl}/users/${user.id || user._id}`;
      const headers= {headers:{'x-auth-token': token}};

      try {

        const response = await axios.get(url, headers);
        const newUser = response.data;
        dispatch(setUser(newUser));
        
        // Si l'utilisateur n'a pas encore validé son mail, on le redirige vers la page de connexion
        if ( !newUser.has_valided_email ) {
          dispatch(setRedirect({
            status: true,
            link: "/registration",
            params: [],
            deconnexion: false,
          }));
        }
        // Si l'utilisateur n'a pas encore fait ça verification d'identité, on le redirige vers la page sumbub
        else if (
          !newUser.has_send_kyx ||
          ( newUser.has_send_kyx && newUser.status_kyx !== "GREEN" )
        ) {
          dispatch(setRedirect({
            status: true,
            link: "/sumsub",
            params: [],
            deconnexion: false,
          }))
        }
        // Si l'utilisateur n'a pas encore faire le quizz, on le redirige vers la page du quizz
        else if(!newUser.hasDoneQuiz){
          dispatch(setRedirect({
            status: true,
            link: "/quizz",
            params: [],
            deconnexion: false,
          }))
        }

      }catch (err) {
        console.log(err.response.data);
      }finally {
        setLoading(false);
      }

    }

    // Point d'entrée (Check une fois à chaque montage du module)
    if ( shouldCheck.current ) {

      shouldCheck.current = false;

      // Si l'utilisateur n'est pas connecté => On le redirige vers la page de connexion
      // On en profite pour récupérer l'url vers laquel il voulais allez et la mettre en history
      if ( !user && !token ) {
        dispatch(setRedirect({
          status: true,
          link: "/connexion",
          params: [{name: "redirection", value: NOT_CONNECTED}, {name: "history", value: encodeURI(window.location.pathname)}],
          deconnexion: true,
        }));
      }
      else {

        const decodedToken = jwt(token);

        // Si le token de connexion n'est plus valide => On redirige vers la page de connexion
        if ( Date.now() >= decodedToken.token_exp_date ) {
          dispatch(setRedirect({
            status: true,
            link: "/connexion",
            params: [
              {name: "redirection",value: SESSION_EXPIRED},
              {name: "email", value: user.email || "",}
            ],
            deconnexion: true,
          }))
        }
        // Si le token est valide, on va aller récupérer les nouvelles données de l'utilisateur depuis l'API
        else {
          fechUserData();
        }
      }
    }

  }, [dispatch, token, user]);


  {/* PARTIE POUR GERER LA SESSION */}

  // Fonction pour mettre à jour le lastInteraction
  const setGlobalLastInteraction = () => {
    lastInteraction.current = getTime(); // Lors d'une interaction avec l'application, on met à jours le lastInteraction, en mettant le datetime de l'interaction
  }

  // Fonction pour surveiller les interactions de l'utilisateurs avec l'application
  const checkInteractivities = () => {

    timeoutInteractivity.current = setTimeout(() => {
      // Si l'utilisateur n'a pas fait d'interactions lors l'interval (SESSION_DURATION) de secondes
      if ( getTime() - lastInteraction.current > SESSION_DURATION ) {
        setPopup(true); // On affiche la popup pour demander de prolanger la session
        setDeconnexionTimeout(); // On lance le timeout, pour le deconnecter
      }
      // Si l'utilisateur a fait une interaction lors des x dernières secondes
      else {

        const headers= {headers:{'x-auth-token': token}};
        axios.get(`${urls.apiUrl}/auth/refresh`, headers)
        .then(res => {
          const token = res.headers["x-auth-token"];
          dispatch(setToken(token)); // On refresh le token
        })
        .catch(err => console.log(err.response.data))
        .finally(() => checkInteractivities()) // On appel la même fonction, pour faire une boucle

      }
    }, CHECK_SESSION_INTERVAL);

  };

  // Fonction pour déconnecter l'utilisateur en cas de non interaction
  const setDeconnexionTimeout = () => {
    timeoutPopupDeconnexion.current = setTimeout(() => {
      dispatch(setRedirect({
        status: true,
        link: "/connexion",
        params: [
          {name: "redirection", value: SESSION_EXPIRED},
          {name: "email", value: user.email || ""},
        ],
        deconnexion: true,
      }));
    }, POPUP_MESSAGE_DURATION);
  };

  // Fonction pour prolanger la durée de vie de la session
  const extendSession = () => {
    clearTimeout(timeoutPopupDeconnexion.current); // On annule le timeout de la deconnexion
    setGlobalLastInteraction(); // On met à jours la variable lastInteraction au datetime courant
    checkInteractivities(); // On relance la fonction qui vérifie les interactions de l'utilisateur avec l'application
    setPopup(false); // On cache la popup de prolongation de session
  }

  // Fonction pour avoir la phrase dans la popup de prolongation de la session traduite
  const getFormatedTime = () => {
    const ms = POPUP_MESSAGE_DURATION;
    const text = texts[lang].deconnexionMessage;
    return <p>{text.replace("$time", Math.floor(ms / 1000 / 60))}</p>;
  };

  // Fonction pour avoir le datetime actuelle
  const getTime = () => Date.now();

  // Hook pour mettre des écouteurs d'évenements sur le body
  useEffect(() => {

    // On place les écouteurs (ACTIVITY_EVENTS) d'événements sur le body pour mettre à jour le temps de la dernière interaction de l'utilisateur avec l'application
    ACTIVITY_EVENTS.forEach((eventName) => {
      document.addEventListener(eventName, setGlobalLastInteraction, true);
    });
    checkInteractivities(); // On lance la fonction qui vérifie chaque x (CHECK_SESSION_INTERVAL) secondes que l'utilisateur à bien fait une intercation sur l'application

    // Lors de la destruction du compsant :
    return function cleanup() {

      // On va enlever tous les écouteurs d'événements
      ACTIVITY_EVENTS.forEach((eventName) => {
        document.removeEventListener(eventName, setGlobalLastInteraction, true);
      });

      // On va supprimer tous les intervals
      clearTimeout(timeoutInteractivity.current);
      clearTimeout(timeoutPopupDeconnexion.current);
    }

  }, [])

  if ( loading ) return <LoadinTemplate />
  else 
  return (
    <div className="layout-protected-roots">
      <Outlet />
      {
        popup &&
        <PopupDeconnexion
          text={getFormatedTime()}
          extendSession={extendSession}
        />
      }
    </div>
  )

  // return <div>
  //   {lastInteraction.current}
  //   <button onClick={extendSession}>Extend</button>
  //   <button onClick={() => {
  //     dispatch(setRedirect({
  //       status: true,
  //       link: "/connexion",
  //       params: [{name: "redirection", value: MAIL_NOT_VALIDATED}],
  //       deconnexion: true,
  //     }));
  //   }}>
  //     Connexion
  //   </button>
  //   {
  //     popup && <p>popup</p>
  //   }
  // </div>

}

export default ProtectedRootsLayout;
