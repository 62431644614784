import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { roundNumber } from "../../helpers/roundNumberFunc";

import axios from "axios";
import texts from "../../texts/apportAffaire"
import urls from "../../constants/urls";
import Card from "../../components/card";
import CardAA from "../../components/cardAA";
import GreyCard from "../../components/greyCard";
import InfosBar from "../../components/infosBar";
import FormSectionTitle from "../../components/formSectionTitle";
import { LoaderContext } from "../../context/Context";
import { useLayoutEffect, useContext } from 'react';



const ApportAffView = () => {
  const loader = useContext(LoaderContext);
  const { token, user } = useSelector((state) => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)
  const headers = {
    headers: {
      "x-auth-token": token,
    },
  };
  const [leveDirect, setLeveDirect] = useState(0);
  const [filleuleCommition, setFilleuleCommition] = useState([]);
  const [benefice, setBenefice] = useState(0);
  const [beneficeCapital, setBeneficeCapital] = useState(0);
  const [hasRedirect, setHasRedirect] = useState(false);
  const [redirectToTransac, setRedirectToTransac] = useState(false);
  const [commercialController, setCommercialController] = useState(false);
  const [redirectToCommercial, setRedirectToCommercial] = useState(false);
  const [totalWihtdraw, setTotalWithdraw] = useState(0);
  const [totalWihtdrawCapital, setTotalWithdrawCapital] = useState(0);
  const [redirectDetails, setRedirectDetails] = useState(false);
  const [hasSentComRequest,setHasSentComRequest] =useState(false)
  const [goToVizioLink,setGoToVizioLink] =useState(false)
  const [prime, setPrime] = useState(null);
 
 
  // const getRandomColor = () => {
  //   const codes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F"];
  //   let hexaCode = "";
  //   for (let i = 0; i < 6; i++)
  //     hexaCode += codes[Math.floor(Math.random() * codes.length)];
  //   return `#${hexaCode}`;
  // };
 
  const colors = ['red', 'green', 'blue', 'purple', 'orange', 'pink', 'cyan', 'magenta', 'teal', 'lime', 'olive', 'maroon', 'navy', 'brown', 'black', 'gray', 'silver', 'gold', 'tan', 'beige', 'salmon', 'plum', 'peach'];
  let currentIndex = 0;

  const getNextColor = () => {
    const nextColor = colors[currentIndex];
    currentIndex = (currentIndex + 1) % colors.length;
    return nextColor;
  }

 
  useLayoutEffect(() => {
   
    axios.get(`${urls.apiUrl}/prime/user/${user.id}`, headers)
       .then((res)=>{
        // console.log(res.data)
        setPrime(res.data)
        })
        .catch ( err => console.log(err));
    
    axios.get(`${urls.apiUrl}/fonds/business`, headers)
    .then((res)=>{
      
      let fondsBusiness = res.data
      let fondAACapital = (fondsBusiness.filter(i=>i.name==="Apport-Affaire-Capitalisation"))[0]
      
      let fondAAReal= fondsBusiness.filter(i=>i.name==="Apport-Affaire-Real-Estate")[0]



    axios.get(`${urls.apiUrl}/users/${user.id}`, headers)
    .then((res)=>{
      // console.log(res.data)
      setHasSentComRequest(res.data.hasSentCommercialRequest)})
      .catch ( err => console.log(err));

    axios
      .get(`${urls.apiUrl}/investment/user/${user.id}`, headers)
      .then((res) => {
        //!beneReal && benefCapital
        let benef = res.data.filter((i)=>i.status === "validé" && i.fond ===fondAAReal._id) // && i.fond === "id du fond de aa-realestate"
        // console.log(benef)
        let benefCapital = res.data.filter((i)=>i.status === "validé" && i.fond ===fondAACapital._id)
        // console.log(benefCapital);


        setFilleuleCommition(benef.concat(benefCapital));//! benefReal.concat(benefCaptal)
        setBenefice(
          benef
            .map((b) => roundNumber(b.montant))
            .reduce((prev, next) => prev + next,0)
        ); //!beneficeReal ainsi que beneficeCapital

        setBeneficeCapital(
          benefCapital
            .map((b) => roundNumber(b.montant))
            .reduce((prev, next) => prev + next,0)
        );

        axios.get(`${urls.apiUrl}/reseau/parrain/${user.id}`,headers)
        .then((res) => {
          let users = res.data.filter((user) => {
            return user.depth === 0;
          });
          setLeveDirect(
            users
              .map((i) => roundNumber(i.totalLeveeReseau))
              .reduce((prev, next) => prev + next ,0)
          )
         
          axios.get(`${urls.apiUrl}/payment/mollie/withdraw`, headers)
          .then((res) => {
            loader.onSetIsLoading(false);
            if(res.data.length <1 )return;
            const totalwithdraw = res.data //! totalwithdraw par fond aussi
              .map((i) => i.status === "processed" && i.compte === "Apport-Affaire-Real-Estate" && roundNumber(i.amount))
              .reduce((prev, next) => prev + next, 0);
            setTotalWithdraw(totalwithdraw);

            const totalwithdrawCapital = res.data //! totalwithdraw par fond aussi
              .map((i) => i.status === "processed" && i.compte === "Apport-Affaire-Capitalisation" && roundNumber(i.amount))
              .reduce((prev, next) => prev + next, 0);
            setTotalWithdrawCapital(totalwithdrawCapital);
          })
          .catch ( err => console.log(err));
        })
        .catch ( err => console.log(err));
      })
      .catch ( err => console.log(err));
    })
    .catch ( err => console.log(err));
   
    return()=>{
      loader.onSetIsLoading(true);
    }

  }, []);

  const showCommercialSubmit = () => {
    setCommercialController(!commercialController);
  };
  const handleRedirect = () => {
    setHasRedirect(true);
  };
  const handleGoToVizioLink =(e)=>{
    setGoToVizioLink(true)
  }
  const handleRedirectToTransac = () => {
    setRedirectToTransac(true);
  };
  const benefDispo = benefice - totalWihtdraw; //!pour chaque société
  const benefDispoCapital = beneficeCapital - totalWihtdrawCapital; //!pour chaque société

  const handleRedirectDetails = () => {
    setRedirectDetails(true);
  };
  const handleRedirectCommercial = () => {
    setRedirectToCommercial(true);
  };

  if(goToVizioLink)
    return <Navigate to={"//calendly.com/viziocapital/presentation-de-vizio-capital?month=2022-11"} />;
    // https://calendly.com/viziocapital/presentation-de-vizio-capital?month=2022-11

  if (redirectToCommercial)
    return <Navigate to={"/apport-affaire/commercial"} replace={true} />;
  

  if (hasRedirect)
    return (
      <Navigate
        to={"/investissements/withdraw"}
        replace={true}
        // state={{ max: benefDispo }}
      />
    );

  if (redirectToTransac)
    return (
      <Navigate to={"/investissements/financial-transactions"} replace={true} />
    );
  if (redirectDetails)
    return <Navigate to={"/apport-affaire/detail"} replace={true} />;

  filleuleCommition.sort(
    (a, b) => new Date(b.dateContrat) - new Date(a.dateContrat)
  );

  return (
    <>
      <div className="layout-profile-container">
        <div className="-big-card-container">
          <div className="information-container -apporAf">
            <div className="-title">
              <h4 className="-texte">{texts[lang].infop}</h4>
            </div>

            
            <div className="-information">
            {showCommercialSubmit &&  ( 
                // leveDirect
                (hasSentComRequest ?
                //   <InfosBar
                //   classes="-success"
                //   content={
                //     `Votre demande pour devenir commercial est en cours de traitement. Il ne vous reste qu'une seule étape : Prenez rendez-vous avec notre direction pour un entretien personnalisé ! <a href="https://calendly.com/viziocapital/rendez-vous-devenir-commercial-pour-vizio-capital?month=2022-09" target="_blank">Rendez-vous : "devenir commercial pour VIZIO CAPITAL" - VIZIO CAPITAL</a> `}
                  
                // /> 
                !user.hasSignedComContract && <InfosBar
                  classes="-success"
                  content={texts[lang].infoboxCommercialEnCours}
                    btnlabel={texts[lang].labelRdv}
                    btnClasse="-card-body-btn"
                    action={handleGoToVizioLink}
                />
                :
                leveDirect>100000 ? 
                <InfosBar
                classes="-info"
                content={texts[lang].becomeCommercial}
                 
                btnlabel={texts[lang].labelBeCom}
                btnClasse="-card-body-btn"
                action={handleRedirectCommercial}
              />:"")
                
               
              )} 
              <InfosBar
                classes="-info"
                content={
                  texts[lang].infobox1
                }
              />
              <InfosBar
                classes="-error"
                content={
                  texts[lang].infobox2
                }
              />
              <InfosBar
                classes="-error"
                content={
                  texts[lang].infobox3
                }
              />

              
            </div>
          </div>
          <div className="title-dicrect">{texts[lang].labelTitle.toUpperCase()}</div>

          <div className="dashboard-container -card-container">
            <Card
              classes={"first-elmt "}
              header={texts[lang].montantleve}
              amount={`${roundNumber(leveDirect)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              button
              text={texts[lang].montantlevetext}
              btnlabel={texts[lang].montantlevebtn}
              action={handleRedirectDetails}
            />

            <CardAA
            classes={"-apport"}
              header={texts[lang].benefdispo}
              amount={`${roundNumber(benefDispo)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              amount2 = {`${roundNumber(benefDispoCapital)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`} //!benefDispo deuxiemme societé
              text2 ={texts[lang].benefdispoCapitaltext}
              society1={" Vizio Real Estate"}
              society2={" Vizio Capitalisation"}
              button
              text={texts[lang].benefdispotext}
              btnDisable={benefDispo >= 100 || benefDispoCapital >= 100 ? false : true}
              btnlabel={texts[lang].benefdispobtn}
              action={handleRedirect}
            />

            <GreyCard
              header={texts[lang].totalbenef}
              amount={`${roundNumber(benefice)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}
              amount2={`${roundNumber(beneficeCapital)
                .toLocaleString("en-EU")
                .replaceAll(",", " ")} €`}//!benef deuxiemme societé
              text2={texts[lang].totalbeneftext}
              society1={" Vizio Real Estate"}
              society2={" Vizio Capitalisation"}
              // button
              text={texts[lang].totalbeneftext}
              // btnlabel={"Historique de retrait"}
              // action={handleRedirectToTransac}
            />
          </div>
          <div className="affaire-container">
            <div className="affaire-scontainer -no-padding">
              <FormSectionTitle
                title={texts[lang].detailbenef}
                classes="-simple-2"
              />

              <div className="security-form-container">
                <div className="_affaire_container">
                  <div className="_affaire_header">
                    <span></span>
                    <span>{texts[lang].nom}</span>
                    <span>{texts[lang].prenom}</span>
                    <span>{texts[lang].date}</span>
                    <span>{texts[lang].commission}</span>
                    <span>{texts[lang].status}</span>
                    <span>{texts[lang].investissement}</span>
                    <span></span>
                  </div>

                  <ul className="_affaire_list">
                    {filleuleCommition.map((fil, id) => {
                      return (
                        <li key={id} className="_affaire">
                          <div className="_picto">
                            <a style={{ backgroundColor: getNextColor() }}>
                              {`${
                                fil.filleul != null
                                  ? fil.filleul.lastname.charAt(0).toUpperCase()
                                  : "-"
                              }${
                                fil.filleul != null
                                  ? fil.filleul.firstname
                                      .charAt(0)
                                      .toUpperCase()
                                  : "-"
                              }`}
                            </a>
                          </div>
                          <div data-label={texts[lang].dTnom}>
                            {fil.filleul != null ? fil.filleul.lastname : ""}
                          </div>
                          <div data-label={texts[lang].dTprenom}>
                            {fil.filleul != null ? fil.filleul.firstname : ""}
                          </div>
                          <div data-label={texts[lang].dTdate}>
                            {new Date(fil.dateContrat).toLocaleDateString()}
                          </div>
                          <div data-label={texts[lang].dTcommission}>
                            {" "}
                            {roundNumber(fil.montant)
                              .toLocaleString("en-EU")
                              .replaceAll(",", " ")}{" "}
                            €
                          </div>
                          <div data-label={texts[lang].dTstatus} className="-succes">
                            validé
                          </div>
                          <div data-label={texts[lang].dTinvestissement}>
                            {fil.fromFond?fil.fromFond.fond.name :texts[lang].fondAA}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="affaire-container">
            <div className="affaire-scontainer -no-padding">
              <FormSectionTitle
                title="Prime d'apport d'affaire"
                classes="-simple-2"
              />

              <div className="security-form-container_prime">
                <div className="_affaire_container_prime">
                  <div className="_affaire_header_prime">
                    <span></span>
                    <span>NOM</span>
                    <span>DATE </span>
                    <span>COMISSION</span>
                    <span>STATUT</span>
                    <span>INFORMATION</span>
                    <span></span>
                  </div>

                  <ul className="_affaire_list_prime">
                  {prime?.map((pri, id) => {
                      return ( 
                      <li key={id} className="_affaire_prime">
                          <div className="_picto"> <a style={{ backgroundColor: getNextColor() }}>V G</a></div>
                          <div data-label="NOM :"> {pri.companyName}</div>
                          <div data-label="DATE :"> {new Date(pri.date).toLocaleDateString()}</div>
                          <div data-label="COMISSION :">{roundNumber(pri.amount).toLocaleString("en-EU").replaceAll(",", " ")}{" "}€</div>
                          <div data-label="STATUS : " className="-succes">{pri.statut}</div>
                          <div data-label="INFORMATION :">{pri.primeName} </div>
                        </li>
                        )})}
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
export default ApportAffView;
