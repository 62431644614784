// quizz

export default {
  fr:{
    
    firstStepFirstLabel:"Avant de démarrer l’aventure, mesurons vos connaissances en investissement !",
    firstStepSecondLabel:"Afin de répondre au mieux à vos attentes, Nous aimerions connaitre vos connaissances dans le domaine de l’investissement en général. Ces informations restent confidentielles.",
    firstBtnText:"Démarrer",

    stepOneLabelQuestion:"A combien s'élève votre portefeuille d'investissement ? ",
    stepOneLabelAnswer:[
      "Choisissez une reponse",
      "0€ - 5 000€",
      "5 000€ - 50 000€",
      "50 000€ - 250 000€",
      "250 000€ - 1M€",
      "+1M€",
    ],

    stepTwoLabelQuestion:"Quels sont vos objectifs d'investissement ? ",
    stepTwoLabelAnswer:[
      "Se constituer un patrimoine",
      "Augmenter vos revenus",
      "Compléter votre retraite",
      "Préparer l'avenir de votre famille",
    ],

    stepThreeLabelQuestion:"Quels sont vos objectifs financiers ?",
    stepThreeLabelAnswer:[
      "Epargner",
      "Patrimoine",
      "Indépendance financière",
      "Liberté financière",
    ],

    stepFourLabelQuestion:"Quels secteurs vous interessent le plus ?",
    stepFourLabelAnswer:[
      "Immobilier",
      "Cryptommonaies",
      "Bourse",
      "Nouvelles technologies",
      "Start-up",
    ],

    stepFiveLabelQuestion:"D'où proviennent vos fonds ?",
    stepFiveLabelAnswer:[
      "Choisissez une reponse",
      "Épargne", 
      "Revenu", 
      "Héritage", 
      "Donation"
    ],
    stepSixLabel :"Il est important de vous informer avant d'investir ! Veuillez télécharger les documents suivants :",
    stepSixSubLabel:[
      "Vizio Capitalisation : ",
      "Real Estate : "
    ],
    stepSixLabelAnswer :[
      "France : <a href='https://viziocapital.com/wp-content/uploads/2022/12/Document-Information-Synthetique-complet-vizio-capitalisation-1-1.pdf' target='_blank'>Document information synthetique</a>",
      "Belgique : <a href='https://viziocapital.com/wp-content/uploads/2022/11/Vizio-capitalisation-220831-Note-dinformation-Obligation-Zero-Coupon-.pdf' target='_blank'>Note d'information obligation</a>",
      "France : <a href='https://viziocapital.com/wp-content/uploads/2022/12/Document-Information-Synthetique-complet-Vizio-real-estate-1-1.pdf' target='_blank'>Document information synthetique</a>",
      "Belgique : <a href='https://viziocapital.com/wp-content/uploads/2022/11/vizio-REAL-ESTATE-220729-Note-dinformation-Obligation-v.realestate-.pdf' target='_blank'>Note d'information obligation</a>",
    
    ],
    stepSixLabelQuestionCheckBox:"J'ai téléchargé et lu les notes d'informations et les documents d'informations concernant Vizio Capitalisation et Real Estate. : ",

    stepsBtnText:"Valider ma réponse",
    indicationLabel:"Selectionner une réponse",

    inputIndication:"Plusieurs réponses possibles",

    lastStepFirstLabel:"Merci !",
    lastStepSecondLabel:"Ces informations nous seront utiles et nous aiderons à définir votre profil d’investisseur. A vous de jouer maintenant et bienvenue chez Vizio Capital !",
    lastBtnText:"Découvrir Vizio Capital",

    backQuestion:"Question précédente"
    
   
  },

  en:{
    
    firstStepFirstLabel:"Before starting the adventure, let's measure your investment knowledge!",
    firstStepSecondLabel:"In order to meet your expectations the most, we would like to know your knowledge in the field of investment in general. This informations remains confidential.",
    firstBtnText:"Start",

    stepOneLabelQuestion:"How big your investment portfolio is?",
    stepOneLabelAnswer:[
      "Select an answer",
      "0€ - 5 000€",
      "5 000€ - 50 000€",
      "50 000€ - 250 000€",
      "250 000€ - 1M€",
      "+1M€",
    ],

    stepTwoLabelQuestion:"What are your investment goals?",
    stepTwoLabelAnswer:[
      "Building up a heritage.",
      "Increase your income",
      "Complete your retirement",
      "Preparing for the future of your family",
    ],


    stepThreeLabelQuestion:"What are your financial goals?",
    stepThreeLabelAnswer:[
      "Save money",
      "Inheritance",
      "Financial independency",
      "Financial freedom",
    ],
    stepFourLabelQuestion:"Which sectors interest you the most?",
    stepFourLabelAnswer:[
      "Immovable",
      "Cryptomonies",
      "Sotck exchange",
       "New technologies",
       "Startup"
    ],

    stepFiveLabelQuestion:"Where do your funds come from?",
    stepFiveLabelAnswer:[
      "Select an answer",
      "Savings", 
      "Income", 
      "Inheritance", 
      "Donation"
    ],

    stepsBtnText:"Validate my answer",
    indicationLabel:"Select an answer",
    inputIndication:"A number of possible answers.",
    

    lastStepFirstLabel:"Thanks!",
    lastStepSecondLabel:"This information will be useful to us and we will help define your investor profile. It's up to you now.",
    lastBtnText:"Discover Vizio Capital",

    backQuestion:"Previous question"
    }
}