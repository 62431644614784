export default () =>  (
    <svg viewBox="0 0 100 100">
        <g id="DESIGN-APP" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Group-8">
                <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                <g id="ic_Edit-Copy" transform="translate(8.333333, 16.666667)" fill="#FFC542">
                    <path d="M43.7644565,72.0226078 L42.52035,73.9556933 C42.4417072,74.0778881 42.3428069,74.1870934 42.2275055,74.2790512 C40.9626122,75.2878575 39.0304609,75.2324816 37.8410935,74.1474063 C37.2152469,73.5764383 36.8805006,72.789279 36.9311005,72.0592052 L36.9286156,66.9585074 L30.8183349,62.216085 C30.2156664,61.7483309 29.7510457,61.1390928 29.4702508,60.4482257 C28.5763517,58.248878 29.7236032,55.7762439 32.0306582,54.9257679 L56.4352403,45.9292285 C56.9077328,45.7550482 57.3532561,45.8318247 57.6936973,46.0494361 C58.1166855,46.3079443 58.364994,46.7709622 58.3300794,47.2552996 C58.3240943,47.3478121 58.307303,47.4407744 58.2787178,47.532794 L51.6240387,70.4125633 C50.9674061,72.6701632 48.5057093,73.9928046 46.1267343,73.3761934 C45.527403,73.2208516 44.9686017,72.9489815 44.4860111,72.5777146 L43.7644565,72.0226078 L43.7644565,72.0226078 Z M41.4473684,70.3886288 L39.9123183,69.1666667 L39.91236,71.9075977 C39.9112483,71.9251499 39.9216178,71.9503808 39.9568377,71.9836283 C40.063376,72.0842005 40.2597889,72.1101159 40.4110001,72.0548608 L41.4473684,70.3886288 L41.4473684,70.3886288 Z M39.9122807,65.7754451 L45.7756931,70.2604044 C45.9612952,70.4023726 46.1769591,70.5066961 46.4088603,70.5664581 C47.3299503,70.8038273 48.2779704,70.2973898 48.5273847,69.4447874 L53.7280702,51.6666667 L39.9122807,65.7754451 L39.9122807,65.7754451 Z M38.4803526,64.7916667 L52.1929825,50.2083333 L33.327864,57.4092324 C32.4485447,57.7448725 32.0125836,58.7177812 32.3520588,59.5826226 C32.458798,59.8545499 32.6355728,60.0945612 32.8651111,60.2790277 L38.4803526,64.7916667 Z" id="Shape-Copy"></path>
                    <g id="noun-envelope-185619-copy" transform="translate(-0.000000, 0.000000)" fillRule="nonzero">
                        <path d="M74.2755244,58.3333333 L67.0289045,58.3333333 C66.0286821,58.3333333 65.2170942,57.5166873 65.2170942,56.5102311 C65.2177009,55.503775 66.0292877,54.6871289 67.0289045,54.6871289 L74.2755244,54.6871289 C77.2719678,54.6871289 79.710334,52.2335658 79.710334,49.2184473 L79.710334,9.11426107 C79.710334,6.0997675 77.2719678,3.64620442 74.2755244,3.64620442 L9.05780898,3.64620442 C6.06136552,3.64620442 3.62299937,6.0997675 3.62299937,9.11488606 L3.62299937,49.2190723 C3.62299937,52.2335658 6.06136552,54.6871289 9.05780898,54.6871289 L16.3044288,54.6871289 C17.3046512,54.6871289 18.1162391,55.503775 18.1162391,56.5102311 C18.1156324,57.5166873 17.3040456,58.3333333 16.3044288,58.3333333 L9.05780898,58.3333333 C4.06151086,58.3333333 0,54.2427595 0,49.2190723 L0,9.11488606 C0,4.09057387 4.06151086,0 9.05780898,0 L74.2755244,0 C79.2718225,0 83.3333333,4.09057387 83.3333333,9.11426107 L83.3333333,49.2184473 C83.3333333,54.2427595 79.2718225,58.3333333 74.2755244,58.3333333 Z" id="Path"></path>
                        <path d="M40.1502288,33.7719298 C38.2338854,33.7719298 36.3212329,33.1611762 34.6853975,31.9365258 L6.80376232,11.0489132 C5.99997768,10.4456422 5.82036951,9.28083712 6.40187,8.44641753 C6.98337049,7.60880703 8.10260361,7.42994129 8.90629598,8.02947895 L36.7879312,28.9170915 C38.8262279,30.4470948 41.4778283,30.4470948 43.516125,28.9170915 L71.3977602,8.02947895 C72.2015449,7.42620794 73.3213316,7.61254038 73.9021862,8.44641753 C74.4836867,9.28029467 74.3004645,10.4456741 73.5002939,11.0446055 L45.6186587,31.9322181 C43.9792862,33.1574747 42.0664799,33.7719298 40.1501365,33.7719298 L40.1502288,33.7719298 Z" id="Path"></path>
                        <path d="M9.34476523,50.6578947 C8.89196727,50.6578947 8.43976026,50.4821855 8.09292275,50.1349582 C7.40336918,49.4362972 7.40336918,48.3011034 8.09292275,47.6024116 L25.7711804,29.6906625 C26.460734,28.9920014 27.5811297,28.9920014 28.2707136,29.6906625 C28.9602672,30.3893236 28.9602672,31.5245173 28.2707136,32.2232091 L10.592456,50.1349582 C10.249755,50.4821855 9.79754803,50.6578947 9.34475007,50.6578947 L9.34476523,50.6578947 Z" id="Path"></path>
                        <path d="M72.4775683,50.6578947 C72.0247704,50.6578947 71.5725634,50.4821855 71.2257259,50.1349582 L53.5474682,32.2232091 C52.8579146,31.524548 52.8579146,30.3893543 53.5474682,29.6906625 C54.2370218,28.9920014 55.3574175,28.9920014 56.0470014,29.6906625 L73.7252591,47.6024116 C74.4148126,48.3010727 74.4148126,49.4362665 73.7252591,50.1349582 C73.3825582,50.4821855 72.9303511,50.6578947 72.4775532,50.6578947 L72.4775683,50.6578947 Z" id="Path"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
)