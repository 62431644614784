import React, { Component } from 'react';
import Button from '../components/button';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import { setUser,setFeedback } from '../redux/store';
import axios from "axios";
import urls from "../constants/urls";
import { useSelector } from "react-redux";
import texts from "../texts/investementQuizz";

import FormRowCheckbox from '../components/formRowCheckbox';
import InfosBar from '../components/infosBar';
import InputSelect from '../components/inputSelect';
import feedTexts from '../texts/feedback';

import InputText from '../components/inputText';

import { LoaderContext } from "../context/Context";



class FirstInvestementView extends Component {
  static contextType = LoaderContext;
  constructor(props) {
    super(props);
    const {lang} = this.props.parameters
    //state à localiser
    this.state = {
      steps : [
        {
          id : 0,
          label:texts[lang].stepOnelabel,
          information:texts[lang].stepOneInformation,
          questions : [
            {
              id:0, 
              label:texts[lang].questionOneLabel,
              value : [],
              type:"number",
              input:true,
              error:false,
              min:18,
              message:""
            },

            {
              id:1,
              select:true,
              label:texts[lang].questionTwoLabel,
              error:false,
              conditional:true,
              value:[],
              choices:[
                {
                  id:0, 
                  label:texts[lang].questionTwoChoices[0],
                  point:0,

                },
                {id:1, 
                label:texts[lang].questionTwoChoices[1],
                point:0,
                },
                {id:2, 
                label:texts[lang].questionTwoChoices[2],
                point:0,
                },
                {id:3, 
                label:texts[lang].questionTwoChoices[3],
                point:0,
                },
                {id:4, 
                label:texts[lang].questionTwoChoices[4],
                point:0,
                },
                {id:5, 
                label:texts[lang].questionTwoChoices[5],
                point:0,
                },
                {id:6, 
                label:texts[lang].questionTwoChoices[6],
                point:0,
                },
                
              ],
              
              
            },

            {
              id:2,
              par_label:texts[lang].questionThreeParLabel,
              value:[],
              label:texts[lang].questionThreeLabel,
              sublabel:texts[lang].questionThreeSubLabel,
              type:"tel",
              radio:true,
                choices:[
                  
                  {id:0, 
                  label:texts[lang].yesOrNoChoices[0],
                  status:false
                  
                  },
                  {id:1, 
                  label:texts[lang].yesOrNoChoices[1],
                  status:false
                  },
                ],
                status:false
            },
            
            // {
            //   id:3,
            //   label:texts[lang].questionFourLabel,
            //   select:true,
            //   choices:[
            //         {
            //           id:0, 
            //           label:texts[lang].activityChoices[0],
            //           point:0,
            //         },
            //         {
            //           id:1, 
            //           label:texts[lang].activityChoices[1],
            //           point:0,
            //         },
            //         {
            //           id:2, 
            //           label:texts[lang].activityChoices[2],
            //           point:0,
            //         },
            //         {
            //           id:3, 
            //           label:texts[lang].activityChoices[3],
            //           point:0,
            //         },
            //         {
            //           id:4, 
            //           label:texts[lang].activityChoices[4],
            //           point:0,
            //         },
            //         {
            //           id:5, 
            //           label:texts[lang].activityChoices[5],
            //           point:0,
            //         },
            //         {
            //           id:6, 
            //           label:texts[lang].activityChoices[6],
            //           point:0,
            //         },
            //         {
            //           id:7, 
            //           label:texts[lang].activityChoices[7],
            //           point:0,
            //         },
            //         {
            //           id:8, 
            //           label:texts[lang].activityChoices[8],
            //           point:0,
            //         },   
                    
            //       ],
            //       value:[],
                 
                  
            // },

            {
              id:4,
              label:texts[lang].questionFiveLabel,
              select:true,
              choices:[
                    {
                      id:0, 
                      label:texts[lang].professionChoices[0],
                      point:0,
                    },
                    {
                      id:1, 
                      label:texts[lang].professionChoices[1],
                      point:0,
                    },
                    {
                      id:2, 
                      label:texts[lang].professionChoices[2],
                      point:0,
                    },
                    {
                      id:3, 
                      label:texts[lang].professionChoices[3],
                      point:0,
                    },
                    {
                      id:4, 
                      label:texts[lang].professionChoices[4],
                      point:0,
                    },
                    {
                      id:5, 
                      label:texts[lang].professionChoices[5],
                      point:0,
                    },
                    {
                      id:6, 
                      label:texts[lang].professionChoices[6],
                      point:0,
                    },
                    {
                      id:7, 
                      label:texts[lang].professionChoices[7],
                      point:0,
                    }, {
                      id:8, 
                      label:texts[lang].professionChoices[8],
                      point:0,
                    }, {
                      id:9, 
                      label:texts[lang].professionChoices[9],
                      point:0,
                    }, {
                      id:10, 
                      label:texts[lang].professionChoices[10],
                      point:0,
                    }, {
                      id:11, 
                      label:texts[lang].professionChoices[11],
                      point:0,
                    },
                  ],
                  value:[],
                      
            },

            // {
            // id:5,
            // label:texts[lang].questionSixLabel,
            // select_if:true,
            // choices:[
                
            //   {
            //     id:0, 
            //     label:texts[lang].activityConjointChoices[0],
            //     point:0,
            //   },
            //   {
            //     id:1, 
            //     label:texts[lang].activityConjointChoices[1],
            //     point:0,
            //   },
            //   {
            //     id:2, 
            //     label:texts[lang].activityConjointChoices[2],
            //     point:0,
            //   },
            //   {
            //     id:3, 
            //     label:texts[lang].activityConjointChoices[3],
            //     point:0,
            //   },
            //   {
            //     id:4, 
            //     label:texts[lang].activityConjointChoices[4],
            //     point:0,
            //   },
            //   {
            //     id:5, 
            //     label:texts[lang].activityConjointChoices[5],
            //     point:0,
            //   },
            //   {
            //     id:6, 
            //     label:texts[lang].activityConjointChoices[6],
            //     point:0,
            //   },
            //   {
            //     id:7, 
            //     label:texts[lang].activityConjointChoices[7],
            //     point:0,
            //   },
            //   {
            //     id:8, 
            //     label:texts[lang].activityConjointChoices[8],
            //     point:0,
            //   },   
                  
            //     ],
            //     value:[""],
                
                
            // },

            {
              id:6,
              label:texts[lang].questionSevenLabvel,
              select_if:true,
              choices:[
                {
                  id:0, 
                  label:texts[lang].professionChoices[0],
                  point:0,
                },
                {
                  id:1, 
                  label:texts[lang].professionChoices[1],
                  point:0,
                },
                {
                  id:2, 
                  label:texts[lang].professionChoices[2],
                  point:0,
                },
                {
                  id:3, 
                  label:texts[lang].professionChoices[3],
                  point:0,
                },
                {
                  id:4, 
                  label:texts[lang].professionChoices[4],
                  point:0,
                },
                {
                  id:5, 
                  label:texts[lang].professionChoices[5],
                  point:0,
                },
                {
                  id:6, 
                  label:texts[lang].professionChoices[6],
                  point:0,
                },
                {
                  id:7, 
                  label:texts[lang].professionChoices[7],
                  point:0,
                }, {
                  id:8, 
                  label:texts[lang].professionChoices[8],
                  point:0,
                }, {
                  id:9, 
                  label:texts[lang].professionChoices[9],
                  point:0,
                }, {
                  id:10, 
                  label:texts[lang].professionChoices[10],
                  point:0,
                }, {
                  id:11, 
                  label:texts[lang].professionChoices[11],
                  point:0,
                },
              ],

              value:[""],
  
            },

            {
              id:7,
              label:texts[lang].questionEightLabel,
              select:true,
              choices:[
                {
                  id:0, 
                  label:texts[lang].questionEightChoices[0],
                  point:0,
                },
                {
                  id:1,
                  label:texts[lang].questionEightChoices[1],
                  point:0,
                },
                {
                  id:2,
                  label:texts[lang].questionEightChoices[2],
                  point:0,
                },
                {
                  id:3,
                  label:texts[lang].questionEightChoices[3],
                  point:0,
                },
              ],
              value:[]
            },

            {
              id:8, 
              label:texts[lang].questionNineLabel,
              select:true,
              choices:[
                {
                  id:0, 
                  label:texts[lang].questionNineChoices[0],
                  point:0,
                },
                {
                  id:1,
                  label:texts[lang].questionNineChoices[1],
                  point:0,
                },
                {
                  id:2,
                  label:texts[lang].questionNineChoices[2],
                  point:0,
                },
                {
                  id:3,
                  label:texts[lang].questionNineChoices[3],
                  point:0,
                },
                {
                  id:4,
                  label:texts[lang].questionNineChoices[4],
                  point:0,
                },
                {
                  id:5,
                  label:texts[lang].questionNineChoices[5],
                  point:0,
                },
                {
                  id:6,
                  label:texts[lang].questionNineChoices[6],
                  point:0,
                },
                {
                  id:7,
                  label:texts[lang].questionNineChoices[7],
                  point:0,
                },
                {
                  id:8,
                  label:texts[lang].questionNineChoices[8],
                  point:0,
                },
                {
                  id:9,
                  label:texts[lang].questionNineChoices[9],
                  point:0,
                },
                {
                  id:10,
                  label:texts[lang].questionNineChoices[10],
                  point:0,
                },
                {
                  id:11,
                  label:texts[lang].questionNineChoices[11],
                  point:0,
                },
                {
                  id:12,
                  label:texts[lang].questionNineChoices[12],
                  point:0,
                },
                {
                  id:13,
                  label:texts[lang].questionNineChoices[13],
                  point:0,
                },
               
              ],
              value:[]
            },

            {
              id:9,
              label:texts[lang].questionTenLabel,
              radio:true,
              status:false,
              sublabel:texts[lang].questionTenSubLabel,
              type:"text",
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
              ],
              value:[]
            },

            {
              id:10,
              label:texts[lang].questionElevenLabel,
              radio:true,
              status:false,
              instru_fin:true,
              substatus:false,
              sublabel:texts[lang].questionElevenSubLabel,
              type:"string",
              subchoices:[
                {
                  id:0,
                  label:texts[lang].questionEightChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].questionEightChoices[1],
                },
                {
                  id:1,
                  label:texts[lang].questionEightChoices[2],
                }
              ],
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
              ],
              value:[]
            }
          ]
        },
        {
          id : 1,
          label: texts[lang].stepTwoLabel,
          questions: [
            {
              id:11,
              label:texts[lang].questionTwelveLabel,
              radio:true,
              status:false,
              instru_fin:true,
              sublabel:texts[lang].questionTwelveSubLabel,
              type:"text",
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                  point:1,
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                  point:0,
                },
              ],
              value:[]
            },
            {
              id:12,
              label:texts[lang].questionThirteenLabel,
              
              checkbox_fin:true,
              choices:[
                texts[lang].questionThirteenChoices[0],
                texts[lang].questionThirteenChoices[1],
                texts[lang].questionThirteenChoices[2],
                texts[lang].questionThirteenChoices[3],
                texts[lang].questionThirteenChoices[4],
                texts[lang].questionThirteenChoices[5],
                texts[lang].questionThirteenChoices[6],
                texts[lang].questionThirteenChoices[7],
                texts[lang].questionThirteenChoices[8],//autre
                texts[lang].questionThirteenChoices[9],
              ],
              value:[""],
              subvalue:[]
            },
            {
              id:13,
              label:texts[lang].questionFourteenLabel,
              radio:true,
              status:false,
              instru_immo:true,
              sublabel:texts[lang].questionFourteenSubLabel,
              type:"text",
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                 
                  point:0,
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                  
                  point:0,
                },
              ],
              value:[]
            },
            
            {
              id:14,
              label:texts[lang].questionFifteenLabel,
              checkbox_immo:true,
              choices:[
                texts[lang].questionFifteenChoices[0],
                texts[lang].questionFifteenChoices[1],
                texts[lang].questionFifteenChoices[2],
              ],
              value:[""],
            },

            {
              id:15,
              label:texts[lang].questionSixteenLabel,
              select_point:true,
              choices : [
                {
                  id:11,
                  label:texts[lang].questionSixteenChoices[0],
                  point:0,
                },
                {
                  id:12,
                  label:texts[lang].questionSixteenChoices[1],
                  point:1,
                },
                {
                  id:13,
                  label:texts[lang].questionSixteenChoices[2],
                  point:-1,
                },
                {
                  id:14,
                  label:texts[lang].questionSixteenChoices[3],
                  point:0,
                }
              ],
              value:[]
            },

            {
              id:16,
              label:texts[lang].questionSeventeenLabel,
              select_point:true,
              choices : [
                {
                  id:15,
                  label:texts[lang].trueOrFalseChoices[0],
                  point:0,
                },
                {
                  id:16,
                  label:texts[lang].trueOrFalseChoices[1],
                  point:1,
                },
                {
                  id:17,
                  label:texts[lang].trueOrFalseChoices[2],
                  point:2,
                },
                
              ],
              value:[],
            },

            {
              id:17,
              label:texts[lang].questionEighteenLabel,
              select_point:true,
              choices : [
                {
                  id:18,
                  label:texts[lang].questionEighteenChoices[0],
                  point:0,
                },
                {
                  id:19,
                  label:texts[lang].questionEighteenChoices[1],
                  point:1,
                },
                {
                  id:20,
                  label:texts[lang].questionEighteenChoices[2],
                  point:-1,
                },
                {
                  id:21,
                  label:texts[lang].questionEighteenChoices[3],
                  point:0,
                }
              ],
              value:[],
            },

            {
              id:18,
              label:texts[lang].questionNineteenLabel,
              select_point:true,
              choices : [
                {
                  id:22,
                  label:texts[lang].trueOrFalseChoices[0],
                  point:0,
                },
                {
                  id:23,
                  label:texts[lang].trueOrFalseChoices[1],
                  point:-1,
                },
                {
                  id:24,
                  label:texts[lang].trueOrFalseChoices[2],
                  point:1,
                },
                {
                  id:25,
                  label:texts[lang].trueOrFalseChoices[3],
                  point:0,
                }
                
              ],
              value:[],
            },

            {
              id:19,
              label:texts[lang].questionTwentyLabel,
              select_point:true,
              choices : [
                {
                  id:26,
                  label:texts[lang].trueOrFalseChoices[0],
                  point:0,
                },
                {
                  id:27,
                  label:texts[lang].trueOrFalseChoices[1],
                  point:1,
                },
                {
                  id:28,
                  label:texts[lang].trueOrFalseChoices[2],
                  point:0,
                },
                {
                  id:29,
                  label:texts[lang].trueOrFalseChoices[3],
                  point:0,
                }
                
              ],
              value:[],
            },

            {
              id:20,
              label:texts[lang].questionTwentyOneLabel,
              select_point:true,
              choices : [
                {
                  id:30,
                  label:texts[lang].trueOrFalseChoices[0],
                  point:0,
                },
                {
                  id:31,
                  label:texts[lang].trueOrFalseChoices[1],
                  point:1,
                },
                {
                  id:32,
                  label:texts[lang].trueOrFalseChoices[2],
                  point:0,
                },
                {
                  id:33,
                  label:texts[lang].trueOrFalseChoices[3],
                  point:0,
                }
                
              ],
              value:[],
            },
            
          ]
        },
        {
          id:2,
          label:texts[lang].stepThreelabel,
          questions:[
            {
              id:21,
              label:texts[lang].questionTwentyTwoLabel,
              checkbox:true,
              subvalue:[],
              choices:[
                texts[lang].questionTwentyTwoChoices[0],
                texts[lang].questionTwentyTwoChoices[1],
                texts[lang].questionTwentyTwoChoices[2],
                texts[lang].questionTwentyTwoChoices[3],
              ],
              value:[],
              
            },

            {
              id:22,
              label:texts[lang].questionTwentyThreeLabel,
              select:true,
              choices:[
                {
                  id:34,
                  label:texts[lang].moneySlices[0],
                  point:0,
                },
                {
                  id:35,
                  label:texts[lang].moneySlices[1],
                  point:0,
                },
                {
                  id:36,
                  label:texts[lang].moneySlices[2],
                  point:0,
                },
                {
                  id:37,
                  label:texts[lang].moneySlices[3],
                  point:0,
                },
                {
                  id:38,
                  label:texts[lang].moneySlices[4],
                  point:0,
                },
              ],
              value:[],
            },

            {
              id:23,
              label:texts[lang].questionTwentyFourLabel,
              select:true,
              choices:[
                {
                  id:39,
                  label:texts[lang].moneySlices[0],
                  point:0,
                },
                {
                  id:40,
                  label:texts[lang].moneySlices[1],
                  point:0,
                },
                {
                  id:41,
                  label:texts[lang].moneySlices[2],
                  point:0,
                },
                {
                  id:42,
                  label:texts[lang].moneySlices[3],
                  point:0,
                },
                {
                  id:43,
                  label:texts[lang].moneySlices[4],
                  point:0,
                },
              ],
              value:[],
            },

            {
              id:24,
              label:texts[lang].questionTwentyFiveLabel,
              checkbox:true,
              subvalue:[],
              choices:[
                texts[lang].questionTwentyFiveChoices[0],
                texts[lang].questionTwentyFiveChoices[1],
                texts[lang].questionTwentyFiveChoices[2],
                texts[lang].questionTwentyFiveChoices[3],
                texts[lang].questionTwentyFiveChoices[4],
                texts[lang].questionTwentyFiveChoices[5],
              ],
              value:[],
            },

            {
              id:25,
              label:texts[lang].questionTwentySixLabel,
              checkbox:true,
              subvalue:[],
              choices:[
                texts[lang].questionTwentySixChoices[0],
                texts[lang].questionTwentySixChoices[1],
                texts[lang].questionTwentySixChoices[2],
                texts[lang].questionTwentySixChoices[3],
              ],
              value:[]
              
            },

            {
              id:26,
              label:texts[lang].questionTwentySevenLabel,
              checkbox:true,
              subvalue:[],
              choices:[
                texts[lang].questionTwentySevenChoices[0],
                texts[lang].questionTwentySevenChoices[1],
                texts[lang].questionTwentySevenChoices[2], 
                texts[lang].questionTwentySevenChoices[3],
                texts[lang].questionTwentySevenChoices[4],
                texts[lang].questionTwentySevenChoices[5],
                texts[lang].questionTwentySevenChoices[6],
                texts[lang].questionTwentySevenChoices[7]
              ],
              value:[]
            },

            {
              id:27,
              label:texts[lang].questionTwentyEightLabel,
              radio:true,
              status:false,
              sublabel:texts[lang].questionTwentyEightSublabel,
              type:"text",
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
              ],
              value:[]
            }
          ]
        },
        {
          id:3,
          label:texts[lang].stepFourlabel,
          questions:[
            {
              id:28,
              label:texts[lang].questionTwentyNineLabel,
              select_point:true,
              choices:[
                {
                  id:44,
                  label:texts[lang].questionTwentyNineChoices[0],
                  point:0,
                },
                {
                  id:45,
                  label:texts[lang].questionTwentyNineChoices[1],
                  point:0,
                },
                {
                  id:46,
                  label:texts[lang].questionTwentyNineChoices[2],
                  point:0,
                },
                {
                  id:47,
                  label:texts[lang].questionTwentyNineChoices[3],
                  point:0,
                },
                {
                  id:48,
                  label:texts[lang].questionTwentyNineChoices[4],
                  point:0,
                },
                {
                  id:49,
                  label:texts[lang].questionTwentyNineChoices[5],
                  point:0,
                },
                {
                  id:87,
                  label:texts[lang].questionTwentyNineChoices[6],
                  point:0,
                },
                {
                  id:88,
                  label:texts[lang].questionTwentyNineChoices[7],
                  point:0,
                },
              ],
              value:[]
            },

            {
              id:29,
              label:"Quel pourcentage de votre épargne représenterait cette somme ?",
              select:true,
              choices:[
                {
                  id:80,
                  label:texts[lang].questionThirtyChoices[0],
                  point:0,
                },
                {
                  id:81,
                  label:texts[lang].questionThirtyChoices[1],
                  point:0,
                },
                {
                  id:82,
                  label:texts[lang].questionThirtyChoices[2],
                  point:0,
                },
                {
                  id:83,
                  label:texts[lang].questionThirtyChoices[3],
                  point:0,
                },
                {
                  id:84,
                  label:texts[lang].questionThirtyChoices[4],
                  point:0,
                },
                {
                  id:84,
                  label:texts[lang].questionThirtyChoices[5],
                  point:0,
                },
                {
                  id:85,
                  label:texts[lang].questionThirtyChoices[6],
                  point:0,
                },
              ],
              value:[]
            },

            // {
            //   id:30,
            //   label:texts[lang].questionThirtyOneLabel,
            //   select:true,
            //   choices:[
            //     {
            //       id:50,
            //       label:texts[lang].questionThirtyOneChoices[0],
            //       point:0,
            //     },
            //     {
            //       id:51,
            //       label:texts[lang].questionThirtyOneChoices[1],
            //       point:0,
            //     },
            //     {
            //       id:52,
            //       label:texts[lang].questionThirtyOneChoices[2],
            //       point:0,
            //     },
            //     {
            //       id:53,
            //       label:texts[lang].questionThirtyOneChoices[3],
            //       point:0,
            //     },
            //     {
            //       id:54,
            //       label:texts[lang].questionThirtyOneChoices[4],
            //       point:0,
            //     },
            //   ],
            //   value:[],
            // },

            {
              id:31,
              label:texts[lang].questionThirtyTwoLabel,
              select:true,
              choices:[
                {
                  id:55,
                  label:texts[lang].questionThirtyTwoChoices[0],
                  point:0,
                },
                {
                  id:56,
                  label:texts[lang].questionThirtyTwoChoices[1],
                  point:0,
                },
                {
                  id:57,
                  label:texts[lang].questionThirtyTwoChoices[2],
                  point:0,
                },
                {
                  id:58,
                  label:texts[lang].questionThirtyTwoChoices[3],
                  point:0,
                },
                {
                  id:59,
                  label:texts[lang].questionThirtyTwoChoices[4],
                  point:0,
                },
              ],
              value:[],
            },

            {
              id:32, 
              label:texts[lang].questionThirtyThreeLabel,
              select_point:true,
              choices:[
                {
                  id:60,
                  label:texts[lang].questionThirtyThreeChoices[0],
                  point:0,
                },
                {
                  id:61,
                  label:texts[lang].questionThirtyThreeChoices[1],
                  point:-1,
                },
                {
                  id:62,
                  label:texts[lang].questionThirtyThreeChoices[2],
                  point:1,
                },
                
              ],
              value:[],
            },

            {
              id:33,
              label:texts[lang].questionThirtyFourLabel,
              select_point:true,
              choices:[
                {
                  id:63,
                  label:texts[lang].questionThirtyFourChoices[0],
                  point:0,
                },
                {
                  id:64,
                  label:texts[lang].questionThirtyFourChoices[1],
                  point:-3
                },
                {
                  id:65,
                  label:texts[lang].questionThirtyFourChoices[2],
                  point:0,
                },
                {
                  id:66,
                  label:texts[lang].questionThirtyFourChoices[3],
                  point: 3
                },
                
              ],
              value:[]
            },

            {
              id:34,
              label:texts[lang].questionThirtyFiveLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },
            
            {
              id:35,
              label:texts[lang].questionThirtySixLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },

            {
              id:36,
              label:texts[lang].questionThirtySevenLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },

            {
              id:37,
              label:texts[lang].questionThirtyEightLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },

            {
              id:38,
              label:texts[lang].questionNewOneLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },
            {
              id:39,
              label:texts[lang].questionNewTwoLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },
            {
              id:40,
              label:texts[lang].questionNewThreeLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },
            {
              id:41,
              label:texts[lang].questionNewFourLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },
            {
              id:42,
              label:texts[lang].questionThirtyNineLabel,
              radio_if:true,
              choices:[
                {
                  id:0,
                  label:texts[lang].yesOrNoChoices[0],
                },
                {
                  id:1,
                  label:texts[lang].yesOrNoChoices[1],
                },
                
              ],
              value:[]
            },

            {
              id:43,
              label:texts[lang].questionFortyLabel,
              checkbox:true,
              subvalue:[],
              choices:[
                texts[lang].questionFortyChoices[0],
                texts[lang].questionFortyChoices[1],
                texts[lang].questionFortyChoices[2],
                ],
              value:[]
            },

            // {
            //   id:40,
            //   label:texts[lang].questionFortyOneLabel,
            //   input:true,
            //   value:[]
            // },

            // {
            //   id:41,
            //   label:"Vous souhaitez investir en société immobilière pour :",
            //   select:true,
            //   choices :[
            //     {
            //       id:0,
            //       label:texts[lang].questionFortyOneLabelChoices[0],
            //       point:0
            //     },
            //     {
            //       id:0,
            //       label:texts[lang].questionFortyOneLabelChoices[1],
            //       point:0
            //     },
            //     {
            //       id:1,
            //       label:texts[lang].questionFortyOneLabelChoices[2],
            //       point:0
            //     },
            //   ],
            //   value:[]
            // }
          ]
        },
        
        {
          id:4,
          label:texts[lang].stepFivelabel,
          class:"-grey",
          questions:[
            {
              id:42,
              parag:true,
              texts:[
                texts[lang].questionFortyTwoLabel[0],
                texts[lang].questionFortyTwoLabel[1],
                texts[lang].questionFortyTwoLabel[2],
                texts[lang].questionFortyTwoLabel[3],
                texts[lang].questionFortyTwoLabel[4],
                texts[lang].questionFortyTwoLabel[5],
                texts[lang].questionFortyTwoLabel[6],
                texts[lang].questionFortyTwoLabel[7],
                texts[lang].questionFortyTwoLabel[8]

              ],
              value:[]
              
              
            },

            {
              id:43,
              checkbox:true,
              subvalue:[],
              choices:[
                texts[lang].questionFortyThreeLabel[0],
                texts[lang].questionFortyThreeLabel[1],
                texts[lang].questionFortyThreeLabel[2],
                texts[lang].questionFortyThreeLabel[3],
              ],
              value:[]
            }
          ]
        }
      ],
      

      profils:[
        {
          id:0,
          label:texts[lang].profileDef,
          texts:[
            texts[lang].profileDefContent[0], 
            texts[lang].profileDefContent[1],
            texts[lang].profileDefContent[2],
            texts[lang].profileDefContent[3],
          ]
        },
        {
          id:1,
          label:texts[lang].profileMod,
          texts:[
            texts[lang].profileModContent[0],
            texts[lang].profileModContent[1],
            texts[lang].profileModContent[2],
            texts[lang].profileModContent[3],

          ]
        },
        {
          id:2,
          label:texts[lang].profileAgr,
          texts:[
            texts[lang].profileAgrContent[0],
            texts[lang].profileAgrContent[1],
            texts[lang].profileAgrContent[2]
          ]
        }
      ],
      recomandations:[
       texts[lang].message[0],
        texts[lang].message[1],
        texts[lang].message[2],
        texts[lang].message[3],
        texts[lang].message[4],
      ],
      currentStep:0,
      is_Ok:false,
      activate:false,
      has_send_form:this.props.user.user.hasDoneInvestQuizz,
      error:false,
      instru_fin:false,
      instru_immo:false,
      message:texts[lang].champObligatoire,
      total:0,
    }
  
  }
  //call api pour approche real-time
  componentDidMount(){
    const  user = this.props.user.user
    const token = this.props.user.token
    const headers = {
      headers: {
        "x-auth-token": token,
      },
     
    }
    // console.log(user)
    axios
     .get(`${urls.apiUrl}/users/${user.id}`, headers)
    
     .then((res)=>{
      // console.log(res.data)
        this.setState({has_send_form: res.data.hasDoneInvestQuizz, total:res.data.note})
        this.context.onSetIsLoading(false);  
     })
     .catch( err => console.log(err.response.data))
  }
    //? on reset la loader : 
    componentWillUnmount(){
      this.context.onSetIsLoading(true);
    }
  //
  setFeedback = ({type, title, message}) => {
    this.props.setFeedback({
      show: true,
      type,
      title,
      message,
      messages: false,
    })
    setTimeout( () => {
      this.props.setFeedback()
    }, 5000);
    // setTimeout(, 5000);
  }
  
  //trouver le step dans lequel (etape des questions)
  findCurrentStep = () => {
    return this.state.steps.find((step) => step.id === this.state.currentStep);
  };
  //fonctions d'increamentations et de decrementation des steps
  incrementStep = () => {
    let step = this.state.currentStep;
    if (step <= this.state.steps.length - 1) {
      step++;
      document.getElementsByClassName("container-small")[0].scrollTo(0, 0) //scroll to top of section
      this.setState({ currentStep: step });
      this.setState({error:false})
    }
  };
  decrementStep = () => {
    let step = this.state.currentStep;
    if (step >= 1) {
      step--;
      document.getElementsByClassName("container-small")[0].scrollTo(0, 0) //scroll to top of section
      this.setState({ currentStep: step });
      this.setState({error:false})
    }
  };

  //fonction pour gerer les CheckBoxs
  handleCheckBoxChange = (id, value, choice) => {
      // console.log(value)
      let step = this.state.steps.find((step) => step.id === this.state.currentStep) //trouver l'objet
      let question = step.questions.find((o)=> o.id === id)
      if (value.target.checked) {
        question.value = [...question.value, choice];
        
      }
      else{
        question.value = question.value.filter((c) => c !== choice);
      }
      if(this.state.steps[4].questions[1].value.length === 4){
        this.setState({is_Ok : true})
        const conditions = this.state.steps[4]
        conditions.class = ""
      }else{
        this.setState({is_Ok : false})
        const conditions = this.state.steps[4]
         conditions.class = "-grey"
      }
      if(question.value.length !== 0)
      {
        question.error = true
      }else{
        question.error = false
      }

      

     
  
      
      this.setState({ });
  
      
    }
  
//fonctions pour gerer les entrées libres (inputs)
  handleInputChange = (id,value)=>{
    

    let step = this.state.steps.find((step) => step.id === this.state.currentStep)
    // let obj = this.state.steps.find((o) => o.id === id)
    let question = step.questions.find((o)=> o.id === id)
    
    question.value =  value
    if(question.value.length !== 0)
    {
      question.error = true
    }else{
      question.error = false
    }
    this.setState ({})
   };

   //fonction pour gerer les inputs checkbox avec appel de input-text en condition
   handleOtherCheckbox = (id,value)=>{
    
    let step = this.state.steps.find((step) => step.id === this.state.currentStep)
    let obj = this.state.steps.find((o) => o.id === id)
    let question = step.questions.find((o)=> o.id === id)
    
    question.subvalue = value;
    // console.log(question.subvalue)

    // console.log(question.value)
    if(question.value.length !== 0)
    {
      question.error = true
    }else{
      question.error = false
    }
    this.setState ({})
   }
   // fonction pour gerer les inputs-radion sans appel de input-text derrière
  handleRadioChange = (id,e) => {
    
    let step = this.state.steps.find((step) => step.id === this.state.currentStep)
    
   
    let question = step.questions.find((o)=>o.id === id)
      const choices = question.choices
        if(e.target.value ==="Oui"){
          question.status = true
          question.instru_fin && this.setState({instru_fin:true})
          question.instru_immo && this.setState({instru_immo:true})
          choices[0].status = true
          choices[1].status = false
          question.value = ""
        }else{
          question.status = false
          question.instru_fin && this.setState({instru_fin:false})
          question.instru_immo && this.setState({instru_immo:false})
          choices[0].status = false
          choices[1].status = true
          question.value = "Non"
        }
        if(question.value.length !== 0)
        {
          question.error = true
        }else{
          question.error = false
        }
        this.setState({})
    }
  // fonction pour gerer les inputs-radion avec appel de input-text derrière
  handleRadioIfChange = (id,e) => {
  let step = this.state.steps.find((step) => step.id === this.state.currentStep)

  // let obj = this.state.steps.find((o) => o.questions.find((i)=>i.id === id))

  let question = step.questions.find((o)=>o.id === id)
  const choices = question.choices
  const res = choices.find((o)=>o.label === e.target.value)
  question.value = res.label
  
  if(question.value.length !== 0)
  {
    question.error = true
  }else{
    question.error = false
  }
  this.setState({})
  
  }
   
// fonction  pour gerer les input-select
  handleSelectChange = (id,value) => { 
    let step = this.state.steps.find((step) => step.id === this.state.currentStep)
    // let obj = this.state.steps.find((o) => o.questions.find((i)=>i.id === id))
    let question = step.questions.find((o)=>o.id === id)
    const choices = question.choices
    const res = choices.find ((o)=>o.id === parseInt(value))
    // console.log (res.point)
   
    
    question.value = res.label
    let tab = ["Marié(e)","Cohabitant légal","PACS"]//*localisation
    if(question.conditional){
    if(tab.includes(question.value)){
      this.setState({activate : true})
    }
    else{
      this.setState({activate : false})
    }
  }
    if(question.value.length !== 0)
    {
      question.error = true
    }else{
      question.error = false
    }
    this.setState({})
    this.setState({total : this.state.total + res.point})
    
  }
  //fonction de soummission du formulaire
  handleSubmit = ()=>{
    // alert(this.state.total)
    let body = {
      investQuizz : this.state.steps,
      note: this.state.total}
    const  user = this.props.user.user
    const token = this.props.user.token
    
    const headers = {
      headers: {
        "x-auth-token": token,
      },
    }
    const { lang } = this.props.parameters;
    axios
    .patch(`${urls.apiUrl}/users/${user.id}`,body,headers)
    .then((res)=>{
      this.setState({has_send_form: true})
      {
        this.setFeedback({
          type: "",
          title: feedTexts[lang].editProfileSuccesTitle,
          message: feedTexts[lang].editProfileSuccesMessage,
        })
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }
//fonction pour activer le statut d'erreur des input-text quand ils sont vides
checkEntry = ()=>{
  this.setState({error:true})
}

  render (){
    const step = this.findCurrentStep();
    const test = step.questions.find((o)=>o.value.length === 0)
    const  user = this.props.user.user
    const {lang} = this.props.parameters


    
    
   
    return(
       !this.state.has_send_form ? // on verifie si il n'a pas encore rempli le questionnaire d'investissement 

      (<div className="layout-profile-container" >
        <div className="dashboard-container">
      <div className = "inv-container dashboard-scontainer -no-padding">
        <div className="form-section-title -simple-2">
          <h4 className="-texte">{texts[lang].questionnaire}</h4>
        </div>
        <div className="security-form-container">
       {step.information ?
        <InfosBar
        classes="-info"
        content={step.information}/>:""}
         
         <p className="step-label">{step.label}</p>
        
      {step.questions.map((o,id)=> 
      ( 
        //les Inputs
        o.input ? 
       <InputText
       key={id}
        label={o.label}
        value={o.value}
        type={o.type}
        min={o.min?o.min:""}
        error ={this.state.error && !o.error ? this.state.message : "" }
        handleInputChange={(e)=>this.handleInputChange(o.id,e.target.value)}
       
        />
        :
        //Les selects
        o.select ? 
        
        <InputSelect
        // value={o.value}
        label={o.label}
        inputs={o.choices}
        error ={this.state.error && !o.error ? this.state.message : "" }
        handleSelectChange={(e)=>this.handleSelectChange(o.id,e.target.value)}
        />
        :
        o.select_point ?
        <InputSelect
        // value={o.value[0]}
        label={o.label}
        inputs={o.choices}
        error ={this.state.error && !o.error ? this.state.message : "" }
        handleSelectChange={(e)=>this.handleSelectChange(o.id,e.target.value)}
        />
        :
        this.state.activate && o.select_if ?
        <InputSelect
        // value={o.value[0]}
        label={o.label}
        inputs={o.choices}
        error ={this.state.error && !o.error ? this.state.message : "" }
        handleSelectChange={(e)=>this.handleSelectChange(o.id,e.target.value)}
        />
        :
        //les radios avec conditions
        o.radio ? 
        <>
        <p className="inv-title">{o.par_label}</p>
        
        {<p className="inv-title">{o.label}</p>}
        {o.choices.map((i,id)=>
        <div key={id}>
        <input className="inv-radio"type="radio" key={i.id} id={i.id} name={o.label} checked={i.status} value={i.label} 
        onChange={(e)=>this.handleRadioChange(o.id,e)}
        />
        {i.label}
        
        </div>)}
        <p className ="-error">{this.state.error && !o.error ? this.state.message : "" }</p>

        {o.status &&
        <InputText
        label={o.sublabel}
        value={o.value}
        type={o.type}
        error ={this.state.error && !o.error ? this.state.message : "" }
        handleInputChange={(e)=>this.handleInputChange(o.id,e.target.value)}
        />}

        {o.substatus && 
        <div key={o.id}>

        <InputText
        label={o.sublabel}
        value={o.value}
        error ={this.state.error && !o.error ? this.state.message : "" }
        handleInputChange={(e) => this.handleInputChange(o.id, e.target.value)} />

        <InputSelect
          label={o.sublabel}
          inputs={o.subchoices}
          error ={this.state.error && !o.error ? this.state.message : "" }
          />

          </div> }
        </>
        :
        //Les radios sans conditions
        o.radio_if ? 
        <>
        
        {<p className="inv-title">{o.label}</p>}
        {o.choices.map((i, id)=>
          <div key={id}>
            <input 
              className="inv-radio" 
              type="radio" 
              key={i.id} 
              id={i.id} 
              name={o.label} 
              value={i.label} 
              checked={i.checked} 
              onChange={(e) => this.handleRadioIfChange(o.id, e)} />
              {i.label}
          </div>)}
          <p className ="-error">{this.state.error && !o.error ? this.state.message : "" }</p>
        
        </>
        :
        //Les Checkboxs
        o.checkbox ?
        <div>
        <p className="inv-title label-checkbox">{o.label}</p>
        <div className="inv-checkbox">{o.choices.map((value,index)=>
        <div key={index}>
        <FormRowCheckbox
        label={value}
        
        isActive={o.value.includes(value)}
        handleCheckboxChange={(e)=>
          this.handleCheckBoxChange(
            o.id,
            e,
            value
          )
        }/>
       
        </div>)
        }</div>
        <p className ="-error">{this.state.error && !o.error ? this.state.message : "" }</p>
        {/* {console.log(o.value)} */}
        {o.value.includes("Autres") &&
        <InputText
        label={texts[lang].questionTenSubLabel}
        value={o.subvalue}
        // error ={this.state.error && !o.error ? this.state.message : "" }
        handleInputChange={(e) => this.handleOtherCheckbox(o.id, e.target.value)} />
        }
        </div>
        :
        //les chexkboxs conditionnels
        this.state.instru_fin && o.checkbox_fin ?
        <div>
        <p className="inv-title label-checkbox">{o.label}</p>
        <div className="inv-checkbox">{o.choices.map((value,index)=>
        <div key={index}>
        <FormRowCheckbox
        label={value}
        
        isActive={o.value.includes(value)}
        handleCheckboxChange={(e)=>
          this.handleCheckBoxChange(
            o.id,
            e,
            value
          )
        }/></div>)
        }</div>
        <p className ="-error">{this.state.error && !o.error ? this.state.message : "" }</p>
        {o.value.includes(texts[lang].questionThirteenChoices[8]) &&
        <InputText
        label={texts[lang].questionTenSubLabel}
        value={o.subvalue}
        // error ={this.state.error && !o.error ? this.state.message : "" }
        handleInputChange={(e) => this.handleOtherCheckbox(o.id, e.target.value)} />
        }
        </div>
        :
        this.state.instru_immo && o.checkbox_immo ?
        <div>
        <p className="inv-title label-checkbox">{o.label}</p>
        <div className="inv-checkbox">{o.choices.map((value,index)=>
        <div key={index}>
        <FormRowCheckbox
        label={value}
        
        isActive={o.value.includes(value)}
        handleCheckboxChange={(e)=>
          this.handleCheckBoxChange(
            o.id,
            e,
            value
          )
        }/></div>)
        }</div>
        <p className ="-error">{this.state.error && !o.error ? this.state.message : "" }</p>
        
        </div>
        :
       //les textes
        o.parag  ?
        <>
        {o.texts.map((i,index)=>
         <p dangerouslySetInnerHTML={{__html: i}} key={index} className="parag"></p>
         )}</>
        :
        ""
      )
       
      )
     }
    {/* Les bouttons  (step et envoi du form)*/}
    <div className={`form-footer ${this.state.currentStep < 1 ? "-first-step" : ""}`}>
      
  
      <Button
        isLoading={false}
        label={texts[lang].btnPrec}
        classes="btn -left -grey"
        icon
        type="button"
        handleClick={this.decrementStep}
      />
      <Button
        isLoading={false}
        label={this.state.currentStep <this.state.steps.length-1 ? texts[lang].btnNext : texts[lang].btnSubm}
        classes={step.class ? `btn ${step.class}` : "btn"}
        icon
        
        type="button"
        handleClick={
          !test && this.state.currentStep <this.state.steps.length-1 ?this.incrementStep
          : 
          this.state.is_Ok ? this.handleSubmit : 
          this.checkEntry}
      />
    </div>
   
    
    
      </div>
      </div>
       </div>
      </div>) 
      :
      //sinon on affiche son profl d'investisseur 
     <div className="layout-profile-container">
       <div className="dashboard-container">
        <div className = "inv-container dashboard-scontainer ">
        <div className="form-section-title">
          <h4 className="-texte">{texts[lang].votreProfile}</h4>
        </div>
        <h1 className="-profil">
          {
          user.note <= 5 ? this.state.profils[0].label
          : 
          user.note<=10 && user.note >= 6 ? this.state.profils[1].label 
          : 
          this.state.profils[2].label
          }  
        </h1>
          {
          user.note <= 5 ? this.state.profils[0].texts.map((text,id)=><p dangerouslySetInnerHTML={{__html: text}} key={id} className="parag"></p>)
          : 
          user.note<=10 && user.note >= 6 ? this.state.profils[1].texts.map((text,id)=><p dangerouslySetInnerHTML={{__html: text}}  key={id} className="parag"></p>)
          : 
          this.state.profils[2].texts.map((text,id)=><p dangerouslySetInnerHTML={{__html: text}}key={id} className="parag"></p>)
          }  

      <Link className="-link" to ="/invest"> 
      <Button
        isLoading={false}
        label={texts[lang].labelBtnInvestir}
        classes="btn"
        icon
        type="button"
        style={{
          width:300
        }}
        // handleClick={}
      />
      </Link >   
        
        </div>
        </div>
        <div className="dashboard-container">
        <div className = "inv-container dashboard-scontainer">
        <div className="form-section-title">
          <h4 className="-texte">{texts[lang].nosReconmendations} </h4>
        </div>
        {this.state.recomandations.map((text, id)=><p key={id} className="parag" dangerouslySetInnerHTML={{__html: text}}></p>)}

        <Link className="-link" to ="//viziocapital.com/disclaimer">{texts[lang].goToDisc}</Link>
        </div>
        </div>
      </div>)
    
  }

}
const mapStateToProps = state => ({
  parameters: state.persisedReducer.parameters,
  user: state.persisedReducer.user,
});
const actions = {
  setUser,
  setFeedback,
}
export default connect(mapStateToProps, actions)(FirstInvestementView)