import IconChevronRight from "./icons/icon-chevron-right"


const Button = ({classes, isLoading, label, icon, type, style ,handleClick, disable,}) => {
  return (
    <div className={`btn ${classes ? classes : ""} ${disable ? "-disable" : ""} `} style={style ? style : {}} >
      {
        !disable
        ? <button onClick={handleClick} type={type}>
            {icon && !isLoading && <IconChevronRight />}
            {isLoading ? <span className="dual-spinner"></span> : label }
          </button>
        : <button type="button">{label}</button>
      }
    </div>
  )
}

export default Button

