import InputSelect from "../../components/inputSelect";
import InputText from "../../components/inputText";
import FormRowCheckbox from "../../components/formRowCheckbox";
import FormRow from "../../components/formRow";
import FormRowRadio from "../../components/formRowRadio";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";

import texts from "../../texts/apportAffaire" 
import urls from "../../constants/urls";
import Button from "../../components/button";

import { LoaderContext } from "../../context/Context";
import { useLayoutEffect, useContext } from 'react';
const BecomeCommercialView = () => {
  const loader = useContext(LoaderContext);
  const { user,token} = useSelector(state => state.persisedReducer.user);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)

  const headers = {
    headers : {
      "x-auth-token": token
    }
  }
  useLayoutEffect(() => {
    axios.get(`${urls.apiUrl}/reseau/parrain/${user.id}`,headers)
    .then((res) => {
      loader.onSetIsLoading(false);
      let users = res.data.filter((user) => {
        return user.depth === 0;

      });
      // console.log(users)
      setLeveDirect(users[0].totalLeveeReseau);
      console.log(user.hasSentCommercialRequest);

      
      if(users[0].totalLeveeReseau <100000 || user.hasSentCommercialRequest){
        setRedirect(true)
      }
      
    }).catch((err)=>(console.log(err)))

    return()=>{
      loader.onSetIsLoading(true);
    }
  }, []);
  // console.log(user.id)
  const nivEtude = [
    {
      id: texts[lang].niveauEtudeChoices[0],
      label: texts[lang].niveauEtudeChoices[0],
      value: texts[lang].niveauEtudeChoices[0],
    },
    {
      id: texts[lang].niveauEtudeChoices[1],
      label: texts[lang].niveauEtudeChoices[1],
      value: texts[lang].niveauEtudeChoices[1],
    },

    {
      id: texts[lang].niveauEtudeChoices[2],
      label: texts[lang].niveauEtudeChoices[2],
      value: texts[lang].niveauEtudeChoices[2],
    },

    {
      id: texts[lang].niveauEtudeChoices[3],
      label: texts[lang].niveauEtudeChoices[3],
      value: texts[lang].niveauEtudeChoices[3],
    },

    {
      id: texts[lang].niveauEtudeChoices[4],
      label: texts[lang].niveauEtudeChoices[4],
      value: texts[lang].niveauEtudeChoices[4],
    },

    {
      id: texts[lang].niveauEtudeChoices[5],
      label: texts[lang].niveauEtudeChoices[5],
      value: texts[lang].niveauEtudeChoices[5],
    },

    {
      id: texts[lang].niveauEtudeChoices[6],
      label: texts[lang].niveauEtudeChoices[6],
      value: texts[lang].niveauEtudeChoices[6],
    },

    {
      id: texts[lang].niveauEtudeChoices[7],
      label: texts[lang].niveauEtudeChoices[7],
      value: texts[lang].niveauEtudeChoices[7],
    },

    {
      id: texts[lang].niveauEtudeChoices[8],
      label: texts[lang].niveauEtudeChoices[8],
      value: texts[lang].niveauEtudeChoices[8],
    },

    {
      id: texts[lang].niveauEtudeChoices[9],
      label: texts[lang].niveauEtudeChoices[9],
      value: texts[lang].niveauEtudeChoices[9],
    },

    {
      id: texts[lang].niveauEtudeChoices[10],
      label: texts[lang].niveauEtudeChoices[10],
      value: texts[lang].niveauEtudeChoices[10],
    },
  ];
  const permis = [
    {
      id: texts[lang].yesOrNoChoices[0],
      label: texts[lang].yesOrNoChoices[0],
      value: texts[lang].yesOrNoChoices[0],
    },
    {
      id: texts[lang].yesOrNoChoices[1],
      label: texts[lang].yesOrNoChoices[1],
      value: texts[lang].yesOrNoChoices[1],
    },
    {
      id: texts[lang].yesOrNoChoices[2],
      label: texts[lang].yesOrNoChoices[2],
      value: texts[lang].yesOrNoChoices[2],
    },
  ];

  const vehicule = [
    {
      id: texts[lang].vehiculeChoices[0],
      label: texts[lang].vehiculeChoices[0],
      value: texts[lang].vehiculeChoices[0],
    },
    {
      id: texts[lang].vehiculeChoices[1],
      label: texts[lang].vehiculeChoices[1],
      value: texts[lang].vehiculeChoices[1],
    },
    {
      id: texts[lang].vehiculeChoices[2],
      label: texts[lang].vehiculeChoices[2],
      value: texts[lang].vehiculeChoices[2],
    },
    {
      id: texts[lang].vehiculeChoices[3],
      label: texts[lang].vehiculeChoices[3],
      value: texts[lang].vehiculeChoices[3],
    },
  ];
  const formationFinance = [
    {
      id: texts[lang].yesOrNoChoices[0],
      label: texts[lang].yesOrNoChoices[0],
      value: texts[lang].yesOrNoChoices[0],
    },
    {
      id: texts[lang].yesOrNoChoices[1],
      label: texts[lang].yesOrNoChoices[1],
      value: texts[lang].yesOrNoChoices[1],

    },
    {
      id: texts[lang].yesOrNoChoices[2],
      label: texts[lang].yesOrNoChoices[2],
      value: texts[lang].yesOrNoChoices[2],
    },
  ];
  const tempsFormation = [
    {
      id: texts[lang].yesOrNoChoices[0],
      label: texts[lang].yesOrNoChoices[0],
      value: texts[lang].yesOrNoChoices[0],
    },
    {
      id: texts[lang].yesOrNoChoices[1],
      label: texts[lang].yesOrNoChoices[1],
      value: texts[lang].yesOrNoChoices[1],

    },
    {
      id: texts[lang].yesOrNoChoices[2],
      label: texts[lang].yesOrNoChoices[2],
      value: texts[lang].yesOrNoChoices[2],
    },
  ];
  const tempsPasserSemaineFormation = [
    {
    
      id: texts[lang].tempsPasserSemaineFormationChoices[0],
      label: texts[lang].tempsPasserSemaineFormationChoices[0],
      value: texts[lang].tempsPasserSemaineFormationChoices[0],
    },
    {
      id: texts[lang].tempsPasserSemaineFormationChoices[1],
      label: texts[lang].tempsPasserSemaineFormationChoices[1],
      value: texts[lang].tempsPasserSemaineFormationChoices[1],
    },
    {
      id: texts[lang].tempsPasserSemaineFormationChoices[2],
      label: texts[lang].tempsPasserSemaineFormationChoices[2],
      value: texts[lang].tempsPasserSemaineFormationChoices[2],
    },
    {
      id: texts[lang].tempsPasserSemaineFormationChoices[3],
      label: texts[lang].tempsPasserSemaineFormationChoices[3],
      value: texts[lang].tempsPasserSemaineFormationChoices[3],
    },
    {
      id: texts[lang].tempsPasserSemaineFormationChoices[4],
      label: texts[lang].tempsPasserSemaineFormationChoices[4],
      value: texts[lang].tempsPasserSemaineFormationChoices[4],
    },
    {
      id: texts[lang].tempsPasserSemaineFormationChoices[5],
      label: texts[lang].tempsPasserSemaineFormationChoices[5],
      value: texts[lang].tempsPasserSemaineFormationChoices[5],
    },
  ];
  const statusEntrepreneur = [
    {
      id: texts[lang].yesOrNoChoices[0],
      label: texts[lang].yesOrNoChoices[0],
      value: texts[lang].yesOrNoChoices[0],
    },
    {
      id: texts[lang].yesOrNoChoices[1],
      label: texts[lang].yesOrNoChoices[1],
      value: texts[lang].yesOrNoChoices[1],

    },
    {
      id: texts[lang].yesOrNoChoices[2],
      label: texts[lang].yesOrNoChoices[2],
      value: texts[lang].yesOrNoChoices[2],
    },
  ];

  const statusPref = [
    {
      id: texts[lang].statusPrefChoices[0],
      label: texts[lang].statusPrefChoices[0],
      value: texts[lang].statusPrefChoices[0],
    },
    {
      id: texts[lang].statusPrefChoices[1],
      label: texts[lang].statusPrefChoices[1],
      value: texts[lang].statusPrefChoices[1],
    },
    {
      id: texts[lang].statusPrefChoices[2],
      label: texts[lang].statusPrefChoices[2],
      value: texts[lang].statusPrefChoices[2],
    },
    { 
      id: texts[lang].statusPrefChoices[3],
      label: texts[lang].statusPrefChoices[3],
      value: texts[lang].statusPrefChoices[3],
    },
    {
      id: texts[lang].statusPrefChoices[4],
      label: texts[lang].statusPrefChoices[4],
      value: texts[lang].statusPrefChoices[4],
    },
    {
      id: texts[lang].statusPrefChoices[5],
      label: texts[lang].statusPrefChoices[5],
      value: texts[lang].statusPrefChoices[5],
    },
    {
      id: texts[lang].statusPrefChoices[6],
      label: texts[lang].statusPrefChoices[6],
      value: texts[lang].statusPrefChoices[6],
      
    },
  ];

  const transmittion = [
    {
      id: texts[lang].transmissionChoices[0],
      label: texts[lang].transmissionChoices[0],
      value: texts[lang].transmissionChoices[0],
    },
    {
      id: texts[lang].transmissionChoices[1],
      label: texts[lang].transmissionChoices[1],
      value: texts[lang].transmissionChoices[1],
    },
    {
      id: texts[lang].transmissionChoices[2],
      label: texts[lang].transmissionChoices[2],
      value: texts[lang].transmissionChoices[2],
    },
    {
      id: texts[lang].transmissionChoices[3],
      label: texts[lang].transmissionChoices[3],
      value: texts[lang].transmissionChoices[3],
    },
    {
      id: texts[lang].transmissionChoices[4],
      label: texts[lang].transmissionChoices[4],
      value: texts[lang].transmissionChoices[4],

    },
    {
      id: texts[lang].transmissionChoices[5],
      label: texts[lang].transmissionChoices[5],
      value: texts[lang].transmissionChoices[5],
    },
    {
      id: texts[lang].transmissionChoices[6],
      label: texts[lang].transmissionChoices[6],
      value: texts[lang].transmissionChoices[6],
    },
    {
      id: texts[lang].transmissionChoices[7],
      label: texts[lang].transmissionChoices[7],
      value: texts[lang].transmissionChoices[7],
    },
    {
      id: texts[lang].transmissionChoices[8],
      label: texts[lang].transmissionChoices[8],
      value: texts[lang].transmissionChoices[8],
    },
    {
      id: texts[lang].transmissionChoices[9],
      label: texts[lang].transmissionChoices[9],
      value: texts[lang].transmissionChoices[9],
    },
  ];

  const temperament = [
    {
      id: texts[lang].temperamentChoices[0],
      label: texts[lang].temperamentChoices[0],
      value: texts[lang].temperamentChoices[0],
    },
    {
      id: texts[lang].temperamentChoices[1],
      label: texts[lang].temperamentChoices[1],
      value: texts[lang].temperamentChoices[1],

    },

    {
      id: texts[lang].temperamentChoices[2],
      label: texts[lang].temperamentChoices[2],
      value: texts[lang].temperamentChoices[2],
    },
    {
      id: texts[lang].temperamentChoices[3],
      label: texts[lang].temperamentChoices[3],
      value: texts[lang].temperamentChoices[3],
    },
    {
      id: texts[lang].temperamentChoices[4],
      label: texts[lang].temperamentChoices[4],
      value: texts[lang].temperamentChoices[4],
    },
    {
      id: texts[lang].temperamentChoices[5],
      label: texts[lang].temperamentChoices[5],
      value: texts[lang].temperamentChoices[5],
    },
    {
      id: texts[lang].temperamentChoices[6],
      label: texts[lang].temperamentChoices[6],
      value: texts[lang].temperamentChoices[6],
    },
    {
      id: texts[lang].temperamentChoices[7],
      label: texts[lang].temperamentChoices[7],
      value: texts[lang].temperamentChoices[7],
    },
    {
      id: texts[lang].temperamentChoices[8],
      label: texts[lang].temperamentChoices[8],
      value: texts[lang].temperamentChoices[8],
    },
    {
      id: texts[lang].temperamentChoices[9],
      label: texts[lang].temperamentChoices[9],
      value: texts[lang].temperamentChoices[9],
    },
    {
      id: texts[lang].temperamentChoices[10],
      label: texts[lang].temperamentChoices[10],
      value: texts[lang].temperamentChoices[10],
    },
    {
      id: texts[lang].temperamentChoices[11],
      label: texts[lang].temperamentChoices[11],
      value: texts[lang].temperamentChoices[11],
    },
    {
      id: texts[lang].temperamentChoices[12],
      label: texts[lang].temperamentChoices[12],
      value: texts[lang].temperamentChoices[12],
    },
  ];
  const activite = [
    {
      id: texts[lang].activiteChoices[0],
      label: texts[lang].activiteChoices[0],
      value: texts[lang].activiteChoices[0],
    },
    {
      id: texts[lang].activiteChoices[1],
      label: texts[lang].activiteChoices[1],
      value: texts[lang].activiteChoices[1],
    },

    {
      id: texts[lang].activiteChoices[2],
      label: texts[lang].activiteChoices[2],
      value: texts[lang].activiteChoices[2],
    },
    {
      id: texts[lang].activiteChoices[3],
      label: texts[lang].activiteChoices[3],
      value: texts[lang].activiteChoices[3],
    },
    {
      id: texts[lang].activiteChoices[4],
      label: texts[lang].activiteChoices[4],
      value: texts[lang].activiteChoices[4],
    },
    {
      id: texts[lang].activiteChoices[5],
      label: texts[lang].activiteChoices[5],
      value: texts[lang].activiteChoices[5],
    },
    {
      id: texts[lang].activiteChoices[6],
      label: texts[lang].activiteChoices[6],
      value: texts[lang].activiteChoices[6],
    },
  ];

  // niveau d'etude
  const [nivEtudeValue, setNivEtudeValue] = useState("");
  // proffession
  const [profession, setProfession] = useState("");
  // permis
  const [permisValue, setPermisValue] = useState("");
  // vehicule
  const [vehiculeValue, setVehiculeValue] = useState("");
  // formation suivis
  const [formation, setFormation] = useState("");
  const [formationContent, setFormationContent] = useState("");
  // temps pconssacrer formation
  const [TempsValue, setTempsValue] = useState("");
  const [cbTempsValue, setCbTempsValue] = useState("");

  // temps semaine pour vizio
  const [CdTempsSemVizioValue, setCdTempsSemVizioValue] = useState("");
  // langue parler
  const [langueParler, setLangueParler] = useState([
    {
      id: 1,
      label: texts[lang].langueChoices[0],
      value: false,
    },
    {
      id: 2,
      label: texts[lang].langueChoices[1],
      value: false,
    },
    {
      id: 3,
      label: texts[lang].langueChoices[2],
      value: false,
    },
    {
      id: 4,
      label: texts[lang].langueChoices[3],
      value: false,
    },
    {
      id: 5,
      label: texts[lang].langueChoices[4],
      value: false,
    },
    {
      id: 6,
      label: texts[lang].langueChoices[5],
      value: false,
    },
    {
      id: 7,
      label: texts[lang].langueChoices[6],
      value: false,
    },
  ]);
  const [valuelangueParler,setValuelangueParler] =useState([])

  // motivation
  const [motivationValue, setMotivationValue] = useState("");
  // status entrepreuneur
  const [statusEntValue, setStatusEntValue] = useState("");
  // status préferé
  const [statusPrefValue, setStatusPrefValue] = useState("");
  const [autreStatusPref, setAutreStatusPref] = useState("");
  //ressource apporter a vizio
  const [ressourceApp, setRessourceApp] = useState("");
  //passion
  const [passionsValue, setPassionsValue] = useState([
    {
      id: 1,
      label: texts[lang].passionChoices[0],
      value: false,
    },
    {
      id: 2,
      label: texts[lang].passionChoices[1],
      value: false,
    },
    {
      id: 3,
      label: texts[lang].passionChoices[2],
      value: false,
    },
    {
      id: 4,
      label: texts[lang].passionChoices[3],
      value: false,
    },
    {
      id: 5,
      label: texts[lang].passionChoices[4],
      value: false,
    },
    {
      id: 6,
      label: texts[lang].passionChoices[5],
      value: false,
    },
    {
      id: 7,
      label: texts[lang].passionChoices[6],
      value: false,
    },
    {
      id: 8,
      label: texts[lang].passionChoices[7],
      value: false,
    },
    {
      id: 9,
      label: texts[lang].passionChoices[8],
      value: false,
    },
    {
      id: 10,
      label: texts[lang].passionChoices[9],
      value: false,
    },
    {
      id: 11,
      label: texts[lang].passionChoices[10],
      value: false,
    },
  ]);
  const [valuePassion,setValuePassion] =useState([])
  const [autrePassions, setAutrePassions] = useState("");
  // Ce que je souhaite transmettre à mes enfants / proches :
  const [transmetttreValue, setTransmettreValue] = useState("");
  const [autreTrans, setAutreTrans] = useState("");
  // Quel est mon tempérament ?
  const [temperamentValue, setTemperamentValue] = useState("");
  const [autretemp, setAutretemp] = useState("");
  // Si je devais changer ou apporter quelque chose à ce monde
  const [changement, setChangement] = useState("");
  // Qu’est ce qui pourrait m’effrayer ou me déplaire dans ce genre d’activité :
  const [deplaire, setDeplaire] = useState("");
  const [autreDeplaire, setAutreDeplaire] = useState("");
  const [donneManquant,setDonneManquant] = useState(false)
  const [redirectToAP, setRedirectToAP] = useState(false);
  const [leveDirect, setLeveDirect] = useState(0)

  const [redirect,setRedirect] = useState(false)

 

  

  // console.log(leveDirect)
 



  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  // niveau d'etude
  const handleNivEtude = (e) => {
    setNivEtudeValue(e.target.value);
  };

  // proffession
  const handleProfession = (e) => {
    setProfession(e.target.value);
  };

  // permis
  const handlePermis = (e) => {
    setPermisValue(e.target.value);
  };

  // vehicule
  const handleVehicile = (e) => {
    setVehiculeValue(e.target.value);
  };

  // formation suivis
  const handleFormation = (e) => {
    setFormation(e.target.value);
  };
  const handleFormationContent = (e) => {
    setFormationContent(e.target.value);
  };

  //temps pconssacrer formation
  const handleTime = (e) => {
    setTempsValue(e.target.value);
  };
  const handleCbTime = (e) => {
    setCbTempsValue(e.target.value);
  };

  // temps semaine pour vizio
  const handleTimePerWeek = (e) => {
    setCdTempsSemVizioValue(e.target.value);
  };
  //Langue parler
  const handleMyLangueChange = (e, id) => {
    const langues = langueParler;
    langues[id - 1].value = e.target.checked;
    var checkedValues = langues.filter((o)=>
    (o.value===true)
    )
    const checkedValue =checkedValues.map((c)=>c.label)
    setValuelangueParler(checkedValue)
    setLangueParler([...langues]);
    console.log(langues)
  };
  // motivation
  const handleMotivation = (e) => {
    setMotivationValue(e.target.value);
  };

  // status entrepreuneur
  const handleStatusEntrepreneur = (e) => {
    setStatusEntValue(e.target.value);
  };

  // status préferé
  const handleStatusPreferer = (e) => {
    setStatusPrefValue(e.target.value);
  };
  const handleAutreStatusPref = (e) => {
    setAutreStatusPref(e.target.value);
  };
  //
  const handleRessource = (e) => {
    setRessourceApp(e.target.value);
  };

  ///
  const handleMyPassionChange = (e, id) => {
    const passions = passionsValue;
    passions[id - 1].value = e.target.checked;
    var checkedValues = passions.filter((o)=>
    (o.value===true)
    )
    const checkedValue =checkedValues.map((c)=>c.label)
    setValuePassion(checkedValue)
    setPassionsValue([...passions]);
  };

  ///
  const handleTransmettre = (e) => {
    setTransmettreValue(e.target.value);
  };
  const handleAutreTransmettre = (e) => {
    setAutreTrans(e.target.value);
  };
  ///
  const handleTemperament = (e) => {
    setTemperamentValue(e.target.value);
  };
  const handleAutretemperament = (e) => {
    setAutretemp(e.target.value);
  };
  ///
  const handleApporterChangement = (e) => {
    setChangement(e.target.value);
  };
  ///
  const handleDepl = (e) => {
    setDeplaire(e.target.value);
  };
  const handleAutreDepl = (e) => {
    setAutreDeplaire(e.target.value);
  };
  const handleRedirectAP = () => {
    ;
  };

  let navigate = useNavigate()

  const handleFormSubmit =(e)=>{
    e.preventDefault()
    var headers = {
      headers: {
        "x-auth-token": token,  
      }}
      //setting des headers : 
      headers ={...headers, "x-auth-token" : token}

    //preparation du body : 
    const body =
          {
            idUser:user.id,
            userLastName:user.lastname,
            userFirstName:user.firstname,
            userBirthday:user.dateOfBirth,
            userNivEtude:nivEtudeValue,
            userProf : profession,
            userPermis: permisValue,
            userVehicule: vehiculeValue,
            userFormation:formation,
            userFormationSuivis: formationContent!=null?formationContent:"",
            userTempsFormation:TempsValue,
            userCbTempsFormation:cbTempsValue!=null?cbTempsValue:"",
            userCbTimeVizioWeek:CdTempsSemVizioValue,
            userLangueParler:valuelangueParler,
            userMotivation: motivationValue,
            userEntStatus:statusEntValue,
            userPrefStatus:statusPrefValue,
            userAutreStatus:autreStatusPref!=null?autreStatusPref:"",
            userRessourceApporter:ressourceApp,
            userPassion:valuePassion,
            userTransmettre:transmetttreValue,
            userAutreTransm:autreTrans!=null?autreTrans:"",
            userTemperament:temperamentValue,
            userAutreTemper:autretemp!=null?autretemp:"",
            userChangement:changement,
            userDeplaire:deplaire,
            userAutreDeplaire:autreDeplaire!=null?autreDeplaire:"",
            montantLevee:leveDirect
        
          }
        
        // ("Selectionner une reponse" || null)
            if ( 
              nivEtudeValue === ("" || texts[lang].niveauEtudeChoices[0]) ||
              profession  === "" ||
              permisValue === ("" || texts[lang].yesOrNoChoices[0]) ||
              vehiculeValue === ("" || "Selectionner une reponse") ||
              formation === ("" || texts[lang].yesOrNoChoices[0]) ||
              // formationContent === "" ||
              TempsValue === ("" || texts[lang].yesOrNoChoices[0]) ||
              // cbTempsValue === "" ||
              CdTempsSemVizioValue === "" ||
              valuelangueParler ===valuelangueParler.length ===0||
              motivationValue === "" ||
              statusEntValue === ("" || texts[lang].yesOrNoChoices[0]) ||
              statusPrefValue === ("" || "Selectionner une reponse") ||
              // autreStatusPref ==="" ||
              ressourceApp === "" ||
              valuePassion === valuePassion.length === 0 ||
              transmetttreValue === ("" || "Selectionner une reponse") ||
              // autreTrans ===""||
              temperamentValue === ("" || "Selectionner une reponse") ||
              // autretemp ===""||
              changement === "" ||
              deplaire === ("" || "Selectionner une reponse") 
              // autreDeplaire ===""
            ){setDonneManquant (true)}
            else
            {
              let result =  window.confirm(texts[lang].continuerBtn)
              if(result === true){
              setDonneManquant (false)
              axios.post(`${urls.apiUrl}/request`, body, {headers})
              .then(
                setRedirectToAP(true)
              )   
          
          }
    
    }
    }

  
  //==========================================================e
    // console.log(redirectToAP)
  if(redirectToAP) return <Navigate to={"/apport-affaire"} replace={true}/>;



  if(redirect) return <Navigate to={"/apport-affaire"} replace={true}/>
  return (
    <>
        
      <div className="layout-profile-container">
        <div className="dashboard-container">
          <div className="dashboard-scontainer">
          
          { donneManquant === true && <p className="error">{texts[lang].errorMsg}</p>}

            <form className="form-container" onSubmit= {handleFormSubmit}>
              <div className="form-section-title " >
                <p>{texts[lang].commercialFormLabel}</p>

              </div>

              <FormRow classes="-two">
                <InputSelect
                  label={texts[lang].niveauEtudeLabel}
                  inputs={nivEtude.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={nivEtudeValue}
                  handleSelectChange={(e) => {
                    handleNivEtude(e);
                  }}
                />
                <InputText
                  label={texts[lang].professionLabel}
                  value={profession}
                  handleInputChange={(e) => {
                    handleProfession(e);
                  }}
                  max={100}
                />
              </FormRow>

              <FormRow classes="-two">
                <InputSelect
                  label={texts[lang].permisLabel}
                  inputs={permis.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={permisValue}
                  handleSelectChange={(e) => {
                    handlePermis(e);
                  }}
                />
                <InputSelect
                  label={texts[lang].vehiculeLabel}
                  inputs={vehicule.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={vehiculeValue}
                  handleSelectChange={(e) => {
                    handleVehicile(e);
                  }}
                />
              </FormRow>

              <FormRow classes="-two">
                <InputSelect
                  label={
                    texts[lang].formationLabel
                  }
                  inputs={formationFinance.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={formation}
                  handleSelectChange={(e) => {
                    handleFormation(e);
                  }}
                />
                {formation === texts[lang].yesOrNoChoices[1] && (
                  <InputText
                    label={texts[lang].formationSubLabel}
                    value={formationContent}
                    handleInputChange={(e) => {
                      handleFormationContent(e);
                    }}
                    max={200}
                  />
                )}

                <InputSelect
                  label={texts[lang].tempsConsacrerFormLabel}
                  inputs={tempsFormation.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={TempsValue}
                  handleSelectChange={(e) => {
                    handleTime(e);
                  }}
                />
                {TempsValue === texts[lang].yesOrNoChoices[1] && (
                  <InputSelect
                    label={texts[lang].tempsConsacrerFormSubLabel}
                    value={cbTempsValue}
                    inputs={tempsPasserSemaineFormation.map((n) => {
                      return { id: n.id, label: n.label };
                    })}
                    handleSelectChange={(e) => {
                      handleCbTime(e);
                    }}
                  />
                )}
              </FormRow>
              <FormRow classes="-One">
                <InputSelect
                  label={texts[lang].tempsConsacrerFormSemaineLabel}
                  inputs={tempsPasserSemaineFormation.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={CdTempsSemVizioValue}
                  handleSelectChange={(e) => {
                    handleTimePerWeek(e);
                  }}
                />
              </FormRow>
              <FormRow classes="-One">
                <div className="-checkbox">
                  <p>{texts[lang].langueLabel}  </p>
                  {langueParler.map((n) => {
                    return (
                      <FormRowCheckbox
                        isActive={n.value}
                        label={n.label}
                        handleCheckboxChange={(event) =>
                          handleMyLangueChange(event, n.id)
                        }
                      />
                    );
                  })}
                </div>
              </FormRow>
              <FormRow classes="-One">
                <InputText
                  label={texts[lang].motivationLabel}
                  value={motivationValue}
                  handleInputChange={(e) => {
                    handleMotivation(e);
                  }}
                  max={500}
                />
              </FormRow>
              <FormRow classes="-two">
                <InputSelect
                  label={texts[lang].statusLabel}
                  inputs={statusEntrepreneur.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={statusEntValue}
                  handleSelectChange={(e) => {
                    handleStatusEntrepreneur(e);
                  }}
                  error={statusEntValue === "Selectionner une reponse"}
                />
                <InputSelect
                  label={texts[lang].statusPrefLabel}
                  inputs={statusPref.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={statusPrefValue}
                  handleSelectChange={(e) => {
                    handleStatusPreferer(e);
                  }}
                />
              </FormRow>
              {statusPrefValue === texts[lang].commonVariables[2] && (
                <FormRow classes="-One">
                  <InputText
                    label={texts[lang].statusPrefSubLabel}
                    value={autreStatusPref}
                    handleInputChange={(e) => {
                      handleAutreStatusPref(e);
                    }}
                    max={100}
                  />
                </FormRow>
              )}
              <FormRow classes="-One">
                <InputText
                  label={texts[lang].ressourceApportéLabel}
                  value={ressourceApp}
                  handleInputChange={(e) => {
                    handleRessource(e);
                  }}
                  max={200}
                />
              </FormRow>
              <FormRow classes="-One">
                <div className="-checkbox">
                  <p>{texts[lang].passionLabel}</p>
                  {passionsValue.map((n) => {
                    return (
                      <FormRowCheckbox
                        label={n.label}
                        isActive={n.value}
                        handleCheckboxChange={(event) =>
                          handleMyPassionChange(event, n.id)
                        }
                      />
                    );
                  })}
                </div>
              </FormRow>
              <FormRow classes="-One">
                <InputSelect
                  label={texts[lang].transmissionLabel}
                  inputs={transmittion.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={transmetttreValue}
                  handleSelectChange={(e) => {
                    handleTransmettre(e);
                  }}
                />
              </FormRow>
              {transmetttreValue === texts[lang].commonVariables[2] && (
                <FormRow classes="-One">
                  <InputText
                    label={texts[lang].transmissionSubLabel}
                    value={autreTrans}
                    handleInputChange={(e) => {
                      handleAutreTransmettre(e);
                    }}
                    max={100}
                  />
                </FormRow>
              )}

              <FormRow classes="-One">
                <InputSelect
                  label={texts[lang].temperamentLabel}
                  inputs={temperament.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={temperamentValue}
                  handleSelectChange={(e) => {
                    handleTemperament(e);
                  }}
                />
              </FormRow>
              {temperamentValue === texts[lang].commonVariables[2] && (
                <FormRow classes="-One">
                  <InputText
                    label={texts[lang].temperamentSubLabel}
                    value={autretemp}
                    handleInputChange={(e) => {
                      handleAutretemperament(e);
                    }}
                    max={100}
                  />
                </FormRow>
              )}

              <FormRow classes="-One">
                <InputText
                  label={texts[lang].changerLeMondeLabel}
                  value={changement}
                  handleInputChange={(e) => {
                    handleApporterChangement(e);
                  }}
                />
              </FormRow>
              <FormRow classes="-One">
                <InputSelect
                  label={texts[lang].activitelabel}
                  inputs={activite.map((n) => {
                    return { id: n.id, label: n.label };
                  })}
                  value={deplaire}
                  handleSelectChange={(e) => {
                    handleDepl(e);
                  }}
                />
              </FormRow>
              {deplaire === texts[lang].commonVariables[2] && (
                <FormRow classes="-One">
                  <InputText
                    label={texts[lang].activiteSublabel}
                    value={autreDeplaire}
                    handleInputChange={(e) => {
                      handleAutreDepl(e);
                    }}
                    max={100}
                  />
                </FormRow>
              )}
                  <Button
                  classes = '-tiny'
                  type="submit"
                  label={texts[lang].deposerDemande}
                  icon />         
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BecomeCommercialView;
// export default AddHugeInvestor
