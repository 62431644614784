import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { setUser, setToken } from "../redux/store";

import InformationTemplate from "../components/informationTemplate";
import IconMailNotValide from '../components/icons/icon-mail-not-valide';
import IconMailValide from "../components/icons/icon-mail-valide";

import LoadinTemplate from "../components/loadingTemplate";

import urls from '../constants/urls';

import texts from "../texts/mailValidation"; 
import { useRef } from "react";
import { LoaderContext } from "../context/Context";
import { useLayoutEffect, useContext } from 'react';

const MailValidationView = () => {
  const loader = useContext(LoaderContext);
  const { token } = useParams();
  const { lang } = useSelector(state => state.persisedReducer.parameters);

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const shouldFech = useRef(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();


  useLayoutEffect(() => {

    async function validateMail() {

      try {

        const res = await axios.patch(`${urls.apiUrl}/mail/${token}`);
        const userToken = res.headers["x-auth-token"];
        const user = res.data;
        
        dispatch(setToken(userToken));
        dispatch(setUser(user));
        navigate("/sumsub", { replace: true})

      }catch(err) {
        if ( err.response.status === 400 ) setError(1);
        else setError(2);
      } finally {
        loader.onSetIsLoading(false);
        // setIsLoading(false);
      }

    }

    if ( shouldFech.current ) {
      shouldFech.current = false;
      if (!token || token.length <= 0) navigate("/connexion", { replace: true});
      else validateMail();
    }
    return()=>{
      loader.onSetIsLoading(true);
    }

  }, []);

  const getContent = (error, texts) => {

    if (error && error === 1) return {
      icon: <IconMailValide />,
      texts: {
        title: texts[lang].alreadyValidateTitle,
        parag: texts[lang].alreadyValidateParag,
      }
    }
    else if (error && error === 2) return {
      icon : <IconMailNotValide />,
      class: "-error",
      texts: {
        title: texts[lang].errorTitle,
        parag: texts[lang].errorParag,
      }
    }
    else return {
      icon : <IconMailValide />,
      texts: {
        title: texts[lang].title,
        parag: texts[lang].error,
      }
    }
  }

  const content = getContent(error, texts);

  return (
    isLoading
    ? <LoadinTemplate />
    : <InformationTemplate
      icon={content.icon}
      classes={content.class}
      title={content.texts.title}
      parag={content.texts.parag}
    />
  )
}


export default MailValidationView;