import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import {
  editFieldProfile,
  setFeedback,
  prepopulateEditProfileForm,
} from "../../redux/store";

import EditProfileFormPersonal from "../../components/editProfile/editProfileFormPersonal";
import EditProfileFormProfessional from "../../components/editProfile/editProfileFormProfessional";
import EditProfileFormContacts from "../../components/editProfile/editProfileFormContacts";

import InfosBar from "../../components/infosBar";
import FormRowCheckbox from "../../components/formRowCheckbox";
import Button from "../../components/button";

import regexes from "../../constants/regex";
import urls from "../../constants/urls";

import texts from "../../texts/registration";
import feedTexts from "../../texts/feedback";

import { getPayloadObject, checkInput } from "../../helpers/registration";
import ParrainForm from "../../components/editProfile/parrainForm";

import { LoaderContext } from "../../context/Context";

const styleCheckBox = {
  marginBottom: 20,
  marginTop: 50,
};

class DashboardEditProfile extends Component {
  static contextType = LoaderContext;
  constructor(props) {
    super(props);
    this.shouldFetch = React.createRef();
    this.shouldFetch.current = true;
    this.state = {
      isLoading: false,
      timeOut: 0,
    };
  }

  componentDidMount = () => {
    if (this.shouldFetch.current) {
      this.shouldFetch.current = false;

      const { user, token } = this.props.user;
      const headers = { headers: { "x-auth-token": token } };

      axios
        .get(`${urls.apiUrl}/users/${user.id || user._id}`, headers)
        .then((res) => {
          this.props.prepopulateEditProfileForm(res.data)
          this.context.onSetIsLoading(false);  
        })
        .catch((err) => console.log(err.response.data));
    }
  };
  componentWillUnmount = ()=>{
    this.context.onSetIsLoading(true);
  }

  handleSimpleInputChange = (event, name) => {
    this.props.editFieldProfile({
      name,
      field: {
        error: false,
        value: event.target.checked,
      },
    });
  };

  handleInputChange = ({ event, regex, errorText, name }) => {
    const value = event.target.value;
    const error = checkInput(
      value,
      regex,
      texts[this.props.parameters.lang].errorEmpryField,
      errorText
    );
    const payload = getPayloadObject(name, value, error);
    this.props.editFieldProfile(payload);
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    if (this.state.isLoading) return false;

    const { payload, error } = this.checkInputs();

    if (!error) {
      const { user, token } = this.props.user;
      const { lang } = this.props.parameters;

      this.setState({ isLoading: true });

      if (user && token) {
        const headers = { headers: { "x-auth-token": token } };
        axios
          .patch(
            `${urls.apiUrl}/users/${user.id || user._id}`,
            payload,
            headers
          )
          .then((res) => {
            this.setFeedback({
              type: "",
              title: feedTexts[lang].editProfileSuccesTitle,
              message: feedTexts[lang].editProfileSuccesMessage,
            });
          })
          .catch((err) => {
            this.setFeedback({
              type: "error",
              title: feedTexts[lang].editProfileErrorTitle,
              message: feedTexts[lang].editProfileErrorMessage,
            });
          })
          .finally(() => this.setState({ isLoading: false }));
      }
    }
  };

  checkInputs = () => {
    const { lang } = this.props.parameters;
    const errors = [];
    let errorText = false;

    const {
      lastName,
      firstName,
      date,
      nativeCountry,
      telNum,
      residenceCountry,
      postalAddress,
      city,
      postalCode,
      isContacts,
      contacts,
      isProfessionnel,
      socialDenomination,
      companyNumber,
      headquartersAdress,
      companyPostalCode,
      municipality,
      companyCountry,
    } = this.props.editeProfileForm;

    // Check lastName
    errorText = checkInput(
      lastName.value,
      regexes.default,
      texts[lang].errorEmpryField,
      texts[lang].errorLastName
    );
    if (errorText)
      errors.push(getPayloadObject("lastName", lastName.value, errorText));

    // Check firstName
    errorText = checkInput(
      firstName.value,
      regexes.default,
      texts[lang].errorEmpryField,
      texts[lang].errorFirstName
    );
    if (errorText)
      errors.push(getPayloadObject("firstName", firstName.value, errorText));

    // Check date
    errorText = checkInput(
      date.value,
      regexes.default,
      texts[lang].errorEmpryField,
      texts[lang].errorDate
    );
    if (errorText) errors.push(getPayloadObject("date", date.value, errorText));

    // Check native country
    errorText = checkInput(
      nativeCountry.value,
      regexes.default,
      texts[lang].errorEmpryField,
      texts[lang].errorNativeCountry
    );
    if (errorText)
      errors.push(
        getPayloadObject("nativeCountry", nativeCountry.value, errorText)
      );

    // Check phone
    errorText = checkInput(
      telNum.value,
      regexes.phone,
      texts[lang].errorEmpryField,
      texts[lang].errorTelNum
    );
    if (errorText)
      errors.push(getPayloadObject("telNum", telNum.value, errorText));

    // Check postal adress
    errorText = checkInput(
      postalAddress.value,
      regexes.default,
      texts[lang].errorEmpryField,
      texts[lang].errorPostalAddress
    );
    if (errorText)
      errors.push(
        getPayloadObject("postalAddress", postalAddress.value, errorText)
      );

    // Check postal code
    errorText = checkInput(
      postalCode.value,
      regexes.postalCode,
      texts[lang].errorEmpryField,
      texts[lang].errorPostalCode
    );
    if (errorText)
      errors.push(getPayloadObject("postalCode", postalCode.value, errorText));

    // Check city
    errorText = checkInput(
      city.value,
      regexes.default,
      texts[lang].errorEmpryField,
      texts[lang].errorCity
    );
    if (errorText) errors.push(getPayloadObject("city", city.value, errorText));

    // Check residence country
    errorText = checkInput(
      residenceCountry.value,
      regexes.default,
      texts[lang].errorEmpryField,
      texts[lang].errorResidenceCountry
    );
    if (errorText)
      errors.push(
        getPayloadObject("residenceCountry", residenceCountry.value, errorText)
      );

    if (isProfessionnel.value) {
      // Check social denomination
      errorText = checkInput(
        socialDenomination.value,
        regexes.default,
        texts[lang].errorEmpryField,
        texts[lang].errorSocialDenominations
      );
      if (errorText)
        errors.push(
          getPayloadObject(
            "socialDenomination",
            socialDenomination.value,
            errorText
          )
        );

      // Check company number
      errorText = checkInput(
        companyNumber.value,
        regexes.default,
        texts[lang].errorEmpryField,
        texts[lang].errorCompanyNumber
      );
      if (errorText)
        errors.push(
          getPayloadObject("companyNumber", companyNumber.value, errorText)
        );

      // Check social denomination
      errorText = checkInput(
        headquartersAdress.value,
        regexes.default,
        texts[lang].errorEmpryField,
        texts[lang].errorHeadquartersAdress
      );
      if (errorText)
        errors.push(
          getPayloadObject(
            "headquartersAdress",
            headquartersAdress.value,
            errorText
          )
        );

      // Check company postalCode
      errorText = checkInput(
        companyPostalCode.value,
        regexes.postalCode,
        texts[lang].errorEmpryField,
        texts[lang].errorCompanyPostalCode
      );
      if (errorText)
        errors.push(
          getPayloadObject(
            "companyPostalCode",
            companyPostalCode.value,
            errorText
          )
        );

      // Check company postalCode
      errorText = checkInput(
        municipality.value,
        regexes.default,
        texts[lang].errorEmpryField,
        texts[lang].errorMunicipality
      );
      if (errorText)
        errors.push(
          getPayloadObject("municipality", municipality.value, errorText)
        );

      // Check company postalCode
      errorText = checkInput(
        companyCountry.value,
        regexes.default,
        texts[lang].errorEmpryField,
        texts[lang].errorCountry
      );
      if (errorText)
        errors.push(
          getPayloadObject("companyCountry", companyCountry.value, errorText)
        );
    }

    // Check if add contacts is checked end has contacts
    if (isContacts.value && contacts.length < 1) {
      errors.push(getPayloadObject("isContacts", isContacts.value, true));
    }

    if (errors.length) {
      errors.forEach((error) => this.props.editFieldProfile(error));
      return {
        error: true,
        payload: false,
      };
    } else
      return {
        error: false,
        payload: {
          type: isProfessionnel.value ? "Entreprise" : "Particulier",
          lastname: lastName.value,
          firstname: firstName.value,
          dateOfBirth: new Date(date.value),
          address: postalAddress.value,
          zipCode: postalCode.value,
          country: nativeCountry.value,
          phone: telNum.value,
          city: city.value,
          fiscalCountry: residenceCountry.value,
          entreprise: isProfessionnel.value
            ? {
                name: socialDenomination.value,
                address: headquartersAdress.value,
                city: municipality.value,
                country: companyCountry.value,
                companyNumber: companyNumber.value,
                zipCode: companyPostalCode.value,
              }
            : false,
          personeToContact: this.getContacts(contacts),
        },
      };
  };

  getContacts = (contacts) => {
    return contacts.map((contact) => ({
      firstname: contact.firstname,
      lastname: contact.lastname,
      email: contact.email,
      address: contact.address,
      zipCode: contact.zipCode,
      city: contact.city,
      country: contact.country,
      phone: contact.phone,
    }));
  };

  setFeedback = ({ type, title, message }) => {
    this.props.setFeedback({
      show: true,
      type,
      title,
      message,
      messages: false,
    });
    setTimeout(this.props.setFeedback, 5000);
  };

  render() {
    const { lang } = this.props.parameters;
    const { isContacts, isProfessionnel } = this.props.editeProfileForm;

    return (
      <div className="dashboard-container">
        <div className="dashboard-scontainer">
          <form onSubmit={this.onFormSubmit}>
            <InfosBar
              content={
                texts[lang].editUserNotice
              }
              classes="-info"
              style={{
                marginBottom: 40,
              }}
            />

            {/* Personnal Informations */}
            <EditProfileFormPersonal
              texts={texts[lang]}
              handleInputChange={this.handleInputChange}
            />
            <ParrainForm/>

            {/* Contacts Informations */}
            {/* <FormRowCheckbox
              isActive={isContacts.value}
              error={isContacts.error}
              style={styleCheckBox}
              label={texts[lang].labelIsContacts}
              // handleCheckboxChange={(event) => this.handleSimpleInputChange(event, "isContacts")}
              handleCheckboxChange={()=>{}}
            /> */}

            {/* Ccntacts */}
            {isContacts.error && (
              <InfosBar content={texts[lang].errorContacts} classes="-error" />
            )}
            {isContacts.value && (
              <EditProfileFormContacts texts={texts[lang]} />
            )}

            {/* Professional Informations */}
            {/* On ne permet pas a un utilisateur de changer son type, si il c'est enregister en tans que particulier. il a effectuer les KYC et non les KYB ! */}
            {/* Si un changement de status (particulier -> professionnel devais ce produire, il faudrai une fonctionalité hors de ce formulaire) */}
            {/* <FormRowCheckbox
              isActive={isProfessionnel.value}
              error={isProfessionnel.error}
              style={styleCheckBox}
              label={texts[lang].labelIsProfessionnel}
              handleCheckboxChange={(event) => this.handleSimpleInputChange(event, "isProfessionnel")}
            /> */}
            {/* 
            {
              isProfessionnel.value &&
              <EditProfileFormProfessional
                texts={texts[lang]}
                handleInputChange={this.handleInputChange}
              />
            }

            <Button
              label={texts[lang].labelBtnEditProfile}
              classes="-small"
              handleClick={this.handleAddUser}
              isLoading={this.state.isLoading}
              icon
              style={{
                marginTop: 60,
              }}
            /> */}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  parameters: state.persisedReducer.parameters,
  user: state.persisedReducer.user,
  editeProfileForm: state.notPersisedReducer.editeProfileForm,
});

const actions = {
  editFieldProfile,
  setFeedback,
  prepopulateEditProfileForm,
};

export default connect(mapStateToProps, actions)(DashboardEditProfile);
